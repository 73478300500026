import React from 'react';
import "../../global.js";

export class GridDocument extends React.Component {
    truncate = (str, n) => {
        return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    };

    render() {
        let url = '/docs/part' + this.props.parent_id + '/' + this.props.id;
        return (
        <>
	    <div className="document_tpl_card">
		    <a href={url} title={this.props.name}>
		        <span className="card-body__box">
			        <h4 className="card-body__title">{this.props.name}</h4>
			        <p className="card-body__description">{this.truncate(this.props.description, 150)}</p>
			    </span>
			    <span className="card-body__link-open">Открыть документ</span>
			    <img src="/assets/images/icons-document.svg" />
			</a>
		</div>
        </>
        );
    }
}
export default GridDocument;