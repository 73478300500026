import React from 'react';
import './resourceviewer.css'
//import NavMenu from "../../components/header-nav/NavMenu";
import HeaderNavigation from "../../templates/tmp1/chunk/header_navigation";
//import {Link} from "react-router-dom";
import MediaQuery from 'react-responsive';
class Header extends React.Component {

    render(){
        let token = localStorage.getItem('tokenAPI');
        let classDiv = '';
        if(token){
            classDiv = 'toppanel-wrapper-overview innerpage';
        }
        return (
            <>
            <MediaQuery minWidth={768}>
                <HeaderNavigation />
            </MediaQuery>
            <MediaQuery maxWidth={768}>
                <></>
            </MediaQuery>
            {/*}
                <div className={classDiv}>
                    <NavMenu/>
                </div>
                {token &&
                <div className="uk-container-expand">

                    <div className="data-uk-parallax  parallax_wrapper" data-uk-grid-margin>
                        <section id="main_content" className="uk-container uk-container-center">

                            <section
                                className="elementor-section elementor-top-section elementor-element elementor-element-3887a3e elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                data-id="3887a3e" data-element_type="section"
                                data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                                <div className="elementor-container elementor-column-gap-no">
                                    <div className="elementor-row">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2c4c128"
                                            data-id="2c4c128" data-element_type="column">
                                            <div className="elementor-column-wrap elementor-element-populated">
                                                <div className="elementor-widget-wrap elementor-widget-wrap-0">
                                                    <div
                                                        className="elementor-element elementor-element-3e66355 gt3_moved_element elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-html"
                                                        data-id="3e66355" data-element_type="widget"
                                                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                                                        data-widget_type="html.default">
                                                        <div className="elementor-widget-container">
                                                            <img width="122" height="124"
                                                                 src="assets/images/parallax/cross2.png"
                                                                 className="attachment-full size-full" alt=""
                                                                 loading="lazy"
                                                                 title="triangle"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-widget-wrap elementor-widget-wrap-1">
                                                    <div
                                                        className="elementor-element elementor-element-588453c gt3_rotated_element elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image"
                                                        data-id="588453c" data-element_type="widget"
                                                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                                                        data-widget_type="image.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="elementor-image">
                                                                <img width="122" height="124"
                                                                     src="https://livewp.site/wp/md/ewebot/wp-content/uploads/sites/64/2019/12/triangle_orange.png"
                                                                     className="attachment-full size-full" alt=""
                                                                     loading="lazy" title="triangle"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-widget-wrap elementor-widget-wrap-2">
                                                    <div
                                                        className="elementor-element elementor-element-dd59b49 gt3_rotated_element elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-html"
                                                        data-id="dd59b49" data-element_type="widget"
                                                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                                                        data-widget_type="html.default">
                                                        <div className="elementor-widget-container">
                                                            <img width="122" height="124"
                                                                 src="assets/images/parallax/cross2.png"
                                                                 className="attachment-full size-full" alt=""
                                                                 loading="lazy"
                                                                 title="triangle"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-widget-wrap elementor-widget-wrap-3">
                                                    <div
                                                        className="elementor-element elementor-element-8a4f5ad gt3_rotated_element elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image"
                                                        data-id="8a4f5ad" data-element_type="widget"
                                                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                                                        data-widget_type="image.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="elementor-image">
                                                                <img width="122" height="124"
                                                                    src="https://livewp.site/wp/md/ewebot/wp-content/uploads/sites/64/2019/12/triangle_blue.png"
                                                                    className="attachment-full size-full"
                                                                    alt=""
                                                                    loading="lazy"
                                                                    title="triangle"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-widget-wrap elementor-widget-wrap-4">
                                                    <div
                                                        className="elementor-element elementor-element-8e6876c gt3_moved_element elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-html"
                                                        data-id="8e6876c" data-element_type="widget"
                                                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                                                        data-widget_type="html.default">
                                                        <div className="elementor-widget-container">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="33px"
                                                                 height="33px">
                                                                <path fill="rgb(87, 230, 193)"
                                                                      d="M11.927,6.550 L8.279,7.527 L9.256,11.175 C9.464,11.950 9.004,12.746 8.229,12.954 C7.454,13.162 6.658,12.702 6.450,11.927 L5.473,8.279 L1.825,9.257 C1.050,9.464 0.254,9.004 0.046,8.229 C-0.162,7.454 0.298,6.658 1.073,6.450 L4.721,5.473 L3.744,1.825 C3.536,1.050 3.996,0.254 4.771,0.046 C5.546,-0.161 6.342,0.298 6.550,1.073 L7.527,4.721 L11.175,3.744 C11.950,3.536 12.746,3.996 12.954,4.771 C13.162,5.545 12.702,6.342 11.927,6.550 Z"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>


                            <div className="features-wrapper">
                                <a href="/call" className="features-item features-el-1 flip-card ancher">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">ЭКСТРЕННЫЙ ВЫЗОВ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">Наши специалисты в сети 24/7<br/>обеспечат
                                                    защиту ваших прав
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flip-card-back">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">ЭКСТРЕННЫЙ ВЫЗОВ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">Наши специалисты в сети 24/7<br/>обеспечат
                                                    защиту ваших прав
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="/chat/" className="features-item features-el-1 flip-card ancher">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front features-dark">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">МОИ ОБРАЩЕНИЯ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">Здесь хранятся все переписки, звонки, видео доступные для просмотра.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flip-card-back">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">МОИ ОБРАЩЕНИЯ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">Здесь хранятся все переписки, звонки, видео доступные для просмотра.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <Link to="/situation/" className="features-item features-el-1 flip-card ancher">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front features-lightblue">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">СИТУАЦИИ И РЕКОМЕНДАЦИИ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">Примеры распространённых экстренных ситуаций (задержания,
арест, вызовы) и рекомендации по поведению в них.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flip-card-back">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">СИТУАЦИИ И РЕКОМЕНДАЦИИ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">Примеры распространённых экстренных ситуаций (задержания,
арест, вызовы) и рекомендации по поведению в них.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/docs/" className="features-item features-el-1 flip-card ancher">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front features-blue">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">ОБРАЗЦЫ ДОКУМЕНТОВ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">В этом разделе вы можете найти образцы заявлений, ходатайств,
договоров и других юридических документов.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flip-card-back">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">ОБРАЗЦЫ ДОКУМЕНТОВ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">В этом разделе вы можете найти образцы заявлений, ходатайств,
договоров и других юридических документов.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </section>
                    </div>
                </div>
                                                */}
            </>
        );
    }
}
export default Header;