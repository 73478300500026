import React from 'react';
//import "./mainpage.css";
import {Link} from "react-router-dom";

class MainPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            news: [],

        };
    }

    componentDidMount() {
        let limit = 3;
        let url = global.apiLink + 'news/list?page=1&limit='+limit;
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === 200) {
                        this.setState({
                            news: result.payload.news,
                            countPage:result.payload.countPage
                        });
                    }
                }
            );
    }


    render(){
        
    
    let newsList = null;
    const {news} = this.state;
    var MonthArray=[
           'ЯНВ',
           'ФЕВ',
           'МАР',
           'АПР',
           'МАЙ',
           'ИЮН',
           'ИЮЛ',
           'АВГ',
           'СЕН',
           'НОЯ',
           'ДЕК',
        ];
        
    newsList = news.map((element) => {

        let imageLink = "/assets/images/no-image.png";
        if(element.file)imageLink = global.apiLink + 'uploads/' + element.file.path + '/' + element.file.name;
        
        let dateDB = new Date(element.created_at);
        let CreatedDay = ('00' + dateDB.getUTCDate()).slice(-2);
        let CreatedMonth = ('00' + (dateDB.getUTCMonth()+1)).slice(-2);
        let CreatedYear = dateDB.getUTCFullYear();

        return (<>
        
        
        <div className="uk-width-medium-1-3 news-content">
                <div className="news-item">
                    <Link to={"/news/"+element.alias} className="news-item-image" style={{
                        background:'url(' + imageLink + ')',
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        display: "block",
                        position: "relative",
                    }}
                    ></Link>
                    <div className="news-item-date">{CreatedDay}<div className="news-item-date-month">{MonthArray[CreatedMonth-1]}</div><div className="news-item-date-yaer">{CreatedYear}</div></div>
                    <div className="news-item-shield">
                        <Link className="news-item-title" to={"/news/"+element.alias}>{element.title}</Link>
                        <div className="news-item-description">
                            {element.anonce_text}
                        </div>
                    </div>
                </div>
        </div>
    </>);
});
        return (
            <>
                <div className="uk-container-expand mainpage">
                    <div className="data-uk-parallax  parallax_wrapper" data-uk-grid-margin>
                        <div id="scene" data-relative-input="true">
                            <img src="assets/images/parallax/_0.png" data-depth='0.1' className="img-1 uk-hidden-small" />
                            <img src="assets/images/parallax/_1.png" data-depth='0.5' className="img-2 uk-hidden-small" />
                            <img src="assets/images/parallax/_2.png" data-depth='0.2' className="img-3 uk-hidden-small" />
                            <img src="assets/images/parallax/_3.png" data-depth='0.1' className="img-4 uk-hidden-small" />
                            <img src="assets/images/parallax/fix_bg.jpg" className="img-fix-bg uk-hidden-medium uk-hidden-large uk-hidden-xlarge uk-hidden-2xlarge" />
                        </div>

                        <section id="main_content" className="uk-container uk-container-center">
                            <div data-elementor-type="wp-page" data-elementor-id="3425" className="elementor elementor-3425" data-elementor-settings="[]" style={{width: "100%"}}>
                                <div className="elementor-inner">
                                    <div className="elementor-section-wrap" style={{width: "100%"}}>
                                        <section className="elementor-section elementor-top-section elementor-element elementor-element-3887a3e elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="3887a3e" data-element_type="section" data-settings='{"background_background":"classNameic"}' >
                                            <div className="elementor-container elementor-column-gap-no">
                                                <div className="elementor-row">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2c4c128" data-id="2c4c128" data-element_type="column">
                                                        <div className="elementor-column-wrap elementor-element-populated">
                                                            <div className="elementor-widget-wrap elementor-widget-wrap-0">
                                                                <div className="elementor-element elementor-element-3e66355 gt3_moved_element elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-html" data-id="3e66355" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="html.default">
                                                                    <div className="elementor-widget-container">
                                                                        <img width="122"  height="124"
                                                                             src="assets/images/parallax/cross2.png"
                                                                             className="attachment-full size-full" alt="" loading="lazy"
                                                                             title="triangle" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-widget-wrap elementor-widget-wrap-1">
                                                                <div className="elementor-element elementor-element-588453c gt3_rotated_element elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="588453c" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                                                                    <div className="elementor-widget-container">
                                                                        <div className="elementor-image">
                                                                            <img width="122"  height="124" src="https://livewp.site/wp/md/ewebot/wp-content/uploads/sites/64/2019/12/triangle_orange.png" className="attachment-full size-full" alt="" loading="lazy" title="triangle" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-widget-wrap elementor-widget-wrap-2">
                                                                <div className="elementor-element elementor-element-dd59b49 gt3_rotated_element elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-html" data-id="dd59b49" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="html.default">
                                                                    <div className="elementor-widget-container">
                                                                        <img width="122"  height="124"
                                                                             src="assets/images/parallax/cross2.png"
                                                                             className="attachment-full size-full" alt="" loading="lazy"
                                                                             title="triangle" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-widget-wrap elementor-widget-wrap-3">
                                                                <div className="elementor-element elementor-element-8a4f5ad gt3_rotated_element elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="8a4f5ad" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                                                                    <div className="elementor-widget-container">
                                                                        <div className="elementor-image">
                                                                            <img width="122"  height="124" src="https://livewp.site/wp/md/ewebot/wp-content/uploads/sites/64/2019/12/triangle_blue.png" className="attachment-full size-full" alt="" loading="lazy" title="triangle" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-widget-wrap elementor-widget-wrap-4">
                                                                <div className="elementor-element elementor-element-8e6876c gt3_moved_element elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-html" data-id="8e6876c" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="html.default">
                                                                    <div className="elementor-widget-container">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="33px" height="33px">
                                                                            <path fill="rgb(87, 230, 193)" d="M11.927,6.550 L8.279,7.527 L9.256,11.175 C9.464,11.950 9.004,12.746 8.229,12.954 C7.454,13.162 6.658,12.702 6.450,11.927 L5.473,8.279 L1.825,9.257 C1.050,9.464 0.254,9.004 0.046,8.229 C-0.162,7.454 0.298,6.658 1.073,6.450 L4.721,5.473 L3.744,1.825 C3.536,1.050 3.996,0.254 4.771,0.046 C5.546,-0.161 6.342,0.298 6.550,1.073 L7.527,4.721 L11.175,3.744 C11.950,3.536 12.746,3.996 12.954,4.771 C13.162,5.545 12.702,6.342 11.927,6.550 Z"></path>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>



                            <div className="text-container">
                                <div className="scene-text-title">Персональный юрист</div>
                                <div className="scene-text-content">Онлайн консультант по правовым вопросам<br />
                                    24/7 прямо из вашего кармана</div>
                                <div className="scene-button-container">
                                    <a href="/" className="uk-button uk-button-primary uk-button-large" >Узнать больше</a>
                                </div>
                            </div>
                            <div className="clear"></div>



                            <div className="features-wrapper">
                                <a href="/call" className="features-item features-el-1 flip-card ancher">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">ЭКСТРЕННЫЙ ВЫЗОВ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">Наши специалисты в сети 24/7<br/>обеспечат
                                                    защиту ваших прав
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flip-card-back">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">ЭКСТРЕННЫЙ ВЫЗОВ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">Наши специалисты в сети 24/7<br/>обеспечат
                                                    защиту ваших прав
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="/chat/" className="features-item features-el-1 flip-card ancher">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front features-dark">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">МОИ ОБРАЩЕНИЯ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">Здесь хранятся все переписки, звонки, видео доступные для просмотра.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flip-card-back">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">МОИ ОБРАЩЕНИЯ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">Здесь хранятся все переписки, звонки, видео доступные для просмотра.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <Link to="/situation/" className="features-item features-el-1 flip-card ancher">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front features-lightblue">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">СИТУАЦИИ И РЕКОМЕНДАЦИИ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">Примеры распространённых экстренных ситуаций (задержания,
арест, вызовы) и рекомендации по поведению в них.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flip-card-back">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">СИТУАЦИИ И РЕКОМЕНДАЦИИ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">Примеры распространённых экстренных ситуаций (задержания,
арест, вызовы) и рекомендации по поведению в них.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/docs/" className="features-item features-el-1 flip-card ancher">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front features-blue">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">ОБРАЗЦЫ ДОКУМЕНТОВ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">В этом разделе вы можете найти образцы заявлений, ходатайств,
договоров и других юридических документов.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flip-card-back">
                                            <div className="features-margin">
                                                <div className="features-title uk-h3">ОБРАЗЦЫ ДОКУМЕНТОВ</div>
                                                <div className="features-hr"></div>
                                                <div className="features-introtext">В этом разделе вы можете найти образцы заявлений, ходатайств,
договоров и других юридических документов.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </section>
                    </div>
                </div>


                <div id="alltimeaccess" className="uk-container uk-container-center uk-margin-large-bottom">
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-2">
                            <div className="data-uk-parallax " data-uk-grid-margin>
                                <div id="why_important_1" data-relative-input="true">
                                    <img src="assets/images/whyimportant/1_1.png" data-depth='0.1'  className="wi_img-1-1 uk-hidden-small uk-hidden-medium" />
                                    <img src="assets/images/whyimportant/1_2.png" data-depth='0.1'  className="wi_img-1-2 uk-hidden-small uk-hidden-medium" />
                                    <img src="assets/images/whyimportant/1_3.png" data-depth='0.2'  className="wi_img-1-3 uk-hidden-small uk-hidden-medium" />
                                    <img src="assets/images/whyimportant/1_1.png" className="wi_img-fix uk-hidden-medium uk-hidden-large uk-hidden-xlarge uk-hidden-2xlarge" />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-medium-1-2">
                            <div className="uk-h6 color-lightblue">ПОЧЕМУ ЭТО ВАЖНО</div>
                            <div className="uk-h2">ПОСТОЯННЫЙ ДОСТУП</div>
                            <div className="features-hr color-lightblue"></div>
                            <div className="uk-text-large">
                                <p>Система «Мой Адвокат. Экстренная помощь» позволяет круглосуточно получить юридическую помощь как онлайн, так и с помощью телефонного звонка и электронной почты. Наши специалисты проконсультируют Вас в течение 5 минут. Защита будет оказана в срочном порядке, когда дело не терпит отлагательств. Особенно это важно в экстренных случаях.</p>
                                <p>Для урегулирования конфликтов в той или иной сфере нет необходимости тратить время на посещение юридических консультаций ‒ справку по любым юридическим вопросам можно получить, не выходя из дома или офиса, имея под рукой компьютер, ноутбук или телефон.</p>
                                <p>Наши специалисты в режиме онлайн окажут не только устную консультацию и информационную поддержку с конкретными рекомендациями по решению Ваших проблем, но и помогут проанализировать или составить любой юридический документ и направить его на указанный электронный адрес. Обращаясь к нашим специалистам, вы экономите и время, и финансовые ресурсы.</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="uk-container-expand services-wrapper">
                    <div className="uk-container uk-container-center">
                        <div className="uk-grid">
                            <div className="uk-width-medium-1-1 services-title-wrapper">
                                <div className="uk-h1 uk-text-center services-header-title">ОСНОВНЫЕ УСЛУГИ КОРОТКО</div>
                                <div className="uk-container-center all-hr color-lightblue"></div>
                            </div>
                            <div className="uk-width-medium-1-1">
                                <div className="uk-grid">
                                    <div className="uk-width-medium-1-2 services-item">
                                        <div className="services-image"><i className="fas fa-chart-bar"></i></div>
                                        <div className="uk-float-left"></div>
                                        <div className="services-title">Оперативная юридическая помощь</div>
                                        <div className="uk-float-left"></div>
                                        <div className="services-introtext">Maecenas elementum sapien in metus placerat finibus.Lorem ipsum dolor sit amet, consectetuer </div>
                                    </div>
                                    <div className="uk-width-medium-1-2 services-item">
                                        <div className="services-image"><i className="fas fa-chart-bar"></i></div>
                                        <div className="services-title">Первая услуга интересная</div>
                                        <div className="uk-float-left"></div>
                                        <div className="services-introtext">Maecenas elementum sapien in metus placerat finibus.Lorem ipsum dolor sit amet, consectetuer</div>
                                    </div>
                                    <div className="uk-width-medium-1-2 services-item">
                                        <div className="services-image"><i className="fas fa-chart-bar"></i></div>
                                        <div className="services-title">Первая услуга интересная</div>
                                        <div className="uk-float-left"></div>
                                        <div className="services-introtext">Maecenas elementum sapien in metus placerat finibus.Lorem ipsum dolor sit amet, consectetuer</div>
                                    </div>
                                    <div className="uk-width-medium-1-2 services-item">
                                        <div className="services-image"><i className="fas fa-chart-bar"></i></div>
                                        <div className="services-title">Первая услуга интересная</div>
                                        <div className="uk-float-left"></div>
                                        <div className="services-introtext">Maecenas elementum sapien in metus placerat finibus.Lorem ipsum dolor sit amet, consectetuer</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="pocketlawyer" className="uk-container uk-container-center uk-margin-large-top uk-margin-large-bottom">
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-2">
                            <div className="uk-h6 color-lightblue uk-margin-large-top">ПОЧЕМУ ЭТО ВАЖНО</div>
                            <div className="uk-h2">АДВОКАТ "В КАРМАНЕ"</div>
                            <div className="features-hr color-lightblue"></div>
                            <div className="uk-text-large">
                                <p>«Мой Адвокат. Экстренная помощь» — это мобильное приложение для операционных систем IOS и Android, а также для Windows. Правовая система представляет собой дистанционный сервис на основе современных IT-технологий. Имея под рукой ноутбук или телефон с установленным приложением «Мой Адвокат. Экстренная помощь», Вы можете экстренно и без ограничений получить информационную поддержку по юридическим вопросам. Используя приложение, можно самостоятельно изучить рекомендации и практические советы по решению конкретной или схожей проблемы в нормативной базе приложения.</p>
                                <p>В любой ситуации, с какой бы сложностью Вы ни столкнулись, независимо в каком регионе России Вы бы ни находились, сервис экстренной адвокатской помощи гарантированно обеспечит Вас квалифицированным советом, поддержкой и инструкцией как действовать именно в Ваших интересах.</p>
                                <p>Программа разработана и рассчитана для огромного круга пользователей. Система представляет собой базу правовой информации, содержащую законодательство, судебную практику, комментарии законодательства, формы документов, проекты нормативных правовых актов, международные правовые акты, технические нормы и правила и многие другие нормативные акты, а также практические рекомендации для физических и юридических лиц по эффективным действиям в экстренных ситуациях, при возникновении правовых проблем, с образцами документов и ответами на часто возникающие вопросы.</p>
                                <p>Все материалы в системе можно скачивать, печатать, копировать и пересылать по электронной почте. Приложение также содержит справочные и рекламные материалы: телефоны судов, ГУВД, прокуратур, перечень и телефоны адвокатских палат РФ, а также список квалифицированных действующих юристов и адвокатов по различным специализациям по каждому субъекту РФ. При помощи приложения «Мой Адвокат. Экстренная помощь» Вы сможете оперативно найти решение любого юридического вопроса, не выходя из дома и не тратя время на поиски квалифицированного юриста.</p>
                            </div>
                        </div>
                        <div className="uk-width-medium-1-2">
                            <div className="data-uk-parallax " data-uk-grid-margin>
                                <div id="why_important_2" data-relative-input="true">
                                    <img src="assets/images/whyimportant/2_1.png" data-depth='0.1'  className="wi_img-2-1 uk-hidden-small uk-hidden-medium" />
                                    <img src="assets/images/whyimportant/2_2.png" data-depth='0.1'  className="wi_img-2-2 uk-hidden-small uk-hidden-medium" />
                                    <img src="assets/images/whyimportant/2_3.png" data-depth='0.2'  className="wi_img-2-3 uk-hidden-small uk-hidden-medium" />
                                    <img src="assets/images/whyimportant/2_1.png" className="wi_img-fix uk-hidden-medium uk-hidden-large uk-hidden-xlarge uk-hidden-2xlarge" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="uk-container-expand team-wrapper">
                    <div className="uk-container uk-container-center">
                        <div className="uk-grid">
                            <div className="uk-width-medium-1-1 team-title-wrapper">
                                <div className="uk-h1 uk-text-left services-header-title">СОБСТВЕННАЯ КОМАНДА СПЕЦИАЛИСТОВ</div>
                                <div className="uk-h3 uk-text-left color-lightblue">СПИЦИАЛИСТОВ ВЫСШЕГО КЛАССА</div>
                                <div className="uk-container-left all-hr color-lightblue"></div>
                            </div>
                            <div className="uk-width-medium-1-1">
                                <div className="uk-grid">
                                    <div className="uk-width-1-2 uk-width-medium-1-4 team-item">
                                        <div className="team-bg1"><img src="assets/images/team/1.png" /></div>
                                        <div className="team-bg2"><img src="assets/images/team/2.png" /></div>
                                        <div className="team-bg3"><img src="assets/images/team/3.png" /></div>
                                        <div className="team-persona" style={{
                                            background:"url(assets/images/persona/1.jpg)",
                                            backgroundSize: "cover"
                                        }}></div>
                                        <div className="team-shiald">
                                            <div className="team-persona-name">
                                                Антонина Крамер
                                            </div>
                                            <div className="team-persona-status">
                                                ВЕДУЩИЙ СПЕЦИАЛИСТ
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-width-medium-1-4 team-item">
                                        <div className="team-bg1"><img src="assets/images/team/1.png" /></div>
                                        <div className="team-bg2"><img src="assets/images/team/2.png" /></div>
                                        <div className="team-bg3"><img src="assets/images/team/3.png" /></div>
                                        <div className="team-persona" style={{
                                            background:"url(assets/images/persona/2.jpg)",
                                            backgroundSize: "cover"
                                        }}></div>
                                        <div className="team-shiald">
                                            <div className="team-persona-name">
                                                Дарья Фролова
                                            </div>
                                            <div className="team-persona-status">
                                                СЕМЕЙНЫЕ СПОРЫ
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-width-medium-1-4 team-item">
                                        <div className="team-bg1"><img src="assets/images/team/1.png" /></div>
                                        <div className="team-bg2"><img src="assets/images/team/2.png" /></div>
                                        <div className="team-bg3"><img src="assets/images/team/3.png" /></div>
                                        <div className="team-persona"  style={{
                                            background:"url(assets/images/persona/3.jpg)",
                                            backgroundSize: "cover"
                                        }}></div>
                                        <div className="team-shiald">
                                            <div className="team-persona-name">
                                                Аделаида Синицева
                                            </div>
                                            <div className="team-persona-status">
                                                ИМУЩЕСТВЕННЫЕ СПОРЫ
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-width-medium-1-4 team-item">
                                        <div className="team-bg1"><img src="assets/images/team/1.png" /></div>
                                        <div className="team-bg2"><img src="assets/images/team/2.png" /></div>
                                        <div className="team-bg3"><img src="assets/images/team/3.png" /></div>
                                        <div className="team-persona"  style={{
                                            background:"url(assets/images/persona/4.jpg)",
                                            backgroundSize: "cover"
                                        }}></div>
                                        <div className="team-shiald">
                                            <div className="team-persona-name">
                                                Людмила Длиннофамильева
                                            </div>
                                            <div className="team-persona-status">
                                                ОЧЕНЬ ДЛИННАЯ ПРАВО
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="bigcasedatabase" className="uk-container uk-container-center uk-margin-large-top uk-margin-large-bottom">
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-2">
                            <div className="data-uk-parallax " data-uk-grid-margin>
                                <div id="why_important_3" data-relative-input="true">
                                    <img src="assets/images/whyimportant/1_1.png" data-depth='0.1'  className="wi_img-1-1 uk-hidden-small uk-hidden-medium" />
                                    <img src="assets/images/whyimportant/1_2.png" data-depth='0.1'  className="wi_img-1-2 uk-hidden-small uk-hidden-medium" />
                                    <img src="assets/images/whyimportant/1_3.png" data-depth='0.2'  className="wi_img-1-3 uk-hidden-small uk-hidden-medium" />
                                    <img src="assets/images/whyimportant/1_1.png" className="wi_img-fix uk-hidden-medium uk-hidden-large uk-hidden-xlarge uk-hidden-2xlarge" />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-medium-1-2">
                            <div className="uk-h6 color-lightblue">ПОЧЕМУ ЭТО ВАЖНО</div>
                            <div className="uk-h2">БОЛЬШАЯ БАЗА КЕЙСОВ</div>
                            <div className="features-hr color-lightblue"></div>
                            <div className="uk-text-large">
                                <p>Для потенциального клиента в приложении размещена информация о кейсах с описанием практики по различным делам в понятной для него форме. В нашем приложении Вы найдете реальный практический пример со всеми подробностями, деталями по шагам и основными практическими рекомендациями для физических и юридических лиц по эффективным действиям в экстренных ситуациях, возникающих при различных жизненных ситуациях, требующих юридического вмешательства.</p>
                                <p>Для удобства пользователя вся система разделена на блоки по отраслям права. Каждый найдет информацию по интересующей ситуации в уголовном, семейном, жилищном, трудовом, наследственном, земельном, налоговом и др. праве, а также решение вопросов по гражданским, арбитражным делам в соответствии с нормами законодательства.</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="uk-container-expand block-separ-wrapper">
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-1">
                        </div>
                    </div>
                </div>


                <div id="flexibletariffs" className="uk-container-expand ">
                    <div className="uk-grid bg-biryza">
                        <div className="uk-width-medium-1-1">
                            <div className="uk-container uk-container-center uk-margin-large-top uk-margin-large-bottom">
                                <div className="uk-grid">
                                    <div className="uk-width-medium-1-2">
                                        <div className="uk-h6 color-lightblue uk-margin-large-top">ПОЧЕМУ ЭТО ВАЖНО</div>
                                        <div className="uk-h2">АДВОКАТ "В КАРМАНЕ"</div>
                                        <div className="features-hr color-lightblue"></div>
                                        <div className="uk-text-large">
                                            <p>«Мой Адвокат. Экстренная помощь» — это мобильное приложение для операционных систем IOS и Android, а также для Windows. Правовая система представляет собой дистанционный сервис на основе современных IT-технологий. Имея под рукой ноутбук или телефон с установленным приложением «Мой Адвокат. Экстренная помощь», Вы можете экстренно и без ограничений получить информационную поддержку по юридическим вопросам. Используя приложение, можно самостоятельно изучить рекомендации и практические советы по решению конкретной или схожей проблемы в нормативной базе приложения.</p>
                                            <p>В любой ситуации, с какой бы сложностью Вы ни столкнулись, независимо в каком регионе России Вы бы ни находились, сервис экстренной адвокатской помощи гарантированно обеспечит Вас квалифицированным советом, поддержкой и инструкцией как действовать именно в Ваших интересах.</p>
                                            <p>Программа разработана и рассчитана для огромного круга пользователей. Система представляет собой базу правовой информации, содержащую законодательство, судебную практику, комментарии законодательства, формы документов, проекты нормативных правовых актов, международные правовые акты, технические нормы и правила и многие другие нормативные акты, а также практические рекомендации для физических и юридических лиц по эффективным действиям в экстренных ситуациях, при возникновении правовых проблем, с образцами документов и ответами на часто возникающие вопросы.</p>
                                            <p>Все материалы в системе можно скачивать, печатать, копировать и пересылать по электронной почте. Приложение также содержит справочные и рекламные материалы: телефоны судов, ГУВД, прокуратур, перечень и телефоны адвокатских палат РФ, а также список квалифицированных действующих юристов и адвокатов по различным специализациям по каждому субъекту РФ. При помощи приложения «Мой Адвокат. Экстренная помощь» Вы сможете оперативно найти решение любого юридического вопроса, не выходя из дома и не тратя время на поиски квалифицированного юриста.</p>
                                        </div>
                                    </div>
                                    <div className="uk-width-medium-1-2">
                                        <div className="data-uk-parallax " data-uk-grid-margin>
                                            <div id="why_important_4" data-relative-input="true">
                                                <img src="assets/images/whyimportant/4_1.png" data-depth='0.1'  className="wi_img-4-1 uk-hidden-small uk-hidden-medium" />
                                                <img src="assets/images/whyimportant/4_2.png" data-depth='0.1'  className="wi_img-4-2 uk-hidden-small uk-hidden-medium" />
                                                <img src="assets/images/whyimportant/4_3.png" data-depth='0.2'  className="wi_img-4-3 uk-hidden-small uk-hidden-medium" />
                                                <img src="assets/images/whyimportant/4_1.png" className="wi_img-fix uk-hidden-medium uk-hidden-large uk-hidden-xlarge uk-hidden-2xlarge" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="uk-container-expand counter-wrapper">
                    <div className="uk-container uk-container-center">
                        <div className="uk-grid">
                            <div className="uk-width-medium-1-1">
                                <div className="uk-grid">
                                    <div className="uk-width-medium-1-4 counter-item ">
                                        <div className="counter-count" id="counter-item-1" data-to-value="423">0</div>
                                        <div className="counter-descr">Отработанных объектов</div>
                                    </div>
                                    <div className="uk-width-medium-1-4 counter-item">
                                        <div className="counter-count" id="counter-item-2" data-to-value="733">0</div>
                                        <div className="counter-descr">Специалистов</div>
                                    </div>
                                    <div className="uk-width-medium-1-4 counter-item">
                                        <div className="counter-count" id="counter-item-3" data-to-value="124">0</div>
                                        <div className="counter-descr">млрд. руб в обороте</div>
                                    </div>
                                    <div className="uk-width-medium-1-4 counter-item">
                                        <div className="counter-count" id="counter-item-4" data-to-value="1536">0</div>
                                        <div className="counter-descr">Заданных вопросов</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="uk-container-expand feedback-wrapper">
                    <div className="feedback-bg">
                        <div className="uk-container uk-container-center feedback-content">
                            <div className="uk-grid ">
                                <div className="uk-width-medium-1-1">
                                    <div className="uk-container uk-container-center">
                                        <div className="uk-grid">
                                            <div className="uk-width-medium-1-1 feedback-panel-wrapper">
                                                <img src="assets/images/feedback/1.png" className="feedback-bgn feedback-bg1" />
                                                <img src="assets/images/feedback/2.png" className="feedback-bgn feedback-bg2" />
                                                <img src="assets/images/feedback/1.png" className="feedback-bgn feedback-bg3" />
                                                <img src="assets/images/feedback/2.png" className="feedback-bgn feedback-bg4" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-container uk-container-center feedback-content">
                        <div className="uk-grid ">
                            <div className="uk-width-medium-1-1">
                                <div className="uk-container uk-container-center">
                                    <div className="uk-grid">
                                        <div className="uk-width-medium-1-1 feedback-panel-wrapper">
                                            <div className="feedback-panel">
                                                <div className="feedback-icon-circle"></div>
                                                <div className="feedback-qtext">
                                                    <div className="feedback-qtext-upper">
                                                        Есть вопросы? Свяжитесь с нами!
                                                    </div>
                                                    <div className="feedback-qtext-bottom">
                                                        ИЛИ ПОЗВОНИТЕ ПО ТЕЛЕФОНУ <noindex>8 (800) 550 89 72</noindex>
                                                    </div>
                                                </div>
                                                <div className="feedback-sendform-btn-wrapper">
                                                    <a href="#" className="uk-button uk-button-primary uk-button-large feedback-sendform-btn">
                                                        <i className="fas fa-phone-alt"></i> Заказать звонок
                                                    </a>
                                                </div>
                                                <div className="feedback-form-inputs">
                                                    <form>
                                                        <div className="form-field uk-margin">
                                                            <input type="text" className="uk-input uk-text-center" id="callback_name" placeholder="ВАШЕ ИМЯ" />
                                                        </div>
                                                        <div className="form-field uk-margin">
                                                            <input type="text" className="uk-input uk-text-center" id="callback_phone" placeholder="Номер телефона* +7 (xxx) xxx-xx-xx" />
                                                        </div>
                                                        <div className="form-field uk-margin">
                                                            <input type="text" className="uk-input uk-text-center" id="callback_email" placeholder="Email*" />
                                                        </div>
                                                        <div className="uk-clearfix"></div>
                                                    </form>
                                                    <div className="uk-clearfix"></div>
                                                </div>
                                                <div className="uk-clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="uk-container-expand news-wrapper bg-gradient1">
                    <div className="uk-container uk-container-center">
                        <div className="uk-grid">
                            <div className="uk-width-medium-1-1">
                                <div className="uk-grid">
                                    <div className="uk-width-medium-1-1 news-header ">
                                        <div className="uk-h1 uk-text-left news-header-title uk-text-uppercase">Последний новости в блоге или примеры случаев</div>
                                        <div className="uk-container-left all-hr color-lightblue"></div>
                                        <Link className="uk-h3 uk-text-left color-lightblue news-header-subtitle" to="/news">Все новости</Link>
                                    </div>
                                </div>

                                <div className="uk-grid news-content-wrapper">
                                    {newsList}
                                    <div className="uk-clearfix"></div>
                                </div>              
                                <div className="uk-clearfix"></div>       
{/*
                                <div className="uk-grid news-content-wrapper">
                                    <div className="uk-width-medium-1-3 news-content">
                                        <div className="news-item">
                                            <div className="news-item-shield">
                                                <div className="news-item-title">УГОЛОВНОЕ ПРАВО</div>
                                                <div className="news-item-description">
                                                    Правительство Челябинска запретило убивать гусей
                                                </div>
                                            </div>
                                            <div className="news-item-image" style={{
                                                background:"url(assets/images/news/1.png)",
                                                backgroundSize: "cover"
                                            }}
                                            ></div>
                                            <div className="news-item-date">28<div className="news-item-date-month">СЕН</div></div>
                                        </div>
                                    </div>
                                    <div className="uk-width-medium-1-3 news-content">
                                        <div className="news-item">
                                            <div className="news-item-shield">
                                                <div className="news-item-title">УГОЛОВНОЕ ПРАВО</div>
                                                <div className="news-item-description">
                                                    Правительство Челябинска запретило убивать гусей
                                                </div>
                                            </div>
                                            <div className="news-item-image" style={{
                                                background:"url(assets/images/news/1.png)",
                                                backgroundSize: "cover"
                                            }}></div>
                                            <div className="news-item-date">28<div className="news-item-date-month">СЕН</div></div>
                                        </div>
                                    </div>
                                    <div className="uk-width-medium-1-3 news-content">
                                        <div className="news-item">
                                            <div className="news-item-shield">
                                                <div className="news-item-title">УГОЛОВНОЕ ПРАВО</div>
                                                <div className="news-item-description">
                                                    Правительство Челябинска запретило убивать гусей
                                                </div>
                                            </div>
                                            <div className="news-item-image" style={{
                                                background:"url(assets/images/news/1.png)",
                                                backgroundSize: "cover"
                                            }}
                                            ></div>
                                            <div className="news-item-date">
                                                28<div className="news-item-date-month">СЕН</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-clearfix"></div>
                                </div>
                                <div className="uk-clearfix"></div>
/**/}



                            </div>
                            <div className="uk-clearfix"></div>
                        </div>
                        <div className="uk-clearfix"></div>
                    </div>
                    <div className="uk-clearfix"></div>
                </div>


                <div className="uk-container uk-container-center contacts-wrapper ">
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-3 uk-text-center contacts-item">
                            <i className="fas fa-map-marker-alt"></i> <span className="contacts-item-text">165, Чайковского, Челябинск</span>
                        </div>
                        <div className="uk-width-medium-1-3 uk-text-center contacts-item">
                            <i className="fas fa-envelope"></i> <span className="contacts-item-text">info@my-advo.cat</span>
                        </div>
                        <div className="uk-width-medium-1-3 uk-text-center contacts-item">
                            <i className="far fa-clock"></i> <span className="contacts-item-text">24 / 7</span>
                        </div>
                    </div>
                </div>


{/* Мобильное меню 
                <div id="tm-offcanvas" className="uk-offcanvas">
                    <div className="uk-offcanvas-bar">
                        <ul className="uk-nav uk-nav-offcanvas uk-nav-parent-icon" data-uk-nav="{multiple:true}" >
                            <li className="uk-active">
                                <a href="layouts_frontpage.html">Личный кабинет</a>
                            </li>
                            <li>
                                <a href="layouts_portfolio.html">Домой</a>
                            </li>
                            <li>
                                <a href="layouts_blog.html">Поддержка</a>
                            </li>
                            <li>
                                <a href="layouts_documentation.html">Юристы</a>
                            </li>
                            <li>
                                <a href="layouts_contact.html">Контакты</a>
                            </li>
                        </ul>
                    </div>
                </div>
*/}
 

            </>





        )
    }




    /*
        render(){
            return (
              <>
                <div className="container-full-width">
                    <div className="row sidebar_none">
                        <div className="content-container span12">
                            <section id='main_content'>
                                <div data-vc-full-width="true" data-vc-full-width-init="false" className="vc_custom_1521452496323">
                                    <div className="container">
                                        <div className="vc_row wpb_row vc_row-fluid vc_row-has-fill">
                                            <div className="text-center wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner vc_custom_1491902584282">
                                                    <div className="wpb_wrapper">
                                                        <div className="gt3_spacing gt3_spacing-height_tablet-on gt3_spacing-height_mobile-on">
                                                            <div className="gt3_spacing-height gt3_spacing-height_default" style={{"height":"288px"}}></div>
                                                            <div className="gt3_spacing-height gt3_spacing-height_tablet" style={{"height":"188px"}}></div>
                                                            <div className="gt3_spacing-height gt3_spacing-height_mobile" style={{"height":"88px"}}></div>
                                                        </div>
                                                        <div data-color="#ffffff" className="gt3_custom_text" style={{"color":"#ffffff","fontSize": "18px", "lineHeight": "167%"}}>
                                                            <p style={{"textAlign": "center"}}>Мы здесь, чтобы помочь!</p>
                                                        </div>
                                                        <div className="vc_empty_space" style={{"height": "16px"}}><span className="vc_empty_space_inner"></span></div>
                                                        <div data-color="#ffffff" className="gt3_custom_text" style={{"color":"#ffffff","fontSize": "54px","lineHeight": "113%"}}>
                                                            <div className="gt3_custom_text-font_size_tablet" style={{"fontSize":"40px","lineHeight": "113%"}}>
                                                                <div className="gt3_custom_text-font_size_mobile" style={{"fontSize":"16px","lineHeight": "113%"}}>
                                                                    <p style={{"textAlign": "center"}}><span className="gt3_fontWeight" style={{"fontWeight": "bold"}}>Квалифицированная, надежная и эффективная</span></p>
                                                                    <p style={{"textAlign": "center"}}><span className="gt3_fontWeight" style={{"fontWeight": "bold"}}>Юридическая поддержка</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="vc_empty_space" style={{"height": "36px"}}><span className="vc_empty_space_inner"></span></div>
                                                        <div className="d-none gt3_module_button gt3_btn_customize button_alignment_inline "><a className="button_size_large btn_icon_position_right vc_custom_1491903193872" href="http://gt3demo.com/wp/wizelaw/contacts/" style={{"backgroundColor": "#ffffff", "color": "#27323d", "borderWidth": "1px", "borderStyle": "solid", "borderRadius": "3px", "borderColor": "#ffffff"}}
                                                                data-default-bg="#ffffff" data-default-color="#27323d" data-default-border="#ffffff" data-hover-bg="#c24747" data-hover-color="#ffffff"><span className="gt3_btn_text">ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ</span><div className="btn_icon_container"><span className="gt3_btn_icon fa fa-external-link" style={{"color": "#c24747", "fontSize": "16px", "lineHeight": "18px"}} data-default-icon="#c24747" ></span></div></a></div>
                                                        <div
                                                            className="d-none gt3_module_button gt3_btn_customize button_alignment_inline "><a className="button_size_large vc_custom_1491917260670" href="http://gt3demo.com/wp/wizelaw/about-us/" style={{borderWidth: "1px", borderStyle: "solid", borderRadius: "3px"}} data-hover-color="#303a44" data-hover-border="#ffffff"><span className="gt3_btn_text">О ПРОЕКТЕ</span></a></div>
                                                    <div
                                                        className="gt3_spacing gt3_spacing-height_tablet-on gt3_spacing-height_mobile-on">
                                                        <div className="gt3_spacing-height gt3_spacing-height_default" style={{height:"146px"}}></div>
                                                        <div className="gt3_spacing-height gt3_spacing-height_tablet" style={{height:"90px"}}></div>
                                                        <div className="gt3_spacing-height gt3_spacing-height_mobile" style={{height:"40px"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix"></div>
                    <div data-vc-full-width="true" data-vc-full-width-init="false" className="vc_custom_1491908380313">
                        <div className="container">
                            <div className="vc_row wpb_row vc_row-fluid custom_iconbox_border rgba_border vc_row-has-fill">
                                <div className="wpb_column vc_column_container vc_col-sm-4">
                                    <div className="vc_column-inner vc_custom_1491918675580">
                                        <div className="wpb_wrapper">
                                            <div className="gt3_icon_box gt3_icon_box_icon-position_ gt3_icon_box_icon-position_left gt3_icon_box__icon_icon_size_custom gt3-box-image"><i className="gt3_icon_box__icon" style={{width:"40px", fontSize:"40px"}}><img width="80" height="80" src="wp-content/uploads/sites/11/2017/04/home04_icon1.png" className="attachment-full size-full" alt="" /></i>
                                                <div
                                                    className="gt3_icon_box-content-wrapper" style={{"marginLeft":"55px"}}>
                                                    <div className="gt3_icon_box__title">
                                                        <h4 style={{color:"#ffffff", fontSize: "18px", lineHeight: "25.2px"}}>Судебный процесс по гражданскому делу</h4>
                                                    </div>
                                                    <div className="gt3_icon_box__text" style={{color:"#b9c8d5",fontSize: "16px", lineHeight: "25.12px"}}>Адвокат должен будет<br /> договориться о найме.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column vc_column_container vc_col-sm-4">
                                <div className="vc_column-inner vc_custom_1491918680121">
                                    <div className="wpb_wrapper">
                                        <div className="gt3_icon_box gt3_icon_box_icon-position_ gt3_icon_box_icon-position_left gt3_icon_box__icon_icon_size_custom gt3-box-image"><i className="gt3_icon_box__icon" style={{width:"40px", fontSize:"40px"}}><img width="80" height="80" src="wp-content/uploads/sites/11/2017/04/home04_icon2.png" className="attachment-full size-full" alt="" /></i>
                                            <div
                                                className="gt3_icon_box-content-wrapper" style={{"marginLeft":"55px"}}>
                                                <div className="gt3_icon_box__title">
                                                    <h4 style={{color:"#ffffff",fontSize: "18px", "lineHeight": "25.2px"}}>Право собственности</h4>
                                                </div>
                                                <div className="gt3_icon_box__text" style={{color:"#b9c8d5",fontSize: "16px", lineHeight: "25.12px"}}>Мы команда юристов, которые<br /> использует только полностью проверенных адвокатов</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner vc_custom_1491918684401">
                                <div className="wpb_wrapper">
                                    <div className="gt3_icon_box gt3_icon_box_icon-position_ gt3_icon_box_icon-position_left gt3_icon_box__icon_icon_size_custom gt3-box-image"><i className="gt3_icon_box__icon" style={{width:"40px", fontSize:"40px"}}><img width="80" height="80" src="wp-content/uploads/sites/11/2017/04/home04_icon3.png" className="attachment-full size-full" alt="" /></i>
                                        <div
                                            className="gt3_icon_box-content-wrapper" style={{"marginLeft":"55px"}}>
                                            <div className="gt3_icon_box__title">
                                                <h4 style={{color:"#ffffff",fontSize: "18px", "lineHeight": "25.2px"}}>Медицинское право</h4>
                                            </div>
                                            <div className="gt3_icon_box__text" style={{color:"#b9c8d5", fontSize: "16px", lineHeight: "25.12px"}}>Мы можем предоставить квалифицированных<br /> и опытных адвокатов.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="vc_row-full-width vc_clearfix"></div>
        <div className="vc_custom_1521452601363">
            <div className="container">
                <div className="vc_row wpb_row vc_row-fluid vc_row-has-fill">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                                <div className="vc_empty_space" style={{"height": "41px"}}><span className="vc_empty_space_inner"></span></div>
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-4">
                                        <div className="vc_column-inner vc_custom_1491571276928">
                                            <div className="wpb_wrapper">
                                                <div className="gt3_services_box to-left wpb_animate_when_almost_visible wpb_fadeInLeft fadeInLeft ">
                                                    <div className="gt3_services_img_bg services_box-front" style={{backgroundColor: "#255379", backgroundImage: "url(wp-content/uploads/sites/11/2017/04/services_bg1.jpg)"}}>
                                                        <div className="index_number" style={{fontSize: "72px", lineHeight: "65px"}}>01</div>
                                                        <div className="gt3_services_box_title" style={{fontSize: "30px", lineHeight: "33px"}}>Кодексы</div>
                                                    </div>
                                                    <div className="gt3_services_box_content services_box-back" style={{minHeight: "275px", backgroundColor: "#255379", fontSize: "16px", lineHeight: "30px"}}>
                                                        <div className="text_wrap">Мы можем найти адвокатов для обработки показаний, проверок, судебных разбирательств, и кто может даже помочь вам написать вашу документацию.</div>
                                                        <div className="fake_space"></div>
                                                    </div> <a className="gt3_services_box_link" href="/codex/">Перейти</a> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wpb_column vc_column_container vc_col-sm-4">
                                        <div className="vc_column-inner vc_custom_1491571282207">
                                            <div className="wpb_wrapper">
                                                <div className="gt3_services_box to-top wpb_animate_when_almost_visible wpb_fadeInUp fadeInUp ">
                                                    <div className="gt3_services_img_bg services_box-front" style={{backgroundColor: "#255379", backgroundImage: "url(wp-content/uploads/sites/11/2017/04/services_bg2.jpg)"}}>
                                                        <div className="index_number" style={{fontSize: "72px", lineHeight: "65px"}}>02</div>
                                                        <div className="gt3_services_box_title" style={{fontSize: "26px", lineHeight: "33px"}}>Вопрос-ответ</div>
                                                    </div>
                                                    <div className="gt3_services_box_content services_box-back" style={{minHeight: "275px", backgroundColor: "#255379", fontSize: "16px", lineHeight: "30px"}}>
                                                        <div className="text_wrap">Мы можем найти адвокатов для обработки показаний, проверок, судебных разбирательств, и кто может даже помочь вам написать вашу документацию</div>
                                                        <div className="fake_space"></div>
                                                    </div> <a className="gt3_services_box_link" href="/qa/">Перейти</a> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wpb_column vc_column_container vc_col-sm-4">
                                        <div className="vc_column-inner vc_custom_1491571288047">
                                            <div className="wpb_wrapper">
                                                <div className="gt3_services_box to-right wpb_animate_when_almost_visible wpb_fadeInRight fadeInRight ">
                                                    <div className="gt3_services_img_bg services_box-front" style={{backgroundColor: "#255379", backgroundImage: "url(wp-content/uploads/sites/11/2017/04/services_bg3.jpg)"}}>
                                                        <div className="index_number" style={{fontSize: "72px", lineHeight: "65px"}}>03</div>
                                                        <div className="gt3_services_box_title" style={{fontSize: "30px", lineHeight: "33px"}}>Ситуации</div>
                                                    </div>
                                                    <div className="gt3_services_box_content services_box-back" style={{minHeight: "275px", backgroundColor: "#255379", fontSize: "16px", lineHeight: "30px"}}>
                                                        <div className="text_wrap">Мы можем найти адвокатов для обработки показаний, проверок, судебных разбирательств, и кто может даже помочь вам написать вашу документацию.</div>
                                                        <div className="fake_space"></div>
                                                    </div> <a className="gt3_services_box_link" href="/situation/">Перейти</a> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div data-vc-full-width="true" data-vc-full-width-init="false" className="vc_custom_1521452510769">
            <div className="container">
                <div className="vc_row wpb_row vc_row-fluid vc_row-has-fill vc_row-o-equal-height vc_row-o-content-bottom vc_row-flex">
                    <div className="wpb_column vc_column_container vc_col-sm-4">
                        <div className="vc_column-inner vc_custom_1491812581406">
                            <div className="wpb_wrapper">
                                <div className="vc_empty_space" style={{height: "10px"}}><span className="vc_empty_space_inner"></span></div>
                                <div className="gt3_icon_box gt3_icon_box_icon-position_ gt3_icon_box_icon-position_left gt3_icon_box__icon_icon_size_custom wpb_animate_when_almost_visible wpb_right-to-left right-to-left icon-bg"><i className="gt3_icon_box__icon fa fa-line-chart" style={{color:"#ffffff",fontSize:"26px"}}><span className="gt3_icon_box__icon-bg" style={{backgroundColor:"rgba(255,255,255,0.25)"}}></span></i>
                                    <div className="gt3_icon_box-content-wrapper" style={{marginLeft:"102.5px"}}>
                                        <div className="gt3_icon_box__title">
                                            <h3 style={{color:"#ffffff",fontSize: "16px", lineHeight: "22.4px"}}>Закон о малом бизнесе</h3>
                                        </div>
                                        <div className="gt3_icon_box__text" style={{color:"#ffffff"}}>Мы предоставляем юридическую консультацию, чтобы убедиться, что вы имеете понимание вашей проблемы.</div>
                                    </div>
                                </div>
                                <div className="vc_empty_space" style={{height: "104px"}}><span className="vc_empty_space_inner"></span></div>
                                <div className="gt3_icon_box gt3_icon_box_icon-position_ gt3_icon_box_icon-position_left gt3_icon_box__icon_icon_size_custom wpb_animate_when_almost_visible wpb_right-to-left right-to-left icon-bg"><i className="gt3_icon_box__icon fa fa-users" style={{color:"#ffffff",fontSize:"26px"}}><span className="gt3_icon_box__icon-bg" style={{backgroundColor:"rgba(255,255,255,0.25)"}}>></span></i>
                                    <div className="gt3_icon_box-content-wrapper" style={{marginLeft:"102.5px"}}>
                                        <div className="gt3_icon_box__title">
                                            <h3 style={{color:"#ffffff",fontSize: "16px", lineHeight: "22.4px"}}>Трудовой кодекс</h3>
                                        </div>
                                        <div className="gt3_icon_box__text" style={{color:"#ffffff"}}>Претензии к работодателям, претензии по общей ответственности и компенсации.</div>
                                    </div>
                                </div>
                                <div className="vc_empty_space" style={{height: "92px"}}><span className="vc_empty_space_inner"></span></div>
                            </div>
                        </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-4 vc_hidden-xs">
                        <div className="vc_column-inner vc_custom_1491814234571">
                            <div className="wpb_wrapper">
                                <div className="wpb_single_image wpb_content_element vc_align_center wpb_animate_when_almost_visible wpb_fadeIn fadeIn">
                                    <figure className="wpb_wrapper vc_figure">
                                        <div className="vc_single_image-wrapper vc_box_border_grey"><img width="320" height="455" src="wp-content/uploads/sites/11/2017/04/home04_iphone.png" className="vc_single_image-img attachment-full" alt="" srcSet="wp-content/uploads/sites/11/2017/04/home04_iphone.png 320w, wp-content/uploads/sites/11/2017/04/home04_iphone-211x300.png 211w"
                                                sizes="(max-width: 320px) 100vw, 320px" /></div>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-4">
                        <div className="vc_column-inner vc_custom_1491812591366">
                            <div className="wpb_wrapper">
                                <div className="vc_empty_space" style={{height: "10px"}}><span className="vc_empty_space_inner"></span></div>
                                <div className="gt3_icon_box gt3_icon_box_icon-position_ gt3_icon_box_icon-position_right gt3_icon_box__icon_icon_size_custom wpb_animate_when_almost_visible wpb_left-to-right left-to-right icon-bg"><i className="gt3_icon_box__icon fa fa-heartbeat" style={{color:"#ffffff",fontSize:"26px"}}><span className="gt3_icon_box__icon-bg" style={{backgroundColor:"rgba(255,255,255,0.25)"}}>></span></i>
                                    <div className="gt3_icon_box-content-wrapper" style={{marginRight:"102.5px"}}>
                                        <div className="gt3_icon_box__title">
                                            <h3 style={{color:"#ffffff",fontSize: "16px", lineHeight: "22.4px"}}>Страхование безопасности</h3>
                                        </div>
                                        <div className="gt3_icon_box__text" style={{color:"#ffffff"}}>Мы определяем, есть ли потенциальная ответственность со стороны нашего клиента.</div>
                                    </div>
                                </div>
                                <div className="vc_empty_space" style={{"height": "104px"}}><span className="vc_empty_space_inner"></span></div>
                                <div className="gt3_icon_box gt3_icon_box_icon-position_ gt3_icon_box_icon-position_right gt3_icon_box__icon_icon_size_custom wpb_animate_when_almost_visible wpb_left-to-right left-to-right icon-bg"><i className="gt3_icon_box__icon fa fa-shield" style={{color:"#ffffff",fontSize:"26px"}}><span className="gt3_icon_box__icon-bg" style={{backgroundColor:"rgba(255,255,255,0.25)"}}>></span></i>
                                    <div className="gt3_icon_box-content-wrapper" style={{marginRight:"102.5px"}}>
                                        <div className="gt3_icon_box__title">
                                            <h3 style={{color:"#ffffff",fontSize:"16px", lineHeight: "22.4px"}}>Закон о развитии бизнеса</h3>
                                        </div>
                                        <div className="gt3_icon_box__text" style={{color:"#ffffff"}}>Сложный судебный процесс с участием бизнес-клиентов в спорах с другими предприятиями.</div>
                                    </div>
                                </div>
                                <div className="vc_empty_space" style={{height: "92px"}}><span className="vc_empty_space_inner"></span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="vc_row-full-width vc_clearfix"></div>
        <div className="clear"></div>
        </section>
        </div>
        </div>
        </div>

              </>
            );
        }

        /**/
}

export default MainPage;
