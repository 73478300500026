import React from 'react'
import {Link} from "react-router-dom";
import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";
import {PremiumAccess} from "../../layouts/personal-cab/PremiumAccess";

let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
class PresetGet extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            preset: null,
            Resources: null,
            isPremium: Boolean(localStorage.getItem('isPremium')?(JSON.parse(localStorage.getItem('isPremium'))):false),
            isAuth: Boolean((localStorage.getItem('tokenAPI'))?true:false),
        };
        this.PREloader = <div dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)}></div>
    }

    createMarkup = (s) => {
        return {__html: s};
    }

    componentDidMount() {
        const presetid = this.props.match.params.presetid;
        let url = global.apiLink + 'api/preset/preset/get?id='+presetid;
        fetch(url, {headers: headers})
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === 200) {
                        this.setState({
                            preset: result.payload.element,
                            Resources: result.payload.Resources,
                        });
						
						document.getElementById("bc__last-chain").innerText = result.payload.element.title;
                    }
                }
            );
    }
    
    render() {
        let {preset, Resources, isPremium} = this.state;
        document.getElementById("root").style.background = "#fafafb";
        const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Адвокатская практика',
                path: '/preset/',				
            },
            {
                name: '',
                path: '',				
            },			
        ];    
        return (
            <div className="presets-page page">
                <div className="container ">
                    <div className="row">
                        <BreadCrumbs crumbs={breadcrumbs} />
					    <PageTitle pageTitle={preset ? preset.title : this.PREloader} />
                    </div>
                </div>
                <div className="container">
                    <div className="row presets-content-wrapper">
                        {isPremium? <>
                        
                        {preset ? <>
                            <div className='col-lg-12'>
                                <div dangerouslySetInnerHTML={{__html: preset.description}} />
                                <div className='row mt-5'>
                                    {Resources && Resources.map((TableElements)=>{
                                        {/*    return(<>
                                                <div className="col-md-6 mb-3">
                                                    <div id={"TableElements_"+TableElements.table_name} className='card shadow-sm'>
                                                        <div className='card-body'>
                                                            <h4>{TableElements.table_title}</h4>
                                                            {TableElements.list.map((row)=>{
                                        let LinkUrl = "";
                                        switch(TableElements.table_name){
                                            case "fas_resource_codex":
                                                LinkUrl = "/codex/"+row.base64_uid;
                                            break;
                                            case "fas_resource_situation":
                                                LinkUrl = "/situation/"+btoa(row.codex_id);
                                            break;
                                            case "documents":
                                                LinkUrl = "/docs/"+row.id;
                                            break;
                                            case "fas_resource_qa":
                                                LinkUrl = "/qa/";
                                            break;
                                            case "fas_account_juristusers":
                                                LinkUrl = "/docs/";
                                            break;
                                        }    
                                                                return (<>
                                                                    <Link to={LinkUrl}>{row.title?row.title:row.name}</Link><br />
                                                                </>);
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
									</>);*/ }
											
											
											
                                          return(<>
                                                <div className="col-md-6 mb-3">
                                                    <div id={"TableElements_"+TableElements.table_name} className='callout callout-info'>
                                                        <div className='card-body'>
                                                            <h5>{TableElements.table_title}</h5>
															<ul className="invent-list-square">
                                                            {TableElements.list.map((row)=>{
                                        let LinkUrl = "";
                                        switch(TableElements.table_name){
                                            case "fas_resource_codex":
                                                LinkUrl = "/codex/"+row.base64_uid;
                                            break;
                                            case "fas_resource_situation":
                                                LinkUrl = "/situation/"+btoa(row.codex_id);
                                            break;
                                            case "documents":
                                                LinkUrl = "/docs/"+row.id;
                                            break;
                                            case "fas_resource_qa":
                                                LinkUrl = "/qa/";
                                            break;
                                            case "fas_account_juristusers":
                                                LinkUrl = "/docs/";
                                            break;
                                        }    
										                        
                                                                return (<>
																<li>
                                                                    <Link to={LinkUrl}>{row.title?row.title:row.name}</Link>
																</li>
                                                                </>);
																
                                                            })}
															</ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>);											
                                    })}
                                </div>
                            </div>
                        </> : this.PREloader}
                        <div className="clearfix"></div>
                        
                        </> : <>
                        
                        <PremiumAccess CheckForPremium={true} />
                                </>}
                    </div>
                    <div className="clearfix"></div>
                    <div><br /><br /><br /><br /></div>
                </div>
            </div>
        );
    }
}

export default PresetGet;