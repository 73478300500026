import React from 'react';
import GridCard from '../panel/GridCard';
import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";


import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "../panel.styles.css";

import "../../global";

let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));

export class Serviceslist extends React.Component {
     

    constructor(props) {
        super(props);

        this.state = {
            pageData: [
                {
                    title: "Наши сервисы",
                    items: global.servicesList,
                }
            ]
        }
    }

    componentDidMount() {
       // this.getPresets();
       // this.getNews();
    }

    render(){
        const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Сервисы',
                path: '',				
            },
        ];			
           
            return(
                <>
                <div className="page-panel page">
                    <div className="container ">
                        <div className="row">
							<BreadCrumbs crumbs={breadcrumbs} />						
							<PageTitle pageTitle="Сервисы" />
                        </div>
                        <div className="row">						

                                {
                                    this.state.pageData.map((block, i) => {
                                        return (<>
                                            <div className="col-md-12 mt-3">
                                                <div className="grid_container row">
                                                    {
                                                        block.items.map(item => <GridCard key={item.href} {...item} />)
                                                    }
                                                </div>
                                            </div>
                                            { i !== this.state.pageData.length - 1 ? 
                                                <div className="col-md-12">&nbsp;</div> : "" }
                                        </>);
                                    })
                                }
                            <div className="col-md-12">&nbsp;</div>
                        </div>
                    </div>
                </div>
                </>
            );
    }
}

export default Serviceslist;