import React from 'react';
import { Link } from 'react-router-dom';
import PageTitle from "../elements/PageTitle";
import "../../global.js";

const tokenAPI = localStorage.getItem("tokenAPI");

export class ResourcePage extends React.Component {
    constructor() {
      super();
  
      this.state = {
        dataResourceQuickstart: '',
        resourceid: 0,
        partTitle: "",
        content: "",
		tags:[],
		htmlTagClouds: "",
		dataResourceTags: '',
      }
    }

   utils = {
		dummy: document.createElement('div'),
		escapeHTML: function(s) {
			return {__html: s};
		}
	}

  createMarkup = (s) => {
    return {__html: s};
  }

	
gettingResources = async (pid) => {
    let self = this;
    let output = {};
    let headers = new Headers();
    headers.append('Authorization','Bearer '+tokenAPI);
    const api_url = await fetch(global.apiLink+`api/qa/qa/list?gn=`+pid+`&getcontent=1`,{
        headers:headers
    });
    output.quickstart = await api_url.json();
    if(output.quickstart.status !== 200) {
        alert("Ошибка при загрузке данных quickstart");
        return false;
    }
    let partTitle = "";
    if(pid == "up"){
        partTitle = "По уголовным делам";
    }else if(pid == "ap"){
        partTitle = "По административным делам";
    }else if(pid == "np"){
        partTitle = "По налоговым делам";
    }
    self.setState({
        partTitle: partTitle,
    });
    return output;
}
 showTagCloud = (json) => {
        let dataResourceTvalue = [];
        if (json !== undefined)
            var tmp = json;//JSON.parse(json);
        for (var dataResourceTVItem in tmp) {
            dataResourceTvalue[dataResourceTVItem] = tmp[dataResourceTVItem];
        }

        return (
            <div>
                {json && dataResourceTvalue.map((answer, i) => {
                        let content = dataResourceTvalue[i].content;
                        let title = dataResourceTvalue[i].title;
                        return (
                            <div key={i}>
                                <div
                                    dangerouslySetInnerHTML={this.createMarkup("<span style='float:left;'>" + (title) + ".&nbsp;</span>" + content)}/>
                                <br/>
                            </div>
                        );
                    }
                )}
            </div>
        );
    } 
    componentDidMount() {
        let resourceId= this.props.match.params.alias;
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + tokenAPI);
        
        let url = global.apiLink+'/api/qa/qa/get?id='+resourceId;
        fetch(url,{
            headers:headers
        })
            .then(res => res.json())
            .then((response)=>{
                console.log(response);
                if(response.payload){
                    this.setState({
                        title: response.payload.title,
                        content: response.payload.content,
                        resourceid: response.payload.id
                    })
                }
            })
            

        let self = this;
        let part = self.props.match.params.part;


        part = part.replace("part_", "");

        self.gettingResources(part).then(output => {
		
			let htmlTagClouds = '';
			


        const tags = [];
        const tagsCloud = [];	
const counts = {};
const counts2 = {};	
	
        if (output.quickstart.payload.list && output.quickstart.payload.list.nodes) {
            let listNodes = output.quickstart.payload.list.nodes;
			let k = 0;
			
            for(let i=0; i < listNodes.length; i++) {
				tags[i] = listNodes[i].element.tags;

                const re =  /\s*(?:,|$)\s*/;///\s+/;
                const nameList = tags[i].split(re);
                for( let z=0; z < nameList.length; z++ ){
                    tagsCloud[k++] = nameList[z];
                }
               // resultComplete += "<div onclick='document.querySelector("+'".situation-search-input"'+").value = "+'"'+list[i].tags+'"'+";document.querySelector("+'".situation-search-input"'+").focus();'>"+list[i].tags+"</div>";
            }

			//const counts = {};

            tagsCloud.forEach(function (x) { 
                counts[x] = (counts[x] || 0) + 1; 
            });
            console.log(counts);


            tagsCloud.forEach(function (x) { 
                counts2[x]= (counts[x] / 10 < 1) ? counts[x] / 10 + 0.9 + "em": (counts[x] / 10 > 2) ? "2em" : counts[x] / 10 + "em";
            });
			
            tagsCloud.forEach(function (x) { 
                //this.state.htmlTagClouds += '<li style={{fontSize: "' +counts2[x] + '"}} data-value="'+x+'">'+x+'</li>';
                htmlTagClouds += '<li data-value="'+x+'">'+x+'</li>';
            });
			htmlTagClouds = '<li data-value="all-branches">показать все</li>' + htmlTagClouds;
				console.log(htmlTagClouds);
				
        }

            self.setState({
                dataResourceQuickstart: output.quickstart,
                dataResourceQa: output.qa,
				dataResourceTags: htmlTagClouds,
            });

console.log(self.state.dataResourceTags);










			
			
			
			
            self.createQATree(output.quickstart.payload.list);
        });
		
		
		
		
		
		
		
		
    const SelectFilter=(elem)=>{
        let filter = {};

        filter.theme = elem.getAttribute("data-for");
        let filteredContent = document.querySelectorAll(".js-filtered-content");
        if(filteredContent){
            for(let i = 0; i < filteredContent.length; i++){
                if(filteredContent[i].getAttribute("data-content") == filter.theme){
                    filter.for = filteredContent[i];
                    break;
                };
            };
        };
        //let filterController = elem.querySelector(".js-choice li");

            let filterController = document.querySelectorAll(".js-choice li");
    if(filterController){
        for(let i = 0; i < filterController.length; i ++){
             changeFilterContent(filterController[i], filter.for);
			  filterController[i].addEventListener("click", changeFilterContent.bind(null, filterController[i], filter.for));
        };
    };

       // changeFilterContent(filterController, filter.for);

       
    }


    const changeFilterContent=(controller, filterContent)=>{


        let filteredElems = filterContent.querySelectorAll("[data-filter-group]");
        if(filteredElems){


            switch(controller.getAttribute("data-value")){
                case "all-branches":
                    for(let i = 0; i < filteredElems.length; i++){
                        if(filteredElems[i].classList.contains("js-content-is-hidden")){
                            filteredElems[i].classList.remove("js-content-is-hidden");
                        };
                    };
                    break;
                default:
                    for(let i = 0; i < filteredElems.length; i++){
                        let match = false;
                        let filterGroupArr = filteredElems[i].getAttribute("data-filter-group").split(" ");
                        for(let i = 0; i < filterGroupArr.length; i++){
                            if(filterGroupArr[i] == controller.getAttribute("data-value")){
                                match = true;
                            };
                        };

                        switch(match){
                            case true:
                                if(filteredElems[i].classList.contains("js-content-is-hidden")){
                                    filteredElems[i].classList.remove("js-content-is-hidden");
                                };
                                break;
                            case false:
                                if(!filteredElems[i].classList.contains("js-content-is-hidden")){
                                    filteredElems[i].classList.add("js-content-is-hidden");
                                };
                                break;
                        };
                    };
                    break;
            };
        };
    }		 
            let filters = document.querySelectorAll(".js-select-filter");
    if(filters){
        for(let i = 0; i < filters.length; i ++){
            let filter = new SelectFilter(filters[i]);
        };
    };		
		
		
		
		
		
		
		
		
	document.querySelector("#tagcloud-list").innerHtml = this.state.dataResourceTags;	
		
		
		
    }
  
    render(){
        const { partTitle, dataResourceQuickstart, dataResourceTags } = this.state;
        
		
		document.getElementById("root").style.background = "#fafafb";

            return(
                <>
                    <div className="qa-page page">
                        <div className="container ">
                            <div className="row">
                                    <div className="col-md-12 breadcrumbs">
                                        <ul>
                                            <li className="">
                                                <a className="codexTreeFont" href="/">
                                                Главная
                                                </a>
                                            </li>										
                                            <li className="">
                                                <a className="codexTreeFont" href="/qa/">
                                                Вопрос-ответ
                                                </a>
                                            </li>
                                            <li className="">
                                                {partTitle}
                                            </li>
                                        </ul>
                                    </div>							
								<PageTitle pageTitle={partTitle && partTitle} />

                                </div>
                            <div className="row">
                                <div className="col-md-9 grid_container-faqs js-filtered-content" data-content="doctors">
										{dataResourceQuickstart && dataResourceQuickstart.payload.list.nodes.map((answer, i) => {
											let item_content = answer.element.content;
											let item_group_name = answer.element.group_name;
											let item_title = answer.element.title.replace(/\s+/g, ' ');
											//item_title = item_title.replaceAll(" по ст. "+item_group_name+" УК РФ","");
											//item_title = item_title.replaceAll("по ст. "+item_group_name+"УК РФ","");
											let item_id = answer.element.id;
											return(
											<>
                                                <div className="faq-div" key={item_id} data-filter-group={"all-branches "+answer.element.tags}>
                                                    <h4><span>{item_title}</span></h4>
                                                    <p dangerouslySetInnerHTML={this.createMarkup(item_content)}></p>
						                            <a href={"/qa/"+item_id}>Перейти</a>
                                                </div>														
                                            </>                    
                                            );
                                        })}
                                </div>
								<div className="col-md-3">
								    <div className="right-nav aside-nav">
                    <nav>
                        <a href="/qa/part_ap/" ><span>Административные дела</span></a>
                        <a href="/qa/part_up/" ><span>Уголовные дела</span></a>
                        <a href="/qa/part_np/"><span>Налоговые дела</span></a>
                    </nav>
					
					<div>
					    <div className="tagcloud-wrapper js-select-filter" data-for="doctors">
            <ul id="tagcloud-list" className="tagcloud-wrapper__list js-choice" choices-black autocomplete="off">
			</ul>
										</div>
					</div>
					
					
                    </div>
				</div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="qa-search-description"></div>
                                        </div>
                                        {/*<div className="col-md-12">
                                            <Link to="/qa" className="situation_money" style={situation_money}>
                                                <div className="situation_title" style={situation_title}>Вопрос-Ответ</div>
                                            </Link>
                                        </div>
                                        <div className="col-md-12" style={{clear:"left", height: "45px"}}><hr /></div>
                                        <div className="col-md-12">
                                            <h5>Разделы:</h5>
                                        </div>
                                        <div className="col-md-12">
                                            <a href="/qa/part_ap/" className="situation_money" style={situation_money}>
                                                <div className="situation_title" style={situation_title}>По административным делам</div>
                                            </a>
                                        </div>
                                        <div className="col-md-12">
                                            <a href="/qa/part_up/" className="situation_hummer" style={situation_hummer}>
                                                <div className="situation_title" style={situation_title}>По уголовным делам</div>
                                            </a>
                                        </div>
                                        <div className="col-md-12">
                                            <a href="/qa/part_np/" className="situation_nalog" style={situation_nalog}>
                                                <div className="situation_title" style={situation_title}>По налоговым делам</div>
                                            </a>
                                        </div>/**/}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                <br /><br /><br /><br /><br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <script>
                        $(function(){ });
                    </script>
                </>
            );
    }
}

export default ResourcePage;