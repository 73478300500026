import React from 'react'
import Subject from "./subject";
import './style.css'
import Section from "../docs/section";
import "../../global.js";
import SubjectsElement from "./subjectsElement";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import "./subjects.style.css";
import BreadCrumbs from "../elements/BreadCrumbs";
import PageTitle from "../elements/PageTitle";
import {PremiumAccess} from "../../layouts/personal-cab/PremiumAccess";

class Subjects extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            region_id: props.match.params.region_id ? props.match.params.region_id : 0,
            subjects: [],
            countItems: 0,
            isPremium: Boolean(localStorage.getItem('isPremium')?(JSON.parse(localStorage.getItem('isPremium'))):false),
            isAuth: Boolean((localStorage.getItem('tokenAPI'))?true:false),
        };


    }

    componentDidMount() {
        let url = global.apiLink + "/subjects/subject/list?region_id=" + this.state.region_id;
        fetch(url)
            .then(res => res.json())
            .then((result) => {
                    if (result.payload) {
                        this.setState({
                            subjects: result.payload,
                            countItems: result.payload.length
                        });
                        //Прилипание карты НАЧАЛО

                        var docPreview = document.getElementById("region_map");
                        var docPreviewWidth = parseInt(docPreview.offsetWidth);
                        let scrollTop = window.pageYOffset;
                        var docPreviewBounding = docPreview.getBoundingClientRect();
                        var docPreviewTop = parseInt(scrollTop + docPreviewBounding.top),
                            heightWindow = window.innerHeight,
                            heightDocument = document.documentElement.scrollHeight;
                        docPreview.style.margin = "0!important";

                        var TopMenuHeight = document.querySelector(".block_1 .site-header").offsetHeight;

                        window.addEventListener('scroll', function (event) {

                            const resetMapStyles = () => {
                                docPreview.style.position = "relative";
                                docPreview.style.width = "auto";
                                docPreview.style.top = "auto";
                                docPreview.style.bottom = "auto";
                            }

                            scrollTop = window.pageYOffset;
                            let itemTranslate = Math.max(0, (scrollTop + heightWindow + (TopMenuHeight + 5)) - heightDocument);

                            if (scrollTop + heightWindow + (TopMenuHeight + 5) >= heightDocument) {
                                if (this.document.body.clientWidth <= 992) {
                                    resetMapStyles();
                                    return;
                                }
                                docPreview.style.position = "fixed";
                                docPreview.style.width = docPreviewWidth + "px";
                                docPreview.style.top = "auto";
                                docPreview.style.bottom = (itemTranslate + 5) + "px";
                            } else if ((docPreviewTop - (scrollTop + TopMenuHeight + 5)) < 0) {
                                if (this.document.body.clientWidth <= 992) {
                                    resetMapStyles();
                                    return;
                                }
                                docPreview.style.position = "fixed";
                                docPreview.style.width = docPreviewWidth + "px";
                                docPreview.style.top = (TopMenuHeight + 5) + "px";
                                docPreview.style.bottom = "auto";
                            } else {
                                docPreview.style.position = "relative";
                                docPreview.style.width = "auto";
                                docPreview.style.top = "auto";
                                docPreview.style.bottom = "auto";
                            }
                        });

                        //Прилипание карты КОНЕЦ

                    }
                },
                (error) => {
                    console.log(error);
                });


        url = global.apiLink + "/regions/region/get?id=" + this.state.region_id;
        fetch(url)
            .then(res => res.json())
            .then((result) => {
                    if (result.payload) {
                        this.setState({
                            regionInfo: result.payload
                        });
                    }
                },
                (error) => {
                    console.log(error);
                });
    }

    render() {
        document.getElementById("root").style.background = "#fff";
        const {
            subjects, 
            countItems, 
            regionInfo,
            isPremium,
            isAuth
        } = this.state;
        let listSubjects = null;
        let cardCoordinates = [];


        const breadcrumbs = [
            {
                name: 'Главная',
                path: '/',
            },
            {
                name: 'Справочная информация',
                path: '/regions/',
            },
            {
                name: regionInfo && regionInfo.name,
                path: '',
            }
        ];
        if (subjects.length > 0) {
            listSubjects = subjects.map((section) => {
                if (parseInt(section.coordx) && parseInt(section.coordy)) {
                        /* let settings = {};
                        settings.section = [section.coordx, section.coordy];
                        cardCoordinates.push(settings);
 */
                        let settings = {};
                        let coords = [section.coordx, section.coordy];
                        settings.section = {
                            type: 'Point',
                            coordinates: coords
                        };
                        settings.properties = {
                            balloonContentBody: "<h6>" + section.name + "</h6> <br /> " + section.str_address,
                            balloonContent: section.str_address,
                            clusterCaption: section.name,
                            hintContent: section.name
                        };
                        cardCoordinates.push(settings);

                    }
                    //console.log(section.str_address);
                    return <SubjectsElement key={section.id} sectionid={section.id} name={section.name}
                                            contacts={section.contacts}
                                            schedule={section.schedule} type={section.type_id}
                                            str_address={section.str_address}/>
                }
            )
        }
        //cardCoordinates.map(element => console.log(element));


        if (countItems) {
            let StyleMap = {
                border: "1px solid #f0f0f0",
                padding: "0",
                borderRadius: ".25",
                width: "100%",
                height: "95vh",
                margin: "0",
                bottom: "0"
            };


            return (
                <>
                    <div className="page-docs page">
                        <div className="container ">
                            <div className="row">
                                <BreadCrumbs crumbs={breadcrumbs}/>
                                <PageTitle pageTitle={regionInfo && regionInfo.name}/>
                            </div>

                            <div className="row page-docs__content">
                                <div className="col-lg-6 col-md-6 col-12 page-docs__subjects">
                                    <div className="PREloader">{listSubjects}</div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 page-docs__map">
                                    {isAuth ? <div id="region_map">
                                        {/*{cardCoordinates.length > 0 &&*/}
                                        <YMaps
                                            query={{
                                                apikey: 'df639532-a255-4e82-b3e0-06ad7483393e',
                                                load: 'package.full'
                                            }}>
                                            <Map
                                                defaultState={{
                                                    center: cardCoordinates[0].section.coordinates,
                                                    zoom: 12,
                                                    controls: ['zoomControl', 'searchControl', 'typeSelector', 'fullscreenControl', 'trafficControl']
                                                }}
                                                modules={["geolocation", "geocode", 'geoObject.addon.balloon', 'geoObject.addon.hint']}
                                                style={StyleMap}
                                                width={"100%"}
                                                height={"95vw"}>
                                                {cardCoordinates.map(element => <Placemark type='Point'
                                                                                           geometry={element.section}
                                                                                           properties={element.properties}></Placemark>)}
                                            </Map>
                                        </YMaps>
                                        {/*}*/}
                                    </div>
                                :
                                <><PremiumAccess CheckForAuth={true} text={<>
                                    <h4 className="alert-box_title alert-heading">Для просмотра карты с государственными организациями необходимо авторизоваться.</h4>
                                    <p>Полный доступ к справочной информации имеют только авторизованные пользователи.</p>
                                </>} /></>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            );
        } else {
            return (
                <div className="container">
                    <div className="filter-search-result-accordion">
                    </div>
                </div>
            );
        }


    }
}

export default Subjects;