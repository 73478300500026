import React from 'react';

const JuristCard = (props) => {
    let {
        href,
        bg,
        title,
        description,
    } = props;

    return (
                        <div className="col-lg-4 col-md-4 col-sm-12 mt-30">
                            <div className="single_service_card">
                                <h4 dangerouslySetInnerHTML={{__html: title}}></h4>
                                <p dangerouslySetInnerHTML={{__html: description}}></p>
                                <a href={href} className="btnd">Подробнее</a>
                                <div className="single_img">
                                    <img src={bg} alt="" />
                                </div>
                                <div className="jus_element">
                                    <img src="/assets/images/bgs/justice_blue.svg" alt="" />
                                </div>
                            </div>
                        </div>	
    );
}

export default JuristCard;