import React from "react";
import LeftMenu_on_LK from '../../../layouts/personal-cab/LeftMenu_on_LK';
import "../../../global";
import PageTitle from "../../elements/PageTitle";
import BreadCrumbs from "../../elements/BreadCrumbs";
import {Helmet} from "react-helmet";

let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));

let APIendpoint = "https://api.my-advo.cat";
let CheckRepeatTime = 120;
let CheckRepeatTimeCounter = 0;
let isLoadingPayStatus = true;

class PendingPay extends React.Component{

    yook_customer_email = React.createRef();
    yook_customer_phone = React.createRef();
    yook_captcha = React.createRef();
    yook_captcha_text = React.createRef();
    yook_captcha_hash = React.createRef();
    yook_captcha_privpol = React.createRef();

    constructor(props) {
        super(props);
        this.state  = {
            payStatus: null,
            isPremium: false,
            isLoading: true,
            isGettingPayStatus: true,
        };
    }

    stringFormat = (value, pattern) => {
        let i = 0;
        const v = value.toString();
        return pattern.replace(/#/g, _ => v[i++]);
    }

    getUserInfo = (f = null) => {
        let self = this;
        let url = APIendpoint + '/api/user/get-profile';
        fetch(url, { 
            method:'GET',
            headers:headers,
        })
            .then(response=>response.json())
            .then((json)=>{
                console.log(json);
                if (json.status !== 200) {
                    localStorage.clear();
                    window.location.href = '/login';
                }else if(json.payload){
                    self.setState( { isLoading: false } );
                    if(f !== null)if(typeof (f) === "function")f();
                }
            });
    }
    

    getPayStatus = (f = null) => {
        let self = this;
        let payid = self.props.match.params.payid;
        let url = APIendpoint + '/api/pay/yookassa-pay/get-pay-status?payid='+payid;
        fetch(url, { 
            method:'GET',
            headers:headers,
        })
            .then(response=>response.json())
            .then((json)=>{
                let res = { payStatus: null };
                if (json.status !== 200) {
                    //window.location.href = '/payments';
                    if(json.status == 404){
                        res = { payStatus: "notfound" };
                    }
                    
                }else if(json.payload){
                    res = { payStatus: json.payload.status };
                    if(json.payload.status == "pending"){
                        setTimeout(() => {
                            if(CheckRepeatTime > CheckRepeatTimeCounter){
                                self.getPayStatus( f );
                                CheckRepeatTimeCounter++;
                            }
                        }, 5000);
                    } else {
                        isLoadingPayStatus = false;
                        res.isGettingPayStatus = false;
                    }
                    console.log(res);
                }
                self.setState( res );
            });
    }

    createMarkup = (s) => {
        return {__html: s};
    }

    componentDidMount() {
        let self = this;
        if( self.props.match.params.payid !== null ){
            self.getUserInfo( () => {
                let url = APIendpoint + '/api/user/get-profile';
                fetch(url, { 
                    method:'GET',
                    headers:headers,
                })
                .then(response=>response.json())
                .then((json)=> {
                    if (json.status !== 200) {
                        localStorage.clear();
                        window.location.href = '/login';
                    }else if(json.payload){
                        self.setState({isLoading: false});
                        self.getPayStatus();
                    }
                });
            } );
        }else{
            console.log("asd");
        }
    }

    render() { 
        let self = this;
        let { 
            isLoading,
            isGettingPayStatus,
            payStatus
        } = self.state;

        const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Ожидание оплаты',
                path: '',				
            },
        ];

        return (
            <>
                <Helmet>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                <div className="page-docs pay_page page">
                    <div className="container">
                        <div className="row">
							<BreadCrumbs crumbs={breadcrumbs} />						
							<PageTitle pageTitle="Оплата" />
                        </div>
                        <div className="row">
                            {isLoading ? 
                                <div className="col-md-12 text-center" dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)} /> 
                            :
                                <>
                                    <div className="col-md-2 mb-3">
                                        <LeftMenu_on_LK />
                                    </div>
                                    <div className={"col-md-10"}>
                                        <div className="row">
                                            <div className="col-lg-12 pay_form_tarif_list">
                                                <div className="mb-5">
                                                    {payStatus == "notfound" ?
                                                        <>
                                                            <h3>Платёж не найден</h3>
                                                                <div 
                                                                    className="price_sale_btn_wrapper price_sale_pay pt-2 text-left"
                                                                >
                                                                    <a
                                                                        className="go_to_callpage_btn"
                                                                        href="/tarifs/"
                                                                        style={{padding: 0, float: "none", display: "block"}}
                                                                    >
                                                                        <div className="go_to_callpage_title text-center"
                                                                            style={{
                                                                                fontSize:"1.1rem", 
                                                                            }}
                                                                        >Оплатить</div>
                                                                    </a>
                                                                </div>
                                                        </>
                                                        :
                                                        <>
                                                            {isGettingPayStatus ? 
                                                                <>
                                                                    <h3>Ожидание оплаты</h3>
                                                                    <div className="col-md-12" dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)} /> 
                                                                </> 
                                                                : 
                                                                <>
                                                                    <h3>Статус платежа получен</h3>
                                                                    {payStatus == "succeeded"?
                                                                        <b>Оплата прошла успешно</b>
                                                                    :
                                                                        <b>Оплата не осуществлена</b>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
export default PendingPay;