import React from "react";
import { Spinner } from "react-bootstrap";


const Button = (props) => {
    const { children, loading, className } = props;
    return (
        <button
            {...props}
            loading={loading.toString()}
            className={`mobile-btn ${className}`}>
            {
                loading ?
                <Spinner animation="border" size="sm" />
                : children
            }
        </button>
    );
}

export default Button;