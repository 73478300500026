import React from 'react';

const SituationCard = (props) => {
    let {
        href,
        bg,
        title,
        description,
    } = props;

    return (
    <li className="single-section-info">
        <div className="section-icon">
            <img src={bg} className="grid_container_item_image" height="55px" />
        </div>
		<div className="section-info">
		    <h3>
		        <a href={href} dangerouslySetInnerHTML={{__html: title}}></a>
		    </h3>
		    { 
		        description.length > 6 
			    ? <p dangerouslySetInnerHTML={{__html: description}}></p> 
			    : "" 
		    }		
        </div>
	</li>
    );
}

export default SituationCard;