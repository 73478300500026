import React from 'react';
import { YMaps, Map } from 'react-yandex-maps';
//import Punycode from 'punycode';

import "../../global.js";
import handleStatus from "../../helper/ajaxStatus";
import {PremiumAccess} from "../../layouts/personal-cab/PremiumAccess";
var tokenAPI = localStorage.getItem("tokenAPI");
var headers = new Headers();
headers.append('Authorization','Bearer '+tokenAPI);
const punycode = require('punycode');

export class Citys_List extends React.Component {
    constructor() {
      super();
  
      this.state = {
        dataJuristPage: '',
        datastateInfo: '',
        datacityInfo: '',
        resourceid: 0,
        dataJuristList: '',
        centerCoord: [],
        isPremium: Boolean(localStorage.getItem('isPremium')?(JSON.parse(localStorage.getItem('isPremium'))):false),
        isAuth: Boolean((localStorage.getItem('tokenAPI'))?true:false),
      }
    }

   utils = {
		dummy: document.createElement('div'),
		escapeHTML: function(s) {
			return {__html: s};
		}
	}
	
gettingJuristPage = async (id) => {
    let output = {};
    const api_url = await 
    fetch(global.apiLink+`api/is/is/getjurist?id=`+id,{headers:headers});
    output.JuristPage = await api_url.json();
    handleStatus(output.JuristPage.status);
    if(output.JuristPage.status !== 200){
        //alert("Не верные данные авторизации list");
        return false;
    }
    return output;
}
	
gettingStateName = async (id) => {
    let output = {};
    const api_url = await 
    fetch(global.apiLink+`api/is/is/get-state?id=`+id,{headers:headers});
    output.stateInfo = await api_url.json();
    handleStatus(output.stateInfo.status);
    if(output.stateInfo.status !== 200){
        //alert("Не верные данные авторизации list");
        return false;
    }
    //console.log("op1");
    //console.log(output);
    return output;
}   

gettingCityName = async (id) => {
    let output = {};
    const api_url = await 
    fetch(global.apiLink+`api/is/is/get-city?id=`+id,{headers:headers});
    output.cityInfo = await api_url.json();
    handleStatus(output.cityInfo.status);
    if(output.cityInfo.status !== 200){
        //alert("Не верные данные авторизации list");
        return false;
    }
    //console.log("op2");
    //console.log(output);
    return output;
}
	
gettingJuristAddresses = (addressses) => {
    let count = addressses.length;
    let result = "";
    for(let i = 0; i < count; i++){
        let item = addressses[i];
        let innerText = item["country"]+", "+
        item["state"]+", "+
        item["city"]+", "+
        item["street"]+", "+
        item["house"]+
        (item["office"]?(", "+item["office"]):"");
        result += "<div>"+innerText+"</div>";
    }
    return result;
}

extractHostname = (url)=>{
    var hostname;
    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }
    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];
    return hostname;
}
  

makeListParams = (array, elName, wrappertagname = "div", attributes = "", isLink = false, isIcons = false)=>{
    let SocialIcons = [];
    if(isIcons === true){
        SocialIcons = {
            "vk.com": '<i class="fa fa-vk" aria-hidden="true"></i>',
            "www.instagram.com": '<i class="fa fa-instagram" aria-hidden="true"></i>',
            "www.facebook.com": '<i class="fa fa-facebook" aria-hidden="true"></i>',
            "ok.ru": '<i class="fa fa-odnoklassniki" aria-hidden="true"></i>',
            "twitter.com": '<i class="fa fa-twitter" aria-hidden="true"></i>',
        };
    }
    let count = array.length;
    let result = "";
    for(let i = 0; i < count; i++){
        let item = array[i];
        let innerText = item[elName];
        if(innerText == "-")continue;
        if(isLink == true){
            let title = this.extractHostname(item[elName]);
            if(isIcons === true){
                title = SocialIcons[this.extractHostname(item[elName])];
            }
            if(title === undefined)continue;
            try {
                title = punycode.toUnicode(title);
            } catch (error) {
                console.log(error);
            }
            
            innerText = "<a href='"+item[elName]+"' target='_blank'>"+title+"</a>";
        }
        result += "<"+wrappertagname+" "+attributes+">"+innerText+"</"+wrappertagname+">";
    }
    return result;
}
  
gettingJuristList = async (id) => {
    let output = {};
    const api_url = await 
    fetch(global.apiLink+`api/is/is/juristlistbystate?id=`+id+'&type='+this.props.juristtype,
        {headers:headers});
    output.JuristList = await api_url.json();
    handleStatus(output.JuristList.status);
    if(output.JuristList.status !== 200){
        //alert("Не верные данные авторизации list");
        return false;
    }
    return output;
}

  
getCoordinateProvider = (InnerdataJuristList)=>{
    let output = [];
    if(InnerdataJuristList){
        let dataJuristList_Counter = 0;
        let dataJuristList_Count = InnerdataJuristList.length;
        for(let j = 0; j < dataJuristList_Count; j++){
            let dataJuristItem = InnerdataJuristList[j];
            console.log(dataJuristItem);
            let addresses = dataJuristItem.address;
            let addresses_Count = addresses.length;
            for(let i = 0; i < addresses_Count; i++){
                let addresses_Item = addresses[i];
                let addressStr 	=	addresses_Item["country"]+", "+
                                    addresses_Item["state"]+", "+
                                    addresses_Item["city"]+", "+
                                    addresses_Item["street"]+", "+
                                    addresses_Item["house"]+
                                    (addresses_Item["office"]?(", "+addresses_Item["office"]):"");
                output[dataJuristList_Counter++] = { 
                    "address" : addressStr,
                    "name" : dataJuristItem["name"],
                    "juristuser_id" : dataJuristItem["juristuser_id"],
                    "jurist_type" : dataJuristItem["jurist_type"],
                    "city_id" : dataJuristItem["city_id"],
                    "state_id" : dataJuristItem["state_id"]
                };
            }
        }
        
    }
    return output;
}
  
componentDidMount() {
    let self = this;

    let url = global.apiLink + '/api/user/get-profile';
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
    
    fetch(url, {
        method: 'GET',
        headers: headers
    })
    .then(response => response.json())
    .then((response) => {
        console.log(response);
        if (response.status !== 200) {
            localStorage.clear();
            window.location.href = '/login';
        }
    });

    const cityid = self.props.cityid;
    const stateid = self.props.stateid;
    const juristid = self.props.juristid;
    
    self.setState({
        stateid: stateid
    });

    self.gettingStateName(stateid).then(output => {
        self.setState({
            datastateInfo: output.stateInfo,
            stateName: output.stateInfo.payload.value
        });
    });
	  
	
    self.gettingCityName(cityid).then(output => self.setState({
        cityName: output.cityInfo.payload.value
    }));

    self.gettingJuristList(stateid).then(output => self.setState({
        dataJuristList: output.JuristList,
        JuristCount: output.JuristList.payload.count
    }));

    self.gettingJuristPage(juristid).then(output => self.setState({
        dataJuristPage: output.JuristPage,
    }));
    
}

  createMarkup = (s) => {
    return {__html: s};
  }
  
render(){
	var myMap;
    let self = this;
    const cityid = self.props.cityid;
    const juristtype = self.props.juristtype;
    
    var { 
        stateName, 
        dataJuristList, 
        stateid, 
        dataJuristPage, 
        cityName,
        isPremium,
        isAuth
    } = self.state;

    var JuristType = "";
    var JuristName = "";
    var JuristServices = "";
    var JuristSocNets = "";
    var JuristLinks = "";
    var JuristLinksText = "";
    var JuristPhones = "";
    var JuristPhonesText = "";
    var JuristAddresses = "";
    var JuristAddressesText = "";
    var JuristRegNum = "";
    var WorkingTime = "";
    var socialnetsText = "";
    let image = "";
    if(dataJuristPage){
        JuristName = self.state.dataJuristPage.payload.name;
        JuristRegNum = self.state.dataJuristPage.payload.reg_num;

        image = self.state.dataJuristPage.payload.image;
        if(!image) image = "/assets/images/no-avatar-300x300.png";

        JuristSocNets = self.state.dataJuristPage.payload.socialnets;
        socialnetsText = self.makeListParams(JuristSocNets, "link", "li", ' class="social_icon_item"', true, true);
        
        JuristPhones = self.state.dataJuristPage.payload.phones;
        JuristPhonesText = self.makeListParams(JuristPhones, "number", "li", ' class=""');
        
        JuristLinks = self.state.dataJuristPage.payload.links;
        JuristLinksText = self.makeListParams(JuristLinks, "link", "li", ' class="link_icon_item"', true);
        
        console.log(self.state.dataJuristPage.payload); 
        JuristAddresses = self.state.dataJuristPage.payload.address;
        JuristAddressesText = self.gettingJuristAddresses(JuristAddresses);

        let jurist_type= self.state.dataJuristPage.payload.jurist_type;
        if(jurist_type == "lawyer") JuristType = "Адвокат";
        if(jurist_type == "jurist") JuristType = "Юрист";
        if(jurist_type == "notary") JuristType = "Нотариус";
        JuristServices = self.state.dataJuristPage.payload.services;
        WorkingTime = self.state.dataJuristPage.payload.workingtime;
    }
    
	let JuristTypeText = "Нотариусы";
	if(juristtype == "lawyer"){
		JuristTypeText = "Адвокат";
	}else if(juristtype == "jurist"){
		JuristTypeText = "Юрист";
    }else JuristTypeText = "Нотариус";



    

    var CoordinateArrayX = [];
    var CoordinateArrayY = [];

	function myGeoCode(ymaps, address) {
		ymaps.geocode(address.address, {
			results: 1
		}).then(function (res) {
			let firstGeoObject = res.geoObjects.get(0);
			
			let coords = firstGeoObject.geometry.getCoordinates();
            CoordinateArrayX[CoordinateArrayX.length] = coords[0];
            CoordinateArrayY[CoordinateArrayY.length] = coords[1];
            
		});
		
	}

    function MiddleArithmeticalNumber (x){
        let s = x.reduce((a,b)=>parseFloat(a) + parseFloat(b))
        return s / x.length;
     }

     var centerX, centerY = [];
     

    function PaintToMap (myMap, objectManager){
        centerX = MiddleArithmeticalNumber(CoordinateArrayX);
        centerY = MiddleArithmeticalNumber(CoordinateArrayY);
        self.setState({
            centerCoord: [centerX.toFixed(6), centerY.toFixed(6)],
        });
        myMap.geoObjects.add(objectManager);
        myMap.setBounds(objectManager.getBounds(), {zoomMargin: 10});
    }

    async function init (ymaps, myMap) {
		if(!dataJuristPage)return false;
        console.log("dataJuristPage");
        console.log(dataJuristPage);
        
		let dataJuristArray = self.getCoordinateProvider([dataJuristPage.payload]);
        console.log("dataJuristArray");
        console.log(dataJuristArray);
		if(!dataJuristArray)return false;
		//dataJuristArray = self.getCoordinateProvider(dataJuristList.payload.array);
        if(dataJuristArray) setTimeout(function(){
            
                    var objectManager = new ymaps.ObjectManager({
                        clusterize: true,
                        gridSize: 32,
                        clusterDisableClickZoom: true
                    });
                    objectManager.objects.options.set('preset', 'islands#blueDotIcon');
                    objectManager.clusters.options.set('preset', 'islands#blueClusterIcons');
            var itemsProcessedmGC = 0;
            
            dataJuristArray.forEach( (address) => {
                itemsProcessedmGC++;
                myGeoCode(ymaps, address);
                if(itemsProcessedmGC === dataJuristArray.length) {
                    setTimeout(function(){
                            var objects = [];
                            var itemsProcessed = 0;
                            dataJuristArray.forEach( (address, index) => {
                                    itemsProcessed++;
                                    console.log(address);
                                    let coords = [ CoordinateArrayX[index], CoordinateArrayY[index] ];
                                    let JuristLink = "/is/"+address.jurist_type+"/"+address.state_id+"/"+address.city_id+"/"+address.juristuser_id;
                                    objects.push({
                                        type: 'Feature',
                                        id: index,
                                        geometry: {
                                            type: 'Point',
                                            coordinates: coords
                                        },
                                        properties: {
                                            balloonContent: address.address,
                                            clusterCaption: address.name,
                                            hintContent: address.name,
                                            balloonContentBody:  '<a href="'+JuristLink+'" target="_blank">'+address.name+'</a>'+ '<br />'+address.address
                                        }
                                    });
                                    if(itemsProcessed === dataJuristArray.length) {
                                        setTimeout(function(){
                                            objectManager.add(objects);
                                            centerX = MiddleArithmeticalNumber(CoordinateArrayX);
                                            centerY = MiddleArithmeticalNumber(CoordinateArrayY);
                                            self.setState({
                                                centerCoord: [centerX.toFixed(6), centerY.toFixed(6)],
                                            });
                                            myMap.geoObjects.add(objectManager);
                                            //myMap.setBounds(objectManager.getBounds(), {zoomMargin: 4});
                                        }, 500);
                                    }
                            });
                        
        
                    }, 500);
                }
            } );
        }, 1000);
    }
        return (
            <div className="page-is page">
                <div className="container quick-start">
                    <div className="row">
                    <div className="col-md-12 quick-start-title-wrapper">
                        <div className="uk-h1 quick-start-header-title">Адвокаты, юристы, нотариусы</div>
                        <div className="all-hr all-hr-2 color-lightblue"></div>
                    </div>
                    <div className="col-md-12 breadcrumbs mb-3">
								<ul className="">
                                            <li ><a href="/is/">Адвокаты, юристы, нотариусы</a></li>
                                            <li ><a href={"/is/"+juristtype}>{JuristTypeText}</a></li>
                                            <li ><a href={"/is/"+juristtype+"/"+stateid+"/"}>{stateName}</a></li>
											<li ><a href={"/is/"+juristtype+"/"+stateid+"/"+cityid+"/"}>{cityName}</a></li>
                                            <li >{JuristName}</li>
                                        </ul>
                    </div>
                        <div className="bgs">
                        </div>
                        <div className="col-lg-8">
                            <div className="col-md-12 header">
                                <div className="card card-default uk-card-body shadow-sm" >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <img src={image} class="rounded float-left" style={{width:"100%"}} alt="..." />
                                                    </div>
                                                    <div className="col-md-9">
                                                        <h1 className="h3">{JuristName}</h1>
                                                        <p className="lead">{JuristType}</p>
                                                        <p className={"lead " + (!JuristRegNum && "d-none")}>
                                                            Регистрационный номер: <b>{JuristRegNum}</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12"> <hr /> </div>
                                            <div className="col-md-12"> 
                                                <h4 className="h5">Адреса:</h4>
                                                <ul className="list-unstyled" dangerouslySetInnerHTML={this.createMarkup(JuristAddressesText)} />
                                            </div>
                                            <div className={"col-md-12 " + (!JuristPhonesText && "d-none")}> <hr /> </div>
                                            <div className={"col-md-12 " + (!JuristPhonesText && "d-none")}> 
                                                <h4 className="h5">Номера телефонов:</h4>
                                                <ul className="list-unstyled" dangerouslySetInnerHTML={this.createMarkup(JuristPhonesText)} />
                                            </div>
                                            <div className={"col-md-12 " + (!JuristLinksText && "d-none")}> <hr /> </div>
                                            <div className={"col-md-12 " + (!JuristLinksText && "d-none")}> 
                                                <h4 className="h5">Сайты:</h4>
                                                <ul className="list-unstyled" dangerouslySetInnerHTML={this.createMarkup(JuristLinksText)} />
                                            </div>
                                            <div className={"col-md-12 " + (!socialnetsText && "d-none")}> <hr /> </div>
                                            <div className={"col-md-12 " + (!socialnetsText && "d-none")}> 
                                                <h4 className="h5">Социальные сети:</h4>
                                                <ul className="list-unstyled" dangerouslySetInnerHTML={this.createMarkup(socialnetsText)} />
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card card-default uk-card-body mb-3 shadow-sm" >
                                <div className="row">
                                            <div className="col-md-12">
                                                <h4 className="h5 mb-4">Оказываемые услуги:</h4>
                                                <ul className="list-unstyled">
                                                    {JuristServices && JuristServices.map( (answer, i) => {
                                                        return(
                                                            <>
                                                                <li className="btn btn-biryza btn-biryza-mod">{answer.title}</li>
                                                            </>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                            {(WorkingTime) && 
                                                <>
                                                    <div className="col-md-12"> <hr /> </div>
                                                    <div className="col-md-12">
                                                        <h4 className="h5">Рабочее время:</h4>
                                                        <ul className="list-unstyled">
                                                                <li className=""><b>Понедельник:</b> {WorkingTime.ponedelnik}</li>
                                                                <li className=""><b>Вторник:</b> {WorkingTime.vtornik}</li>
                                                                <li className=""><b>Среда:</b> {WorkingTime.sreda}</li>
                                                                <li className=""><b>Четверг:</b> {WorkingTime.chetverg}</li>
                                                                <li className=""><b>Пятница:</b> {WorkingTime.pyatnica}</li>
                                                                <li className=""><b>Суббота:</b> {WorkingTime.subbota}</li>
                                                                <li className=""><b>Воскресение:</b> {WorkingTime.voskresenie}</li>
                                                        </ul>
                                                    </div>
                                                </>
                                            }
                                            
                                </div>
                            </div>
                            {isPremium ? <div className="card card-default  shadow-sm" >
                                <div id="YMapsIDcallbackID" style={{width:"100%"}}>
                                    <YMaps
                                            query={{
                                                ns: 'use-load-option',
                                                apikey: '078de686-4f74-432f-b339-5f518467d195',
                                                load: 'package.full'
                                            }}
                                        >
                                        <Map
                                            key={dataJuristList}
                                            defaultState={self.state.centerCoord && { center: self.state.centerCoord, zoom: 5}} height="600px" width="100%" 
                                            state={self.state.centerCoord && {
                                                center: self.state.centerCoord,
                                                zoom:15,
                                                controls: ['zoomControl', 'searchControl', 'typeSelector',  'fullscreenControl', 'trafficControl']
                                            }}
                                            modules={["geolocation", "geocode", 'geoObject.addon.balloon', 'geoObject.addon.hint']}
                                            onLoad={ymaps => {
                                                ymaps.ready(() => {
                                                    init(ymaps, myMap)
                                                });
                                            }}
                                            instanceRef={yaMap => {
                                                if (yaMap) {
                                                    myMap = yaMap;
                                                }
                                            }}
                                            />
                                        </YMaps>
                                </div>
                            </div>:<><div className="card card-default  shadow-sm" >
									<div className='card-body'>
										<PremiumAccess CheckForPremium={true} text={<>
											<h4 className="alert-box_title alert-heading">Хотите получить возможность просматривать карту с местоположением юристов?</h4>
											<p>Полный доступ к карте с расположением юристов, адвокатов и нотариусов имеют только Премиум пользователям.</p>
										</>} />
										</div>
								</div></>}
                        </div>
                    </div>
                </div>
            </div>
        );
}
}

export default Citys_List;