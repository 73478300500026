import React from 'react';
import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";
import "../../global.js";

export class ContactUs extends React.Component {
  
    render(){
        document.getElementById("root").style.background = "#fafafb";
        const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Контактная информация',
                path: '',				
            },
        ];			
            return(
                <>
                <div className="page-priv-pol page">
                    <div className="container quick-start ">
                        <div className="row">
						    <BreadCrumbs crumbs={breadcrumbs} />							 
                            <PageTitle pageTitle="Контактная информация" />
						</div>
						<div className="row"> 
                            <div id="ce_mainContainer" style={{maxWidth:"100%"}}>
                                <p><b>E-mail:</b> myadvocat@pl-llc.ru</p>
                                <p><b>Телефон:</b> 8 (800) 550 89 72 (звонок по РФ бесплатный)</p>
								<p><b>Режим работы:</b> 24/7</p>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            );
    }
}

export default ContactUs;