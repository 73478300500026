import React from 'react';
import {Link} from 'react-router-dom';

import OwlCarousel from 'react-owl-carousel';
import PanelCard from './panel/PanelCard';
import GridCard from './panel/GridCard';
import MediaQuery from 'react-responsive';
import Header from './panel/Header';
import PageTitle from "./elements/PageTitle";


import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./panel.styles.css";

import "../global";
import * as utils from '../utils.js';

let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));

export class Panel extends React.Component {
     

    constructor(props) {
        super(props);

        this.state = {
            pageData: [
                {
                    title: global.servicesTitle,
                    items: global.servicesList
                }
            ],
            presets_resources: null,
            news: null
        }
    }

    getComplete = async () => {
		
        let query = document.querySelector(".all-search-input").value;
        let target = document.querySelector("input[name=target]").value;

        if (query != null) {
        	query = utils.escapeAndTrim(query);
        } else {
			query = '';
		}
        console.log('query=' + query);
        if (query.length < 3) {
        	document.querySelector(".all_search_autocomplete").innerHTML = '';
        	return false;
        }

        if (target != null) {
        	target = utils.escapeAndTrim(target);
        } else {
			target = '';
		}
        if (!target || !utils.elementInArray(target, ['all'])) {
        	target = "all";
        } else {
        	target = "all";
        }
        console.log('target=' + target);
        /*if (target.length) {
        	target = '&target=' + target;
        } else {
        	target = '';
        }*/

        let url = global.apiLink + '/api/search/complete?q=' + query + '&target=' + target;

        const api_ResourceLL = await fetch(url, {
        	headers: headers
        });
        var output = await api_ResourceLL.json();

        if (output.status == 200) {
        	let list = output.payload.list;
        	let resultComplete = "";
        	for (let i = 0; i < list.length; i++) {
        		let linkTitleTmp = list[i].tags;
        		let linkUrlTmp = '/search/?target=' + target + '&q=' + linkTitleTmp.split(" ").join("+");
        		resultComplete += "<div onclick='window.location.href=\"" + linkUrlTmp + "\";'>" + linkTitleTmp + "</div>";
        	}

        	let curQuery = document.querySelector(".all-search-input").value;
        	if (curQuery != null) {
        		curQuery = utils.escapeAndTrim(curQuery);
        	} else {
				curQuery = '';
			}
        	console.log('curQuery=' + curQuery);
        	if (curQuery.length > 2) {
        		document.querySelector(".all_search_autocomplete").innerHTML = resultComplete;
        	} else {
        		document.querySelector(".all_search_autocomplete").innerHTML = '';
        	}
        } else {
        	return false;
        }
    }

    getPresets = async () => {
        let url = global.apiLink + '/api/preset/preset/list?limit=8';

        const api_ResourceLL = await fetch(url, { headers: headers });
        var output = await api_ResourceLL.json();

        if (output.status == 200) {
            if(output.payload.list !== undefined){
                let list = output.payload.list;
                this.setState({presets_resources:list});
            }
        }else{
            console.log("Не верные данные авторизации getComplete");
            return false;
        }
    }

    getNews = async () => {
        let limit = 4;
        let url = global.apiLink + 'news/list?page=1&limit='+limit;
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === 200) {
                        this.setState({
                            news: result.payload.news,
                        });
                    }
                }
            );
    }

    componentDidMount() {
        this.getPresets();
        this.getNews();
    }

    render(){
        let {presets_resources, news} = this.state;

        var MonthArray=[
               'ЯНВ',
               'ФЕВ',
               'МАР',
               'АПР',
               'МАЙ',
               'ИЮН',
               'ИЮЛ',
               'АВГ',
               'СЕН',
			   'ОКТ',
               'НОЯ',
               'ДЕК',
            ];
            
            return(
                <>
                <MediaQuery minWidth={768}>
                <div className="page-panel page">
                    <div className="container ">
                            <div className="row">
							    <PageTitle pageTitle="Мобильная правовая система «Мой адвокат»" />
                                {/*<div className="col-md-12 quick-start-title-wrapper">
                                    <h1 className="uk-h1 quick-start-header-title">
									    <span className="quick-start-title-border">Мобильная правовая система</span>
									</h1>
	</div>*/}
                                <div className="col-md-12">
                                    <div className="all-search-wrapper">
                                        <form className="uk-search" method="GET" action="/search/" style={{display: "block"}}>
                                            <input type="hidden" name="target" value="all" />
                                            <label for="search"><i className="fa fa-search" aria-hidden="true"></i><span className="sr-only">Поиск</span></label>
                                            <input type="search" name="q" onChange={()=>{this.getComplete()}} placeholder="Что делать, если..." autocomplete="off" className="all-search-input" maxLength={255} required="required" />
                                            <button type="submit" className="search_btn" >Найти</button>
                                            <div className="all_search_autocomplete"></div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 mt-3 need_a_help">
                                    <div className='bg_full_width'></div>
                                    <div className="card text-dark mb-5 shadow-sm go_to_call_page_wrapper mt-4" style={{background: "rgb(230 245 251)",border: "solid 2px rgb(194 235 250)"}}>
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-lg-2 col-md-12 text-center'>
                                                    <img src="/assets/images/bg_btn_male.png" className='bg_callbtn bg_btn_male' style={{width:"300px"}} />
                                                </div>
                                                <div className='col-lg-10 col-md-12 go_to_call_page_description'>
                                                    <div className='row'>
                                                        <div className='col-lg-8 col-md-12 col-sm-12 col-12'>
														    <h2>Нужна срочная юридическая помощь?</h2>
                                                            <h4 style={{color:"#3f3f3f"}}>Получите квалифицированный совет по видеозвонку от ведущих адвокатов и юристов России.</h4>
															<h5 style={{color:"#777"}}>Оперативно. Конфиденциально.</h5>
                                                        </div>
                                                        <div className='col-lg-4 col-md-12 col-sm-12 col-12 pulse-button-wrap'>
                                                            {/*}<a href="/call/" className="pulse-button">
            
                                                                <span className="pulse-button__icon">
                                                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                                                </span>
                                                                <span className="pulse-button__text">Мне нужна консультация</span>

                                                                <span className="pulse-button__rings"></span>
                                                                <span className="pulse-button__rings"></span>
                                                                <span className="pulse-button__rings"></span>

                                                            </a>{/**/}
                                                            <a href="/call/?do" className="go_to_callpage_btn">
															    <div className="go_to_callpage_icon">
                                                                    <img className="phone_image_on_need_a_halp" src="/assets/images/panel/Shape.png" />
                                                                </div>
																<div className="go_to_callpage_title">
																    Позвоните нам 
																</div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 {/*}<div className="col-md-12">
                                    <div className='row'>
                                        <div className="col-md-6"><h2 className='mb-4'>Ситуации наших последних клиентов</h2></div>
                                        <div className="col-md-6" style={{textAlign:"right"}}><Link to="/preset/" className="read_more_on_panel">Читать все</Link></div>
                                    </div>
                                    <div className='oul-courusel-wrapper mb-4'>
                                        {presets_resources && <OwlCarousel items={4} className="owl-theme" loop margin={30}> 
                                            {presets_resources.map((preset_item)=>{
                                                return (
                                                    <div>
                                                        <Link to={"/preset/p"+preset_item.id} className='shadow-sm panel_preset_item'>
                                                            <img className="img shadow-sm" src= {preset_item.pic}/>
                                                            <div class="carousel-caption d-none d-md-block">
                                                                <h5 dangerouslySetInnerHTML={{__html: preset_item.title}}/>
                                                                <div dangerouslySetInnerHTML={{__html: preset_item.description}}/>
                                                            </div>
                                                        </Link>
                                                    </div> 
                                                );
                                            }
                                            )} 
                                            </OwlCarousel>
                                        }
                                    </div>
                                </div>{/**/}
								

        <div className="col-md-12 mt-5 mb-5 about_wrapper section-padding overflow-hidden">
            <div className="row ">
                <div className="col-lg-6 ps-2 ps-lg-4">
                    <div className="about_content about_content_2">
                        <div className="section-title title-1 title-3">
                            <h2 className="mb-4">О нас</h2>
                            <h4>Квалифицированная, оперативная, конфиденциальная юридическая помощь людям, попавшим в трудную жизненную ситуацию.</h4>
                        </div>
                        <p>«Мой адвокат» — это правовая система, в которой каждый найдет ответ по любому юридическому вопросу от опытных адвокатов и юристов по всей РФ.</p>
                        <p>Уголовное преследование, административные споры, бытовые правонарушения, проблемы с налогами — по этим и другим вопросам Вы можете обратиться в нашу круглосуточную службу поддержки в любое удобное время и получить консультацию в кратчайшие сроки.</p>
                        
                        <div className="row">
                            <div className="col-md-4">
                                <div className="single_author">
                                    <img src="/assets/images/panel/signature.svg" alt="" />
                                </div>
                            </div>
                            <div className="col-md-4">
                               <div className="author_name">
                                <h5>Вячеслав Чулков</h5>
                                <small>(адвокат)</small>
                               </div>
                            </div>
                            <div className="col-md-4">
                                <a href="/about/" className="btnd mt-35">Подробнее</a>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
				
                <div className="col-lg-6 mt-4 mt-lg-0" style={{"paddingLeft": "64px"}}>
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="single_about_img single_about_img_2">
                                <img src="/assets/images/panel/chulkov_vv.jpg" alt="" style={{height: "358px;"}} />
                            </div>
                        </div>
                        <div className="col-sm-4 mt-4 mt-sm-0">
                            <div className="about_funfact_items about_funfact_items_2 bg-center bg-cover text-center mb-20" style={{backgroundImage: "url(https://my-advo.cat/assets/images/is/advokat.jpg)", opacity: ".9"}}>
                                <div className="icons">
                                    <img src="/assets/images/panel/icons-laurel.png" alt="" />
                                </div>
                                <h3><span className="counter">мы</span></h3>
                                <h5>поможем</h5>
                            </div>
                            <div className="about_funfact_items about_funfact_items_2  bg-center bg-cover text-center" style={{backgroundImage: "url(https://my-advo.cat/assets/images/is/notarius.jpg)", marginTop: "30px", opacity: ".9"}}>
                                <div className="icons">
                                    <img src="/assets/images/panel/icons-scale.png" alt="" />
                                </div>
                                <h3><span className="counter">24/7</span></h3>
                                <h5>всегда на связи</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</div>
								
                                {
                                    this.state.pageData.map((block, i) => {
                                        return (<>
										{/*<div className="col-md-12 mt-5">
                                                <h2>{block.title}</h2>
										</div>/**/}
                                            <div className="col-md-12 mt-6 mb-5">
                                                <div className="grid_container grid_container-main row">
												    <div className="grid_container_item-head">
                                                        <div className="grid_container_item-head_title">
                                                            <h2 className="mb-4">{block.title}</h2>
                                                            {/*<h4>База знаний по юридическим вопросам</h4>/**/}
                                                        </div>
                                                        <div className="grid_container_item-head_description">
                                                            Нормативно-правовые документы, кодексы РФ в актуальной редакции, база адвокатов, конструктор документов и другая востребованная правовая информация.
                                                        </div>
                                                    </div>												
                                                    {
												
                                                        block.items.map(item =>  item.position=="global" ? <GridCard key={item.href} {...item} /> : <></>)
                                                    }
                                                </div>
                                            </div>
                                        </>);
                                    })
                                }								
							
                                <div className="col-md-12 mt-5 can_consult">
                                    <div className='bg_full_width'></div>
                                    <div className="card text-dark mb-5 shadow-sm go_to_call_page_wrapper" style={{background: "rgb(230 245 251)",border: "solid 2px rgb(194 235 250)"}}>
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <img src="/assets/images/bg_callbtn.png" className='bg_callbtn' />
                                                </div>
                                                <div className='col-md-9 go_to_call_page_description'>
                                                    <div className='row'>
                                                        <div className='col-lg-12 text-left'>
                                                            <h2>Готовы проконсультировать вас</h2>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                            <h5 style={{color:"#777"}}>
                                                                Ведущие юристы и адвокаты России готовы ответить на правовые вопросы в специальном чате. Вы можете задать любые вопросы и запросить помощи по вашим юридическим делам.
                                                            </h5>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-sm-12 col-12 text-center pulse-button-wrap'>
                                                            {/*}<a href="/call/" className="pulse-button">
            
                                                                <span className="pulse-button__icon">
                                                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                                                </span>
                                                                <span className="pulse-button__text">Мне нужна консультация</span>

                                                                <span className="pulse-button__rings"></span>
                                                                <span className="pulse-button__rings"></span>
                                                                <span className="pulse-button__rings"></span>

                                                            </a>{/**/}
															{/*<a href="/chat/" className="go_to_callpage_btn">
                                                                Мне нужна консультация
	                                                        </a>*/}
															
                                                            <a href="/chat/" className="go_to_callpage_btn">
															    <div className="go_to_callpage_icon">
                                                                    <img style={{width: "75%"}} className="phone_image_on_need_a_halp" src="/assets/images/panel/icons-chat-64.png" />
                                                                </div>
																<div className="go_to_callpage_title">
																    Напишите нам 
																</div>
                                                            </a>															
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-5">
                                    <div className='row'>
                                        <div className="col-md-6"><h2 className='mb-4'>Ситуации наших последних клиентов</h2></div>
                                        <div className="col-md-6" style={{textAlign:"right"}}><Link to="/preset/" className="read_more_on_panel">Читать все</Link></div>
                                    </div>
                                    <div className='oul-courusel-wrapper mb-5'>
                                        {presets_resources && <OwlCarousel items={4} className="owl-theme" loop margin={30}> 
                                            {presets_resources.map((preset_item)=>{
                                                return (
                                                    <Link to={"/preset/p"+preset_item.id} className='shadow-sm panel_preset_item panel_preset_bg iconbox-style7-hover-moving-border'>
														<div style={{maxHeight: "150px", overflow: "hidden",display:"inline-block"}}>
                                                            <div style={{height: "100%",overflow: "hidden", bottom: "unset", top:"unset", left:"unset", right:"unset", position: "relative", padding: "0"}} class="carousel-caption d-none d-md-block">
                                                                <h5 style={{fontSize:"1.05em", fontWeight: "600"}} dangerouslySetInnerHTML={{__html: preset_item.title}}/>
                                                                <div dangerouslySetInnerHTML={{__html: preset_item.description}}/>
                                                            </div>
                                                        </div>
                                                    </Link> 
                                                );
                                            }
                                            )} 
                                            </OwlCarousel>
                                        }
                                    </div>
                                </div>									


                                <div className="col-md-12 mb-5">
                                    <div className='row'>
                                        <div className="col-md-6"><h2 className='mb-4'>Юридические новости</h2></div>
                                        <div className="col-md-6" style={{textAlign:"right"}}><Link to="/news/" className="read_more_on_panel">Читать все</Link></div>
                                    </div>
                                    <div className='grid_container grid_container-main row'>
                                        {news && news.map((el) => {

                                            let imageLink = "/assets/images/no-image.png";
                                            if (el.image) {
												imageLink = el.image;
											}
                                            let elDate = (el.pubdate).split(" ");
                                            //let dateDB = new Date(el.pubdate);
                                            //let CreatedDay = ('00' + dateDB.getUTCDate()).slice(-2);
                                            //let CreatedMonth = ('00' + (dateDB.getUTCMonth()+1)).slice(-2);
                                            //let CreatedYear = dateDB.getUTCFullYear();

                                            return (<>
                                                <div className="grid_container_news-item">
                                                    <Link to={el.url} className="news-item-image" style={{
                                                        background:'url(' + imageLink + ')',
                                                        backgroundSize: "cover",
                                                        backgroundPosition: "center",
                                                        backgroundRepeat: "no-repeat",
                                                        display: "block",
                                                        position: "relative",
                                                        height: "200px"
                                                    }}
                                                    ></Link>
                                                    <div className="news-item-date">{elDate[0]}<div className="news-item-date-month">{elDate[1].slice(0, 3).toLocaleUpperCase()}</div><div className="news-item-date-yaer">{elDate[2]}</div></div>
                                                    <div className="news-item-shield">
                                                        <h4>
														    <Link className="news-item-title" to={el.url}>{el.title}</Link>
														</h4>
                                                        <div className="news-item-description">
                                                            {el.anonce}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>);
                                        })}
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                </MediaQuery>

                </>
            );
    }
}

export default Panel;