import React from 'react';
import "../../global.js";

export class GridSituation extends React.Component {




    truncate = (str, n) => {
        return (str.length > n) ? str.substr(0, n-1) + '...' : str;
      };


    render() {
		let base64_link = btoa(this.props.codex_id);
		let url = '/situation/'+ base64_link;
		
        return (
            <>
	    <div className="document_tpl_card">
		    <span className="card-body__box">
			    <h4 className="card-body__title">{this.props.name}</h4>
			    <p className="card-body__description">{this.truncate(this.props.title, 200)}</p>
			</span>	
			<a href={url} title={this.props.name}>Открыть документ</a>
			<img className="card-body__icon-info" src="/assets/images/icons-info.svg" />
		</div>
            </>
        );
    }
}
export default GridSituation;