import React from 'react';
import {Link} from 'react-router-dom';

import "../../global.js";

export class qs_dtp extends React.Component {

    constructor() {
      super();
      this.state = {

      }
    }

    render(){
        let self = this;
        const alias = self.props.match.params.qa;
        return(
            <>
                <div className="uk-container uk-container-center quick-start">
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-1 quick-start-title-wrapper">
                            <div className="uk-h1 quick-start-header-title">Что делать при ДТП?</div>
                            <div className="all-hr all-hr-2 color-lightblue"></div>
                        </div>
                        <div className="uk-width-medium-1-1 quick-start-list-wrapper">
                            <div className="uk-grid">
                                <div className="uk-width-medium-1-3 quick-start-item-wrapper">
                                    По ДТП
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default qs_dtp;