import React from 'react';
import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";
import "../../global.js";

export class AboutProject extends React.Component {
    componentDidMount() {
		 
    const SelectFilter=(elem)=>{
        let filter = {};

        filter.theme = elem.getAttribute("data-for");
        let filteredContent = document.querySelectorAll(".js-filtered-content");
        if(filteredContent){
            for(let i = 0; i < filteredContent.length; i++){
                if(filteredContent[i].getAttribute("data-content") == filter.theme){
                    filter.for = filteredContent[i];
                    break;
                };
            };
        };
        //let filterController = elem.querySelector(".js-choice li");

            let filterController = document.querySelectorAll(".js-choice li");
    if(filterController){
        for(let i = 0; i < filterController.length; i ++){
             changeFilterContent(filterController[i], filter.for);
			  filterController[i].addEventListener("click", changeFilterContent.bind(null, filterController[i], filter.for));
        };
    };

       // changeFilterContent(filterController, filter.for);

       
    }


    const changeFilterContent=(controller, filterContent)=>{


        let filteredElems = filterContent.querySelectorAll("[data-filter-group]");
        if(filteredElems){


            switch(controller.getAttribute("data-value")){
                case "all-branches":
                    for(let i = 0; i < filteredElems.length; i++){
                        if(filteredElems[i].classList.contains("js-content-is-hidden")){
                            filteredElems[i].classList.remove("js-content-is-hidden");
                        };
                    };
                    break;
                default:
                    for(let i = 0; i < filteredElems.length; i++){
                        let match = false;
                        let filterGroupArr = filteredElems[i].getAttribute("data-filter-group").split(" ");
                        for(let i = 0; i < filterGroupArr.length; i++){
                            if(filterGroupArr[i] == controller.getAttribute("data-value")){
                                match = true;
                            };
                        };

                        switch(match){
                            case true:
                                if(filteredElems[i].classList.contains("js-content-is-hidden")){
                                    filteredElems[i].classList.remove("js-content-is-hidden");
                                };
                                break;
                            case false:
                                if(!filteredElems[i].classList.contains("js-content-is-hidden")){
                                    filteredElems[i].classList.add("js-content-is-hidden");
                                };
                                break;
                        };
                    };
                    break;
            };
        };
    }		 
            let filters = document.querySelectorAll(".js-select-filter");
    if(filters){
        for(let i = 0; i < filters.length; i ++){
            let filter = new SelectFilter(filters[i]);
        };
    };
    }   


     
    render(){
	    document.getElementById("root").style.background = "#fafafb";
        const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'О проекте',
                path: '',				
            },
        ];			
        return(
                <>
                <div className="page-about  page">
                    <div className="container quick-start">
                            <div className="row">
							    <BreadCrumbs crumbs={breadcrumbs} />
							    <PageTitle pageTitle="О проекте «Мой адвокат»" />
                            </div>
							<div className="row">
                                <div className="col-md-9" id="ce_mainContainer" style={{maxWidth:"100%"}}>
                                    <p>Наша правовая система призвана помочь людям, попавшим в трудную жизненную ситуацию. Каждый зарегистрированный пользователь сможет выбрать адвоката или юриста из каталога для консультации и решения проблем с законом. Проблемы могут быть любого характера:</p>
                                    <ul>
                                        <li>Уголовное преследование (задержания, побои, аресты, обыски, вызовы и др.);</li>
                                        <li>Административные нарушения (штрафы за нарушение тишины, ПДД и др.);</li>
                                        <li>Бытовые споры (затопление соседей, порча чужого имущества и др.);</li>
                                    </ul>
                                    <p>Круглосуточная работа наших специалистов позволяет гражданам обращаться в любое удобное время. Служба поддержки ответит на любой вопрос в течение 5 минут. Экстренные ситуации требуют незамедлительного решения, с чем Вам поможет наша правовая система. Сделать правильный выбор поможет рейтинг и отзывы от клиентов.</p>
                                    <p>Общение со специалистами возможно онлайн, с помощью звонка и видеосвязи. Это позволяет не посещать офис для получения помощи и консультаций.</p>
                                    <p>Правовая система «Мой адвокат» разработана для всех и каждого! Стать полноценным пользователем может любой желающий - бизнесмен или простой гражданин. Оформление подписки дает пользователям полноценную поддержку от правовой системы по любым вопросам.</p>
                                    <p>Наши адвокаты и юристы готовы экстренно выехать для оказания квалифицированной помощи в экстренных случаях, даже в тех ситуациях, когда Вы не сможете выйти на связь. Специалисты с Вами свяжутся или отследят ваше местоположение, чтобы прибыть вовремя.</p>
                                    <p>Таким образом, пользователи системы получают квалифицированную помощь, а юристы и адвокаты &mdash; опыт, развитие в рабочей сфере, возможность проявить свои способности в юриспруденции и поучаствовать в новом перспективном проекте!</p>

                                <br /><br /><br /><hr />
								<div className="clearfix"></div>
                                <section className="tagcloud-wrapper js-select-filter" data-for="doctors" style={{display: "none"}}>
                                    <ul className="tagcloud-wrapper__list js-choice" choices-black autocomplete="off">
                                        <li data-value="all-branches">показать все</li>
                                        <li data-value="588">произвол</li>
                                        <li data-value="356">кража</li>
                                        <li data-value="1">насилие</li>
                                    </ul>

                                    <ul className="doctors__list js-filtered-content" data-content="doctors">
                                        <li className="doctors__item" data-filter-group="all-branches 356">Позиция с тегом кража</li>
                                        <li className="doctors__item" data-filter-group="all-branches 356">Позиция с тегами кража и произвол</li>
                                        <li className="doctors__item" data-filter-group="all-branches 356 588">Позиция с тегами кража и произвол</li>
                                        <li className="doctors__item" data-filter-group="all-branches 588">Позиция с тегом произвол</li>
                                        <li className="doctors__item" data-filter-group="all-branches 1">Позиция с тегом насилие</li>
                                    </ul>
                                </section>								
                            </div>
							
							<div className="col-md-3">
								<div className="right-nav-page aside-nav">
                                    <div className="aside-widget">
                                        <img src="/assets/images/bg_btn_male.png" height="140" alt="" className="aside-widget__image" />
                                        <h4 className="aside-widget__title">Нужна срочная помощь?</h4>
                                        <div className="aside-widget__text pulse-button-wrap">
                                            <a href="/call/" className="go_to_callpage_btn">
                                                <div class="go_to_callpage_title">Позвоните нам</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>									
                            </div>
                        </div>
                    </div>
                </div>
                </>
            );
    }
}

export default AboutProject;