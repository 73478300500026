import React from 'react';
import { useAddToHomescreenPrompt } from "./useAddToHomescreenPrompt";
import HeaderNavigation from "../../tmp1/chunk/header_navigation";
//import ReactDOM from 'react-dom';
import "./mainpage.styles.css";
import "./testimonials.styles.css";
import "./application.styles.css";
import "./consulting.styles.css";

export class MainPage extends React.Component {

    HomescreenPrompt = () => {
        const [prompt, promptToInstall] = useAddToHomescreenPrompt();
        const [isVisible, setVisibleState] = React.useState(false);

        const hide = () => setVisibleState(false);

        React.useEffect(
            () => {
            if (prompt) {
                setVisibleState(true);
            }
            },
            [prompt]
        );

        if (!isVisible) {
            return <div />;
        }

        return (
            <div onClick={hide}>
            <button onClick={hide}>Закрыть</button>
            Приветствуем! Добавить на главный экран?
            <button onClick={promptToInstall}>Да</button>
            </div>
        );
    }/**/
    
    
    render(){
        return (<>
        
			{/* <div class="bg_1">
				<div class="dark-glass-2"></div>
			</div> */}
                    <HeaderNavigation />
                        
                    <section className="block main">
                        <div class="block_bg main bg_1">
                            <div class="dark-glass-2"></div>
                        </div>
                        <div className="container block__content">
                        <div className="row ">
                            <div className="col col-lg-12 col-md-12 col-12">
                                <div className="h1 b1_title">Мобильные юридические услуги</div>
                            </div>
                            <div className="col col-lg-12 col-md-12 col-12">
                                <div className="p b1_description">«Мой Адвокат. Экстренная помощь» — это система онлайн консультации<br />
            и оперативной помощи по правовым вопросам <br />
            24/7 прямо из вашего кармана</div>
                            </div>
                            <div className="col col-lg-12 col-md-12 col-12">
                                <div className="p b1_btn_panel text-center">
                                    <div className="btn">ПОПРОБОВАТЬ ПРИЛОЖЕНИЕ</div>
                                </div>
                            </div>
                            <div className="col col-lg-12 col-md-12 col-12">
                                <div className="p b1-scroll-info text-center">
                                    <img src="/assets/temapltes/tmp1/images/scroll-info.png" />
                                </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    
                    {this.HomescreenPrompt()/**/}
                    
                    
                    
                    {/*}<!-- УСЛУГИ -->{/**/}
                    
                    <section className="block" id="services">
                        <div className="block_bg bg_2">
                            <div className="dark-glass-2"></div>
                            <div className="gradient-glass-1"></div>
                        </div>
                        <div className="container block__content">
                        <div className="row ">
                            <div className="col col-lg-12 col-md-12 col-12">
                                <div className="h1 b2_title text-center">Наши услуги</div>
                            </div>
                            <div className="col col-lg-1">&nbsp;</div>
                            <div className="col col-lg-10 col-md-12 col-12">
                                <div className="p b2_description">«Мой Адвокат. Экстренная помощь» - это комплексная интерактивная система консалтингового сопровождения в области гражданского, административного, уголовного, налогового права для физических лиц и индивидуальных предпринимателей, а также бизнеса, позволяющая экономить время и деньги. Наш сервис позволяет с помощью современных информационных технологий получить оперативную юридическкую помощь в экстренных ситуациях. Мы предлагаем своим клиентам широкий спектр услуг, включающий как голосовые консультации, онлайн чат с адвокатом, прикрепление личного специалиста с круглосуточной  онлайн поддержкой и приватным каналом связи , услуги по анализу и подготовке документов, выезд адвоката в целях представительства интересов клиента перед третьими лицами, юридический аудит предприятия и другие услуги.</div>
                            </div>
                            <div className="col col-lg-1">&nbsp;</div>
                            <div className="col col-lg-1">&nbsp;</div>
                            <div className="col col-lg-10 col-md-12 col-sm-12 col-12">
                                <div className="row our-services">
                                    <div className="col col-lg-4 col-md-6 col-sm-6 col-12 os-service-item">
                                        <div className="row">
                                            <div className="col col-lg-12 col-md-12 col-12 text-center os-image"><img src="/assets/temapltes/tmp1/images/ico1.png"/></div>
                                            <div className="col col-lg-12 col-md-12 col-12 text-center os-descr">Современное мобильное<br />
                                            приложение с оперативным <br />
                                            доступом к онлайн-поддержке <br />
                                            и личному специалисту </div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-4 col-md-6 col-sm-6 col-12 os-service-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 col-md-12 col-12 text-center os-image"><img src="/assets/temapltes/tmp1/images/ico2.png"/></div>
                                            <div className="col col-lg-12 col-md-12 col-12 text-center os-descr">Встроенны справочник и база данных<br />
                                            самой необходимй правовой <br />
                                            информацией и ответами на <br />
                                            частые вопросы</div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-4 col-md-6 col-sm-6 col-12 os-service-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 col-md-12 col-12 text-center os-image"><img src="/assets/temapltes/tmp1/images/ico3.png"/></div>
                                            <div className="col col-lg-12 col-md-12 col-12 text-center os-descr">Гибкая подлписочная модель<br />
                                            с бесплатным пробным <br />
                                            периодом</div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-4 col-md-6 col-sm-6 col-12 os-service-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 col-md-12 col-12 text-center os-image"><img src="/assets/temapltes/tmp1/images/ico4.png"/></div>
                                            <div className="col col-lg-12 col-md-12 col-12 text-center os-descr">Богатая база кейсов и реальных<br />
                                            судебных решений с возможностью<br />
                                            быстрого поиска схожих ситуаций</div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-4 col-md-6 col-sm-6 col-12 os-service-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 col-md-12 col-12 text-center os-image"><img src="/assets/temapltes/tmp1/images/ico5.png"/></div>
                                            <div className="col col-lg-12 col-md-12 col-12 text-center os-descr">Приватный онлайн чат с вашим <br />
                                            личным адвокатом с голосолвыми<br />
                                            сообщениями и сквозным<br />
                                            шифрованием</div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-4 col-md-6 col-sm-6 col-12 os-service-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 col-md-12 col-12 text-center os-image"><img src="/assets/temapltes/tmp1/images/ico6.png"/></div>
                                            <div className="col col-lg-12 col-md-12 col-12 text-center os-descr">База шаблонов для различных<br />
                                            юридических документов на все<br />
                                            сулчаи жизни бесплатно</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-1">&nbsp;</div>
                        </div>
                        </div>
                    </section>
                    
                    
                    
                    
                    
                    
                    
                    
                    {/*}<!-- ДОСТИЖЕНИЯ -->{/**/}
                    
                    <section className="block" id="achives">
                        <div className="block_bg bg_3">
                            <div className="fog-glass-2"></div>
                        </div>
                        <div className="container block__content">
                        <div className="row ">
                            <div className="col col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="h1 b3_title text-center">Наши достижения в цифрах</div>
                            </div>
                            <div className="col col-lg-1">&nbsp;</div>
                            <div className="col col-lg-10 col-md-12 col-sm-12 col-12">
                                <div className="row our-achives">
                                    <div className="col col-lg-3 col-md-3 col-sm-6 col-12 oa-achive-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 oa-image">
                                                <img src="/assets/temapltes/tmp1/images/laurel.webp"/>
                                                <div className="oa_laurel_counter">10</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-3 col-md-3 col-sm-6 col-12 oa-achive-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 oa-image">
                                                <img src="/assets/temapltes/tmp1/images/laurel.webp"/>
                                                <div className="oa_laurel_counter">52</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-3 col-md-3 col-sm-6 col-12 oa-achive-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 oa-image">
                                                <img src="/assets/temapltes/tmp1/images/laurel.webp"/>
                                                <div className="oa_laurel_counter">8</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-3 col-md-3 col-sm-6 col-12 oa-achive-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 oa-image">
                                                <img src="/assets/temapltes/tmp1/images/laurel.webp"/>
                                                <div className="oa_laurel_counter">10</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-1">&nbsp;</div>
                            
                            <div className="col col-lg-1">&nbsp;</div>
                            <div className="col col-lg-10 col-md-12 col-sm-12 col-12">
                                <div className="p b3_description">Система «Мой Адвокат. Экстренная помощь» позволяет круглосуточно получить юридическую помощь как онлайн, так и с помощью телефонного звонка и электронной почты. Наши специалисты проконсультируют Вас в течение 5 минут. Защита будет оказана в срочном порядке, когда дело не терпит отлагательств. Особенно это важно в экстренных случаях.</div>
                            </div>
                            <div className="col col-lg-1">&nbsp;</div>
                            <div className="col col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="p b1_btn_panel text-center">
                                    <div className="btn">ЗАПРОСИТЬ КОНСУЛЬТАЦИЮ</div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    
                    
                    
                    
                    
                    
                    
                    
                    {/*}<!-- ФОРМА ЗАХВАТА -->{/**/}
                    
                    <section className="block" id="question">
                        <div className="block_bg bg_4">
                            <div className="dark-glass-2"></div>
                            <div className="gradient-glass-1"></div>
                        </div>
                        <div className="container block__content">
                        <div className="row ">
                            <div className="col col-lg-6">
                                <div className="b4_left_image text-center">
                                    <img src="/assets/temapltes/tmp1/images/man.webp"/>
                                </div>
                            </div>
                            <div className="col col-lg-6 col-md-12 col-sm-12 col-12">
                                
                                <div className="row ">
                                    <div className="col col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="b4_title">Оставьте заявку и мы вам перезвоним</div>
                                    </div>
                                    <div class="col-md-3 col-sm-2 col-1"></div> 
                                    <div className="col col-lg-12 col-md-6 col-sm-8 col-10 form-question">
                                        <form>
                                            <div className="row ">
                                                <div className="col col-lg-5 col-md-12 col-sm-12 col-12">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" id="exampleText1" />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-5 col-md-12 col-sm-12 col-12">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" id="exampleText2" />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-4">
                                                </div>
                                                <div className="col col-lg-10 col-md-12 col-sm-12 col-12">
                                                    <div className="btn request-consult">ОТПРАВИТЬ</div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="col-md-3 col-sm-2 col-1"></div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    
                    
                    
                    
                    
                    {/*}<!-- НАШИ СПЕЦИАЛИСТЫ -->{/**/}
                    
                    <section className="block" id="achives">
                        <div className="block_bg bg_5">
                            <div className="fog-glass-2"></div>
                        </div>
                        <div className="container block__content">
                        <div className="row ">
                            <div className="col col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="h1 b5_title text-center">НАШИ СПЕЦИАЛИСТЫ</div>
                            </div>
                            <div className="col col-lg-1 col-md-1 col-sm-1 col-1">&nbsp;</div>
                            <div className="col col-lg-10 col-md-10 col-sm-10 col-10">
                                <div className="row our-personnel">
                                    <div className="col col-lg-3 col-md-6 col-sm-6 col-12 op-personnel-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 op-image">
                                                <img src="/assets/temapltes/tmp1/images/pers1.webp"/>
                                                <img className="bottom-shadow" src="/assets/temapltes/tmp1/images/bottom-shadow.png"/>
                                                <div className="op-personnel-name">Имя Фамилия</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-3 col-md-6 col-sm-6 col-12 op-personnel-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 op-image">
                                                <img src="/assets/temapltes/tmp1/images/pers2.webp"/>
                                                <img className="bottom-shadow" src="/assets/temapltes/tmp1/images/bottom-shadow.png"/>
                                                <div className="op-personnel-name">Имя Фамилия</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-3 col-md-6 col-sm-6 col-12 op-personnel-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 op-image">
                                                <img src="/assets/temapltes/tmp1/images/pers3.webp"/>
                                                <img className="bottom-shadow" src="/assets/temapltes/tmp1/images/bottom-shadow.png"/>
                                                <div className="op-personnel-name">Имя Фамилия</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-3 col-md-6 col-sm-6 col-12 op-personnel-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 op-image">
                                                <img src="/assets/temapltes/tmp1/images/pers4.webp"/>
                                                <img className="bottom-shadow" src="/assets/temapltes/tmp1/images/bottom-shadow.png"/>
                                                <div className="op-personnel-name">Имя Фамилия</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-1 col-md-1 col-sm-1 col-1">&nbsp;</div>
                        </div>
                        </div>
                    </section>
                    
                    
                    
                    
                    
                    
                    {/*}<!-- ОТЗЫВЫ -->{/**/}
                    
                    <section className="block" id="testimonials">
                        <div className="block_bg bg_6">
                            <div className="dark-glass-2"></div>
                            <div className="gradient-glass-1"></div>
                        </div>
                        <div className="container block__content">
                        <div className="row ">
                            <div className="col col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="h5 b6_pretitle text-center">Почему наш сервис лучший?</div>
                                <div className="h1 b6_title text-center">Отзывы наших клиентов</div>
                            </div>
                            <div className="col col-lg-12 col-md-12 col-sm-12 col-12">
                                {/* <div className="testimonials_wrapper">
                                    
                                </div> */}
                                <div className="testimonials_item">
                                    <div className="testimonials_text">&#171;Для урегулирования конфликтов в той или иной сфере нет необходимости тратить время на посещение юридических консультаций - справку по любым юридическим вопросам можно получить, не выходя из дома или офиса, имея под рукой компьютер, ноутбук или телефон&#187;</div>
                                    <div className="testimonials_image_wrapper">
                                        <img className="testimonials_image" src="/assets/temapltes/tmp1/images/testimonial1.webp" alt="testimonial"/>
                                    </div>
                                    <div className="testimonials_auth">Анатолий Вассерман</div>
                                    <div className="testimonials_whois">Генеральный директор “ООО” Ололо</div>
                                </div>

                                <hr className='testimonials_separator'/>

                                <div className="testimonials_item">
                                    <div className="testimonials_text">&#171;Для урегулирования конфликтов в той или иной сфере нет необходимости тратить время на посещение юридических консультаций - справку по любым юридическим вопросам можно получить, не выходя из дома или офиса, имея под рукой компьютер, ноутбук или телефон&#187;</div>
                                    <div className="testimonials_image_wrapper">
                                        <img className="testimonials_image" src="/assets/temapltes/tmp1/images/testimonial1.webp" alt="testimonial"/>
                                    </div>
                                    <div className="testimonials_auth">Анатолий Вассерман</div>
                                    <div className="testimonials_whois">Генеральный директор “ООО” Ололо</div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    
                    
                    
                    
                    {/*}<!-- ПРИЛОЖЕНИЕ -->{/**/}

                    
                    {/* <section className="block" id="application">
                        <div className="block_bg bg_7">
                            <div className="fog-glass-2"></div>
                        </div>
                        <div className="bg-footage-1"></div>
                        <div className="container block__content">
                        <div className="row ">
                            <div className="col col-lg-12">
                                <div className="h1 b7_title">Установите приложение<br />
                                    прямо сейчас :</div>
                            </div>
                            <div className="col col-lg-1">&nbsp;</div>
                            <div className="col col-lg-10">
                                <div className="row our-application">
                                    <div className="col col-lg-3 oa-application-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 oa-image">
                                                <a href="/">
                                                    <img className="app_btn" src="/assets/temapltes/tmp1/images/app_gp.jpg"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-3 oa-application-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 oa-image">
                                                <a href="/">
                                                    <img className="app_btn" src="/assets/temapltes/tmp1/images/app_apple.jpg"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-3 oa-application-item">
                                        <div className="row ">
                                            <div className="col col-lg-12 oa-image">
                                                <a href="/">
                                                    <img className="app_btn" src="/assets/temapltes/tmp1/images/app_win.jpg"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-1">&nbsp;</div>
                        </div>
                        </div>
                    </section> */}

                    <section className="block" id="application">
                        <div className="block_bg bg_7">
                            <div className="fog-glass-2"></div>
                        </div>
                        <div className='container application__content'>
                            <div className="display-3 application__title">Установите приложение<br />прямо сейчас</div>
                            <div className='application__btn-group'>
                                <a href="/"><img className="app_btn" src="/assets/temapltes/tmp1/images/app_gp.jpg" alt="google play"/></a>
                                <a href="/"><img className="app_btn" src="/assets/temapltes/tmp1/images/app_apple.jpg" alt="app store"/></a>
                                <a href="/"><img className="app_btn" src="/assets/temapltes/tmp1/images/app_win.jpg" alt="windows"/></a>
                            </div>
                        </div>
                    </section>

                    <div className='application__footage'>
                        <div className="bg-footage-1"></div>
                    </div>
                    
                    
                    
                    
                    {/*}<!-- ORDER CONSULTING -->{/**/}

                    {/* <div className="bg-footage-2">
                        <img className="oc-callpanel-femida" src="/assets/temapltes/tmp1/images/callpanel_femida_bg.png" alt="" />
                    </div>
                    
                    <section className="block" id="order-consulting">
                        <div className="block_bg bg_8">
                            <div className="dark-glass-2"></div>
                            <div className="gradient-glass-1"></div>
                        </div>
                        <div className="container block__content">
                            <div className="row ">
                                <div className="col col-lg-12">
                                    <div className="order-consulting_wrapper">
                                        <div className="order-consulting">
                                            <img className="oc-callpanel" src="/assets/temapltes/tmp1/images/callpanel.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section className="block" id="order-consulting">
                        <div className="block_bg bg_8">
                            <div className="dark-glass-2"></div>
                            <div className="gradient-glass-1"></div>
                        </div>
                        <div className='container order-consulting__content'>
                            <div className="order-consulting__femida">
                                <img src="/assets/temapltes/tmp1/images/callpanel_femida_bg.webp" alt="femida" />
                            </div>
                            <img className="order-consulting__callpanel" src="/assets/temapltes/tmp1/images/callpanel.webp" alt='call' />
                        </div>
                    </section>
                    
                    <br className='clearfix' />
                    
                    
            </>);
        }

}

export default MainPage;
