global.apiLink = "https://api.my-advo.cat/";
global.apiWsLink = "wss://api.my-advo.cat:8443/";
global.peerServer = "api.my-advo.cat";
global.signalServer = "wss://api.my-advo.cat:2096/";
global.signalServerHttp = "https://api.my-advo.cat:2096";
global.peerPort = "2087";

global.CssLoader = '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>';
global.SvgDial = '<img class="dial-call-infinity" src="/assets/images/dial.svg?123" />';

global.msgSound = "/assets/sounds/msg.mp3?123";
global.vk_client_id = 8175054;

global.servicesList = [
                        {
                            href: "/situation/",
                            bg: "/assets/images/panel/services_pic/Property 1=deal in court.png",
                            title: "Практические правовые рекомендации",
							position: "global",
                            description: "Что делать, если...<br>Или советы о том, как вести себя в экстренных ситуациях."/*  Практические рекомендации по поведению в экстренных ситуациях Он покажет, как правильно вести себя при задержаниях, обысках, на допросах и в других ситуациях, вызванных проблемами с законом."/**/,
                        },
                        {
                            href: "/codex/",
                            bg: "/assets/images/panel/services_pic/Property 1=justice letter.png",
                            title: "Кодексы, законы, судебная практика",
							position: "global",
                            description: "Сборники кодексов и законов РФ, информация о судебной практике(постановления пленума ВС РФ и пр).",
                        },						
                        {
                            href: "/qa/",
                            bg: "/assets/images/panel/services_pic/Property 1=find justice.png",
                            title: "Вопрос-ответ",
							position: "global",
                            description: "Ответы на типовые юридические вопросы из повседневной жизни. Возможность задать свой вопрос и получить быстрый ответ." /*Он покажет, как правильно вести себя при задержаниях, обысках, на допросах и в других ситуациях, вызванных проблемами с законом."/**/,
                        },
                        {
                            href: "/docs/",
                            bg: "/assets/images/panel/services_pic/Property 1=law document.png",
                            title: "Формы документов",
							position: "global",
                            description: "Образцы типовых правовых документов с возможностью самостоятельного заполнения.",
                        },
                        {
                            href: "/is/",
                            bg: "/assets/images/panel/services_pic/Property 1=lawyer.png",
                            title: "Юристы, адвокаты, нотариусы по РФ",
							position: "global",
                            description: "Справочник адвокатов, юристов и нотариусов, готовых Вам помочь.",
                        },
                        {
                            href: "/regions/",
                            bg: "/assets/images/panel/services_pic/Property 1=contract.png",
                            title: "Справочная информация",
							position: "global",
                            description: "Адреса и телефоны государственных, судебных, правовых организаций по РФ.",
                        },
                        {
                            href: "/call/",
                            bg: "/assets/images/panel/services_pic/chat.svg",
                            title: "Call-центр",
							position: "global",
                            description: "Чаты, аудио/видеозвонки.",
                        },
                        {
                            href: "/news/",
                            bg: "/assets/images/panel/services_pic/Property 1=contract.png",
                            title: "Юридические новости",
							position: "",
                            description: "Cамые важные актуальные правовые новости и события в стране и регионах.",
                        },						
                        /*{
                            href: "/codex/NDQyMDI=",
                            bg: "/assets/images/panel/services_pic/Property 1=constitution book.png",
                            title: "Конституция",
                            description: "Доступ к основному закону РФ",
                        },*/
                       /* {
                            href: "/plenum/",
                            bg: "/assets/images/panel/services_pic/Property 1=lawyer case.png",
                            title: "Судебная практика",
                            description: "Доступ к информации по судебной практике",
                        },*/
                    ];
global.servicesTitle = "Наши сервисы";