import { connect } from "socket.io-client";

export class FemidaSocket {
    static socket_room;
    static room_id;
    static Instance;

    static connectSocket = ({token, room_id, socket_room, events}) => {
        if (!FemidaSocket.Instance 
            || FemidaSocket.room_id !== room_id
            || FemidaSocket.socket_room !== socket_room) {
            console.log('creating new socket instance');
            FemidaSocket.room_id = room_id;
            FemidaSocket.socket_room = socket_room;
            const socket = connect("https://my-advo.cat:2083", {
                query: { 
                    room_id: room_id || null, 
                    socket_room 
                },
                auth: { token },
            });

            if (events?.length) {
                events.forEach(({e, fn}) => socket.on(e, fn));
            }

            FemidaSocket.Instance = socket;
        }
        if (!FemidaSocket.Instance?.connected) {
            FemidaSocket.Instance.connect()
        }
    }
    
    static clear() {
        if (FemidaSocket.Instance) {
            FemidaSocket.Instance.disconnect()
            FemidaSocket.room_id = null;
            FemidaSocket.socket_room = null;
        }
    }
}