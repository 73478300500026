import React from 'react';
import docStyle  from "../docs.module.css";

export class FieldsEnabled extends React.Component{
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {

        };
    }

    render() {
        let props = this.props;
        return(
                <fieldset 
                    key={props.index} 
                    className={"row mb-1 "+docStyle.fieldset_flex}
                    >
                    <div className="col-md-4">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <span className="small">
                                            {props.title}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-8">
                        <input 
                            type="hidden"
                            name={"varval[" + props.index + "][name]"}
                            value={props.name_variable}
                            />
                        <input 
                            type="text"
                            name={"varval[" + props.index + "][value]"}
                            placeholder={props.title}
                            className={docStyle.input_control}
                            />
                    </div>
                </fieldset>
        );
    }
}

export class FieldsDisabled extends React.Component{
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {

        };
    }

    render() {
        let props = this.props;
        return(
            <fieldset 
                key={props.index} 
                className={"row mb-1 "+docStyle.fieldset_flex}
                >
                <div className="col-md-4">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="small">
                                        {props.title}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-md-8">
                    <input 
                        type="text"
                        placeholder={props.title}
                        className={docStyle.input_control}
                        disabled="disabled"
                        />
                </div>
            </fieldset>
        );
    }
}

export default FieldsEnabled;