import React from 'react';
import {Link} from "react-router-dom";

class NavMenu extends React.Component {
    submitFind(event){
        event.preventDefault();
        let ThisUrl = document.location.pathname;
        console.log(ThisUrl);
        ThisUrl = ThisUrl.split("/");
        let targetSearch = ThisUrl[1];
        let targetValueLink = "";
        if(targetSearch !== undefined){
            targetValueLink = "&target="+targetSearch;
        }
        let formData = new FormData(event.target);
        let link = '/search?q='+formData.get('search')+targetValueLink;
        window.location.href = link;
    }
    render() {
       /* let menu= [{name:'Войти в личный кабинет',link:'/lk'},{name:'Новости',link:'/news'}];


        if(localStorage.getItem('tokenAPI')){
            menu = [
                {name: 'Личный кабинет', link: '/lk/'},
                {name: 'Новости', link: '/news/'},
                {name: 'Юристы', link: '/is/'},
                {name: 'Контакты учреждений', link: '/regions/'},
                {name: 'Вопрос-Ответ', link: '/qa/'},
                {name: 'Кодекс', link: '/codex/'},
                {name: 'Пленумы', link: '/plenum/'}
            ];
        }/**/
        let menu = [
                {name: 'Личный кабинет', link: '/lk/'},
                {name: 'Новости', link: '/news/'},
                {name: 'Юристы', link: '/is/'},
                {name: 'Контакты учреждений', link: '/regions/'},
                {name: 'Вопрос-Ответ', link: '/qa/'},
                {name: 'Кодекс', link: '/codex/'},
                {name: 'Пленумы', link: '/plenum/'}
            ];

        let path = window.location.pathname;
        let token = localStorage.getItem('tokenAPI');
        console.log(token);
        if( token){
            menu.push({name:'Выйти',link:'/logout'})
        }
        return (
            <div className="uk-navbar uk-container-expand uk-container-center toppanel-wrapper">
                <div className="uk-container uk-container-center">
                    <nav className="uk-margin-large-bottom">

                        <a href="#tm-offcanvas" className="uk-navbar-toggle uk-visible-small" data-uk-offcanvas=""></a>

                        <ul className="uk-navbar-nav uk-hidden-small">
                            {menu.map((element,i)=>
                            <li key={i} className={element.link === path?'uk-active':''}>
                                <Link to={element.link}>{element.name}</Link>
                            </li>
                            )}
                        </ul>
                        <div className="uk-navbar-flip">
                            <div className="uk-navbar-content">
                                <form className="uk-search"  onSubmit={this.submitFind}>
                                    <input className="uk-search-field" name="search" type="search" autocomplete="off" placeholder="ПОИСК"/>
                                </form>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}

export default NavMenu;