import React, { createRef }  from "react";
import MediaQuery from "react-responsive";
import {Link} from "react-router-dom";
import Button from "../mobile/Button";
import "./registration.styles.css";
import VkSvg from './assets/vk.svg';
import GoogleSvg from './assets/google.svg';
import EyeSvg from './assets/eye-svg.svg';
import BackSvg from './assets/back.svg'; 

import { isMobile } from 'react-device-detect';
import "./assets/form.css";
import {FormErrors} from "../FormErrors";
import {FormMsg} from "../FormMsg";

class Registration extends  React.Component{
    constructor(props) {
        super(props);
    this.state = {
        email: '',
        password: '',
        "repeat-password": '',
        formErrors: {email: '', password: '', repeatPassword: ''},
		formResponceMsg: {msg: ''},		
		formResponceErrors: {error: ''},
        emailValid: false,
        passwordValid: false,
        repeatPasswordValid: false,
        formValid: false,

        loginEntered: false,
        loading: false,
        passwordShown: false,
        MOBILE_URL: process.env.NODE_ENV === 'development' ?
        "http://localhost:3000" : "https://m.my-advo.cat",
    }
    this.handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

        this.handleUserInput= (e) => {
            const name = e.target.name;
            const value = e.target.value;
            console.log('name=' +name);
            console.log('value=' +value);
            this.setState({[name]: value},
                () => { this.validateField(name, value) });
        }
		
        this.validateField = (fieldName, value) => {
            let fieldValidationErrors = this.state.formErrors;
            let emailValid = this.state.emailValid;
            let passwordValid = this.state.passwordValid;
            let repeatPasswordValid = this.state.repeatPasswordValid;
            switch(fieldName) {
                case 'email':
                    emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i);
                    fieldValidationErrors.email = emailValid ? '' : 'Указан некорректный логин.';
                    break;
                case 'password':
                    passwordValid = value.length >= 6;
                    fieldValidationErrors.password = passwordValid ? '' : 'Минимальная длина пароля - 6 символов.';
                    break;
                case 'repeat-password':
                    repeatPasswordValid = passwordValid && value.toLowerCase() == (this.state.password).toLowerCase();
                    fieldValidationErrors.repeatPassword = repeatPasswordValid ? '' : 'Минимальная длина пароля - 6 символов.';
                    break;
                default:
                    break;
            }
            this.setState({
				formErrors: fieldValidationErrors,
                emailValid: emailValid,
                passwordValid: passwordValid,
                repeatPasswordValid: repeatPasswordValid
            }, this.validateForm);
        }
        this.validateForm=()=> {
            this.setState({formValid: this.state.emailValid && this.state.passwordValid  && this.state.repeatPasswordValid});
        }
        this.errorClass=(error)=> {
            return(error.length === 0 ? '' : ' form-line-error');
        }
        this.errorHintClass=(error)=> {
            return(error.length === 0 ? '' : ' form-hint-error');
        }		
}

    badRequest(payload){
        console.log(payload);
        let result='';
        for(let key in payload){
            result +=payload[key].join(' ')+"\n";
        }
        alert(result)
    }

    sendForm = async (e) => {
		
        e.preventDefault();
		
		this.setState({formResponceErrors:{error: ''}});	
		this.setState({formResponceMsg:{msg: ''}});	
		
        let url = global.apiLink+"/api/user/register";
        let formData = new FormData(e.target);
        const res = await fetch(url,{
            body:formData,
            method:'POST'
        });
        const response = await res.json();
        
		
        if (response.status === 200) {
           // alert('На указанный электроннный адрес отправлена ссылка для подтверждения регистрации.');
            let successStr = "<span class=\"text-center\">На указанный электроннный адрес отправлена ссылка для подтверждения регистрации."
			    + "<br/><a href='/login'>Авторизироваться</a>.</span>";
            this.setState({formResponceMsg:{msg: successStr}});					
        }
        else {

            var messageText = "";
            if (response.payload.email !== undefined){
                console.log(response.payload.email);
                if(typeof response.payload.email === 'string' || response.payload.email instanceof String)
                    messageText +=response.payload.email;
                else
                    messageText +=response.payload.email.join(', <br />');
            }
            if(response.payload.password !== undefined){
                console.log(response.payload.password);
                if(messageText !== "")messageText += "<br />";
                if(response.payload.password )
                if(typeof response.payload.password === 'string' || response.payload.password instanceof String)
                    messageText +=response.payload.password;
                else
                    messageText +=response.payload.password.join(', <br />');
                
            }

			let errorStr = "<span class=\"text-center\">Внимание! "
			   +  messageText
			   + "<br />Проверьте корректность введенных данных и повторите попытку.</span>";						
            this.setState({formResponceErrors:{error: errorStr}});			
            return false;
        }
		
        //window.location.href = '/';
    }
	
	
	
    render() {
        document.getElementById("root").style.background = "#fff";
          
        const {
            loginEntered,
            loading,
            passwordShown
        } = this.state;
        
        return (
            <>
            <MediaQuery minWidth={768}>
            <div className="qa-page page pt-0">
                <div className="container">
                    <div className="row">
                        <div className="offset-md-2 col-md-8 card shadow-sm">
                            <div className="row card-body">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <form onSubmit={this.sendForm} autoComplete="off">
                                            <legend className="legend text-center"><h2>Регистрация</h2></legend>
                                            <div className="panel panel-default" id="form_result">
                                                <FormErrors formErrors={this.state.formResponceErrors} />
												<FormMsg formMsg={this.state.formResponceMsg} />
                                            </div>
									    <div className={`${this.state.formResponceMsg.msg && 'd-none'}`}>
                                            <div className="mb-3 form-line">
                                                <label htmlFor="email" className="form-label">
                                                    E-mail <span className="form-required">*</span>
                                                </label>
                                                <input type="email"
                                                       className={`form-control ${this.errorClass(this.state.formErrors.email)}`}
                                                       id="email"
                                                       name="email"
                                                       placeholder="Адрес электронной почты"
                                                       value={this.state.email}
                                                       autoComplete="off"
													   required="required"
													   maxLength={255}
                                                       onChange={this.handleUserInput}
                                                />
                                            <div className={`form-hint-line ${this.errorHintClass(this.state.formErrors.email)}`}>
											    Укажите корректный адрес электронной почты.
											</div>												
                                            </div>
                                            <div className="mb-3 form-line">
                                                <label htmlFor="registerPassword" className="form-label">
                                                    Пароль <span className="form-required">*</span>
                                                </label>
                                                <input type="password"
                                                       className={`form-control ${this.errorClass(this.state.formErrors.password)}`}
                                                       id="registerPassword"
                                                       name="password"
                                                       placeholder="Пароль"
                                                       value={this.state.password}
                                                       autoComplete="off"
													   maxLength={255}
                                                       onChange={this.handleUserInput}
                                                />
                                            <div className={`form-hint-line ${this.errorHintClass(this.state.formErrors.password)}`}>
											    Минимальная длина пароля - 6 символов.
											</div>												
                                            </div>
                                            <div className="mb-3 form-line">
                                                <label htmlFor="registerRepeatPassword" className="form-label">
                                                    Подтверждение пароля <span className="form-required">*</span>
                                                </label>
                                                <input type="password"
                                                       className="form-control"
                                                       id="registerRepeatPassword"
                                                       name="repeat-password"
                                                       placeholder="Повторить пароль"
                                                       value={this.state.repeatPassword}
                                                       autoComplete="off"
													   maxLength={255}
                                                       onChange={this.handleUserInput}
                                                />
                                                <div className={`form-hint-line ${this.errorHintClass(this.state.formErrors.repeatPassword)}`}>
											        Указанные пароли не совпадают.
											    </div>												
                                            </div>
                                            <div className="form-line">
                                                <div className="form-buttons-wrapper">
                                                    <button type="submit"
                                                            className="btn btn-biryza form-submit-button submit-button jf-form-buttons"
                                                            disabled={!this.state.formValid}>
                                                        Зарегистрироваться
                                                    </button>
                                                </div>
                                            </div>
                                            <div id="reg_result"></div>
                                            <div className="form-line">
                                                <div className="d-flex gap-2 login-ask-c">
                                                    <span style={{color: "#000", width: "max-content"}}>Есть аккаунт?</span>
                                                    <Link className="btn btn-link py-0 px-0" to="/login" >Войти</Link>
                                                </div>
                                            </div>
										</div>	

                                        </form>

                                    </div>
                                </div>
                                <div className="col-lg-6 left-border">
                                    <h4 className="mt-5">&nbsp;&nbsp;&nbsp;&nbsp;Регистрируясь, Вы:</h4>
                                    <ul className="mt-3">
                                        <li>соглашаетесь с <a href="/privpol/" target="_blank" title="Политика в отношении обработки персональных данных">условиями</a> в отношении обработки персональных данных</li>
                                        <li>получаете возможность приобретать услуги нашего портала по подписке</li>
                                        <li>обязуетесь соблюдать правила нашего портала и закон об авторском праве</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </MediaQuery>
            <MediaQuery maxWidth={768}>
                <div className="d-flex flex-column align-items-stretch justify-content-center py-5 gap-5 px-3">
                    <a 
                    role="button"
                    href={this.state.MOBILE_URL}
                    className="py-4 px-3 pb-0">
                        <img src={BackSvg} alt="back" />
                    </a>
                    <div className="d-flex flex-column gap-3 align-items-center">
                        <h2 className="mb-0">Регистрация</h2>
                        <span className="font-weight-light text-secondary text-center">Введите почту или номер телефона, чтобы зарегистрироваться</span>
                        <b>Уже есть аккаут? <Link className="mobile-link" to="/login">Войти</Link></b>
                    </div>
                    <form
                    onSubmit={this.sendForm}
                    ref={this.formRef}
                    className="d-flex flex-column gap-3 w-100 align-items-stretch">
                        <label>
                            <small className="d-block mb-2">Почта или номер телефона</small>
                            <input
                            type="text"
                            className="mobile-input"
                            name="email"
                            value={this.state["email"]}
                            onChange={this.handleChange}
                            placeholder="Введите логин"
                            />
                        </label>
                        <label>
                            <small className="d-block mb-2">Пароль</small>
                            <div className="position-relative">
                                <input
                                type={passwordShown ? "text": "password"}
                                className="mobile-input"
                                name="password"
                                value={this.state["password"]}
                                onChange={this.handleChange}
                                placeholder="Введите пароль"
                                />
                                <img 
                                src={EyeSvg} 
                                onClick={() => this.setState({passwordShown: !passwordShown})}
                                className="mobile-input__eye" 
                                alt="показать/скрыть" />
                            </div>
                        </label>
                        <label>
                            <small className="d-block mb-2">Подтвердите пароль</small>
                            <div className="position-relative">
                                <input
                                type={passwordShown ? "text": "password"}
                                className="mobile-input"
                                name="repeat-password"
                                value={this.state["repeat-password"]}
                                onChange={this.handleChange}
                                placeholder="Подтвердите пароль"
                                />
                                <img 
                                    src={EyeSvg} 
                                    onClick={() => this.setState({passwordShown: !passwordShown})}
                                    className="mobile-input__eye" 
                                    alt="показать/скрыть" />
                            </div>
                        </label>
                        <Button 
                        className="mobile-btn mt-2"
                        loading={loading}
                        disabled={this.state["email"].trim().length === 0 || loading}
                        type="submit">Зарегистирироваться</Button>
                    </form>
                </div>
            </MediaQuery>
        </>
        );
    }

}
export default Registration;