import React from 'react';
import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";
import {Helmet} from "react-helmet";
import "../../global.js";

export class RequizitiPage extends React.Component {
  
    render(){
        document.getElementById("root").style.background = "#fafafb";
        const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Реквизиты',
                path: '',				
            },
        ];			
            return(
                <>
                <Helmet>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                <div className="page-priv-pol page">
                    <div className="container quick-start ">
                        <div className="row">
						    <BreadCrumbs crumbs={breadcrumbs} />							 
                            <PageTitle pageTitle="Реквизиты" />
						</div>
                        <noindex>
						    <div className="row"> 
                                <div id="ce_mainContainer" style={{maxWidth:"100%"}}>
								    <p><b>Полное наименование:</b> Общество с ограниченной ответственностью "ПРОГРАМВЕБ"</p>
									<p><b>Сокращенное наименование:</b> ООО "ПРОГРАМВЕБ"</p>
									<p><b>Юридический адрес:</b> 454080, Россия, Челябинская обл., г. Челябинск, ул. Труда, д.156, помещ. 23, офис 1/1</p>
									<p><b>Почтовый адрес:</b> 454080, Россия, Челябинская обл., г. Челябинск, ул. Труда, д.156, помещ. 23, офис 1/1</p>
									<p><b>Телефон:</b> 8 (800) 550 89 72 (бесплатный, круглосуточный)</p>
									<p><b>ИНН/КПП:</b> 7453339330/745301001</p>
									<p><b>ОГРН:</b> 1217400004623</p>
									<p><b>Директор:</b> Криушичев Александр Георгиевич, Действует на основании Устава</p>
								</div>
                            </div>
                        </noindex>
                    </div>
                </div>
                </>
            );
    }
}

export default RequizitiPage;