import React from 'react';

const PageTitle = (props) => {
    let {
        pageTitle
    } = props;

    if (pageTitle === undefined) pageTitle = "Мобильная правовая система";


    return (
    <div className="col-md-12 quick-start-title-wrapper mb-3">
        <h1 className="uk-h1 quick-start-header-title">
		    <span className="quick-start-title-border" dangerouslySetInnerHTML={{__html: pageTitle}}></span>
		</h1>
    </div>		
    );
}

export default PageTitle;