import React, { useEffect, useRef } from "react";
import { getDateString } from '../MessageList/helpers';
import Room from "../room";

let tokenAPI = localStorage.getItem('tokenAPI');
let thisUserId = localStorage.getItem('id_user');
let headers = new Headers();
headers.append('Authorization', 'Bearer ' + tokenAPI);

/*
let formatMessagePreview = (args) => {
    let type = args.type;
    let content = args.content;
    if (!content) content = args;

    let out = '';
    switch(type) {
        case 'text':
            if (content?.length > 45) {
                out = content.slice(0, 42).trim() + '...';
                return out;
            } else {
                return content;
            }
        case 'image':
            return '[Изображение]';
        case 'video':
            return '[Видео]';
        case 'audio':
            return '[Аудио]';
        case 'call':
            return '[Звонок]';
        case 'file':
            return '[Документ]';
        default:
            if (content?.length > 45) {
                out = content.slice(0, 42).trim() + '...';
                return out;
            } else {
                return content;
            }
    }
}
    
let getRoomLoad = (roomid) => {
    let ThisRoom = document.getElementById("room_item_"+roomid);
    if(ThisRoom.getAttribute("data-status") == 0){
        ThisRoom.setAttribute("data-status", 1);
        setTimeout(function run() {
            if(ThisRoom.getAttribute("data-status") < 2)
                fetch(global.apiLink + 'api/chat/chat/get-room?id='+roomid, {headers})
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.status !== 200) {
                                throw new Error(result.status)
                            }
                            if (result.payload) {
                                let roominfo = result.payload.roominfo;
                                console.log(roominfo);
                                //let title = decodeMessage(roominfo.title);
                                //let message = decodeMessage(roominfo.message);
                                let title = formatMessagePreview(roominfo.title);
                                let message = formatMessagePreview({
                                    type:roominfo.message_type,
                                    content:roominfo.message
                                });
                                let msgCreatedon = new Date((parseInt(roominfo.msg_createdon)) * 1000);
                                msgCreatedon = getDateString(msgCreatedon);


                                if(ThisRoom.getAttribute("data-status") == 1){
                                    ThisRoom.setAttribute("data-status", 2);
                                    
                                    let RoomItem = document.querySelectorAll(
                                        "#room_item_"+roominfo.id+" .card-title"
                                    )[0];
                                    RoomItem.innerHTML = title;
                                    //if(roominfo.message_type == "text"){
                                    let RoomItemM = document.querySelectorAll(
                                            "#room_item_"+roominfo.id+" .room_info .card-body span"
                                        )[0];
                                        RoomItemM.innerHTML = message + ` <small class="text-muted"><i>(${msgCreatedon})</i></small>`;
                                    //}
                                    if(roominfo.image){
                                        let RoomItemAvatar = document.querySelectorAll(
                                            "#room_item_"+roominfo.id+" .interlocutor_avatar img"
                                        )[0];
                                        RoomItemAvatar.src = roominfo.image;
                                    }
                                }
                            }
                        }
                    )
                    .catch(function (e) {
                        if (e.message == "401") {
                            window.location.href = '/login';
                        }
                    });
            setTimeout(()=>{
                if(ThisRoom.getAttribute("data-status") == 1)run();
            }, 6000);
        }, 100);
    }
}
/**/
let MnemonicRoomCounter = 0;


let RoomHandleScroll = (getRoomLoad) => {
    console.log("RoomHandleScroll");
    let rlc = document.getElementById("listRoomWrapper");
    let scrollRoomItems = document.querySelectorAll("#listRoomWrapper .room_list_item");
    let scrollRoomItemCount = scrollRoomItems.length;
    let scrollRoomLastItem = scrollRoomItems[(scrollRoomItemCount-1)];
    if(scrollRoomLastItem !== undefined){
        let ViewzoneRoomId = scrollRoomLastItem.getAttribute("data-room-id")
        console.log("asd");
        console.log(ViewzoneRoomId);
        getRoomLoad(ViewzoneRoomId);
        let rlc_top = rlc.getBoundingClientRect().top;
        let rlc_height = rlc.offsetHeight;
        let scrollRoomLastItemHeight = scrollRoomLastItem.offsetHeight;
        let RoomsTopArray = [];
        for(let i = 0; i < (scrollRoomItemCount-1); i++){
            let scrollRoomItemTMP = scrollRoomItems[i];
            let msg_id = scrollRoomItemTMP.getAttribute("data-room-id");
            let scrollRoomItemTop = scrollRoomItemTMP.getBoundingClientRect().top;
            if(scrollRoomItemTop < (rlc_top + rlc_height + scrollRoomLastItemHeight) && scrollRoomItemTop > (rlc_top - scrollRoomLastItemHeight))
            RoomsTopArray.push(msg_id);
        }
        RoomsTopArray.forEach(RoomId=>getRoomLoad(RoomId));
    }
}/**/

const RoomList = (Options) => {
    let thisroomid = Options.thisroomid;
    let isAdvocat = Options.isAdvocat;
    let handleRoomChange = Options.handleRoomChange;
    let getRoomLoad = Options.getRoomLoad;
    const roomListRef = useRef(null);
    
    useEffect(() => {
                let scrollRoomItems = document.querySelectorAll("#listRoomWrapper .room_list_item");
                let scrollRoomItemCount = scrollRoomItems.length;
                if(MnemonicRoomCounter != scrollRoomItemCount){
                    setTimeout(()=>{
                        MnemonicRoomCounter = scrollRoomItemCount;
                        //roomListRef.current.scroll(0, Number.MAX_SAFE_INTEGER);
                        RoomHandleScroll(getRoomLoad);
                    },100);
                }
    }, [Options]);



    let roomList = <h5>Обращений нет</h5>;
    if(isAdvocat && Options.MetaRooms)roomList = Options.MetaRooms.map( (room) =>{
        if(room.visitor_id_user === null && thisUserId != room.id_user){
            return(<Room key={room.id} {...room} roomid={room.id} isAdvocat={isAdvocat} />);
        }
    }) 
        return (<div id="listRoomWrapper" ref={roomListRef} onScroll={()=>{
            RoomHandleScroll(getRoomLoad);
        }} 
            className=""
            style={{
                paddingRight: "0",
                borderRight: "solid 1px #eee",
                background: "#fff",
                height: "calc(100vh - 196px)",
                overflow: "hidden",
                overflowY: "scroll"
            }}>

            {Options.MetaRooms && Options.MetaRooms.map( (room) =>{
                if(room.visitor_id_user !== null || thisUserId == room.id_user){
                    return(<Room key={room.id} handleRoomChange={handleRoomChange} {...room} roomid={room.id} currentRoomId={thisroomid} isAdvocat={false} />);
                }
            })}
            {isAdvocat && roomList}

           </div>);
}

export default RoomList;