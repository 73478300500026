import React from 'react';
import {Link} from "react-router-dom";
import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";

class NewsElement extends React.Component{
    constructor(props) {
        super(props);
        //if (props.match.params.alias) {
            let alias = props.match.params.alias;
            this.state ={
                alias: alias,
                title: '',
                description:'',
                previewUrl:'',
                createdon:'',
                news: [],
                comments: []
            }
        //}
    }

    loadNews = () => {
        let url = global.apiLink+"news/get?alias="+this.state.alias;
        fetch(url)
            .then(res=>res.json())
            .then(
                (result)=>{
                    if(result.status==200 && result.payload){
                        let previewUrl = null;
                        if(result.payload.file){
                            let file = result.payload.file;
                            previewUrl = global.apiLink +'uploads/'+file.path+'/'+file.name;
                        }

                        this.setState({
                            title:result.payload.title,
                            description:result.payload.full_text,
                            previewUrl:previewUrl,
                            createdon:result.payload.created_at
                        });
                    }

                }
        );
    }

    loadComments = () => {
        let url = global.apiLink+"api/news/news/list-comment?alias="+this.state.alias;
        fetch(url)
            .then(res=>res.json())
            .then(
                (result)=>{
                    if(result.status==200 && result.payload){
                        this.setState({
                            comments: result.payload.list,
                        });
                    }

                }
        );
    }

    sendNewsComment = (e) => {
        e.preventDefault();
        let url = global.apiLink+"api/news/news/add-comment";
        let formData = new FormData(e.target);
        let headers = new Headers();
        headers.append('authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        fetch(url, {
            headers: headers, 
            body: formData,
            method:'POST'
        })
            .then(res=>res.json())
            .then(
                (result)=>{
                    if(result.status==200 && result.payload){
                        this.setState({
                            comments: result.payload.list,
                        });
                        document.querySelector(".commentlist-form-textarea").value = "";
                        this.loadComments(this.state.alias);
                    }
                }
        );
    }

    componentDidMount() {
        this.loadNews(this.state.alias);
        this.loadComments(this.state.alias);

        let limit = 9;
        let urlList = global.apiLink + 'news/list?page=1&limit='+limit;
        fetch(urlList)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === 200) {
                        this.setState({
                            news: result.payload.news,
                        });
                    }
                }
            );
    }
    

    render() {
        const {news, comments, title} = this.state;
        let newsList = null;

        let newsComments = "Комментариев нет.";

        var MonthArray=[
               'января',
               'февраля',
               'марта',
               'апреля',
               'мая',
               'июня',
               'июля',
               'августа',
               'cентября',
			   'октября',
               'ноября',
               'декабря',
            ];
            
        newsList = news.map((element) => {

            /*let imageLink = "/assets/images/no-image.png";
            if(element.file)imageLink = global.apiLink + 'uploads/' + element.file.path + '/' + element.file.name;
            if(element.image)imageLink = element.image;			
			
            let elDate = (element.pubdate).split(" ");
            let dateDB = new Date(element.created_at);
            let CreatedDay = ('00' + dateDB.getUTCDate()).slice(-2);
            let CreatedMonth = ('00' + (dateDB.getUTCMonth()+1)).slice(-2);
            let CreatedYear = dateDB.getUTCFullYear();*/

            return (<>
                <div className="col-md-12 news-list">
                    <Link className="news-item-title" to={element.url}>{element.title}</Link>
                    <div className="newslist_date">{element.pubdate}</div>
                    <div className="news-item-description">{element.anonce}</div>
                </div>
            </>);
        });

        
        if(comments){
            newsComments = comments.map((element) => {

                //let imageLink = "/assets/images/no-image.png";
                //if(element.file)imageLink = global.apiLink + 'uploads/' + element.file.path + '/' + element.file.name;
               
                let name = element.name;
                let email = element.email;
    
                if(name === null){
                    name = email;
                }
                let createdon = parseInt(element.createdon) + 18000;
                let dateDB = new Date(0);
                dateDB.setUTCSeconds(createdon);
                let CreatedDay = ('00' + dateDB.getUTCDate()).slice(-2);
                let CreatedMonth = ('00' + (dateDB.getUTCMonth()+1)).slice(-2);
                let CreatedHour = ('00' + (dateDB.getUTCHours())).slice(-2);
                let CreatedMinute = ('00' + (dateDB.getUTCMinutes())).slice(-2);
                let CreatedSecond = ('00' + (dateDB.getUTCSeconds())).slice(-2);
                let CreatedYear = dateDB.getUTCFullYear();
    
                return (<>
                    <div key={element.id} className="col-md-12 news-comment-item">
                        <div className="news-comment-autor"><a href={"mailto:"+email}>{name}</a></div>
                        <div className="news-comment-date">{CreatedDay} {MonthArray[CreatedMonth-1]} {CreatedYear} 
                        <span className="news-comment-time">{CreatedHour}:{CreatedMinute}:{CreatedSecond}</span></div>
                        <div className="news-comment-content">{element.content}</div>
                    </div>
                </>);
    /**/
            });

        }


        document.getElementById("root").style.background = "#fff";
        let newsDate = new Date(this.state.createdon);
        let newsDateDay = ('00' + newsDate.getUTCDate()).slice(-2);
        let newsDateMonth = ('00' + (newsDate.getUTCMonth()+1)).slice(-2);
        let newsDateYear = newsDate.getUTCFullYear();	

        const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Новости',
                path: '/news/',				
            },
            {
                name: this.state.title,
                path: '',				
            },
        ];
		
        return(
            <div className="news-page page">
                <div className="container ">
                    <div className="row">
						<BreadCrumbs crumbs={breadcrumbs} />					
						<PageTitle pageTitle={title && title} />
                    </div>
                    <div className="row">
                        <div className="col-md-9">
						    <p className="createdon_wrapper news_meta">{newsDateDay} {MonthArray[newsDateMonth-1]} {newsDateYear}</p>
                            <div className="image-preview">
                                {this.state.previewUrl?
                                    <img style={{width: "100%"}} src={this.state.previewUrl}/>:
                                    <img style={{width: "100%"}} src="/assets/images/no-image.png"/>
                                }
                            </div>
                            <div className="text-news" dangerouslySetInnerHTML={{__html:this.state.description}}></div>
                        
                            <div className="row">
                                <div className="col-md-9 newslist_comments_wrapper">
                                    <div className="row">
                                        <div className="col-md-12 commentlist_title mt-3"><h4><span>Комментарии:</span></h4></div>
                                        {newsComments}
                                    </div>
                                    {localStorage.getItem('tokenAPI') && <>
                                        <div className="row">
                                            <div className="col-md-12 commentlist_title mt-4"><h4><span>Добавить комментарий:</span></h4></div>
                                            <div className="col-md-12">
                                                <form className="commentlist-form"  onSubmit={this.sendNewsComment}>
                                                    <input type="hidden" name="alias" value={this.state.alias} />
                                                    <textarea className="commentlist-form-textarea" name="content" rows="5"></textarea>
                                                    <input type="submit" className="btn btn-primary" value="Отправить" />
                                                </form>
                                            </div>
                                        </div>
                                    </>}
                                    {!localStorage.getItem('tokenAPI') && <>
                                        <div className="row">
                                            <div className="col-md-12 commentlist_title"><b>Комментарии могут оставлять только авторизованные пользователи.</b></div>
                                        </div>
                                    </>}
                                    <div className="row">
                                        <div className="col-md-12">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 newslist_wrapper">
                            <div className="row">
                                <div className="col-md-12 newslist_title"><h4><span>Новости</span></h4></div>
                                {newsList}
                            </div>
                            <div className="row">
                                <div className="col-md-12">&nbsp;</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )

    }
}
export default NewsElement;