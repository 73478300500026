import React from 'react';
import {Link} from 'react-router-dom';
import Pagination from "../elements/Pagination";

export class ResourceViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            news: [],
            count: 0
        };
    }
    componentDidMount() {
        let limit = 3;
        let url = global.apiLink + 'news/list?page=1&limit='+limit;
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === 200) {
                        this.setState({
                            news: result.payload.news,
                            countPage:result.payload.countPage
                        });
                    }
                }
            );

        const urlParams = new URLSearchParams(window.location.search);
        let query = urlParams.get('q');
        if(query !== null){
            console.log(query);
            document.getElementById("search-list-result").innerHTML = global.CssLoader;
            document.getElementById("search-input").value = query;
            url = global.apiLink + '/api/search?q=' + query;
            fetch(url)
                .then(response => response.json())
                .then((response) => {
                    this.setState({
                        list: response.payload.list,
                        count: response.payload.count
                    });
                    console.log(response.payload.count);
                    if(response.payload.count !== 0){
                        document.getElementById("quick-start-tagsearch").style.display = "none";
                    }
                    document.querySelectorAll(".lds-ellipsis")[0].remove();
                });
        }
    }



    
    onPageChanged = paginationData => {
        let currentPage = paginationData.currentPage;
        const urlParams = new URLSearchParams(window.location.search);
        //document.getElementById("search-list-result").innerHTML = global.CssLoader;
        let query = urlParams.get('q');
        document.getElementById("search-input").value = query;
        let url = global.apiLink + '/api/search?q=' + query+'&page='+currentPage;
        fetch(url)
            .then(response =>response.json())
            .then((response)=>{
                this.setState({
                    list: response.payload.list,
                    count: response.payload.count
                });
                console.log(response.payload.list);
                if(response.payload.count !== 0){
                    document.getElementById("quick-start-tagsearch").style.display = "none";
                }
                if(document.querySelectorAll(".lds-ellipsis")[0]!==undefined){
                    document.querySelectorAll(".lds-ellipsis")[0].remove();
                }
            })
    }

    render() {
        let newsList = null;
        const {news} = this.state;
        var MonthArray=[
               'ЯНВ',
               'ФЕВ',
               'МАР',
               'АПР',
               'МАЙ',
               'ИЮН',
               'ИЮЛ',
               'АВГ',
               'СЕН',
               'НОЯ',
               'ДЕК',
            ];
            
        newsList = news.map((element) => {
    
            let imageLink = "/assets/images/no-image.png";
            if(element.file)imageLink = global.apiLink + 'uploads/' + element.file.path + '/' + element.file.name;
            
            let dateDB = new Date(element.created_at);
            let CreatedDay = ('00' + dateDB.getUTCDate()).slice(-2);
            let CreatedMonth = ('00' + (dateDB.getUTCMonth()+1)).slice(-2);
            let CreatedYear = dateDB.getUTCFullYear();
    
            return (<>
            
            
            <div className="uk-width-medium-1-3 news-content">
                    <div className="news-item">
                        <Link to={"/news/"+element.alias} className="news-item-image" style={{
                            background:'url(' + imageLink + ')',
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            display: "block",
                            position: "relative",
                        }}
                        ></Link>
                        <div className="news-item-date">{CreatedDay}<div className="news-item-date-month">{MonthArray[CreatedMonth-1]}</div><div className="news-item-date-yaer">{CreatedYear}</div></div>
                        <div className="news-item-shield">
                            <Link className="news-item-title" to={"/news/"+element.alias}>{element.title}</Link>
                            <div className="news-item-description">
                                {element.anonce_text}
                            </div>
                        </div>
                    </div>
            </div>
        </>);
    });


    let searchResult = this.state.list.map((element) =>
        <div className='item_wrapper'>
            <div className="blog_content">
                <h4 className="blogpost_title">
                    <i className="fa fa-file-text"></i>
                    <a href={element.link}>{element.title}</a>
                </h4>
                {element.content !=='' &&
                    <div className="clear post_clear" dangerouslySetInnerHTML={{ __html: element.content}}></div>
                }

            </div>
        </div>
    )
        return (
            <>

                <div className="uk-container uk-container-center quick-start">
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-1 quick-start-title-wrapper">
                            <div className="search-line">
                                <form action="/" method="GET">
                                    <input type="text" name="q" autocomplete="off" id="search-input" className="search-input" placeholder="Поиск" />
                                </form>
                            </div>
                            <div className="all-hr all-hr-2 color-lightblue"></div>
                        </div>
                        <div className="uk-width-medium-1-1 quick-start-list-wrapper">
                            <div id="quick-start-tagsearch" className="uk-grid ">
                                
                                <div className="uk-width-medium-1-3 quick-start-item-wrapper">
                                    <div className="quick-start-item-background"
                                         style={{background:'url(assets/images/gai.jpg)',backgroundPosition: "center",backgroundSize: 'cover'}}></div>
                                    <div className="quick-start-item-bottomtitle">Информация о ПДД</div>
                                    <div className="quick-start-item-subtitle">
                                        <a href="/?q=правила дорожного движения">Что делать при ДТП?</a>
                                    </div>
                                </div>

                                <div className="uk-width-medium-1-3 quick-start-item-wrapper">
                                    <div className="quick-start-item-background"
                                         style={{background:'url(assets/images/constitucia1.jpg)',backgroundSize:'cover'}}
                                    ></div>
                                    <div className="quick-start-item-bottomtitle">Защита личности</div>
                                    <div className="quick-start-item-subtitle">
                                        <a href="/?q=экономические преступления">Разводят на бабки?</a>
                                    </div>
                                </div>

                                <div className="uk-width-medium-1-3 quick-start-item-wrapper">
                                    <div className="quick-start-item-background"
                                         style={{background:'url(assets/images/7ya.jpg)',backgroundPosition: "center", backgroundSize:'cover'}}
                                    ></div>
                                    <div className="quick-start-item-bottomtitle">СЕМЕЙНЫЙ КОДЕКС</div>
                                    <div className="quick-start-item-subtitle">
                                        <a href="/?q=семья брак">Условия заключения брака</a>
                                    </div>
                                </div>

                                <div className="uk-width-medium-1-3 quick-start-item-wrapper">
                                    <div className="quick-start-item-background"
                                         style={{background:'url(assets/images/kodeks_eas.jpg)',backgroundSize:'cover'}}
                                    ></div>
                                    <div className="quick-start-item-bottomtitle">СФЕРА БИЗНЕСА</div>
                                    <div className="quick-start-item-subtitle">
                                        <a href="/?q=бизнес предпринимательство">Защититься от рейдерского захвата</a>
                                    </div>
                                </div>

                                <div className="uk-width-medium-1-3 quick-start-item-wrapper">
                                    <div className="quick-start-item-background"
                                         style={{background:'url(assets/images/burokraty.jpg)',backgroundPosition: "center",backgroundSize:'cover'}}
                                    ></div>
                                    <div className="quick-start-item-bottomtitle">СФЕРА БИЗНЕСА</div>
                                    <div className="quick-start-item-subtitle">
                                        <a href="/?q=бизнес предпринимательство">Не дают заниматься бизнесом?</a>
                                    </div>
                                </div>

                                <div className="uk-width-medium-1-3 quick-start-item-wrapper">
                                    <div className="quick-start-item-background"
                                         style={{background:'url(assets/images/perevozky.jpg',backgroundSize:'cover'}}
                                    ></div>
                                    <div className="quick-start-item-bottomtitle">СФЕРА ПЕРЕВОЗОК</div>
                                    <div className="quick-start-item-subtitle">
                                        <a href="/?q=транспорт таможня">Как правильно пройти таможню</a>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div id="search-list-result" className="uk-width-medium-1-1 quick-start-list-wrapper">
                            {searchResult}
                            {(this.state.count > 0) &&
                                <Pagination totalRecords={this.state.count} pageLimit={10} onPageChanged={this.onPageChanged}/>
                            }
                        </div>
                    </div>
                </div>



                <div className="uk-container-expand news-wrapper bg-gradient1 lk_mainpage">
                    <div className="uk-container uk-container-center">
                        <div className="uk-grid">
                            <div className="uk-width-medium-1-1">
                                <div className="uk-grid">
                                    <div className="uk-width-medium-1-1 news-header ">
                                        <div className="uk-h1 uk-text-left news-header-title uk-text-uppercase">Последний новости в блоге или примеры случаев</div>
                                        <div className="uk-container-left all-hr color-lightblue"></div>
                                        <Link className="uk-h3 uk-text-left color-lightblue news-header-subtitle" to="/news">Все новости</Link>
                                    </div>
                                </div>

                                <div className="uk-grid news-content-wrapper">
                                    {newsList}
                                    <div className="uk-clearfix"></div>
                                </div>              
                                <div className="uk-clearfix"></div>  
                            </div>
                            <div className="uk-clearfix"></div>
                        </div>
                        <div className="uk-clearfix"></div>
                    </div>
                    <div className="uk-clearfix"></div>
                </div>
                {/*}
                <div className="filter-search-wrapper-overview">
                    <div className="uk-container-expand filter-search">
                        <div className="uk-grid">
                            <div
                                className="uk-container uk-container-center uk-width-medium-1-1 filter-search-header-wrapper uk-margin-large-top">
                                <div className="uk-grid">
                                    <div className="uk-width-medium-1-3 filter-search-form">
                                        <form>
                                            <input type="text" className="uk-margin-large-left" value=""
                                                   placeholder="ПОИСК"/>
                                        </form>
                                    </div>
                                    <div className="uk-width-medium-2-3 filter-search-buttons">
                                        <div className="filter-title">ФИЛЬТРЫ:</div>
                                        <div className="filter-list">
                                            <ul>
                                                <li>
                                                    Фильтр 1
                                                </li>
                                                <li className="active">
                                                    Фильтр 1
                                                </li>
                                                <li>
                                                    Фильтр 1
                                                </li>
                                                <li>
                                                    Фильтр 1
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-container uk-container-center filter-search-result">
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-1 filter-search-result-title-wrapper">
                            <div className="filter-search-icon-circle"></div>
                            <div className="uk-h1 filter-search-result-header-title">РАЗВОД И СЕМЕЙНОЕ ПРАВО</div>
                            <div className="filter-search-result-header-subtitle">частые вопросы и ситуации</div>
                        </div>
                        <div className="uk-width-medium-1-1 filter-search-result-subtitle-wrapper">
                            <div className="filter-search-result-subtitle">
                                ЧАСТЫЕ ВОПРОСЫ И ОТВЕТЫ
                            </div>
                        </div>
                        <div className="uk-width-medium-1-1 filter-search-result-accordion-wrapper">
                            <div className="filter-search-result-accordion">
                                <div className="uk-accordion" data-uk-accordion="{collapse:false}">
                                    <h3 className="uk-accordion-title">1. Что делать если моя жена не уважает моего
                                        домашнего гуся?</h3>
                                    <div className="uk-accordion-content">Тримайся</div>
                                    <h3 className="uk-accordion-title">1. Что делать если моя жена не уважает моего
                                        домашнего гуся?</h3>
                                    <div className="uk-accordion-content">Тримайся!</div>
                                    <h3 className="uk-accordion-title">1. Что делать если моя жена не уважает моего
                                        домашнего гуся?</h3>
                                    <div className="uk-accordion-content">Тримайся!</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filter-search-wrapper-overview filter-search-wrapper-overview-separator">
                    <div className="uk-container-expand filter-search">
                        <div className="uk-grid">
                            <div
                                className="uk-container uk-container-center uk-width-medium-1-1 filter-search-header-wrapper uk-margin-large-top">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-container uk-container-center filter-search-result">
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-1 filter-search-result-title-wrapper">
                            <div className="filter-search-icon-circle"></div>
                            <div className="uk-h1 filter-search-result-header-title">РАЗВОД И СЕМЕЙНОЕ ПРАВО</div>
                            <div className="filter-search-result-header-subtitle">частые вопросы и ситуации</div>
                        </div>
                        <div className="uk-width-medium-1-1 filter-search-result-subtitle-wrapper">
                            <div className="filter-search-result-subtitle">
                                ЧАСТЫЕ ВОПРОСЫ И ОТВЕТЫ
                            </div>
                        </div>
                        <div className="uk-width-medium-1-1 filter-search-result-accordion-wrapper">
                            <div className="filter-search-result-accordion">
                                <div className="uk-accordion" data-uk-accordion="{collapse:false}">
                                    <h3 className="uk-accordion-title">1. Что делать если моя жена не уважает моего
                                        домашнего гуся?</h3>
                                    <div className="uk-accordion-content">Тримайся</div>
                                    <h3 className="uk-accordion-title">1. Что делать если моя жена не уважает моего
                                        домашнего гуся?</h3>
                                    <div className="uk-accordion-content">Тримайся!</div>
                                    <h3 className="uk-accordion-title">1. Что делать если моя жена не уважает моего
                                        домашнего гуся?</h3>
                                    <div className="uk-accordion-content">Тримайся!</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="uk-container uk-container-center">
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-1">
                            <br />
                        </div>
                    </div>
                </div>{/**/}
                {/*}<div className="uk-container uk-container-center contacts-wrapper">
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-3 uk-text-center contacts-item">
                            <i className="fas fa-map-marker-alt"></i> <span className="contacts-item-text">66 Broklyn Street, New York</span>
                        </div>
                        <div className="uk-width-medium-1-3 uk-text-center contacts-item">
                            <i className="fas fa-envelope"></i> <span
                            className="contacts-item-text">help@attorco.com</span>
                        </div>
                        <div className="uk-width-medium-1-3 uk-text-center contacts-item">
                            <i className="far fa-clock"></i> <span
                            className="contacts-item-text">Mon-Sat: 9am – 6pm</span>
                        </div>
                    </div>
                </div>{/**/}

            </>
        );
    }
}
export default ResourceViewer;