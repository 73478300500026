import React from 'react';

export class PremiumAccess extends React.Component {
    constructor(props) {
        super(props);
		this.props = props;

        this.state = {
            isPremium: Boolean(localStorage.getItem('isPremium')?(JSON.parse(localStorage.getItem('isPremium'))):false),
            isAuth: Boolean((localStorage.getItem('tokenAPI'))?true:false),
            CheckForAuth: Boolean(props.CheckForAuth?true:false),
            CheckForPremium: Boolean(props.CheckForPremium?true:false)
        }
    }


    render(){
		let self = this;
		let { 
			isPremium,
			isAuth, 
			CheckForAuth,
			CheckForPremium
		 } = self.state;
		 
		if(CheckForAuth === true){
			if(isAuth === false){
				let Text = <p className="d-block mb-3">Доступно только авторизованным пользователям.</p>;
				if(self.props.text !== undefined) { Text = self.props.text; }
				return (<>
					<div className="text-center pb-4 pt-2">
						{Text}
						<a href="/login">
							<button className="btn btn-biryza form-submit-button submit-button">
								Авторизироваться
							</button>
						</a>
					</div>
				</>);
			}else{
				return (<></>);
			}
		}else if(CheckForPremium === true){
			if(isPremium === false){
				let Text = <p className="d-block mb-3">Доступно только пользователям с Премиум аккаунтом.</p>;
				if(self.props.text !== undefined) { Text = self.props.text; }
				return (<>
					<div className="text-center pb-4 pt-2">
						{Text}
						<a href="/intarifs">
							<button className="btn btn-biryza form-submit-button submit-button">
								Выбрать тариф
							</button>
						</a>
					</div>
				</>);
			}else{
				return (<></>);
			}
		}
		return (<></>);
    }

}

export default PremiumAccess;