import React from 'react';
//import ReactDOM from 'react-dom';
import "../../../global";
import "./header_navigation.css";

let tokenAPI = localStorage.getItem('tokenAPI');
let headers = new Headers();
headers.append('Authorization','Bearer '+tokenAPI);

export class HeaderNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdvocat: false
        };
    }


		getUserInfo (){
			let self = this;
			let url = global.apiLink + '/api/user/get-profile';
			fetch(url, {
				method: 'GET',
				headers: headers
			})
            .then( response => response.json() )
            .then( (response) => {
                console.log("response");
                console.log(response);
                if(response.status !== 200){
                    window.location.href = '/login';
                }
                if(response.status == 200){
					console.log(response.payload.isAdvocat);
					if(response.payload.isAdvocat !== undefined){
						self.setState({ isAdvocat: response.payload.isAdvocat });
					}
					let isPremium = Boolean(response.payload.premium);
					localStorage.setItem("isPremium", isPremium);
                }
				
            } ).catch( (response)=>{ } );
		}


		componentDidMount() {
			let self = this;
			console.log(window.outerHeight);
			//alert(localStorage.getItem('tokenAPI'));
			if(localStorage.getItem('tokenAPI') !== null){
				let url  = global.apiLink + '/api/token/check-token';
				fetch(url, { headers:headers })
				.then(response =>response.json())
				.then((response)=>{
					if(response.status === 200 && response.payload === true){
						console.log('user loggined');
						self.getUserInfo();
						return;
					}
					localStorage.removeItem('tokenAPI');
					window.location.href = '/login';
				});
			}
		}

		mobile_burger_panel_btn = ()=>{
			let st_display = document.querySelector(".site-header .top_menu").style.display;
			if(st_display == "block"){
				st_display = "none";
			}else{
				st_display = "block";
			}
			document.querySelector(".site-header .top_menu").style.display = st_display;
		}

    render(){
		let { isAdvocat } = this.state;

		let icons = {
			home : '<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="nav-icon-solid"><path d="M10.8484 0.751734C10.6234 0.526769 10.3182 0.400391 9.99999 0.400391C9.68179 0.400391 9.37662 0.526769 9.15159 0.751734L0.751588 9.15173C0.532998 9.37806 0.412045 9.68118 0.414779 9.99581C0.417513 10.3104 0.543716 10.6114 0.766206 10.8339C0.988696 11.0564 1.28967 11.1826 1.60431 11.1853C1.91894 11.1881 2.22207 11.0671 2.44839 10.8485L2.79999 10.4969V18.4001C2.79999 18.7184 2.92642 19.0236 3.15146 19.2487C3.3765 19.4737 3.68173 19.6001 3.99999 19.6001H6.39999C6.71825 19.6001 7.02347 19.4737 7.24852 19.2487C7.47356 19.0236 7.59999 18.7184 7.59999 18.4001V16.0001C7.59999 15.6819 7.72642 15.3766 7.95146 15.1516C8.1765 14.9266 8.48173 14.8001 8.79999 14.8001H11.2C11.5182 14.8001 11.8235 14.9266 12.0485 15.1516C12.2736 15.3766 12.4 15.6819 12.4 16.0001V18.4001C12.4 18.7184 12.5264 19.0236 12.7515 19.2487C12.9765 19.4737 13.2817 19.6001 13.6 19.6001H16C16.3182 19.6001 16.6235 19.4737 16.8485 19.2487C17.0736 19.0236 17.2 18.7184 17.2 18.4001V10.4969L17.5516 10.8485C17.7779 11.0671 18.081 11.1881 18.3957 11.1853C18.7103 11.1826 19.0113 11.0564 19.2338 10.8339C19.4563 10.6114 19.5825 10.3104 19.5852 9.99581C19.5879 9.68118 19.467 9.37806 19.2484 9.15173L10.8484 0.751734Z"></path></svg>',
			services : '<svg width="18" height="21" viewBox="0 0 18 21" xmlns="http://www.w3.org/2000/svg" class="nav-icon-solid"><path d="M2.99902 18.5098L2.99901 18.51H3.012H17.5V19.51H3C2.49363 19.51 1.85733 19.339 1.35829 18.9435C0.877281 18.5623 0.5 17.956 0.5 17.01V3.01001C0.5 2.06404 0.877282 1.45768 1.35829 1.07651C1.85733 0.681045 2.49363 0.51001 3 0.51001H16C16.8269 0.51001 17.5 1.18315 17.5 2.01001V15.5H17.049H17.0351L17.0213 15.5008C16.9927 15.5024 16.9674 15.506 16.9464 15.51H3.011V15.5099L2.99863 15.5102C2.75202 15.5163 2.43689 15.5623 2.15201 15.7321C1.84708 15.9139 1.61631 16.2148 1.53322 16.6414L1.53319 16.6414L1.53216 16.647C1.51176 16.7586 1.5 16.8787 1.5 17.01C1.5 17.5395 1.68902 17.9471 2.03088 18.206C2.34586 18.4445 2.72096 18.5026 2.99902 18.5098ZM16.878 15.5273C16.8776 15.5275 16.8776 15.5274 16.8781 15.5273L16.878 15.5273ZM16 14.51H16.5V14.01V2.01001V1.51001H16H14H13.5V2.01001V8.20099L12.2236 7.5628L12 7.45099L11.7764 7.5628L10.5 8.20099V2.01001V1.51001H10H3V1.50983L2.98667 1.51019C2.71326 1.51748 2.34118 1.57589 2.02806 1.81467C1.68873 2.07344 1.5 2.4803 1.5 3.01001V14.01V14.51H2H16Z"></path></svg>',
			chat : '<svg width="22" height="20" viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg" fill="transparent" class="nav-icon-outline"><path d="M11.75 16.5H9.5L4.5 19V16.5H1V1.5H21V7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5 8.5H11M13.5 10.6C13.5 10.0665 17 9 17 9C17 9 20.5 10.0665 20.5 10.6C20.5 14.8665 17 17 17 17C17 17 13.5 14.8665 13.5 10.6ZM5 5.5H8H5Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
			call : '<svg width="16" height="22" viewBox="0 0 16 22" xmlns="http://www.w3.org/2000/svg" class="nav-icon-solid"><path d="M3.05657 1.41795L4.22357 1.06619C5.54607 0.667551 6.95907 1.31179 7.52543 2.57161L8.4271 4.57733C8.90983 5.65117 8.65544 6.91317 7.79438 7.71612L6.30008 9.10957C6.25641 9.1503 6.22875 9.20532 6.22213 9.26466C6.17777 9.66181 6.447 10.4352 7.06726 11.5095C7.51831 12.2908 7.92656 12.8387 8.27405 13.1468C8.51615 13.3615 8.64943 13.4079 8.70617 13.391L10.7165 12.7764C11.8421 12.4323 13.0619 12.8426 13.7506 13.7971L15.0313 15.5721C15.8374 16.6893 15.6925 18.2306 14.6922 19.1779L13.8058 20.0173C12.8494 20.9231 11.4879 21.2606 10.2191 20.9065C7.46502 20.1378 4.99571 17.8137 2.78383 13.9826C0.568887 10.1462 -0.208306 6.84173 0.508146 4.06994C0.836015 2.80146 1.80214 1.79607 3.05657 1.41795ZM3.48947 2.85413C2.73681 3.081 2.15714 3.68423 1.96042 4.44532C1.35777 6.77684 2.04662 9.70569 4.08287 13.2326C6.1164 16.7548 8.30535 18.815 10.6224 19.4617C11.3836 19.6742 12.2006 19.4717 12.7744 18.9282L13.6608 18.0888C14.1154 17.6582 14.1813 16.9576 13.8149 16.4498L12.5342 14.6748C12.2211 14.241 11.6667 14.0544 11.155 14.2109L9.1397 14.827C7.96999 15.1757 6.90831 14.2342 5.76822 12.2595C4.99992 10.9288 4.64183 9.90012 4.73139 9.09817C4.77779 8.68273 4.97137 8.29763 5.27708 8.01254L6.77139 6.61909C7.16278 6.25411 7.27841 5.68047 7.05898 5.19237L6.15732 3.18665C5.89988 2.614 5.25761 2.32116 4.65647 2.50236L3.48947 2.85413Z"></path></svg>',
		};
        let delim = `\n`;
        return (<>
        <section className="block_1">
			<nav className="site-header sticky-top py-1 navbar navbar-expand-lg navbar-light">
			  <div className="container d-flex flex-column flex-md-row justify-content-between">
				  <div>
					<a className="logo" href="/">
						<img src="/lp/main/assets/images/logo.png" alt="logo" />
						<div className="logo_text">МОЙ АДВОКАТ</div>
					</a>
					<div className="mobile_burger_panel_btn btn" onClick={()=>{this.mobile_burger_panel_btn()}}><i className="fa fa-bars" aria-hidden="true"></i></div>
				  </div>
				
				<div className="top_menu flex-fill">
					<ul className="navbar-nav">
					{/*<li className="nav-item active"><a href="/"><span dangerouslySetInnerHTML={{__html: icons.home}} /> Главная</a></li>*/}
					    <li className="nav-item"><a href="/about/">{/*<span dangerouslySetInnerHTML={{__html: icons.services}} /> */}О проекте</a></li>
	                    <li className="nav-item"><a href="/services/">{/*<span dangerouslySetInnerHTML={{__html: icons.services}} /> */}Сервисы</a></li>
						<li className="nav-item"><a href="/chat/">{/*<span dangerouslySetInnerHTML={{__html: icons.chat}} /> */}Чат</a></li>
						<li className="nav-item"><a href="/intarifs/">{/*<span dangerouslySetInnerHTML={{__html: icons.chat}} /> */}Тарифы</a></li>
						{isAdvocat === true && 
							<li className="nav-item"><a href="/getcall/" className="text-danger">Приём звонков</a></li>
						}
						<li className="nav-item"><a href="/call/">{/*<span dangerouslySetInnerHTML={{__html: icons.call}} /> */}Позвонить</a></li>
						{/*}<li className="nav-item active"><a href="/">Главная</a></li>
						<li className="nav-item"><a href="#services">Услуги <i className="fa fa-caret-down" aria-hidden="true"></i></a>
							<div className="submenu">
								<ul>
									<li><a href="/qa/">Ответы на вопросы</a></li>
									<li><a href="/is/">Нотариусы, адвокаты, юристы</a></li>
									<li><a href="/situation/">Ситуации</a></li>
									<li><a href="/chat/">Обращения в чате</a></li>
									<li><a href="/call/">Звонки на горячую линию</a></li>
									{isAdvocat === true && <li><a href="/getcall/">Приём звонков</a></li>}
									{isAdvocat === true && <li><a href="/operator">Приём звонков (new)</a></li>}
									<li><a href="/docs/">Образцы документов</a></li>
									<li><a href="/regions/">Справочная информмация</a></li>
								</ul>
							</div>
						</li>
						<li className="nav-item"><a href="#achives">Информация <i className="fa fa-caret-down" aria-hidden="true"></i></a>
							<div className="submenu">
								<ul>
									<li><a href="/codex/">Кодексы</a></li>
									<li><a href="/plenum/">Судебная практика</a></li>
									<li><a href="/codex/NDQyMDI=">Конституция Российской Федерации</a></li>
								</ul>
							</div>
						</li>
						<li className="nav-item"><a href="#question">Система <i className="fa fa-caret-down" aria-hidden="true"></i></a>
							<div className="submenu">
								<ul>
									<li><a href="/news/">Новости</a></li>
									<li><a href="/about/">О проекте</a></li>
									<li><a href="/privpol/">Политика конфиденциальности</a></li>
								</ul>
							</div>
						</li>{/**/}
					</ul>
				</div>
				<div className="flex-fill-reverse header-rightside-info">
					<div className="header-phone ml-3">
					    <a href="tel:88006006254" target="_blank" title="Контактный телефон: 8(800)5508972 (круглосуточно)">
							<img 
								src={"/assets/images/feedback/support.svg"}
								style={{width:"45px", float:"left", marginRight:"3px"}}
							/> 
							<small>8 (800) 600 62 54{delim}
							звонок по РФ бесплатный
						</small>
						</a>
                    </div>
					<div className="header-lk-btn">
						{tokenAPI === null && <><a href="/login/" className="btn">ВОЙТИ</a></>}
						{tokenAPI !== null && <><a href="/lk/" className="btn">МОЙ КАБИНЕТ</a>
						<div className="drop-down-lk">
							<ul>
								<li><a href="/lk/">Профиль</a></li>
								{ /*<li><a href="/yookassapay/">Оплатить премиум</a></li>*/ }
								{/*isAdvocat === true && <li><a href="/operator">Приём звонков (new)</a></li>/**/}
								<li><a href="/logout/">Выйти</a></li>
								
						</ul>
						</div></>}
					</div>
				</div>
			  </div>
			</nav>
		</section>
		
		
</>);
        }

}

export default HeaderNavigation;