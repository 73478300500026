import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import '../../global';

import "../../helper/ajaxStatus";

import MainPage         from './template/mainpage';
import Newslist         from '../../components/news/newslist';
import NewsElement      from '../../components/news/newselement';
import Subject          from '../../components/subjects/subject';
import Subjects         from '../../components/subjects/subjects';
import DocumentDetail   from '../../components/docs/DocumentDetail';
import DocPartDetail   from '../../components/docs/DocPartDetail';
import LoginForm        from '../../components/login/loginForm';
import Profile          from '../../components/lk/Profile';
import Logout           from '../../components/login/logout';
import Registration     from '../../components/login/registration';
import VerifyByEmail    from '../../components/login/verifyByEmail';
import ForgotPassword   from '../../components/login/forgotPassword';
//import ResetPassword    from '../../components/login/ResetPassword';
import HeaderLk         from '../../layouts/personal-cab/Header';
import FooterLk         from '../../layouts/personal-cab/Footer';
import CallResource     from '../../components/call/CallResource';
import GetCallResource  from '../../components/get-call/GetCallResource';

import ResourceViewerCodex                  from '../../components/codex/resourceviewer';
import ResourceCodexMainpage                from '../../components/codex/codexmainpage';
import ResourceViewerSituation              from '../../components/situations/resourceviewer';
import ResourceViewerSituationPart          from '../../components/situations/resourceviewerpart';
import ResourceViewerSituationPartAlias     from '../../components/situations/resourceviewerpartalias';
import ResourceViewerQa                     from '../../components/qa/resourceviewer';
import ResourcePage                         from '../../components/qa/resourcepage';
import PartitionPage                        from '../../components/qa/partitionpage';
import ResourceViewerPlenum                 from '../../components/plenum/resourceviewer';
import InformationSystemSP                  from '../../components/is/searchpage';
import Search                               from '../../components/search';
import PrivPol                              from '../../components/infocenter/privpol';
import RequizitiPage                        from "../../components/infocenter/cardofpredpriatia.js";
import AboutProject                         from '../../components/infocenter/aboutprojent';
import ContactUs                            from "../../components/infocenter/ContactUs";
import FeedBack                             from "../../components/infocenter/feedback";
import TarifPage                            from "../../components/infocenter/tarifs";
import Serviceslist                         from '../../components/services/serviceslist';
import ResourceViewerDocs                   from '../../components/docs/resourceviewer'
import ResourceViewerDocsPart from '../../components/docs/DocPartDetail'
import QuickStart_dtp                       from '../../components/quickstart/qs_dtp'
import ChatPage                             from '../../components/chat/chatmainpage'
import RoomPage                             from '../../components/chat/roompage'
import ResourceviewerSubject                from '../../components/subjects/resourceviewer';
import HPanel                               from '../../components/panel';
import YooKassaPage                         from '../../components/lk/paypage/orderPayPage';
import PaymentStatusPage                    from '../../components/lk/paypage/pendingPayPage';
import YooKassa                             from '../../components/lk/paypage/yookassa';
import YooKassaAlert                        from '../../components/lk/paypage/yookassa_alert';
import PaymentsPage                         from '../../components/lk/paypage/payments';
import Presetlist                           from '../../components/preset/list';
import PresetGet                            from '../../components/preset/get';
import OperatorPage from '../../components/operator/OperatorPage';
import { FemidaSocket } from '../../components/operator/socket';
//import Call_node from '../../components/call/Call_node';


export class App extends React.Component {


    componentDidMount() {
        
        FemidaSocket.clear();
        const path = window.location.pathname;
        const isLoginPage = path.includes('login') 
        || path.includes('registration');
        console.log(process.env.NODE_ENV);
        const MOBILE_URL = process.env.NODE_ENV === "development" ?
        "http://localhost:3000" : "https://m.my-advo.cat/";

        // не редиректит на мобильную версию на страницах
        // авторизации и регистрации, чтобы иметь возможность
        // авторизоваться через гугл с мобильных + для корректной
        // работы регистрации
        if(isMobile && !isLoginPage) {
            window.location.href = MOBILE_URL;
        }

        if (window.location.protocol !== 'https:' && process.env.NODE_ENV !== "development") {
            window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
        }
    }
    render(){
        return (<>
                <Router>
                    <Switch>
                        {/*}<Route exact path="/docs" component={HeaderLk} />
                        <Route exact path="/codex" component={HeaderLk} />

                        <Route exact path="/subjects" component={HeaderLk} />
                        <Route exact path="/lk" component={HeaderLk}/>
                        <Route exact path="/qa" component={HeaderLk} />
                        <Route exact path="/situation" component={HeaderLk} />
                        <Route exact path="/is/" component={HeaderLk} />
                        <Route exact path="regions/:id" component={HeaderLk}/>{/**/}
                        <Route path="/*" component={HeaderLk} />
                        <Route exact path="/" component={HeaderLk} />
                    </Switch>
                    <Switch>
                        <Route exact path="/" component={HPanel} />
                        <Route exact path="/pay_yook/" component={YooKassaAlert} />
                        <Route exact path="/tarifs/" component={YooKassaPage} />
                        <Route exact path="/yookassa_widget/" component={YooKassa} />
                        <Route exact path="/payments/" component={PaymentsPage} />
                        <Route exact path="/codex/" component={ResourceCodexMainpage} />
                        <Route path="/codex/:alias/" component={ResourceViewerCodex} />
                        <Route exact path="/chat/" component={ChatPage} />
                        <Route exact path="/chat/:roomid" component={RoomPage} />
                        <Route exact path="/situation/" component={ResourceViewerSituation} />
                        <Route exact path="/situation/part:part/" component={ResourceViewerSituationPart} />
						<Route exact path="/situation/part:part/:alias/" component={ResourceViewerSituationPartAlias} />
							{/*<Route exact path="/situation/:alias/" component={ResourceViewerSituationPartAlias} />*/ }
							{ /* <Route exact path="/docs/"  component={ResourceViewerDocs} />
                        <Route exact path="/docs/part:part" component={DocPartDetail} />
	<Route exact path="/docs/:id"  component={DocumentDetail} />*/ }
						
                        <Route exact path="/docs/" component={ResourceViewerDocs} />
						<Route exact path="/docs/part:part/:id/" component={DocumentDetail} />
						<Route exact path="/docs/part:part/" component={DocPartDetail} />						
						
						
                        <Route exact path="/lk/" component={Profile} />
                        <Route exact path="/is/" component={InformationSystemSP} />
                        <Route exact path="/login" component={LoginForm} />
                        <Route exact path="/login/forgot" component={ForgotPassword} />
                        <Route exact path="/regions/" component={ResourceviewerSubject} />
                        <Route exact path="/regions/:region_id/" component={Subjects} />
                        <Route exact path="/organization/:id" component={Subject} />
                        <Route exact path="/registration" component={Registration} />
                        <Route exact path="/registration/verify" component={VerifyByEmail} />
                        <Route exact path="/logout" component={Logout} />
                        <Route exact path="/plenum/" component={ResourceViewerPlenum} />
                        <Route exact path="/news/" component={Newslist} />
                        <Route exact path="/intarifs/" component={TarifPage} />
                        <Route exact path="/paymentstatus/:payid" component={PaymentStatusPage} />
                        <Route exact path="/call/" component={CallResource} />
                        {/* <Route exact path="/call/" component={Call_node} /> */}
                        <Route exact path="/getcall/" component={GetCallResource} />
                        <Route exact path="/operator" component={OperatorPage} />
                        <Route exact path="/news/:alias" component={NewsElement} />
                        <Route exact path="/qa/"  component={ResourceViewerQa} />
                        <Route path="/qa/part_:part/" component={PartitionPage} />
                        <Route path="/qa/:alias/" component={ResourcePage} />
                        <Route path="/is/:juristtype/:stateid/:cityid/:juristid/" component={InformationSystemSP} />
                        <Route path="/is/:juristtype/:stateid/:cityid/" component={InformationSystemSP} />
                        <Route path="/is/:juristtype/:stateid/" component={InformationSystemSP} />
                        <Route path="/is/:juristtype/" component={InformationSystemSP} />
                        <Route path="/plenum/:alias/" component={ResourceViewerPlenum} />
                        <Route path="/search/" component={Search} />
                        <Route exact path="/privpol/" component={PrivPol} />
                        <Route exact path="/about/" component={AboutProject} />
						<Route exact path="/contactus/" component={ContactUs} />
						<Route exact path="/requisites/" component={RequizitiPage} />
                        <Route exact path="/feedback/" component={FeedBack} />
                        <Route exact path="/landing/" component={MainPage} />
                        <Route exact path="/preset/" component={Presetlist} />
                        <Route path="/preset/p:presetid" component={PresetGet} />
                        <Route path="/quickstart/:qs/" component={QuickStart_dtp} />
						<Route exact path="/services/" component={Serviceslist} />
                    </Switch>
                    <Switch>
                        {/*}<Route exact path="/docs" component={FooterLk} />
                        <Route path="/situation" component={FooterLk} />
                        <Route path="/qa" component={FooterLk} />
                        <Route path="/codex" component={FooterLk} />

                        <Route path="/regions/" component={FooterLk} />
{/**/}
                        <Route exact path="/lawyer/" render={()=>{window.location.replace('https://m.my-advo.cat/lawyer/'); return false;}} />
                        <Route path="/*" component={FooterLk} />
                    </Switch>
                </Router>
            </>);
        }

}

export default App;
