import React from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";
import PremiumAccess from "../../layouts/personal-cab/PremiumAccess";
import SituationRightPanel from "./rightPanel";

import "../../global.js";
import './situations.css';
import * as utils from "../../utils.js";


export class ResourceViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataResourceQuickstart: '',
            resourceid: 0,
            partTitle:"",
            isPremium: Boolean(localStorage.getItem('isPremium')?(JSON.parse(localStorage.getItem('isPremium'))):false),
            isAuth: Boolean((localStorage.getItem('tokenAPI'))?true:false),
        }
    }

    utils = {
        dummy: document.createElement('div'),
        escapeHTML: function (s) {
            return {__html: s};
        }
    }


    gettingResourcesToAccardion = async (pid) => {
		if (pid === null || pid == undefined) {
			return null;
	    } 
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let llLink = global.apiLink + `api/codex/codex/list?pid=${pid}&lvl=1`;
        const api_ResourceLL = await fetch(llLink, {
            headers: headers
        });
        let result = await api_ResourceLL.json();
        if (result.status !== 200) {
            alert("Не верные данные авторизации ll");
            return false;
        }
        return result;
    }

    getFindResources = async (text) => {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let llLink = global.apiLink + `api/codex/codex/list?search=${text}&lvl=1`;
        const api_ResourceLL = await fetch(llLink, {
            headers: headers
        });
        let result = await api_ResourceLL.json();
        if (result.status !== 200) {
            alert("Не верные данные авторизации ll");
            return false;
        }
        return result;
    }


    gettingSearchResourcesToAccardion = async () => {
        let SearchInput = document.getElementById("input_search_on_head");
        if (SearchInput == null) return false;
        let text = SearchInput.value;
        let result = await this.getFindResources(text);
        this.setState({dataResourceLvl1: result});
        return "";
    }

    onKeyUp(event) {
        if (event.charCode === 13) {
            this.gettingSearchResourcesToAccardion();
        }
        return false;
    }

    gettingResources = async (pid) => {
		if (pid === null || pid == undefined) {
			return null;
	    } 		
        const tokenAPI = localStorage.getItem("tokenAPI");
        
		let output = {};
		output.situations = null;
		output.lvl1  = null;
		output.page = null;
		output.qa = null;
		
		
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        const api_url = await fetch(global.apiLink + `api/situation/situation/list`, {
            headers: headers
        });
        output.quickstart = await api_url.json();
        if (output.quickstart.status !== 200) {
            alert("Не верные данные авторизации quickstart");
            return false;
        }
        if (pid == 0 || pid == null || pid == "null" || pid == undefined) {
			return output;
		} 	

        output.lvl1 = await this.gettingResourcesToAccardion(pid);
        console.log(output.lvl1);
        
        const api_url2 = await
            fetch(global.apiLink + `api/codex/codex/get?id=${pid}`, {
                headers: headers
            });
        output.page = await api_url2.json();
        if (output.page.status !== 200) {
            alert("Не верные данные авторизации page");
            return false;
        }

        if (pid === null ) return output;

        let breadcrumb_link = global.apiLink + "api/codex/codex/breadcrumb-codex?id=" + pid + "&title=1";
        const breadcrumbR = await fetch(breadcrumb_link, {
            headers: headers
        });
        output.breadcrumb = await breadcrumbR.json();
        if (output.breadcrumb.status !== 200) {
            // alert("Не верные данные авторизации breadcrumb");
            return false;
        }

        let uriget2 = global.apiLink + `api/qa/qa/list?codex_id=${pid}&getcontent=1`;
        let api_urlget2 = await fetch(uriget2, {
            headers: headers
        });
        output.qa = await api_urlget2.json();
        if (output.qa.status !== 200) {
            alert("Не верные данные авторизации tv");
            return false;
        }
		
        let uriget3 = global.apiLink + `api/situation/situation/list?token=${tokenAPI}&codex_id=${pid}&getcontent=1`;

        let api_urlget3 = await fetch(uriget3, {
            headers: headers
        });		
        output.situations = await api_urlget3.json();
        console.log("output.situations");
        console.log(output.situations);
        if (output.situations.status !== 200) {
            console.log(output.situations);
            alert("Не верные данные авторизации situations");
            return false;
        }
        return output;
    }


    parentFunc = (text) => {
        this.setState({dataResourceList: text});
    }

    createMarkup = (s) => {
        return {__html: s};
    }

    subElements = (nodes) => {
        return (
            <div>
                {nodes &&
                nodes.map(p => (
                    <div key={p.element.id}>
                        <Link to={`/codex/${p.base64_uid}/`} onClick={() => this.setState({"dataResourceList": ""})}>
                            <span dangerouslySetInnerHTML={this.createMarkup(p.element.title)}/>
                        </Link>
                        
                        {p.element.punkts && this.showPunkts(p.element.punkts)}
                        {p.nodes && this.subElements(p.nodes)}
                    </div>
                ))
                }
            </div>
        );
    }

    showPunkts = (json) => {
        let dataResourceTvalue = [];
        if (json !== undefined)
            var tmp = json;//JSON.parse(json);
        for (var dataResourceTVItem in tmp) {
            dataResourceTvalue[dataResourceTVItem] = tmp[dataResourceTVItem];
        }

        return (
            <div>
                {json && dataResourceTvalue.map((answer, i) => {
                        let content = dataResourceTvalue[i].content;
                        let title = dataResourceTvalue[i].title;
                        return (
                            <div key={i}>
                                <div
                                    dangerouslySetInnerHTML={this.createMarkup("<span style='float:left;'>" + (title) + ".&nbsp;</span>" + content)}/>
                                <br/>
                            </div>
                        );
                    }
                )}
            </div>
        );
    }

    sortData = (key, data, type) => {
        let ordered = {};
        let compareFunction = function (a, b) {
            return data[b][key] - data[a][key];
        };
        if (type === "asc") {
            compareFunction = function (a, b) {
                return data[a][key] - data[b][key];
            }
        }
        Object.keys(data).sort(compareFunction).forEach(function (key) {
            ordered[key] = data[key];
        });
        return ordered;
    }

    quickStartAllBtn = () => {
        return "";
    }

    subResOpen = async (ElId, TypeNode, B64uid) => {
        let Element = document.getElementById(ElId);
        if (TypeNode == "article") {
            document.location.href = ("/codex/" + B64uid);
            return false;
        }
        let dataid = Element.getAttribute("data-id");
        let nodes = await this.gettingResourcesToAccardion(dataid);
        ReactDOM.render(
            <div>
                {}
                {nodes &&
                nodes.payload.list.map(p => (
                    <div>
                        <div className={"uk-accordion-" + p.type_node}
                             onClick={() => this.subResOpen("ac_" + p.id, p.type_node, p.base64_uid)}>
                            <span dangerouslySetInnerHTML={this.createMarkup(p.title)}/>
                        </div>
                        {/*p.content && <span class="uk-accordion-article-content" dangerouslySetInnerHTML={this.createMarkup(p.content)} />/**/}
                        <div className="uk-accordion-content" id={"ac_" + p.id} data-id={p.id}></div>
                    </div>
                ))
                }
            </div>,
            Element
        );
    }

    newPageLoader = async (pid, pageNum, pageId) => {
		
		
		if (pid === null || pid == undefined) {
			return null;
	    } 		
		
        const tokenAPI = localStorage.getItem("tokenAPI");
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let llLink = global.apiLink + `api/codex/codex/list?pid=${pid}&recursive=2&page=${pageNum}`;
        const api_ResourceLL = await
            fetch(llLink, {
                headers: headers
            });
        console.log(llLink);
        var output = await api_ResourceLL.json();
        if (output.status !== 200) {
            alert("Не верные данные авторизации");
            return false;
        }
        if (pageNum > output.payload.pagecount) {
            document.getElementById(pageId).innerHTML = "";
            return false;
        }
        let nodes = output.payload.list.nodes;

        ReactDOM.render(
            <div>
                {nodes &&
                nodes.map(p => (
                    <div key={p.id}>
                        <a href={`/codex/${p.base64_uid}/`} onClick={() => this.setState({"dataResourceList": ""})}>
                            <span dangerouslySetInnerHTML={this.createMarkup(p.title)}/>
                        </a>
                        <div className="element-content" dangerouslySetInnerHTML={this.createMarkup(p.content)}></div>
                        {p.punkts && this.showPunkts(p.punkts)}
                    </div>
                ))
                }
            </div>,
            document.getElementById(pageId)
        );
        document.querySelector('body').removeAttribute("data-paginationatwork");

    }
  
    componentDidMount() {
        let self = this;
        let alias = self.props.match.params.alias;

		let resourceId = 0;
        if (alias) resourceId = atob(alias);
		self.setState({resourceId: resourceId});
		
        let partTitle = "";
        let partName = "";
        let pageTitle = "";
		let part = null;
        self.gettingResources(resourceId).then(output => {
			
            let part = output.situations.payload.list[0].parent_id;
            if(part == 1){//
                partName = "partuk"
                partTitle = "Практические рекомендации по УК РФ";
            }else if(part == 2){//
                partName = "partkoap"
                partTitle = "Практические рекомендации по КоАП РФ";
            }else if(part == 3){//
                partName = "partnalog"
                partTitle = "Практические рекомендации по НК РФ";
            }else if(part == 4){//
                partName = "partfamily"
                partTitle = "Гражданские и семейные дела";
            }
            let pageName = output.situations.payload.list[0].name;
            if (pageName) pageName = " ("+pageName+")";
            pageTitle = output.situations.payload.list[0].title;// + pageName;
			
            self.setState({
                dataResourceQuickstart: output.quickstart,
                dataResourceQa: output.qa,
                dataResourceSituation: output.situations,
                partTitle: partTitle,
                partName: partName,
                pageTitle: pageTitle,
                part: part,
            });
        });
    }


    render() {
        let self = this;
        const { 
            dataResourceQuickstart, 
            isPremium, 
            isAuth, 
            pageTitle, 
            dataResourceQa, 
            dataResourceSituation,
            resourceId
        } = this.state;
        
        const alias = self.props.match.params.alias;
        //const tokenAPI = localStorage.getItem("tokenAPI");
        console.log(isPremium);
        //const part = self.props.match.params.part;
        //let resourceId = 0;
//        let partId = 0;
        //if (alias) resourceId = atob(alias);
/*
        if(part == "uk"){
            partId = 1;
        }else if(part == "koap"){
            partId = 2;
        }
/**/
        document.getElementById("root").style.background = "#fafafb";
		const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Сервисы',
                path: '/services/',
            },
            {
                name: 'Практические правовые рекомендации',
                path: '/situation/',				
            },
            {
                name: this.state.partTitle,
                path: '/situation/' + this.state.partName + '/',				
            },
            {
                name: pageTitle && pageTitle,
                path: '',				
            },			
        ];
        if (dataResourceQuickstart != '') {
            return (
                <>
                <div className="page-situation page">
                    <div className="container">
                        <div className="row">
                            <BreadCrumbs crumbs={breadcrumbs} />						
						    <PageTitle pageTitle={pageTitle && utils.replaceByTpl(pageTitle, 'Статья', 'Рекомендации по Статье')} />
                        </div>

                        <div className="row">
                                {dataResourceQuickstart !== undefined && (typeof alias === 'undefined' || alias === null) && dataResourceQuickstart.payload.list &&
                                dataResourceQuickstart.payload.list.nodes.map((p, i) => {
                                    let base64_link = btoa(p.element.codex_id);
                                    return (
                                        <div className="reestr_itemlistbtn" key={p.element.id}>
                                            <Link className="btn btn-biryza" to={`/situation/${base64_link}/`}
                                                  onClick={() => this.setState({"dataResourceQuickstart": ""})}>
                                                {p.element.title}
                                            </Link>
                                        </div>
                                    );
                                })
                                }
                            </div>
                            <div className="row quick-start">
                                <div className={((isPremium && dataResourceQa !== null && dataResourceQa !== undefined &&  dataResourceQa.payload.list.nodes.length > 0) ? "col-md-8 ---right-border" : "col-lg-12")+" filter-search-result-accordion-wrapper"}>
                                    <div className="situations-list-container" id="situations">
                                        {dataResourceSituation && dataResourceSituation.payload.list.nodes.map((answer, i) => {
                                            let item_content = answer.element.content;//+insetShadow;
                                            let item_id = answer.element.id;
                                            return (<>
                                                <div className={"element-content wysiwig_content" + (isPremium == false ? ' text-restricted':"")} key={item_id} dangerouslySetInnerHTML={this.createMarkup(item_content)}></div>
                                                <PremiumAccess CheckForPremium={true} />
											</>);
                                        })}
                                    </div>
                                </div>
                                { (isPremium && resourceId) ? <SituationRightPanel resourceId={resourceId} /> : <></> }
                            </div>
                        </div>


                        <div className="uk-container uk-container-center filter-search-result uk-margin-small-top">
                            
                        </div>
                    </div>
{/**/}
                </>
            );

        } else {
            return (
                <>
                </>
            );
        }
    }
}

export default ResourceViewer;