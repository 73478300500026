import React from 'react';
import {Link} from 'react-router-dom';
import InteractiveMap from '../subjects/mapa';
import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";
import "../../global.js";
import './is.css';
import handleStatus from "../../helper/ajaxStatus";
import "./states_list.styles.css";

export class States_List extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            dataStateList: '',
            resourceid: 0,
            regions: [],
            searchText: ""
        }
    }

    handleSearchChange = (e) => {
        this.setState({
            searchText: e.target.value
        });
    }

    utils = {
        dummy: document.createElement('div'),
        escapeHTML: function (s) {
            return {__html: s};
        }
    }

    gettingStateList = async () => {
        const tokenAPI = localStorage.getItem("tokenAPI");
        let output = {};
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        const api_url = await
            fetch(global.apiLink + `api/is/is/pos-states`, {headers: headers});
        output.StateList = await api_url.json();
        handleStatus(output.StateList.status);
        if (output.StateList.status !== 200) {
            return false;
        }
        return output;
    }

    createMarkup = (s) => {
        return {__html: s};
    }

    componentDidMount() {
        let url = global.apiLink + "/regions/region/list";

        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.payload) {
                        let key = "1";
                        this.setState({regions: result.payload.list});

                        let regions = this.state.regions;
                        Object.keys(regions).map((region_id) => {
                            let regionContent = document.querySelector("[data-code='" + regions[region_id].code + "']").outerHTML;
                            console.log(regions[region_id].code);
                            document.querySelector("[data-code='" + regions[region_id].code + "']").outerHTML = '<a href="/is/' + this.props.juristtype + '/' + regions[region_id].id + '" title="' + regions[region_id].name + '">'
                                + regionContent
                                + '<span className="tooltiptext">' + regions[region_id].name + '</span></a>';
                        });
                    }
                }
            )
    }

    render() {
        let self = this;
        let {regions} = self.state;
        const juristtype = this.props.juristtype;
        var dataStateList = self.state.dataStateList;
        if (dataStateList === '') {
            self.gettingStateList().then(output => self.setState({
                dataStateList: output.StateList,
            }));
        }
        dataStateList = self.state.dataStateList;

        let JuristTypeText = "Нотариусы";
        if (juristtype == "lawyer") {
            JuristTypeText = "Адвокаты";
        } else if (juristtype == "jurist") {
            JuristTypeText = "Юристы";
        }

        const {searchText} = this.state;

        const breadcrumbs = [
            {
                name: 'Главная',
                path: '/',
            },
            {
                name: 'Адвокаты, юристы, нотариусы',
                path: '/is/',
            },
            {
                name: JuristTypeText,
                path: '',
            },
        ];

        return (
            <>
                <div className="page-docs page">
                    <div className="container">
                        <div className="row">
                            <BreadCrumbs crumbs={breadcrumbs}/>
                            <PageTitle pageTitle={JuristTypeText}/>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="short-search-wrapper">
                                    <form className="uk-search">
                                        <input placeholder="Поиск по регионам" autocomplete="off"
                                               className="short-search-input" value={searchText}
                                               onChange={this.handleSearchChange}/>
                                        <button type="button" className="search_btn">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </form>
                                </div>
                                <div className="list-group">

                                    {dataStateList && dataStateList.payload.array.nodes
                                        .filter(({element}) => element?.value?.toLowerCase()
                                            .trim()
                                            .includes(searchText.trim()))
                                        .map((answer, i) => {
                                            //let item_value = answer.element.value;
                                            let item_id = answer.element.id;
                                            return (
                                                <>
                                                    <a key={item_id} href={`/is/${juristtype}/${item_id}/`}
                                                       className="list-group-item list-group-item-action">
                                                        <div className="clearfix other-stat__item sectortransition-all">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <p className="m-0">{answer.element.value ? answer.element.value : ''}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </>
                                            );
                                        })}

                                </div>
                            </div>
                            <div className="col-md-9 col-sm-12 mapa-c">
                                <InteractiveMap regions={dataStateList} partition={`is/${juristtype}`}/>
                            </div>
                            <div className="col-md-12">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default States_List;