import React from 'react';

const GridCard = (props) => {
    let {
        href,
        bg,
        title,
        description,
        bs_cal
    } = props;

    if (bs_cal === undefined) bs_cal = "";


    return (
        <a className={bs_cal + "grid_container_item grow"} href={href}>
            <img src={bg} className="grid_container_item_image"/>
            <div className="grid_container_item_title" 
                dangerouslySetInnerHTML={{__html: title}}>
            </div>
			{description.length > 6 ?
            <div className="grid_container_item_description" 
                dangerouslySetInnerHTML={{__html: description}}>
            </div> : "" }
        </a>
    );
}

export default GridCard;