//import crypto from 'crypto';
/*
const SeparateArray = (array, bufferSize) => {
    let subarray = [];
    for (let i = 0, mcalb = Math.ceil(array.length/bufferSize); i < mcalb; i++){
        subarray[i] = array.slice((i*bufferSize), (i*bufferSize) + bufferSize);
    }
    return subarray;
}


const MargeArray = (ArrayInner1, ArrayInner2) => {
    //let ai1Count = ArrayInner1.length;
    let ai2Count = ArrayInner2.length;
    for( let i = 0; i < ai2Count; i++){
        ArrayInner1.push(ArrayInner2[i]);
    }
    return ArrayInner1
}

const ab2str = (buf) => {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
}

const decode_utf8 = (s) => {
    return decodeURIComponent(escape(s));
  }/**/

export const decodeMessage = (m) => {
    return m;
    //Depricated
    /*let message = m;
    if(message){
        let privK = localStorage.getItem('pvs_private_Key');
        let encMessage = Buffer.from(message, 'base64');
        var bufferSize = (2048 / 8);
        if(encMessage.length > bufferSize){
            var separatedArray = SeparateArray(encMessage, bufferSize);
            let EncriptedArray = [];
            for(let i = 0; i < separatedArray.length; i++){
                let EncriptedTMP = "";
                try {
                    EncriptedTMP = crypto.privateDecrypt({
                            key: privK,
                            padding: crypto.constants.RSA_PKCS1_PADDING,
                            passphrase: '',
                        }, separatedArray[i]
                    );
                } catch (error) {
                    console.log(error); 
                }
                EncriptedArray = MargeArray(EncriptedArray, EncriptedTMP);
            }
            message = (EncriptedArray.toString('utf8'));
            message = ab2str(message.split(","));
            message = decode_utf8(message);
        }
        else{
            try {
                message = crypto.privateDecrypt({
                        key: privK,
                        padding: crypto.constants.RSA_PKCS1_PADDING,
                        passphrase: '',
                    }, encMessage
                ).toString('utf8');
            } catch (error) {
                console.log(error); 
            }
        }
        return message;
    }/**/
}

const getMonthString = (month) => {
    let string = '';
    switch(month) {
        case 0:
            string = 'января';
            break;
        case 1:
            string = 'февраля';
            break;
        case 2:
            string = 'марта';
            break;
        case 3:
            string = 'апреля';
            break;
        case 4:
            string = 'мая';
            break;
        case 5:
            string = 'июня';
            break;
        case 6:
            string = 'июля';
            break;
        case 7:
            string = 'августа';
            break;
        case 8:
            string = 'сентября';
            break;
        case 9:
            string = 'октября';
            break;
        case 10:
            string = 'ноября';
            break;
        case 11:
            string = 'декабря';
            break;
        default:
            break;
    }
    return string;
}

export const getDateString = (date) => {
    const day = date.getDate();
    const month = getMonthString(date.getMonth());
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${hours}:${minutes} ${day} ${month}, ${year}`;
}