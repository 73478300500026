import React from 'react';
import {YMaps, Map, SearchControl, Placemark} from 'react-yandex-maps';

class Subject extends React.Component {
    constructor(props) {
        super(props);
        let idSubject = 0
        if (props.match.params.id) {
            idSubject= props.match.params.id;
        }
        this.state = {
            id:idSubject,
            name: '',
            coordX: '',
            coordY: '',
            contacts: [],
            address: ''
        };
    }

    onBoundsChange(e) {
        console.log(e);
    }

    getcode(ymaps) {
        console.log(ymaps.geolocation);
    }
    componentDidMount() {
        let id = this.state.id;
        let url = global.apiLink+"/subjects/subject/get?id="+id
        fetch(url)
            .then(res => res.json())
            .then((response)=>{
                if(response.payload){
                    console.log(response.payload);
                    this.setState({
                        name:response.payload.name,
                        coordX: response.payload.coordx,
                        coordY: response.payload.coordy,
                        contacts:response.payload.contacts,
                    })
                }
            });
    }

    render() {
        return (

            <div className="uk-container uk-container-center">
                <h3>{this.state.name}</h3>
                <div>

                    {this.state.contacts.map((contact, i) =>
                        <span key={i}>  {contact.data},</span>
                    )}
                    <p>{this.state.address}</p>

                    {(this.state.coordX && this.state.coordY) &&
                    <YMaps query={{
                        apikey: 'df639532-a255-4e82-b3e0-06ad7483393e',
                        load: 'package.full'
                    }}>
                        <Map onLoad={(ymaps) => {
                            //console.log(Object.getOwnPropertyNames(ymaps));
                            //console.log(Object.getOwnPropertyNames(ymaps.Map));
                            //console.log(ymaps.Map.name);
                            let geoCode = ymaps.geocode([this.state.coordX, this.state.coordY]);
                            geoCode.then(res => {
                                let address = res.geoObjects.get(0).properties.getAll().text;
                                this.setState({address: address})
                            });
                        }}
                             defaultState={{
                                 center: [this.state.coordX, this.state.coordY],
                                 zoom: 17,
                                 controls: [],
                             }}
                             width={'400px'}
                             height={'300px'}
                        >
                        </Map>
                    </YMaps>
                    }

                </div>
            </div>

        )
    }
}

export default Subject;