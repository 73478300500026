import React from 'react';
export const FormMsg = ({formMsg}) =>
    <div className="formMsg" style={{display:"block"}}>
        {Object.keys(formMsg).map((fieldName, i) => {
            if (formMsg[fieldName].length > 0) {
                return (
                    <p key={i} dangerouslySetInnerHTML={{__html:formMsg[fieldName]}}></p>
                )
            } else {
                return '';
            }
        })}
    </div>