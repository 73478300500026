import React from 'react';

const PanelCard = (props) => {
    let {
        href,
        bg,
        title,
        description,
        bs_cal,
        height
    } = props;

    if(bs_cal === undefined) bs_cal = "col-xl-3 col-sm-6 col-12 ";


    return (
        <a className={bs_cal + " panel_container_item "} href={href}>
            <div className="panel_container_item_wrapper grow shadow-sm" style={{height:height}}>
                <img src={bg} className="panel_container_item_image"/>
                <div className='panel_container_item_info'>
                    <div className="panel_container_item_title" 
                        dangerouslySetInnerHTML={{__html: title}}>
                    </div>
                    <div className="panel_container_item_description" 
                        dangerouslySetInnerHTML={{__html: description}}>
                    </div>
                </div>
            </div>
        </a>
    );
}

export default PanelCard;