import React from 'react';
import {Link} from "react-router-dom";
import "./assets/form.css";
import {FormErrors} from "../FormErrors";
import {FormMsg} from "../FormMsg";


class VerifyByEmail extends React.Component{
	
    constructor(props) {
        super(props);
    this.state = {
		formResponceErrors: {error: ''},
		formResponceMsg: {msg: ''},
      //  loading: false,
        MOBILE_URL: "https://m.my-advo.cat",
    }	
    }
	
	
    componentDidMount() {
        let url_parsed = new URL(window.location.href)
        let params = new URLSearchParams(url_parsed.search);
        let code = params.get('code');
        let url  = global.apiLink+'/api/user/verify-register?code='+code;
        fetch(url)
            .then(response => response.json())
            .then((response)=>{
                if(response.status === 200){
                   	let successStr = "Ваша почта успешно подтвеждена."
					    + " Спасибо за регистрацию в нашем сервисе."
                        + "<br />Для входа в личный кабинет "
						+ "<a href=\"/login\">авторизуйтесь</a>"
						+ ".";						
                        this.setState({ /*loading: false,*/ formResponceMsg:{msg: successStr}});
                } else {
			
						let errorStr = "Внимание! "
						+ "<br />Подтверждение аккаунта не выполнено. Повторите попытку.";						
                        this.setState({ /*loading: false,*/ formResponceErrors:{error: errorStr}});			
            return false;//this.badRequest(response.payload);					
				}
            })
    }

    render() {
		document.getElementById("root").style.background = "#fff";
        return (
            <>
            <div className="qa-page page pt-0">
                <div className="container">
                    <div className="row">
                        <div className="offset-md-2 col-md-8 --card --shadow-sm">
                            <div className="row card-body">
                                <div className="col-lg-12 col-12">
                                    <div className="row">
                                            <legend className="legend text-center"><h2>Подтверждение регистрации</h2></legend>
                                            <div className="panel panel-default" id="form_result">
                                                <FormErrors formErrors={this.state.formResponceErrors} />
												<FormMsg formMsg={this.state.formResponceMsg} />
                                            </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );

    }
}
export default VerifyByEmail;