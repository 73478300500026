import React  from "react";
import Peer from "peerjs";
import  'bootstrap';
import  './GetCall.css';
import I from '../../assets/icons/icons';
//import { useNavigate } from "react-router-dom";
const tokenAPI = localStorage.getItem("tokenAPI");

var LimitCallPerPage = 50;

var headers = new Headers();
headers.append('Authorization', 'Bearer ' + tokenAPI);
let InnerValRoomId = null;
let InnerValRoomId_my = null;
let callOptions={
    host:global.peerServer,
    port:global.peerPort,
    path:'/',
    config:{
        'iceServers': [
            //{urls:'stun:stun01.sipphone.com'},
            //{urls:'stun:stun.ekiga.net'},
            //{urls:'stun:stun.fwdnet.net'},
            //{urls:'stun:stun.ideasip.com'},
            //{urls:'stun:stun.iptel.org'},
            //{urls:'stun:stun.rixtelecom.se'},
            //{urls:'stun:stun.schlund.de'},
                    //{urls:'stun:turn1.woodycart.ru:3478'},
                    //{urls:'turn:turn1.woodycart.ru:3478'},
                    //{
                        //username: 'turnuser1',
                        //credential: 'tu2pVDfcds',
                        //urls:'stuns:turn1.woodycart.ru:5349'
                    //}//,
            {urls:'stun:stun1.l.google.com:19302'},
            {urls:'stun:stun2.l.google.com:19302'},
            //{urls:'stun:stun3.l.google.com:19302'},
            //{urls:'stun:stunserver.org'},
            //{urls:'stun:stun.softjoys.com'},
            //{urls:'stun:stun.voiparound.com'},
            //{urls:'stun:stun.voipbuster.com'},
            //{urls:'stun:stun.voipstunt.com'},
            //{urls:'stun:stun.voxgratia.org'},
            //{urls:'stun:stun.xten.com'}
        ]
    },
    debug: 0,

};

var peer = new Peer(callOptions);
var heartbeat_msg_q = '--heartbeat-question--',heartbeat_msg_a = '--heartbeat-answer--', heartbeat_interval = null, missed_heartbeats = 0;
var LengthTimeCounter = null;
class CallResource extends React.Component{

    constructor(props) {
        super(props);
      // let ws = new WebSocket('wss://api.my-advo.cat:2096?access_token='+tokenAPI);

        
        //var audioDial = new Audio('/assets/sounds/dial.mp3');
        //this.peer = new Peer(callOptions);
        let self = this;
        //const navigate = useNavigate();
        self.state ={
            call:null,
            stateCall:0,
            VideoAvalable: false,
            AudioAvalable: false,
            VideoUpload: false,
            VideoPlayerStatus: "play",
            CallList: [],
            //WebSocketVar: null,
            peerId: null,
            roomId: null,
            roomInfo: null,
            callLengthTime: null,
            PageNumber: 1,
            CallListCount: null,
            callVideoStreamRemote: false,
            callVideoStreamInner: false,
            modalWindowContent: null,
            PagenatorLoader: false,
            positionBarVideoDuration: null,
            positionBarVideoPercent:null,
            positionBarVideoTimeText:null,
            VideoLoader:null
        };

        this.myChunks = [];
        this.clientChunks = [];

        self.myMediaRecorder = {};
        self.clientMediaRecorder = {};

        peer.on('open', function (peerId){
            self.peerId = peerId;
            console.log("GetCallResource - line 55 - peerId");
            console.log(peerId);
            self.setState({
                peerId: peerId
            });
            //self.connect(peerId);
            self.RegisterPeerAbonement(peerId);
        });

        /*
        peer.on('connection',function (dataConnection){
            console.log("GetCallResource - line 63 - connection");
            console.log(dataConnection);
            let ClientPeerId = dataConnection.peer;
            console.log("dataConnection ClientPeerId");
            console.log(ClientPeerId);
            dataConnection.on('data', function(data) {
                console.log('Полученные данные:', data);
                console.log(data);
                console.log(typeof(data));
                let response = JSON.parse(data);
                    if(response.do !== undefined){
                        switch (response.do){
                            case 'createRoom': {
                                self.CreateCallRoom(ClientPeerId, (room_id) => {
                                    let sendData = JSON.stringify({do: "makeCall", room_id: room_id});
                                    dataConnection.send(sendData).close();
                                    console.log("CreateCallRoom");
                                    console.log(sendData);
                                    self.setState({roomId:room_id});
                                });
                                } break;
                            case 'closeRoom': {
                                    console.log("GetCallResource - line 150 - room canceled");
                                    let sendData = JSON.stringify({do: "roomClosed"});
                                    dataConnection.send(sendData);
                                    if(dataConnection !== undefined)dataConnection.close();
                                    self.disconnect();
                                } break;
                            case 'postPing': {
                                    console.log(response);
                                    let sendData = JSON.stringify({do: "postPong"});
                                    dataConnection.send(sendData);
                                } break;
                        }
                    }
            });
            console.log("dataConnection set on.data");
            dataConnection.on('error', function(err) { 
                console.log("GetCallResource - line 121 - peer dataConnection error");
                console.log(err);
             });
             console.log("dataConnection set on.error");
            dataConnection.on('close', function() { 
                console.log("GetCallResource - line 120 - peer dataConnection close");
                self.disconnect();
             });
             console.log("dataConnection set on.close");
             self.dataConnection = dataConnection;
             console.log("dataConnection set self.dataConnection");
             
        });/**/
        peer.on('call', function(call) {
            self.peercall = call;
            
            let ClientPeerId = call.peer;

            console.log("getCall");
            console.log(call);
            self.setState({call:true,stateCall:1});
        });
        
        peer.on('destroy',function (){
            //console.log('destroy');
            //peer = new Peer(callOptions);
            self.disconnect();
        });
        peer.on('close',function (){
            self.disconnect();
            //self.dataConnection.close();
            /*if(!self.state.WebSocketVar){
                alert("Соединенеи с сокет-сервером отсутствует");
                return;
            }
            if(self.state.WebSocketVar!==null)
            self.state.WebSocketVar.onmessage = function (e){
                let data = JSON.parse(e.data);
                switch (data.type){
                    case 'createRoom':
                        //console.log(data);
                        break;
                    case 'closeRoom':
                        self.mediaStream.getTracks().forEach(function(track) {
                            if (track.readyState == 'live' && 
                            (track.kind === 'audio' || track.kind === 'video')) {
                                track.stop();
                            }
                        });

                        self.setState({call:null,stateCall:0});
                        break;
                }
            }/**/
        });
        peer.on('disconnected',function (e){
            console.log("GetCallResource - line 100 - disconnect");
            console.log(e);
            peer.reconnect();
            
        });
        
        peer.on('error',function (err){ 
            console.log("GetCallResource - line 164 - peer error"); 
            console.log(err); 
        });
        
    }

    CreateCallRoom(ClientPeerId, functionCallback){
        let room_id = null;
        let peer_fetch_Url = 'https://api.my-advo.cat/api/call/call/create-room/?peer_id='+ClientPeerId;
        fetch(peer_fetch_Url, {
            'headers': headers, 
            'method': "GET",
        })
        .then(res => res.json())
        .then((response) => {
            if(response.payload.room.status === true){
                console.log("Комната создана. room ID: "+response.payload.room.id);
                room_id = response.payload.room.id;
                this.setState({roomInfo:response.payload});
                //InnerValRoomId = room_id;
                //InnerValRoomId_my = room_id;
                functionCallback(room_id);
            }else{
                console.log(response.payload.message);
                console.log("Ошибка создания комнаты");
            }/**/
        });
    }

    CloseCallRoom( RoomId ){
        let self = this;
        let peer_fetch_Url = 'https://api.my-advo.cat/api/call/call/close-room/?room_id='+RoomId
        fetch(peer_fetch_Url, {
            'headers': headers, 
            'method': "GET",
        })
        .then(res => res.json())
        .then((response) => {
            if(response.payload.room.status === 0){
                console.log("Комната Закрыта. room ID: "+response.payload.room.id);
                self.myMediaRecorder.stop(); 
            }else{
                console.log(response.payload.message);
                console.log("Ошибка закрытия комнаты");
            }/**/
            this.setState({roomId:null});
            this.setState({roomInfo:null});
        });
    }
    
    checkOnline = () => {
        console.log(peer.connections);
        if(this.state.call === null){
            let peer_fetch_Url = 'https://api.my-advo.cat/api/call/call/set-user-to-break-free/';
            fetch(peer_fetch_Url, {
                'headers': headers, 
                'method': "GET",    
            })
            .then(res => res.json())
            .then((response) => {
                console.log(response);
                if(response.payload.status == 2){
                    let YesICan = window.confirm(`Вы долго не отвечали на звонок.\n`+
                    `Ваш статус сменился на "Отошёл".\n`+
                    `Готовы далее принимать звонки?`);
                    if(YesICan == true){
                        window.location.reload();
                    }else{
                        window.location.href = "/";
                    }
                }
            });
        }
        setTimeout(()=>{this.checkOnline();}, 10000);
    }
    
    MakeUserOnline = () => {
        let IAmOnline = () => {
            let headers = new Headers();
            headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
            let peer_fetch_Url = 'https://api.my-advo.cat/api/call/call/set-user-online/';
            fetch(peer_fetch_Url, {
                'headers': headers, 
                'method': "GET",
            })
            .then(res => res.json())
            .then((response) => {
                if(response.status == 200){
                    console.log("В сети");
                }else{
                    console.log("Ошибка получения статуса В сети");
                }
            });
            setTimeout(()=>IAmOnline(), 13000);
        }
        IAmOnline();
    }

    RegisterPeerAbonement(peerId){
        let peer_fetch_Url = 'https://api.my-advo.cat/api/call/call/add-abonent/?peer_id='+peerId;
        fetch(peer_fetch_Url, {
            'headers': headers, 
            'method': "GET",
        })
        .then(res => res.json())
        .then((response) => {
            if(response.payload.success === true){
                console.log(response.payload.message);
                console.log("Получен абонентский номер");
                this.MakeUserOnline();
                this.checkOnline();
            }else{
                console.log(response.payload.message);
                console.log("Ошибка получения абонентского номера");
            }/**/
        });
    }

    connect(peerId){
        /*let self = this;
        let tmpWS = new WebSocket(global.signalServer+'?access_token='+tokenAPI+'&peer_id='+peerId);

        tmpWS.onopen = function (e){
            if (heartbeat_interval === null) {
                heartbeat_interval = setInterval(function() {
                    try {
                        missed_heartbeats++;
                        if (missed_heartbeats >= 3)
                            throw new Error("Too many missed heartbeats.");
                            tmpWS.send(heartbeat_msg_q);
                    } catch(e) {
                        clearInterval(heartbeat_interval);
                        heartbeat_interval = null;
                        console.log("GetCallResource - line 121 - Closing connection. Reason:");
                        console.warn(e.message);
                        tmpWS.close();
                    }
                }, 8000);
            }
        }

    
        tmpWS.onmessage = function (e){
            if (e.data === heartbeat_msg_a) {
                // reset the counter for missed heartbeats
                missed_heartbeats = 0;
                return;
            }
            let data = JSON.parse(e.data);
            switch (data.type){
                case 'ListCall':
                    console.log("GetCallResource - line 139 - ListCall");
                    console.log(data);
                    break;
                case 'close_room':
                    if(self.mediaStream)
                        self.mediaStream.getTracks().forEach(function(track) {
                            if (track.readyState == 'live' && 
                            (track.kind === 'audio' || track.kind === 'video')) {
                                track.stop();
                            }
                        });
                    console.log("GetCallResource - line 150 - room canceled");
                    console.log(data.id_room);
                    if(self.call === true)self.disconnect();
                    self.setState({
                        call:false,
                        stateCall:0,
                        roomId:data.id_room
                    });
                    break;
            }
        }

        tmpWS.onclose = function(e) {
            console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
            setTimeout(function() {
                console.log("GetCallResource - line 165 - try reconnect peerid");
                console.log(peerId);
                self.connect(peerId);
            }, 1000);
          };
        
        tmpWS.onerror = function(err) {
            console.log("GetCallResource - line 165 - Socket encountered error. Closing socket");
            console.log(err);
            tmpWS.close();
        };
        self.setState({
            WebSocketVar: tmpWS
        });/**/
    }

    disconnect(){
        this.setState({
            call:null,
            stateCall:0,
        });
        this.setState({roomInfo:null});
        this.setState({callLengthTime: null});
        LengthTimeCounter = null;
        this.myMediaRecorder.stop();
        this.clientMediaRecorder.stop(); 
        
        this.peercall.close();
        //this.peercall.close();
        //setTimeout(()=>{ this.clientMediaRecorder.stop(); }, 1000);
        //setTimeout(()=>{ this.ws.send(JSON.stringify({type:'closeRoom',info:{room_id:this.state.roomId}})); }, 3000);
    }

    sendEject(){
        console.log("GetCallResource - line 337 - sendEject");
        /*let sendData = JSON.stringify({do: "closeRoom"});
        console.log(sendData);
        this.dataConnection.send(sendData);/**/
        this.disconnect();
        
        //this.dataConnection.close();
        //this.peercall.close();
        //this.myMediaRecorder.stop();
        
        //setTimeout(()=>{ this.clientMediaRecorder.stop(); }, 1000);
        //setTimeout(()=>{ this.ws.send(JSON.stringify({type:'closeRoom',info:{room_id:this.state.roomId}})); }, 3000);
    }
    /*renderVideoAnswerByState(state) {
        if(state === true){
            return <>
                &nbsp;
                <div 
                    onClick={() => this.answer({video:true})} 
                    className="btn btn-success btn-sm">
                    <i className="fas fa-video"></i>
                    <span>&nbsp;Ответить с видео</span>
                </div>
            </>
        }else{
            return <>
            </>
        }
    }/**/

    setProgressVideoUpload(progress) {
        if(progress === 0)this.setState({VideoUpload:progress});
        if( this.state.VideoUpload === false )return;
        if( progress !== false && progress < this.state.VideoUpload )return;
        this.setState({VideoUpload:progress});
    }
        
    blobToFile = (theBlob, fileName) => {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    playRecords(inner, outer) {
        if(!inner)return "";
        //if(!outer)return "";
        let self = this;
        this.setState({
            VideoLoader: true
        });
        let VideoInner = global.apiLink + 'api/call/call/get-file?link=' + inner;
        let VideoOuter = global.apiLink + 'api/call/call/get-file?link=' + outer;

        let VideoInnerObj = document.createElement("video");
        VideoInnerObj.setAttribute("src", VideoInner);
        VideoInnerObj.classList.add("d-none");
        document.body.appendChild(VideoInnerObj);

        VideoInnerObj.addEventListener("play", function () {
            const WaitDuration = () => {
                VideoInnerObj.play();
                VideoInnerObj.currentTime = VideoInnerObj.currentTime+15;
                if(
                    typeof(VideoInnerObj.duration) != "number" ||
                    VideoInnerObj.duration == Infinity || 
                    isNaN(VideoInnerObj.duration)
                ) {
                    setTimeout(() => WaitDuration(), 100);
                } else {
                        console.log("currentTime");
                        console.log(VideoInnerObj.currentTime);
                        console.log("loadedmetadata");
                        console.log("typeof(VideoInnerObj.duration)");
                        console.log(typeof(VideoInnerObj.duration));
                        console.log(VideoInnerObj.duration);
                        let VideoInnerObjduration = VideoInnerObj.duration;
                        VideoInnerObj.remove();
                        self.setState({
                            positionBarVideoDuration: VideoInnerObjduration,
                            modalWindowContent: <>
                                    <video id="playerVideoBig" src={VideoOuter} className="playerVideoBig" autoPlay playsInline  style={{width: "100%"}}/>
                                    <video 
                                        id="playerVideoSmall"
                                        src={VideoInner}
                                        className="playerVideoSmall"
                                        autoPlay
                                        playsInline
                                        onTimeUpdate={ (event) => {
                                            console.log(event.target.currentTime);
                                            self.progressUpdate(event.target);
                                        } }
                                    />
                        </>});
                }
            }
            WaitDuration();
        });

        VideoInnerObj.addEventListener("canplay", function () {
            VideoInnerObj.play();
        });



        /*
        document.getElementById("CallListinner").innerHTML = "";
        document.getElementById("CallListouter").innerHTML = "";


        let VideoInnerObj = document.createElement("video");

        VideoOuterObj.setAttribute("width", "100%");
        VideoInnerObj.setAttribute("width", "100%");

        VideoOuterObj.setAttribute("autoplay", true);
        VideoInnerObj.setAttribute("autoplay", true);
        
        VideoOuterObj.id = "VideoOuterObj";
        VideoInnerObj.id = "VideoInnerObj";
        document.getElementById("CallListinner").appendChild(VideoInnerObj);
        document.getElementById("CallListouter").appendChild(VideoOuterObj);

        document.getElementById("VideoOuterObj").src = VideoOuter;
        document.getElementById("VideoInnerObj").addEventListener("timeupdate", ()=>{
            this.progressUpdate();
        });
        /**/
    }

    progressUpdate(video) {
        //let video = document.getElementById("playerVideoBig");
        // Устанавливаем позицию воспроизведения
        //let positionBar = document.getElementById("VideopositionBar");
        let videoWidth = Math.ceil(video.currentTime / this.state.positionBarVideoDuration * 100);
        //positionBar.style.width = videoWidth;
        // Заполняем текстовую надпись текущим значением
        let positionBar = Math.ceil( Math.ceil( video.currentTime * 100 ) / 100 );
        
        let SetStateBool = false;
        if(positionBar != this.state.positionBarVideoTimeText){
            SetStateBool = true;
        }else{
            if(video.currentTime == this.state.positionBarVideoDuration){
                SetStateBool = true;
            }
        }
        if(SetStateBool === true){
            
            this.setState({
                VideoLoader: null,
                positionBarVideoPercent:videoWidth,
                positionBarVideoTimeText:positionBar
            });
        }
        /*console.log(positionBar.style.width);
        if(positionBar.style.width > 99){
            setTimeout(()=>{
                if(positionBar.style.width > 99){
                    //document.getElementById("CallListinner").innerHTML = "";
                    //document.getElementById("CallListouter").innerHTML = "";
                }
            },1000);
        }/**/
      }
      
    renderVideoPlayPause(state) {
        if(state == "play"){
            return <>
                <div id="VideoCallPause" onClick={()=>{
                    this.setState({VideoPlayerStatus:"pause"});
                    document.getElementById("VideoInnerObj").pause();
                    document.getElementById("VideoOuterObj").pause();
                }}>
                    <i className="fa fa-pause"></i>
                </div>
            </>
        }else if(state === "pause"){
            return <>
                <div id="VideoCallPlay" onClick={()=>{
                    this.setState({VideoPlayerStatus:"play"});
                    document.getElementById("VideoInnerObj").play();
                    document.getElementById("VideoOuterObj").play();
                }}>
                    <i className="fa fa-play"></i>
                </div>
            </>
        }
    }
    
    renderPaginationCallList(ListCall, PageNumber) {
        console.log("ListCall", ListCall);
        let self = this;
        if(ListCall === false)
            return<>
            </>
        else{
            if(ListCall.roomsCount){
                let ListCount = ListCall.roomsCount;
                let PageCount = Math.ceil(ListCount / LimitCallPerPage);
                return <>
                    {[...Array(PageCount)].map((e, i) => {
                        let page_tmp = (i+1);
                        return <li 
                            key={i} 
                            className={"page-item " + (PageNumber == page_tmp && " active")}
                            aria-current={PageNumber == page_tmp && "page"}
                            >
                            <a 
                                className={"page-link " + (PageNumber == page_tmp && " active")} 
                                href="#"
                                onClick={(e)=>{
                                    e.preventDefault();
                                    self.setState({PagenatorLoader: true});
                                    self.setState({PageNumber: page_tmp});
                                    this.getRooms(page_tmp);
                                }}
                            >{page_tmp}</a>
                        </li>
                    })}
                </>
            }
        }
    }


    renderCallListByState(state) {

        if(state === false){
            return <>
            </>
        }else{
            return <>
                            {(tokenAPI && this.state.adminFree && !this.state.callProcess)&&
                                <>
                                    <div className="col-md-12 text-center">
                                        <div onClick={() => this.call()} className="callback-bt callback-bt-left">
                                            <i className="fa fa-phone "/>
                                        </div>
                                        <div onClick={() => this.call()} className="callback-bt callback-bt-right">
                                            <i className="fa fa-video-camera"/>
                                        </div>
                                    </div>
                                </>
                            }
                    
                            {this.state.callProcess &&
                                <>
                                    <div className="col-md-12 text-center">
                                        <div onClick={() => this.webcamSwitcher()} disabled={(this.state.VideoCall === true) && "disabled"} className="ml-20 callback-bt callback-bt-sm call-webcam-switcher">
                                            <i className="fa fa-video"></i>
                                        </div>
                                        <div onClick={() => this.microphoneSwitcher()} className="ml-20 callback-bt callback-bt-sm call-microphone-switcher">
                                            <i className="fa fa-microphone"></i>
                                        </div>
                                        <div onClick={() => this.disconnect()} className="ml-20 callback-bt hang-up">
                                            <i className="fa fa-phone"/>
                                        </div>
                                    </div>
                                </>
                            }

                            {!tokenAPI && <> 
                                <div className="card-body">
                                    <h4 className="card-title"><b>Звонок доступен только авторизованным пользователям.</b></h4>
                                </div></>
                            }

                            {/*<div className="video_container">
                                <div id="CallListinner"></div>
                                <div id="CallListouter"></div>
                                {this.renderVideoPlayPause(this.state.VideoPlayerStatus)}
                            </div>
                            <div className="video_control">
                                <div id="VideopositionBarWrapper" className="progress" style={{position: "relative"}}>
                                    <div id="VideopositionBar" className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">0 сек</div>
                                    <div id="VideopositionBarTransparentCover" style={style_VPBTC}></div>
                                </div>
                            </div>/**/}

                            {this.state.CallList.list && this.state.CallList.list.map((element) => {
                                let inner = element.media[0].id;
                                let outer = element.media[1].id;
                                
                                let link = "/assets/images/no-avatar-300x300.png";
                                if(element.companion_has_ava){
                                    link = "https://api.my-advo.cat/api/user/get-avatar-image?hash="+element.companion_avatar;
                                }
                                return <>
                                    <div key={element.id} className="card">
                                        <div className="card-body pt-1 pb-1 pl-1 pr-1">
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <span><img className="img-thumbnail" src={link} /></span>&nbsp; &nbsp;
                                                    <span>{element.companion_name ? element.companion_name : element.companion_id}</span>&nbsp; &nbsp;
                                                    <span>{element.companion_login ? element.companion_login : element.companion_email}</span>&nbsp; &nbsp;
                                                    <span>{element.companion_phones ? element.companion_phones : ""}</span>
                                                </div>
                                                <div className="col-md-4">
                                                    <div>{element.creat_at}</div>
                                                </div>
                                                <div className="col-md-1">
                                                    <div className="btn btn-danger btn-sm" 
                                                        onClick={() => { this.playRecords(inner, outer) }}>
                                                        <i className="fa fa-play-circle"></i>
                                                    </div>
                                                </div> 
                                            </div> 
                                        </div>
                                    </div>
                                </>
                            })}
            </>
        }
    }

    
    renderCallBodyInfo(roomInfo) {

        if(roomInfo === null){
            return <>
                                <div className="call_interface_leftside_companion_info">
                                    <div className="call_interface_leftside_companion_avatar">
                                        <div className="call_interface_leftside_companion_avatar_width_overflow">
                                            <img className="call_interface_leftside_companion_avatar_img" src="/assets/images/no-call-300x300.png" />
                                        </div>
                                    </div>
                                    <div className="call_interface_leftside_companion_name">
                                        &nbsp;
                                    </div>
                                    <div className="call_interface_leftside_companion_phone">
                                        {this.state.AudioAvalable?"Ожидаем входящий звонок":"Не найден микрофон! Приём звонков невозможен"}
                                    </div>
                                </div>
            </>
        }else{
            let link = "/assets/images/no-avatar-300x300.png";
            if(roomInfo.user_companion.avatar_image){
                link = "https://api.my-advo.cat/api/user/get-avatar-image?hash="+roomInfo.user_companion_avatar_hash;
            }

            return <>
                                <div className="call_interface_leftside_companion_info">
                                    
                                        <video 
                                            id="callVideoBig"
                                            autoPlay
                                            playsInline
                                        />
                                        {/**}
                                        style={{display:((this.state.stateCall == 2 && this.state.callVideoStreamRemote == true) ? "block" : "none")}}
                                        {/**/}
                                        <video 
                                            id="callVideoSmall"
                                            autoPlay
                                            playsInline
                                            muted
                                        />
                                        {/**}
                                        style={{display:((this.state.stateCall == 2 && this.state.callVideoStreamInner == true) ? "block" : "none")}}
                                        {/**/}
                                    
                                    <div className="call_interface_leftside_companion_avatar">
                                        <div className="call_interface_leftside_companion_avatar_width_overflow">
                                            <img className="call_interface_leftside_companion_avatar_img" src={link} />
                                        </div>
                                    </div>
                                    <div className="call_interface_leftside_companion_name">
                                        {roomInfo.user_companion.name}
                                    </div>
                                    <div className="call_interface_leftside_companion_phone">
                                        {roomInfo.user_companion.phones?roomInfo.user_companion.phones:roomInfo.user_companion.email}
                                    </div>
                                </div>
            </>
        }
    }

    renderAudioWarningByState(state) {

        if(state === false){
            return <>
                <div className="col-md-12">
                    <div className="alert alert-danger" role="alert">
                    <i className="fas fa-microphone-slash"></i>
                    &nbsp;Не найден микрофон! Приём звонков невозможнт
                    </div>
                </div>
            </>
        }else{
            return <>
                <div className="col-md-12">
                    {this.renderButtonByState(this.state.stateCall)}
                </div>
            </>
        }
    }
    renderUploadStatusesByState(state) {

        if(state === false){
            return <>
            </>
        }else{
            return <>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <div className="progress mt-1">
                                <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{width: state+"%"}} ariaValuenow={state} ariaValuemin="0" ariaValuemax="100">{state}%</div>
                            </div>
                        </div>
                    </div>
            </>
        }
    }
    renderCallButton(stateCall){

        switch (stateCall) {
            case 0:
                return <>
                    {this.renderUploadStatusesByState(this.state.VideoUpload)}
                </>
            case 1:
                return <> 
                    <div className="call_interface_leftside_companion_call_right_panel">
                        <audio src={global.apiLink + "/assets/rings/black_boomer.mp4"} autoPlay playsInline />
                        <div 
                            onClick={() => {this.sendEject()}}
                            className="call_interface_leftside_companion_call_btn call_interface_leftside_companion_call_btn_red call_interface_leftside_companion_call_eject"
                            title="Сбросить">
                            <i className="fa fa-phone" />
                        </div>&nbsp;
                        <div 
                            onClick={() => this.answer()}
                            className="call_interface_leftside_companion_call_btn call_interface_leftside_companion_call_btn_green "
                            title="Ответить">
                            <i className="fa fa-phone" />
                        </div> 
                        {this.state.VideoAvalable && <>
                            &nbsp;
                            <div 
                                onClick={() => this.answer({video:true})} 
                                className="call_interface_leftside_companion_call_btn call_interface_leftside_companion_call_btn_green call_interface_leftside_companion_call_hd"
                                title="Ответить с видео">
                                <i className="fa fa-video-camera" />&nbsp; &nbsp;Ответить с видео
                            </div>
                        </>}
                    </div>
                </>
            case 2:
                return  <>
                    <div 
                            onClick={() => {this.sendEject()}}
                            className="call_interface_leftside_companion_call_btn call_interface_leftside_companion_call_btn_red call_interface_leftside_companion_call_eject float-end"
                            title="Завершить">
                            <i className="fa fa-phone" />&nbsp; &nbsp;Завершить
                        </div>
                </>
        }
    }

    renderButtonByState(state) {

        let PicInPic = {
            width: "25%",
            position: "absolute",
            left: "25px",
            bottom: "30px",
        };

        switch (state) {
            case 0:
                return <>
                    <div id="call-stone" className="row">
                        <div className="col-md-12">
                            <div className="alert alert-primary" role="alert">
                                <i className="fa fa-phone" />
                                <span>&nbsp;Ожидаем входящий звонок</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="statuses" style={{width: "500px"}}>
                                {this.renderUploadStatusesByState(this.state.VideoUpload)}
                            </div>
                        </div>
                    </div>
                </>
            case 1:
                return <> 
                    {/*}<div id="call-stone" className="row">
                        <audio src={global.apiLink + "/assets/rings/black_boomer.mp4"} autoPlay playsInline />
                    <div className="col-md-12">
                    <br className="clearfix" />
                    <hr />
                    <br className="clearfix" />
                            <br className="clearfix" />
                            <div onClick={() => {this.sendEject()}} className="btn btn-danger btn-sm">
                                <i className="fa fa-phone"/>
                                <span>&nbsp;Сбросить</span>
                            </div>&nbsp;
                            <div onClick={() => {this.answer()}} className="btn btn-success btn-sm">
                                <i className="fa fa-phone"/>
                                <span>&nbsp;Ответить</span>
                            </div> 
                            {this.renderVideoAnswerByState(this.state.VideoAvalable)}
                        </div>
                    </div>{/**/}
                </>
            case 2:
                return  <>
                    <div id="call-stone" className="row">
                        <div className="col-md-12">
                                    <div className="container containerClientVideoWrapper" style={{width: "100%", marginTop: "20px", /*display: (this.state.callProcess == true?"block":"none"),/**/ marginBottom: "20px", position:"relative"}}>
                                        <div className="card card-body clientVideoWrapper" style={{width: "100%", position:"absolute", boxShadow: "0 0 15px -5px", background: "url(/assets/images/call_bg.jpg)", padding: "1px"}}>
                                            <div id="clientVideoWrapperInner" className="card card-body clientVideoWrapper" style={{width: "100%"}}>
                                                <div className="card-body">
                                                {/*}    <video id="callVideoBig" autoPlay playsInline  style={{width: "100%"}}/>
                                                    <video id="callVideoSmall" autoPlay playsInline muted style={PicInPic}/>
                                                {/**/}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        <div className="col-md-12">
                            <br className="clearfix" />
                            <div onClick={() => this.sendEject()} className="btn btn-danger btn-sm">
                                <i className="fa fa-phone"/>
                                <span>&nbsp;Завершить</span>
                            </div>
                            <br className="clearfix" />
                            <hr />
                            <br className="clearfix" />
                        </div>
                    </div>
                </>
        }
    }



    timeFormat = (ms) => {
        let num = (val) => {
                val = Math.floor(val);
                return val < 10 ? '0' + val : val;
            }
            , sec = ms / 1000
            , hours = sec / 3600  % 24
            , minutes = sec / 60 % 60
            , seconds = sec % 60;
    
        return num(hours) + ":" + num(minutes) + ":" + num(seconds);
    };


    answer = async(parametrs = {}) =>{
        let self = this;
        if(parametrs.video !== true){
            parametrs.video = false;
            self.setState({callVideoStreamRemote:false});
        }else{
            self.setState({callVideoStreamRemote:true});
        }


        navigator.getMedia = ( navigator.mediaDevices.getUserMedia ||
            navigator.getUserMedia || // use the proper vendor prefix
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia
        );

        let MediaPromiss = navigator.getMedia ({ audio: true, video: parametrs.video });


        MediaPromiss.then(mediaStream => {
            self.mediaStream = mediaStream;
        }).catch(function(err) { console.log(err.name + ": " + err.message); });


            
        self.CreateCallRoom(self.peercall.peer, (room_id) => {
                //let sendData = JSON.stringify({do: "makeCall", room_id: room_id});
                //dataConnection.send(sendData).close();
                //console.log("CreateCallRoom");
                //console.log(sendData);
                self.setState({roomId:room_id});
                InnerValRoomId = room_id;
                InnerValRoomId_my = room_id;
                let callVideoSmall = document.getElementById('callVideoSmall');
                let callVideoBig = document.getElementById('callVideoBig');

                
                self.peercall.on('close', function (){
                    console.log('close connection');
                    
                }); 

                self.peercall.on('error', function (err){
                    console.log('err');
                    console.log(err);
                }); 
                
                setTimeout(function() {
                    self.peercall.answer(self.mediaStream); // отвечаем на звонок и передаем свой медиапоток собеседнику
                },1000);

                self.peercall.on('stream', function (remoteStream){
                    console.log('stream on the video tag');

                    /*remoteStream.onactive = ()=>{
                        console.log('remoteStream inactive');
                        self.sendEject()
                    }/**/
                    
                    if(self.state.stateCall === 2)return false;
                
                    self.setState({stateCall:2});
                        
                    console.log('Got stream');

                    console.log("remoteStream");
                    console.log(remoteStream);

                    console.log("self.mediaStream");
                    console.log(self.mediaStream);

                    
                            callVideoSmall.srcObject = self.mediaStream;
                            callVideoSmall.onplay = ()=>{
                                console.log("callVideoStreamInner is activated");
                                console.log(self.mediaStream);
                                self.setState({callVideoStreamInner:true});
                            }
                                

                        setTimeout(function() {
                            callVideoBig.srcObject = remoteStream;
                            callVideoBig.onplay = ()=>{
                                console.log("callVideoStreamRemote is activated");
                                console.log(remoteStream);
                                self.setState({callVideoStreamRemote:true});
                            }
                            remoteStream.oninactive = ()=>{
                                console.log("callVideoStreamInner is activated");
                                console.log(self.mediaStream);
                                self.setState({callVideoStreamInner:false});
                                self.setState({callVideoStreamRemote:false});
                                
                                self.mediaStream.getTracks().forEach(function(track) {
                                    if (track.readyState == 'live' && 
                                    (track.kind === 'audio' || track.kind === 'video')) {
                                        track.stop();
                                    }
                                });
                                self.sendEject();
                            }

                            LengthTimeCounter = Date.now();
                            function CounterF(){
                                let letCallLengthTime = Date.now();
                                if(LengthTimeCounter !== null){
                                    self.setState({callLengthTime: (letCallLengthTime - LengthTimeCounter)});
                                    setTimeout(() => {
                                        CounterF();
                                    }, 1000);
                                }
                            }
                            CounterF();

                            self.myMediaRecorder = new MediaRecorder(self.mediaStream);
                            self.myMediaRecorder.start();
                            self.myMediaRecorder.addEventListener("dataavailable", function(event) {
                                self.myChunks = event.data;
                            });

                            self.clientMediaRecorder = new MediaRecorder(remoteStream);
                            self.clientMediaRecorder.start();
                            self.clientMediaRecorder.addEventListener("dataavailable", function(event) {
                                self.clientChunks = event.data;
                            });
                            //let InnerValRoomId = self.state.roomId;

                            self.myMediaRecorder.onstop = function (e){
                                self.setProgressVideoUpload(0);
                                console.log("msg1_0");
                                let timeInMs = Date.now();
                                const myBlob = new Blob([self.myChunks], {
                                    type: 'video/mp4'
                                });
                                console.log("myMediaRecorder stoped");
                                self.myChunks = [];
                                if(InnerValRoomId){
                                    let url = global.apiLink + 'api/call/call/load-media-to-room?room_id='+InnerValRoomId;
                                    const mediafile = new File([myBlob], "videofile_"+timeInMs+"_1.mp4", { type: "video/mp4" })

                                    let body = new FormData();
                                    body.append('file', mediafile);
                                    fetch(url, {
                                        'headers': headers, 
                                        'method': "POST",
                                        'body': body,
                                    }).then(function(msg){
                                        InnerValRoomId = null;
                                        console.log("msg1_1");
                                        console.log(msg);
                                        self.setProgressVideoUpload(20);
                                        console.log("Запись разговора записана и отправлена на сервер");
                                        setTimeout(()=>{ 
                                            //if(self.clientMediaRecorder.active) self.clientMediaRecorder.stop(); 
                                        }, 1000);
                                    });
                                }
                            };
                                self.clientMediaRecorder.onstop = function (e){
                                    setTimeout(()=>{
                                        self.setProgressVideoUpload(50);
                                        let timeInMs = Date.now();
                                        const clientBlob = new Blob([self.clientChunks], {
                                            type: 'video/mp4'
                                        });
                                        console.log("clientMediaRecorder stoped");
                                        self.clientChunks = [];
                                        if(InnerValRoomId_my){
                                            let url = global.apiLink + 'api/call/call/load-media-to-room?room_id='+self.state.roomId;
                                            const mediafile2 = new File([clientBlob], "videofile_"+timeInMs+"_2.mp4", { type: "video/mp4" })
                                
                                            let body2 = new FormData();
                                            body2.append('file', mediafile2);
                                            fetch(url, {
                                                'headers': headers, 
                                                'method': "POST",
                                                'body': body2,
                                            }).then(function(msg){
                                                console.log("msg2");
                                                console.log(msg);
                                                self.setProgressVideoUpload(100);
                                                console.log("Запись разговора записана и отправлена на сервер");
                                                //alert("Запись разговора записана и отправлена на сервер");
                                                setTimeout(()=>{
                                                    self.setProgressVideoUpload(false);
                                                }, 1000);
                                                //self.state.WebSocketVar.send(JSON.stringify({type:'closeRoom',info:{room_id:self.state.roomId}})); 
                                                self.CloseCallRoom(InnerValRoomId_my);
                                                InnerValRoomId_my = null;
                                                self.getRooms(self.state.PageNumber);
                                            });
                                        }
                                    }, 2000);
                                }

                        
                        },1000);/**/

                    });/**/
                
            }); 
    }
    
    /*getRooms = () => {
        let self = this;
        let url = global.apiLink + 'api/call/call/get-rooms';
        fetch(url, {
            'headers': headers, 
        }).then(response => response.json())
        .then(function(result){
            self.setState({CallList:result.payload});
            console.log(result);
        }).catch(e => {
            console.log(e);
        });
    }/**/

    
    getRooms = (page_number) => {
        let self = this;
        let data = {
            limit: LimitCallPerPage,
            page: page_number
        };
        let queryString = new URLSearchParams(data).toString();
        console.log(queryString);
        let url = global.apiLink + 'api/call/call/get-own-rooms?'+queryString;
        fetch(url, {
            headers: headers, 
            method: "GET",
        }).then(response => response.json())
        .then(function(result){
            
            self.setState({
                CallList: result.payload,
                PagenatorLoader: false
            });
            console.log(result);
        }).catch(e => {
            console.log(e);
        });
    }
    checkAudioVideo = () => {
        let self = this;
        
        /*navigator.getMedia = await ( navigator.getUserMedia || // use the proper vendor prefix
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia ||
            navigator.mediaDevices.getUserMedia
        );/**/

        navigator.mediaDevices.getUserMedia({video: { facingMode: "user" }})
                            .then((stream) => {
                                console.log("setVideoAvalable",true);
                                //if(WebRTCStreamRef.current) WebRTCStreamRef.current.srcObject = stream;
                                self.setState({VideoAvalable:true});
                            })
                            .catch((err) => {
                                console.log("setVideoAvalable",false);
                                self.setState({VideoAvalable:false});
                                console.warn(err);
                            });

        navigator.mediaDevices.getUserMedia({audio: true})
                        .then((stream) => {
                            console.log("setAudioAvalable",true);
                            self.setState({AudioAvalable:true});
                        })
                        .catch((err) => {
                            console.log("setAudioAvalable",false);
                            self.setState({AudioAvalable:false});
                            console.warn(err);
                        });

        /*navigator.getMedia({video: true}, function() {
            self.setState({VideoAvalable:true});
        }, function() {
            self.setState({VideoAvalable:false});
        });
        navigator.getMedia({audio: true}, function() {
            self.setState({AudioAvalable:true});
        }, function() {
            self.setState({AudioAvalable:false});
        });/**/
        return true;
    }

    checkToken = () => {
        fetch(global.apiLink + '/api/token/check-token', 
            { headers: headers })
            .then(response =>response.json())
            .then((response)=>{
                if(response.payload == false){
                    //navigate("/login", { state: {}, replace: true });
                    window.location.href = "/login";
                }
            })
    }


    async componentDidMount() {
        this.checkToken();
        this.checkAudioVideo();
        this.getRooms(this.state.PageNumber);
    }

    render() {

        let style_VPBTC = {
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 99,
        };

        const { modalWindowContent,
            PageNumber,
            callLengthTime,
            roomInfo,
            stateCall,
            VideoAvalable,
            AudioAvalable,
            CallList,
            PagenatorLoader,
            positionBarVideoPercent,
            positionBarVideoTimeText,
            VideoLoader
        } = this.state;

        return(
            <div className="page-call page">
                <div className="container ">
                    <div className="row">
                        <div className="col-md-12 quick-start-title-wrapper title-inner">
                            <div className="uk-h1 quick-start-header-title">Приём звонков</div>
                            <div className="all-hr all-hr-2 color-lightblue"></div>
                        </div>
                        <div id="ce_mainContainer" style={{maxWidth:"100%"}}></div>
                    </div>
                </div>
                <div className="container mb-3">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="call_interface_leftside_wrapper">
                                <div className="call_interface_leftside_header">{roomInfo ? <>Звонок от {roomInfo.room.creat_at}</> : <>&nbsp;</>}</div>
                                {this.renderCallBodyInfo(roomInfo)}
                                <div className="call_interface_leftside_call_info">
                                    {callLengthTime ? 
                                        <>Продолжительность разговора: {this.timeFormat(callLengthTime)}</> : 
                                        <>&nbsp;</>
                                    }
                                </div>
                                <div className="call_interface_leftside_footer">
                                    <div className="call_interface_leftside_call_btnspanel">
                                        <div className="call_interface_leftside_companion_call_btn call_interface_leftside_companion_call_light_audio" style={(VideoAvalable?{borderColor:"#00d31a",color:"#00d31a"}:{color:"#ссс"})}>
                                            <i className="fa fa-phone" />
                                        </div>
                                        <div className="call_interface_leftside_companion_call_btn call_interface_leftside_companion_call_light_video" style={(AudioAvalable?{borderColor:"#00d31a",color:"#00d31a"}:{color:"#ссс"})}>
                                            <i className="fa fa-video-camera" />
                                        </div>
                                        {this.renderCallButton(stateCall)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <ul className="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-videolist-tab" data-bs-toggle="pill" data-bs-target="#pills-videolist" type="button" role="tab" aria-controls="pills-videolist" aria-selected="true">Список звонков</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-notes-tab" data-bs-toggle="pill" data-bs-target="#pills-notes" type="button" role="tab" aria-controls="pills-notes" aria-selected="false">Черновик</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-videolist" role="tabpanel" aria-labelledby="pills-videolist-tab">
                                    <div className="CallListPager">
                                        {CallList && this.renderCallListByState(CallList)}
                                    </div>
                                    <nav className="mt-2" aria-label="Page navigation">
                                        <ul className="pagination">
                                            {PageNumber && this.renderPaginationCallList(CallList, PageNumber)}
                                            {PagenatorLoader && <li className="ml-3">
                                                <span className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Загрузка...</span>
                                                </span>
                                            </li>}
                                        </ul>
                                    </nav>
                                </div>
                                <div className="tab-pane fade" id="pills-notes" role="tabpanel" aria-labelledby="pills-notes-tab">
                                    <div className="mb-3">
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                            {/*this.renderAudioWarningByState(AudioAvalable)/**/}
                        </div>
                    </div>
                </div>
                {modalWindowContent && <>
                    <div className="modal fade show" id="exampleModalLg" tabindex="-1" aria-labelledby="exampleModalLgLabel" aria-modal="true" role="dialog" style={{display: "block"}}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="btn-close" onClick={()=>{this.setState({modalWindowContent:null})}} data-bs-dismiss="modal" aria-label="Закрыть"></button>
                                </div>
                                <div className="modal-body" style={{height: "540px"}}>
                                    {modalWindowContent}
                                    
                                    <div className="video_control">
                                        <div id="VideopositionBarWrapper" 
                                            className="progress"
                                            style={{position: "relative"}}
                                        >
                                            {positionBarVideoPercent && <>
                                                <div 
                                                    id="VideopositionBar"
                                                    className="progress-bar" 
                                                    role="progressbar" 
                                                    style={{ width: positionBarVideoPercent + "%" }}
                                                    aria-valuenow={positionBarVideoPercent}
                                                    aria-valuemin="0" 
                                                    aria-valuemax="100"
                                                >
                                                    {positionBarVideoTimeText} сек
                                                </div>
                                            </>}
                                            <div
                                                id="VideopositionBarTransparentCover"
                                                style={style_VPBTC}
                                                onClick={(e)=>{
                                                    let VPBW = document.getElementById('VideopositionBarWrapper');
                                                    //let positionBar = document.getElementById("VideopositionBar");
                                                    
                                                    let VPBWRect = VPBW.getBoundingClientRect()
                                                    let x = e.pageX - VPBWRect.x,
                                                    clickedValue = Math.ceil(x * 100 / VPBWRect.width);
                                                    //positionBar.style.width = clickedValue + "%";
                                                    console.log("clickedValue");
                                                    console.log(clickedValue);

                                                    let playerVideoBig = document.getElementById('playerVideoBig');
                                                    let playerVideoSmall = document.getElementById('playerVideoSmall');
                                                    //playerVideoBig.pause();
                                                    //playerVideoSmall.pause();

                                                    //let clickedValueSec = Math.ceil(clickedValue / 100 * this.state.positionBarVideoDuration);
                                                    let clickedValueSec = clickedValue / 100 * this.state.positionBarVideoDuration;
                                                    //positionBar.innerHTML = clickedValueSec + " сек ";
                                                    console.log("clickedValueSec");
                                                    console.log(clickedValueSec);
                                                    
                                                    playerVideoBig.currentTime = clickedValueSec;
                                                    playerVideoSmall.currentTime = clickedValueSec;
                                                    if (playerVideoBig.getAttribute('canplay') !== 'true') 
                                                        playerVideoBig.addEventListener("canplay", () =>{
                                                            //setTimeout(()=>{
                                                                console.log();
                                                                playerVideoBig.play();
                                                                playerVideoSmall.play();
                                                                /*this.setState({
                                                                    positionBarVideoPercent:clickedValue,
                                                                    positionBarVideoTimeText:clickedValueSec
                                                                });/**//**/
                                                            //}, 500);
                                                        }//, {once: true}
                                                        );
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" onClick={()=>{this.setState({modalWindowContent:null})}}></div>
                </>}
                {VideoLoader && <>
                    <div className="VideoLoader_centerPage">
                        <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                            <span className="visually-hidden">Загрузка...</span>
                        </div>
                    </div>
                </>}
            </div>
        )
    }

}

export default CallResource;