import React from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import Document from "./document";

class Section extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            documents: []
        };
    }

    render() {
        let childs = this.props.child;
        let section_id = this.props.section_id;

        if(childs){
            const documents = childs.map(documentEl=>
                <Document key={documentEl.id} id={documentEl.id} name={documentEl.name} file={documentEl.files}/>
            );
        }

var docs_money = {
        "position" : "relative",
        "display" : "block",
        "background" : "url(/assets/images/docs/"+section_id+".jpg) center center / cover no-repeat",
        "boxShadow" : "rgb(0 0 0 / 27%) 0px 0px 11px 0px",
        "marginBottom" : "15px",
        "height" : "250px",
        "borderRadius" : "5px"
};

var docs_title = {
        "color": "rgb(255, 255, 255)",
        "fontSize": "18px",
        "lineHeight": "21px",
        "textAlign": "right",
        "padding": "8px 5px",
        "background": "rgb(0, 186, 255)",
        'position': "absolute",
        "bottom": "50px",
        "right": "0px"
    }
;

        return(
            <>
                <div className="col-xl-4 col-md-6 col-12">
                    <Link to={"/docs/part"+section_id+"/"} className="docs_money grow" style={docs_money}>
                        <div className="docs_title" style={docs_title}>{this.props.name}</div>
                    </Link>
                </div>
            </>
        );
    }
}
export default Section;