import React from 'react'
import {Link} from "react-router-dom";
import GridPresetInnerCard from '../panel/GridPresetInnerCard';
import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";
import {PremiumAccess} from "../../layouts/personal-cab/PremiumAccess";

class Presetlist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            presets: null,
            isPremium: Boolean(localStorage.getItem('isPremium')?(JSON.parse(localStorage.getItem('isPremium'))):false),
            isAuth: Boolean((localStorage.getItem('tokenAPI'))?true:false)
        };
        this.PREloader = <div dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)}></div>
    }

    createMarkup = (s) => {
        return {__html: s};
    }
    
    componentDidMount() {
        let url = global.apiLink + 'api/preset/preset/list';
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === 200) {
                        this.setState({
                            presets: result.payload.list,
                        });
                    }
                }
            );
    }
    
    render() {
        //let {presets} = this.state;
        document.getElementById("root").style.background = "#fafafb";
        const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Адвокатская практика',
                path: '',				
            },
        ];		
        return (
            <div className="presets-page page">
                <div className="container">
                    <div className="row">
                        <BreadCrumbs crumbs={breadcrumbs} />					
						<PageTitle pageTitle="Адвокатская практика" />
                    </div>
                    <div className="row">
                        {this.state.presets 
						    ? <div className="col-md-12 mt-3">
                                <div className="grid_container row">
                                {
                                    this.state.presets.map(item => <GridPresetInnerCard key={item.id} {...item} />)
                                }
                                </div>
                            </div>
                            : this.PREloader}
                        <div className="col-md-12">&nbsp;</div>					
                    </div>
                    <div className="clearfix"></div>
                    <div><br /><br /><br /><br /></div>
                </div>
            </div>
        );
    }
}

export default Presetlist;