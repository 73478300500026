import React from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import BreadCrumbs from "../elements/BreadCrumbs";
import PageTitle from "../elements/PageTitle";
//import BackButton from '../buttonback'
import {TreePlenum} from '../../App'

import "../../global.js";

export class ResourceViewer extends React.Component {
    constructor() {
      super();
  
      this.state = {
        dataResourceList: '',
        resourceid: 0,
        PlenumContent: '',
        PlenumTitle: '',
        PlenumAlias: undefined
      }
    }

   utils = {
		dummy: document.createElement('div'),
		escapeHTML: function(s) {
			return {__html: s};
		}
	}
	

  createMarkup = (s) => {
    return {__html: s};
  }

  
selectedPlenum = async (alias) =>{
  let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        //let PlenumLink = global.apiLink + `api/plenum/plenum/get?alias=${alias}`;
		let PlenumLink = global.apiLink + `api/plenum/plenum/get?id=${alias}`;
        const api_ResourceLL = await
            fetch(PlenumLink, {
                headers: headers
            });
        var output = await api_ResourceLL.json();
  return output.payload;
    
};

  
	sortData = (key, data, type) => {
	  let ordered = {};
	  let compareFunction = function(a, b) {
		return data[b][key] - data[a][key];
	  };
	  if (type === "asc") {
		compareFunction = function(a, b) {
		  return data[a][key] - data[b][key];
		}
	  }
	  Object.keys(data).sort(compareFunction).forEach(function(key) {
		ordered[key] = data[key];
	  });
	  return ordered;
	}


componentWillMount(){
  let self = this;
  const alias = self.props.match.params.alias;
    self.setState({
      PlenumAlias: alias,
    });

}

componentDidMount(){
  let self = this;
  const alias = self.props.match.params.alias;
  if(alias !== undefined){
    self.selectedPlenum(alias).then(output => {
      self.setState({
        PlenumContent: output.content,
        PlenumTitle: output.title,
      });
    });
  }
    
}

  
    render(){
        const {PlenumContent, PlenumTitle, PlenumAlias} = this.state;
		document.getElementById("root").style.background = "#fafafb";
		const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Судебная практика',
                path: '',				
            },
        ];
        return (
          <div className="qa-page page">
            <div className="container ">
                <div className="row">
				    <BreadCrumbs crumbs={breadcrumbs} />
					<PageTitle pageTitle="Судебная практика" />
                </div>
                <div className="row">
                                <div className="col-md-12">
                                    <div className="all-search-wrapper">
                                        <form className="uk-search" method="GET" action="/search/" style={{display: "block"}}>
                                            <input type="hidden" name="target" value="plenum" />
                                            <label for="search"><i className="fa fa-search" aria-hidden="true"></i><span className="sr-only">Поиск</span></label>
                                            <input type="search" name="q" onChange={()=>{this.getComplete()}} placeholder="Введите поисковую фразу..." autocomplete="off" className="all-search-input" />
                                            <button type="submit" className="search_btn" >Искать</button>
                                            <div className="all_search_autocomplete"></div>
                                        </form>
                                    </div>
                                </div>
                </div>
                    {PlenumAlias !== undefined && <>
					<div className="row">
                          <div className="col-md-4">
                            <div id="ResourceListC" className="">
                              <TreePlenum tpl="2" />
                            </div>
                          </div>
                          <div className="col-md-8">
                            
                            <div id="ResourceElement" className="row">
                                <div className="col-md-12 mb-3"><h3>{PlenumTitle}</h3></div>
                                <div className="col-md-12 mb-3" dangerouslySetInnerHTML={{__html:PlenumContent}}></div>
                            </div>
                          </div>
						  </div>
                        </>
                    }

                    {PlenumAlias == undefined && <>
                            <TreePlenum tpl="1" />
                        </>
                    }
            </div>
          </div>
        );
    }
}

export default ResourceViewer;