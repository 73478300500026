import React from 'react';
import NavMenu from "./components/header-nav/NavMenu";


class HeaderMp extends React.Component {
    render(){
        return (
			<NavMenu/>
		)
	}
}

export default HeaderMp;
