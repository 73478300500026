import React, { useState } from "react";
import './styles.css';
import '../../../global';



const MessageItem = (props) => {

    const { 
        closedon,
        createdon,
        filename,
        id,
        id_room,
        id_user,
        id_user_sender,
        message,
        message_type, 
        msg_createdon,
        msg_id,
        name,
        status,
        title,
    } = props;

    const isUser = id_user === id_user_sender;

    const createdAt = new Date(Number(msg_createdon) * 1000); 

    const hours = createdAt.getHours() < 10 ? '0' + createdAt.getHours() : createdAt.getHours();
    const minutes = createdAt.getMinutes() < 10 ? '0' + createdAt.getMinutes() : createdAt.getMinutes();
    const time = `${hours}:${minutes}`;
    
    const [isFull, setFull] = useState(false);

  

    return (
        <div id={"message-item-"+msg_id} data-message-id={msg_id} data-status={0} className={`message-item ${isUser ? 'user' : ''}`}>
            {
                isUser ? <></> :
                <span className="message-item__sender" dangerouslySetInnerHTML={{__html:name}} />
            }
            <div className={`message-item__content ${isUser ? 'user' : ''}`}>
                {
                    message_type === 'text' ?
                    <span className="message-item__text mb-2" dangerouslySetInnerHTML={{__html:message}} />
                    : <></>
                }{
                    message_type === 'audio' ?
                    <div className="message-item__text mb-2" >Аудиосообщение</div>
                    : <></>
                }{
                    message_type === 'video' ?
                    <div className="message-item__text mb-2" >
                        <div style={{width:"320px",height:"240px",background:"#333"}}></div><br />
                        Видео
                    </div>
                    : <></>
                }
                {
                    message_type === 'file' ?
                    <>
                    <span className="message-item__text">Файл:</span><br />
                    <a href={global.apiLink + message} disabled className="message-item__link">
                        <span className="message-item__text">Скачать <i>{filename}</i></span>
                    </a>
                    </>
                    : <></>
                }
                {
                    message_type === 'image' ?
                    <div>
                        <div 
                        className={`message-item__image-wrap`}
                        onClick={() => setFull(true)}>
                            <img 
                            src={global.apiLink + message} 
                            alt="preview" 
                            loading="lazy"
                            className="message-item__image" />
                        </div>
                        {<div
                            onClick={() => setFull(false)} 
                            className={"message-item__image-full " + (isFull ? "" : "d-none")}>
                                <img 
                                src={global.apiLink + message} 
                                alt="preview" 
                                loading="lazy"
                                className="message-item__image" />
                        </div>}
                    </div>
                    : <></>
                }

                {
                    message_type === 'call' ?
                    <>
                        <div className="message-item__text mb-2" >
                            <div style={{width:"320px",height:"240px",background:"#333"}}></div><br />
                            Звонок
                        </div>
                    </>
                    : <></>
                }


                <small className="message-item__time">{time}</small>
            </div>
        </div>
    );
}

export default MessageItem;