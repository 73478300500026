import React, { useEffect, useRef } from "react";
import DateBadge from "../MessageList/DateBadge";
import MessageItem from "../MessageItem";
//import { decodeMessage } from '../MessageList/helpers';
import ReactDOMServer from "react-dom/server";

let tokenAPI = localStorage.getItem('tokenAPI');
let thisUserId = localStorage.getItem('id_user');
let headers = new Headers();
headers.append('Authorization', 'Bearer ' + tokenAPI);

let loadMessage = (MsgId) => {
    let ThisMessage = document.getElementById("message-item-"+MsgId);
    let Msg_Status = ThisMessage.getAttribute("data-status");
    if(Msg_Status == 0){
        ThisMessage.setAttribute("data-status", 1);
        setTimeout(function run() {
            if(ThisMessage.getAttribute("data-status") < 2)
            fetch( global.apiLink + 'api/chat/chat/get-msg?id='+MsgId, 
            {
                headers: headers,
            } )
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status !== 200) {
                        throw new Error(result.status)
                    }
                    if (result.payload) {
                        let msg_username = "", msg_family_name = "";
                            let msg_text = result.payload.msg.message;//decodeMessage(result.payload.msg.message);
                            if(thisUserId !== result.payload.msg.id_user_sender){
                                if(result.payload.msg.username)msg_username = result.payload.msg.username;//decodeMessage(result.payload.msg.username);
                                if(result.payload.msg.family_name)msg_family_name = result.payload.msg.family_name;//decodeMessage(result.payload.msg.family_name);
                            }

                            
                            let ThisMessage = document.getElementById("message-item-"+result.payload.msg.id);
                            if(ThisMessage.getAttribute("data-status") == 1){
                                ThisMessage.setAttribute("data-status", 2);
                                let MessageElementText = document.querySelectorAll(
                                    "#message-item-"+result.payload.msg.id+" .message-item__text"
                                )[0];
                                if(result.payload.msg.message_type == "image"){
                                    //let msg_text = ReactFancyBox({thumbnail:global.apiLink + msg_text,
                                    //    image:global.apiLink + msg_text});
                                    let MessageElementImages = document.querySelectorAll(
                                        "#message-item-"+result.payload.msg.id+" .message-item__image"
                                    );
                                    MessageElementImages[0].src = global.apiLink + msg_text;
                                    MessageElementImages[1].src = global.apiLink + msg_text;
                                }
                                
                                if(result.payload.msg.message_type == "file"){
                                    let filename = result.payload.msg.filename?result.payload.msg.filename:"Скачать";
                                    document.querySelectorAll(
                                        "#message-item-"+result.payload.msg.id+" .message-item__link"
                                    )[0].href = global.apiLink + msg_text;
                                    document.querySelectorAll(
                                        "#message-item-"+result.payload.msg.id+" .message-item__link .message-item__text"
                                    )[0].innerHTML = filename;
                                }
                                if(result.payload.msg.message_type == "text"){
                                    MessageElementText.innerHTML = msg_text;
                                }
                                
                                
                                if(result.payload.msg.message_type == "audio"){
                                    let filename = result.payload.msg.filename?result.payload.msg.filename:"Скачать";
                                    let message = ReactDOMServer.renderToString(<>
                                        <audio src={global.apiLink + msg_text} controls style={{ height: "30px", width: "100%", marginBottom: "15px"}} />
                                        <br />Файл: <a href={global.apiLink + msg_text} target="_blank">{filename}</a>
                                    </>);
                                    document.querySelectorAll(
                                        "#message-item-"+result.payload.msg.id+" .message-item__text"
                                    )[0].innerHTML = message;
                                }
                                
                                if(result.payload.msg.message_type == "video"){
                                    let filename = result.payload.msg.filename?result.payload.msg.filename:"Скачать";
                                    let message = ReactDOMServer.renderToString(<>
                                        <video src={global.apiLink + msg_text} controls style={{ width: "100%", height: "240px", marginBottom: "15px", background: "#000"}} />
                                        <br />Файл: <a href={global.apiLink + msg_text} target="_blank" style={{ maxWidth: "500px", display:"block"}}>{filename}</a>
                                    </>);
                                    document.querySelectorAll(
                                        "#message-item-"+result.payload.msg.id+" .message-item__text"
                                    )[0].innerHTML = message;
                                }
                                
                                if(result.payload.msg.message_type == "call"){
                                    let msgData = result.payload.msg;
                                    let filename = msgData.filename?msgData.filename:"Скачать";
                                    let callrecord_1 = msgData.callrecord_1;
                                    let callrecord_2 = msgData.callrecord_2;
                                    let callrecord_2_view = <> </>
                                    let callrecord_2_link = <> </>
                                    let msg_id = msgData.id;
                                    
                                    const PicInPic = {
                                        width: "30%",
                                        position: "absolute",
                                        left: "0",
                                        right: "0",
                                        bottom: "30px",
                                    };
                                    const modalClientVideo_style = {
                                        maxHeight: "300px", 
                                        height: "300px", 
                                        width: "100%", 
                                        marginBottom: "15px"
                                    };
                                    
                                    //if(msgData.message_type == "call"){
                                        filename = result.payload.msg.filename?result.payload.msg.filename:"Скачать";
                                        //let videoArray = refreshListRoom(global.apiLink + message);
                                        if(callrecord_2){
                                            callrecord_2_view = <>
                                                <video id={"modalMyVideo_"+msg_id} src={callrecord_2 && global.apiLink + callrecord_2} style={PicInPic} />
                                            </>
                                            callrecord_2_link = <>
                                                <br />Файл 2: <a href={global.apiLink + callrecord_2} target="_blank">Скачать</a>
                                            </>
                                        }
                                    //}
                                    let message = ReactDOMServer.renderToString(<>
                                        <button
                                            onClick={()=>{
                                                document.getElementById("modalViewer_"+msg_id).style.opacity=1;
                                                document.getElementById("modalViewer_"+msg_id).style.display="block";
                                                document.getElementById("modalViewerWindow_"+msg_id).style.opacity=1;
                                                document.getElementById("modalViewerWindow_"+msg_id).style.display="block";
                                                document.getElementById("modalViewerWindow_"+msg_id).style.marginTop="200px";
                                            }}
                                            type="button"
                                            class="btn btn-primary"
                                            data-bs-toggle="modal"
                                            data-bs-target={"#modalViewer_"+msg_id}>
                                            Просмотреть запись
                                        </button>
                                        <div 
                                            class="modal fade"
                                            id={"modalViewer_"+msg_id}
                                            tabindex="-1"
                                            aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-body">
                                                        <div class="clientVideoWrapper">
                                                            <video 
                                                                id={"modalClientVideo_"+msg_id}
                                                                src={global.apiLink + callrecord_1}
                                                                style={modalClientVideo_style}/>
                                                            {callrecord_2 && callrecord_2_view}
                                                            {/*
                                                                <div id="VideoCallPause" onClick={()=>{
                                                                    this.setState({VideoPlayerStatus:"pause"});
                                                                    document.getElementById("modalMyVideo_" + message_id).pause();
                                                                    document.getElementById("modalClientVideo_" + message_id).pause();
                                                                }}>
                                                                    <i className="fa fa-pause"></i>
                                                                </div>
                                                            {/**/}
                                                        </div>
                                                        <br />Файл 1: <a href={global.apiLink + callrecord_1} target="_blank">{filename}</a>
                                                        {callrecord_2 && callrecord_2_link}
                                                        {/**/}
                                                    </div>
                                                    {/*<p className="uk-text-right">
                                                        <button className="uk-button uk-button-default uk-modal-close" onClick={()=>{
                                                            document.getElementById("modalViewer_"+msg_id).style.opacity=0;
                                                            document.getElementById("modalViewer_"+msg_id).style.display="none";
                                                            this.setState({VideoPlayerStatus:"pause"});
                                                        }} type="button">Закрыть</button>
                                                    </p>/**/}
                                                </div>
                                            </div>
                                        </div>
                                    </>);
                                    document.querySelectorAll(
                                        "#message-item-"+result.payload.msg.id+" .message-item__text"
                                    )[0].innerHTML = message;
                                }



                                if(thisUserId !== result.payload.msg.id_user_sender){
                                    let MessageElementSender = document.querySelectorAll(
                                            "#message-item-"+result.payload.msg.id+" .message-item__sender"
                                        )[0];
                                    MessageElementSender.innerHTML = msg_username + " " + msg_family_name;
                                }
                            } 
                    }
                }
            ).catch(function (e) {
                if (e.message == "401") {
                    window.location.href = '/login';
                }
            });
            setTimeout(()=>{
                if(ThisMessage.getAttribute("data-status") == 1)run();
            }, 6000);
        }, 100);
    }
}

let handleScroll = () => {
    let mlc = document.getElementById("message-list-container");
    let scrollMessageItems = document.querySelectorAll("#message-list-container .message-item");
    let scrollMessageItemCount = scrollMessageItems.length;
    let scrollMessageLastItem = scrollMessageItems[(scrollMessageItemCount-1)];
    loadMessage(scrollMessageLastItem.getAttribute("data-message-id"));
    let mlc_top = mlc.getBoundingClientRect().top;
    let mlc_height = mlc.offsetHeight;
    let scrollMessageLastItemHeight = scrollMessageLastItem.offsetHeight;
    let MessagesTopArray = [];
    for(let i = 0; i < (scrollMessageItemCount-1); i++){
        let scrollMessageItemTMP = scrollMessageItems[i];
        let msg_id = scrollMessageItemTMP.getAttribute("data-message-id");
        let scrollMessageItemTop = scrollMessageItemTMP.getBoundingClientRect().top;
        if(scrollMessageItemTop < (mlc_top + mlc_height - scrollMessageLastItemHeight) && scrollMessageItemTop > (mlc_top - scrollMessageLastItemHeight))
            MessagesTopArray.push(msg_id);
    }
    MessagesTopArray.forEach(MsgId=>loadMessage(MsgId));
}

const MessageList = (Options) => {
    let DateList = [];

    const messageListRef = useRef(null);
    
    useEffect(() => {

            setTimeout(()=>{
                messageListRef.current.scroll(0, Number.MAX_SAFE_INTEGER);
                handleScroll();
                    if(messageListRef.current!==null){
                        messageListRef.current.scroll(0, Number.MAX_SAFE_INTEGER);
                        mlsScrollToBottom(messageListRef.current);
                    }
            },100);
    }, [Options]);


    const mlsScrollToBottom = (mlc) => {
            let mlcScrollHeight = mlc.scrollHeight;
            mlc.scrollTo(0, mlcScrollHeight);
    }



        let namePlaceholder = ReactDOMServer.renderToString(<><span className="textPlaceholder">____________ ______</span></>);
        return (<div id="message-list-container" ref={messageListRef} onScroll={handleScroll} //onLoad={setTimeout(()=>{handleScroll()}, 500)}
            className="message-list">
               {Options.MetaMsgs && Options.MetaMsgs.map(item => {
                   let createdon = item.msg_createdon;
                   let msg_id = item.msg_id;
                   let date_createdon = new Date((parseInt(createdon) + 18000) * 1000);
                   let Month   = (((date_createdon.getMonth()+1)<10) ?( "0" + (date_createdon.getMonth()+1) ): (date_createdon.getMonth()+1));
                   let Day     = (date_createdon.getUTCDate()<10) ?( "0" + date_createdon.getUTCDate()) : date_createdon.getUTCDate();
                   let Year    = date_createdon.getFullYear();
                   let key = Day + Month + Year;
                   let date = false;
                   if(DateList[key] === undefined) {
                       date = Day + '.' + Month + '.' + Year + "г";
                       DateList[key] = date;
                   }
                   //item.message_type = "text";
                   item.name = namePlaceholder;
                    let message = "";
                   if(item.message_type == "text"){
                        for(let i = 0; i < item.lengthofmessage; i++){message += "_";}
                        message = ReactDOMServer.renderToString(<><span className="textPlaceholder">{message}</span></>);
                    }else if(item.message_type == "image"){
                        message = "assets/images/favicon.ico";
                    }
                    item.message = message;
                   item.id_user = thisUserId;
                   return (
                       <>
                           {date !== false && <DateBadge key={msg_id} date={date} />}
                           <MessageItem key={msg_id} {...item} />
                       </>
                   );
               })}
           </div>);
}

export default MessageList;