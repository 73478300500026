import React from 'react';
import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";
import "../../global.js";
import validator from 'validator/validator.js'
import { InputMask } from '@react-input/mask';
import writeletter from "../../assets/images/writeletter.jpg";

export class FeedBack extends React.Component {
    constructor() {
      super();
      this.feedback_form = React.createRef();
      this.feedback_name = React.createRef();
      this.feedback_message = React.createRef();
      this.feedback_privpol = React.createRef();
      this.feedback_submit_bttn = React.createRef();

      this.feedback_email = React.createRef();
      this.feedback_phone = React.createRef();

      this.yook_captcha = React.createRef();
      this.yook_captcha_text = React.createRef();
      this.yook_captcha_hash = React.createRef();

      this.state = {
        feedback_submited: false,
        feedback_onsubmit: false,
        feedback_submit_able: true,
        feedback_message_err: "none",
        feedback_name_err: "none",
        feedback_phone_err: "none",
        feedback_email_err: "none",
        feedback_privpol_err: "none",
        feedback_captcha_err: "none",
      }
    }


    loadCaptchaImage = () => {
        let self = this;
        self.yook_captcha.current.src="/assets/images/icons/loader1.gif";
        let url = global.apiLink+"/api/captcha/captcha/ver1";
        fetch(url)
        .then( response =>response.json() )
        .then(resp=>{
            self.yook_captcha.current.src = "data:image/png;base64,"+resp.payload.imagedata;
            self.yook_captcha_hash.current.value = resp.payload.captchaHash
        });
    }

    stringFormat = (value, pattern) => {
        let i = 0;
        const v = value.toString();
        return pattern.replace(/#/g, _ => v[i++]);
    }

    getUserInfo = () => {
        if(localStorage.getItem('tokenAPI') == null)return false;
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let self = this;
        let url = global.apiLink + '/api/user/get-profile';
        fetch(url, { 
            method:'GET',
            headers:headers,
        })
            .then(response=>response.json())
            .then((json)=>{
                console.log(json);
                if (json.status == 200) {
                    if(json.payload.name !== undefined)
                    self.feedback_name.current.value = json.payload.name;

                    if(json.payload.email !== undefined)
                        self.feedback_email.current.value = json.payload.email;

                    if(json.payload.phones !== undefined){
                        let phones = "";
                        if(typeof (json.payload.phones) === 'string' && json.payload.phones.length > 0)
                            phones = this.stringFormat(json.payload.phones, '############');
                        self.feedback_phone.current.value = phones;
                    }
                }
            });
    }

    feedback_submit = (e) => {
        e.preventDefault();
        if(this.feedback_validation() === true)return false;
        this.setState({
            feedback_onsubmit: true
        });
        
        let captcha_err = "none",
            feedback_submited = false;

        let FedbackForm = this.feedback_form.current;
        const data = new FormData(FedbackForm)
        fetch(global.apiLink + `/api/myac/main/feedback-submit/`, { method: 'POST', body: data })
          .then(res => res.json())
          .then(json => {
            console.log(json);
            if(json.status == 400){
                console.log(json.payload.message);
                captcha_err = "block";
            }else if(json.status == 200){
                feedback_submited = true;
            }   
            this.setState({
                feedback_captcha_err: captcha_err,
                feedback_submited: feedback_submited,
                feedback_onsubmit: false
            });
          });
    }

    feedback_validation = () => {
        let text = this.feedback_message.current.value;
        let name = this.feedback_name.current.value;
        let email = this.feedback_email.current.value;
        let phone = this.feedback_phone.current.value;
        let privpol = this.feedback_privpol.current;
        let submit_able = false;
        let message_err = "block";
        let name_err = "block";
        let privpol_err = "block";
        let email_err = "block";
        let phone_err = "block";
        if(
            text.length <= 3 || 
            text.length == "" || 
            text === undefined
            )submit_able = true;
        else message_err = "none";

        if(
            name.length <= 3 || 
            name.length == "" || 
            name === undefined
            )submit_able = true;
        else name_err = "none";
        
        if(privpol.checked === false) submit_able = true;
        else privpol_err = "none";

        if (validator.isEmail(email) === false) submit_able = true; 
        else email_err = "none";

        if (validator.isMobilePhone(phone) === false) submit_able = true; 
        else phone_err = "none";

        this.setState({
            feedback_submit_able: submit_able,
            feedback_message_err: message_err,
            feedback_name_err: name_err,
            feedback_privpol_err: privpol_err,
            feedback_phone_err: phone_err,
            feedback_email_err: email_err,
        });
        return submit_able;
    }
    
    componentDidMount() {
        this.getUserInfo();
        this.loadCaptchaImage();
    }

    render(){
        let self = this;
        let { feedback_submited, feedback_onsubmit } = self.state;
        document.getElementById("root").style.background = "#fafafb";
        const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',
            },
            {
                name: 'Обратная связь',
                path: '/feedback',
            },
        ];
        
        const modify = (input) => {
          return { mask: input[0] === '0' ? '+_ (___) ___-__-__' : undefined };
        };
        
            return(
                <>
                <div className="page-feedback page">
                    <div className="container quick-start ">
                        <div className="row">
						    <BreadCrumbs crumbs={breadcrumbs} />							 
                            <PageTitle pageTitle="Обратная связь" />
						</div>
                        <div className='card'>
                            <div className='card-body'>
                                <div className="row"> 
                                    {feedback_submited == false ? 
                                    <>
                                    <div className='col-md-5' style={{position:"relative"}}>
                                        <h4>Просто напишите нам!</h4>
                                        <p>Если у вас есть какие-либо вопросы или предложения, просто напишите нам, и мы ответим вам в ближайшее время, мы всегда рады вам.</p>
                                        <div style={{overflow:"hidden"}} >
                                            <img src={writeletter} style={{width:"100%", marginBottom: "-20px"}}/>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <form 
                                            className="" 
                                            action="/" 
                                            method="POST" 
                                            onSubmit={(e)=>this.feedback_submit(e)}
                                            ref={this.feedback_form}
                                        >
                                            <div className="row mb-3"> 
                                                <div className="col-md-6">
                                                    <label htmlFor="feedback_name" className="form-label">Имя</label>
                                                    <input type="text" 
                                                    ref={this.feedback_name} 
                                                    onKeyUp={(e)=>{this.feedback_validation()}} 
                                                    className={"form-control"+(this.state.feedback_name_err == "block"?" is-invalid":"")} 
                                                    name="name"  
                                                    id="feedback_name" placeholder="Иван" required />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="feedback_fam" className="form-label">Фамилия</label>
                                                    <input type="text" className="form-control" name="fam"  id="feedback_fam" placeholder="Иванов" />
                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor="feedback_email" className="form-label">Электронная почта</label>
                                                    <input 
                                                        type="email"
                                                        inputMode="email"
                                                        className={"form-control"+(this.state.feedback_email_err == "block"?" is-invalid":"")} 
                                                        name="email"
                                                        id="feedback_email"
                                                        placeholder="my@mail.ru"
                                                        ref={this.feedback_email}
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor="feedback_phone" className="form-label">Номер телефона</label>
                                                    <InputMask 
                                                        modify={modify} 
                                                        replacement={{ _: /\d/ }}
                                                        mask="+___________"
                                                        type="tel"
                                                        inputMode="tel"
                                                        className={"form-control"+(this.state.feedback_phone_err == "block"?" is-invalid":"")} 
                                                        name="phone"
                                                        id="feedback_phone"
                                                        placeholder="+___________"
                                                        ref={this.feedback_phone}
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className="mb-3">
                                                <label htmlFor="feedback_message" className="form-label">Сообщение</label>
                                                <textarea 
                                                    id="feedback_message" 
                                                    name="message" 
                                                    className={"form-control"+(this.state.feedback_message_err == "block"?" is-invalid":"")} 
                                                    ref={this.feedback_message}
                                                    rows="6"
                                                    placeholder="Ваш отзыв или предложение" 
                                                    onKeyUp={(e)=>{this.feedback_validation()}} 
                                                    required
                                                ></textarea>
                                                <div className="invalid-feedback" style={{display:this.state.feedback_message_err}}>
                                                    Пожалуйста, введите сообщение в текстовое поле.
                                                </div>
                                            </div>

                                            <div className={"yook-customer-captcha text-center"}>
                                                <img 
                                                    className={"img-thumbnail mt-4" + (self.state.feedback_captcha_err == "block"?" is-invalid":"")} 
                                                    id="yook-captcha"
                                                    style={{height:"104px"}}
                                                    ref={ self.yook_captcha }
                                                    src={"/assets/images/icons/loader1.gif"} />
                                                <div onClick={()=>self.loadCaptchaImage()}
                                                    style={{cursor:"pointer"}}>
                                                    Обновить
                                                </div>
                                                <div className="row justify-content-center mt-3">
                                                    <div className="col-md-7">
                                                        <input 
                                                            type="hidden" 
                                                            className={"d-none"} 
                                                            id="yook-captcha-hash"
                                                            ref={ self.yook_captcha_hash }
                                                            name="captchaHash"/>
                                                        <input 
                                                            type="text" 
                                                            className={"form-control yook-captcha-text" + (self.state.feedback_captcha_err == "block"?" is-invalid":"")} 
                                                            id="yook-captcha-text"
                                                            ref={ self.yook_captcha_text }
                                                            name="captcha" 
                                                            placeholder="Капча" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="text-center mb-4 mt-3">
                                                <div className="form-check">
                                                    <input
                                                        id="feedback_privpol" 
                                                        name="privpol" 
                                                        ref={this.feedback_privpol} 
                                                        onChange={(e)=>{this.feedback_validation()}} 
                                                        type="checkbox" 
                                                        className={"form-check-input me-2"+(self.state.feedback_privpol_err == "block"?" is-invalid":"")}
                                                        style={{float:"none"}}
                                                        value="1"
                                                        required 
                                                    />
                                                    <label className="form-check-label" htmlFor="feedback_privpol">Принять <a href="/privpol/" target="_blank">политику конфиденциальности</a></label>
                                                    <div className="invalid-feedback" style={{display:this.state.feedback_privpol_err}}>Сообщение не будет отправлено, без принятия "Политики конфиденциальности"</div>
                                                </div>
                                            </div>
                                            <div className="mb-3 text-center">
                                                {feedback_onsubmit == true ? <>
                                                    <img 
                                                    className={"img-thumbnail mt-4"} 
                                                    style={{height:"50px"}}
                                                    src={"/assets/images/icons/loader1.gif"} />
                                                </>:
                                                <>
                                                <button 
                                                    type="submit"
                                                    ref={this.feedback_submit_bttn} 
                                                    className="btn btn-biryza form-submit-button submit-button jf-form-buttons"
                                                    disabled={(this.state.feedback_submit_able)}
                                                >Отправить
                                                </button>
                                                </>}
                                            
                                            </div>
                                        </form>
                                    
                                    </div>
                                    </> 
                                    : 
                                    <>
                                    <div className='col-md-12'>
                                        <h3 className='text-uppercase'>Сообщение отправлено</h3>
                                        <p>В ближайшее время мы ознакомимся с Вашим письмом и при необходимости отправим ответ на указаный Вами адрес электронной почты.<br />Спасибо, что пользуетесь нашим сервисом. </p>
                                        
                                        <a 
                                                href="/"
                                                className="btn btn-biryza form-submit-button submit-button jf-form-buttons"
                                            >На главную
                                        </a>
                                    </div>
                                    </>}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            );
    }
}

export default FeedBack;