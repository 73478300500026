import React from 'react';
export const FormErrors = ({formErrors}) =>
    <div className='formErrors' style={{display:"block"}}>
        {Object.keys(formErrors).map((fieldName, i) => {
            if(formErrors[fieldName].length > 0){
                return (
                    <p key={i} dangerouslySetInnerHTML={{__html:formErrors[fieldName]}}></p>
                )
            } else {
                return <></>;
            }
        })}
    </div>