import React from 'react';
import InteractiveMap from './mapa';
import BreadCrumbs from "../elements/BreadCrumbs";
import PageTitle from "../elements/PageTitle";

class Regions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regions: [],
            searchText: ""
        };
    }

    handleSearchChange = (e) => {
        this.setState({
            searchText: e.target.value
        });
    }

    componentDidMount() {
        let url = global.apiLink + "regions/region/list";

        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.payload) {
                        let key = "1";
                        console.log(result.payload.list);
                        this.setState({regions: result.payload.list});
						let regions = this.state.regions;
        Object.keys(regions).map((region_id) => {
			let regionContent = document.querySelector("[data-code='"+regions[region_id].code + "']").outerHTML;
			console.log(regions[region_id].code);
			document.querySelector("[data-code='"+regions[region_id].code + "']").outerHTML = '<a href="/regions/'+region_id+'/" title="' + regions[region_id].name + '">'
                + regionContent
                + '</a>';
        });							
						
						
						
                    }
                }
            )

    }

    render() {
        let regions = this.state.regions;
        document.getElementById("root").style.background = "#fff";

        Object.keys(regions).map((region_id) => {
            // console.log('debug render');
            // console.log(regions[region_id]);
        });
			   
           		

        const { searchText } = this.state;
		
		//document.getElementById("root").style.background = "#fafafb";
		const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Справочная информация',
                path: '',				
            },
        ];		

        return (
            <>
            <div className="page-docs page">
                <div className="container">
                    <div className="row">
					    <BreadCrumbs crumbs={breadcrumbs} />
					    <PageTitle pageTitle="Справочная информация" />
					</div>	
					<div className="row page-wisiwig">
<p>В данном разделе представлен структурированный список основных государственных юридических учреждений и организаций по субъектам РФ. 
На интерактивной карте метками отмечено местоположение данных организаций, их контактные данные и режим работы.</p>
                    </div>					
					<div className="row">
                        <div className="col-md-3 col-sm-12" style={{height: "60vh", overflow: "hidden", overflowY: "scroll"}}>
                            <div className="list-group">
                            {
                                Object.keys(regions)
                                .filter(region_id => regions[region_id]?.name?.toLowerCase()
                                    .trim()
                                    .includes(searchText.trim()))
                                .map((region_id, i) =>
								
                                <a key={i} href={'/regions/'+region_id+'/'} className="list-group-item list-group-item-action">
								    <div className="clearfix other-stat__item sectortransition-all">
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="m-0">{regions[region_id].name ? regions[region_id].name : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                )
                            }
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-12 mapa-c">
						
						    <div className="regions-search-wrapper">
                            <form className="uk-search">
	                            <input placeholder="Поиск по регионам" autocomplete="off" className="region-search-input"  value={searchText} onChange={this.handleSearchChange} />
		                        <button type="button" className="search_btn">
		                            <i className="fa fa-search" aria-hidden="true"></i>
		                        </button>
	                        </form>
                            </div>
                            <InteractiveMap regions={regions} />
                        </div>
						<div className="col-md-12">
						    &nbsp;
                        </div>
                    </div>
                </div>
            </div>
			</>
        )
    }
}

export default Regions;