import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card'
import {PayMethods} from "../PayMethods";

export class PayInfoAccordion extends React.Component {
    render(){
        return (<>
			<Accordion defaultActiveKey="0">
				<Card className="mb-1">
						<Accordion.Toggle as={Card.Header} variant="link" eventKey="0" style={{cursor:"pointer"}}>
							Условия оплаты
						</Accordion.Toggle>
					<Accordion.Collapse  eventKey="0">
						<Card.Body>
							Для получения Премиум аккаунта необходимо авторизоваться, перейти на страницу оплаты, выбрать тариф, указать адрес электронной почты и номер телефона, принять условия <a href="/privpol/" className="" title="Политика конфиденциальности">политики конфиденциальности</a> (поставить галочку) и нажать кнопку "Оплатить".<br />
							Оплачивая премиум аккаунт вы принимаете <a href="/assets/files/license.docx" className="" target="_blank" title="Лицензионное соглашение">лицензионное соглашение</a>.<br />
							Далее произойдёт переадресация на страницу оплаты сервиса <noindex>Yookassa.ru</noindex>. Там выберите и используйте подходящий способ оплаты.<br />
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				<Card className="mb-1">
						<Accordion.Toggle as={Card.Header} variant="link" eventKey="1" style={{cursor:"pointer"}}>
							Способы оплаты
						</Accordion.Toggle>
					<Accordion.Collapse eventKey="1">
						<Card.Body>
							Премиум аккаунт на любом тарифе можно оплачивать различными способами.<br /><b>Выбирайте наиболее удобный</b>:
							<br /><br /><PayMethods />                 
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				<Card className="mb-1">
						<Accordion.Toggle as={Card.Header} variant="link" eventKey="2" style={{cursor:"pointer"}}>
							Чем отличаются тарифы?
						</Accordion.Toggle>
					<Accordion.Collapse eventKey="2">
						<Card.Body>
							В зависимости от стоимости тарифа начисляется указанное в нём количество времени, которое будет действовать Премиум аккаун. 
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
			
		</>);
    }
}

export default PayMethods;