import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route, Link} from 'react-router-dom';

import ResourceViewerCodex from "./components/codex/resourceviewer";
import ResourceCodexMainpage from "./components/codex/codexmainpage";
import ResourceViewerSituation from "./components/situations/resourceviewer";
import ResourceViewerSituationPart from "./components/situations/resourceviewerpart";
import ResourceViewerSituationPartAlias from "./components/situations/resourceviewerpartalias";
import ResourceViewerQa from "./components/qa/resourceviewer";
import ResourceViewerPlenum from "./components/plenum/resourceviewer";
import InformationSystemSP from "./components/is/searchpage";
import MainPage from "./components/mainpage";
import Search from "./components/search";
import PrivPol from "./components/infocenter/privpol";
import AboutProject from "./components/infocenter/aboutprojent";
import ContactUs from "./components/infocenter/ContactUs";
import ResourceViewerDocs from './components/docs/resourceviewer'
import ResourceViewerDocsPart from './components/docs/DocPartDetail'
import ResourceViewerLk from './components/lk/resourceviewer'
import QuickStart_dtp from './components/quickstart/qs_dtp'
import ChatPage from './components/chat/chatmainpage'
import RoomPage from './components/chat/roompage'
import ResourceviewerSubject from "./components/subjects/resourceviewer";


import "./global.js";
import "./helper/ajaxStatus.js";
//import "bootstrap/dist/css/bootstrap.min.css";

import './App.css';
import Newslist from "./components/news/newslist";
import NewsElement from "./components/news/newselement";
import Subject from "./components/subjects/subject";
import Subjects from "./components/subjects/subjects";
import DocumentDetail from "./components/docs/DocumentDetail";
import LoginForm from "./components/login/loginForm";
import Profile from "./components/lk/Profile";
import Logout from "./components/login/logout";
import Registration from "./components/login/registration";
import VerifyByEmail from "./components/login/verifyByEmail";
import handleStatus from "./helper/ajaxStatus";
import HeaderMp from "./HeaderMp";
import HeaderLk from "./layouts/personal-cab/Header";
import FooterLk from "./layouts/personal-cab/Footer";
import CallResource from "./components/call/CallResource";
import Serviceslist from "./components/services/serviceslist";


import Coedx_folder_tpl from './components/codex/codex_folder_tpl';
import Plenum_folder_tpl from './components/plenum/plenum_folder_tpl';

export class TreePlenum extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            refreshtime: 1,
            data: {},
            displayChild: [],
            AllLoaded: []
        };
    }


    getThisDocId = () => {
        let pathname = window.location.pathname;
        pathname = decodeURIComponent(pathname).split('/');
        let countArray = pathname.length;
        const alias = pathname[(countArray-1)];
       // const resourceId = atob(alias);
	    const resourceId = alias;
        return resourceId;
    }

    decodeHTML = (html) => {
        var txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }

    createMarkup = (s) => {
        return {__html: s};
    }

    

    getComplete = async () => {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let query = document.querySelector(".all-search-input").value;
        let url = global.apiLink + '/api/search/complete?q=' + query;

        const api_ResourceLL = await fetch(url, { headers: headers });
        var output = await api_ResourceLL.json();

        if (output.status == 200) {
            let list = output.payload.list;
            let resultComplete = "";
            for( let i=0; i < list.length; i++ ){
                resultComplete += "<div onclick='document.querySelector("+'".all-search-input"'+").value = "+'"'+list[i].tags+'"'+";document.querySelector("+'".all-search-input"'+").focus();'>"+list[i].tags+"</div>";
            }
            document.querySelector(".all_search_autocomplete").innerHTML = resultComplete;
        }else{
            alert("Не верные данные авторизации getComplete");
            return false;
        }
    }

    getCodexTree = async (docid = 0) => {
        let thisDocId = this.getThisDocId();

        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let linkGet = global.apiLink + `api/plenum/plenum/list?recursive=1&pid=`+docid+`&lvl=1`;
        const api_url = await fetch(linkGet,{
            headers:headers
        });
        const json = await api_url.json();
        handleStatus(json.status);
        if(json.status !== 200){
            //alert("Не верные данные авторизации getCodexTree("+docid+")");
            return false;
        }

        this.state.data = await json.payload.array;
        this.state.AllLoaded[docid] = await json.payload.array;
        //this.setState("AllLoaded", thisDocs);

        var targetClassName = 'codex-tree-container';
        if(docid !== 0){
            targetClassName = 'submenu_Id' + docid;
        }
        let tpl = this.props.tpl;
        const result = await this.state.data.map(el => {

            //let linkHref = el.element.alias + "_" + el.element.id;
            let linkHref = el.id;//btoa(el.id);
            let isActive = "";
            console.log(thisDocId + " : " + el.id);
            console.log(tpl);
            if(thisDocId == el.id){isActive = "active";}
            if(tpl == 1){
                return (
                    <Plenum_folder_tpl url={"/plenum/"+linkHref} name={el.title} description={el.description} />
                )
            }else if(tpl == 2){
                return (
                    <div 
                       key={el.id}
                        className={"card pb-2 pt-2 pl-2 pr-2 mb-2 shadow-sm "+isActive}
                        data-docid={el.id}
                    >
                        {/*false && (el.type_node != "article" && this.state.displayChild.indexOf(el.id) === -1) && React.createElement('i',{className: 'far fa-folder isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)/**/}
                        {/*false && (this.state.displayChild.indexOf(el.id) !== -1) && React.createElement('i',{className: 'far fa-folder-open isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)/**/}
                        {/*el.type_node == "codex" && React.createElement('i',{className: 'fs-fa-empty mr-2'},)/**/}
                        {/*el.type_node == "plenum" && React.createElement('i',{className: 'far fa-sticky-note mr-2'},)/**/}
                        {/*false && el.type_node != "article" && React.createElement('i',{className: 'fas fa-book mr-2'},)/**/}
                        <a className="" href={"/plenum/"+linkHref} data-toggle="tooltip" data-placement="top" title={el.title}> <span dangerouslySetInnerHTML={this.createMarkup(el.title)} /></a>
                    </div>
                )
            }			
			
            // if(tpl == 1){
            //     return (
            //         <Plenum_folder_tpl url={"/plenum/"+linkHref} name={el.title} description={el.description} />
            //     )
            // }else if(tpl == 2){
            //     return (
            //         <div 
            //            key={el.id}
            //             className={"card pb-2 pt-2 pl-2 pr-2 mb-2 shadow-sm "+isActive}
            //             data-docid={el.id}
            //         >
            //             {/*false && (el.type_node != "article" && this.state.displayChild.indexOf(el.id) === -1) && React.createElement('i',{className: 'far fa-folder isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)/**/}
            //             {/*false && (this.state.displayChild.indexOf(el.id) !== -1) && React.createElement('i',{className: 'far fa-folder-open isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)/**/}
            //             {/*el.type_node == "codex" && React.createElement('i',{className: 'fs-fa-empty mr-2'},)/**/}
            //             {/*el.type_node == "plenum" && React.createElement('i',{className: 'far fa-sticky-note mr-2'},)/**/}
            //             {/*false && el.type_node != "article" && React.createElement('i',{className: 'fas fa-book mr-2'},)/**/}
            //             <a className="" href={"/plenum/"+linkHref} data-toggle="tooltip" data-placement="top" title={el.title}> <span dangerouslySetInnerHTML={this.createMarkup(el.title)} /></a>
            //         </div>
            //     )
            // }/**/

        });

        ReactDOM.render(
            <>{result}</>,
            document.getElementById(targetClassName)
        );
        //this.getBreadCrumbCodex();
    }


    getBreadCrumbCodex = async (e) => {
        const tokenAPI = localStorage.getItem("tokenAPI");
        let getThisDocId = this.getThisDocId();
        const self = this;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let linkGet_BCC = global.apiLink+`api/codex/codex/breadcrumb-codex?id=`+getThisDocId;

        const api_url_BreadCrumbCodex = await
            fetch(linkGet_BCC,{
                headers:headers
            });
        const jsonBCC = await api_url_BreadCrumbCodex.json();
        handleStatus(jsonBCC.status)
        if(jsonBCC.status !== 200){
          //  alert("Не верные данные авторизации");
            //localStorage.removeItem('tokenAPI');
            return false;
        }
        let BreadCrumbCodex = await jsonBCC.payload;

        BreadCrumbCodex = BreadCrumbCodex.reverse();
        BreadCrumbCodex.forEach(function(item, index, array) {
            if(index !== 0){
                var element = document.querySelector('li[data-docid="'+BreadCrumbCodex[index]+'"] .fa-folder-open');
                if(element === null){
                    self.expandParent(BreadCrumbCodex[index]);
                }
            }
        });
        BreadCrumbCodex.forEach(function(item, index, array) {
            if(index !== 0){
                var element = document.querySelector('li[data-docid="'+BreadCrumbCodex[index]+'"]');
                if(element !== null){
                    element.classList.add('active');
                }
            }
        });
        var element = document.querySelector('li[data-docid="'+getThisDocId+'"]');
        if(element !== null){
            element.classList.add('active');
        }
        return BreadCrumbCodex;
    }

    isfolderbtn = (element) => {
        this.expandParent(element.element.id);
        if(this.state.displayChild.indexOf(element.element.id) === -1){
            ReactDOM.render(
                <>
                </>,
                document.getElementById('submenu_Id' + element.element.id)
            );
        }else{
            this.getCodexTree(element.element.id);
        }

    }

    render(){

        this.getCodexTree();
        //this.getPlenumTree();
        let cl = '';
		if (this.props.tpl <2) {
			cl = 'grid_container grid_container-plenum';
		}
        return (
                    <div id="codex-tree-container" className={"row " + cl}>
                        <span dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)} />
                    </div>
        );
    }

    _renderChildren =  async (docid)  => {
        const tokenAPI = localStorage.getItem("tokenAPI");
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let linkGet = global.apiLink+`api/codex/codex/list?recursive=1&pid=`+docid+`&exclude=s`;
        const api_url = await fetch(linkGet,{
            headers:headers
        });
        const json = await api_url.json();
        handleStatus(json.status);
        if(json.status !== 200){
            //alert("Не верные данные авторизации getCodexTree("+docid+")");
            //localStorage.removeItem('tokenAPI');
            return false;
        }
        let subMenu = await json.payload.list;



        const result = subMenu.nodes.map(el => {
            //let linkHref = el.element.alias+"_"+el.element.id;
            let linkHref = el.element.base64_uid;
            return ( <li
                    key={el.element.id}
                    className={el.element.id === this.state.displayChild && "active"}
                    data-docid={el.element.id}
                >
                    {el.nodes && (this.state.displayChild.indexOf(el.element.id) === -1) && React.createElement('i',{className: 'far fa-folder isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)}
                    {el.nodes && (this.state.displayChild.indexOf(el.element.id) !== -1) && React.createElement('i',{className: 'far fa-folder-open isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)}
                    {!el.nodes && React.createElement('i',{className: 'fs-fa-empty mr-2'},)}
                    {!el.nodes && React.createElement('i',{className: 'far fa-sticky-note mr-2'},)}
                    {el.nodes && React.createElement('i',{className: 'fas fa-book mr-2'},)}
                    <a className="resource_title" href={"/codex/"+linkHref} data-toggle="tooltip" data-placement="top" title={el.element.title}> <span dangerouslySetInnerHTML={this.createMarkup(el.element.title)} /></a>

                    {(this.state.displayChild.indexOf(el.element.id) !== -1) &&
                    el.nodes &&
                    this._renderChildren(el.nodes)}
                </li>
            )
        });

        return <ul className="uk-list uk-list-striped">{result}</ul>;
    };

    expandParent = id => {
        let displayChild = this.state.displayChild;
        if(displayChild.length === 0){
            displayChild[0]=id;
        }else if(displayChild.length > 0){
            let indx = displayChild.indexOf(id)
            if(indx === -1 ){
                displayChild[displayChild.length]=id;
            }else{
                displayChild.splice(indx, 1);
            }
        }else{
            displayChild = [];
        }/**/
        this.setState({ displayChild: displayChild });
    };

}


export class TreeCodex extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            refreshtime: 1,
            data: {},
            displayChild: [],
            AllLoaded: []
        };
    }


    getThisDocId = () => {
        let pathname = window.location.pathname;
        const alias = decodeURIComponent(pathname).split('/')[2];
        const resourceId = atob(alias);
        return resourceId;
    }

    decodeHTML = (html) => {
        var txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }


    createMarkup = (s) => {
        return {__html: s};
    }


    getCodexTree = async (docid = 0) => {

        const tokenAPI = localStorage.getItem("tokenAPI");
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let linkGet = global.apiLink + `api/codex/codex/list?recursive=1&pid=`+docid+`&lvl=1`;

        const api_url = await fetch(linkGet,{
            headers:headers
        });
        const json = await api_url.json();
        handleStatus(json.status);
        if(json.status !== 200){
            //alert("Не верные данные авторизации getCodexTree("+docid+")");
            //localStorage.removeItem('tokenAPI');
            return false;
        }

        this.state.data = await json.payload.list;
        this.state.AllLoaded[docid] = await json.payload.list;
        //this.setState("AllLoaded", thisDocs);

        var targetClassName = 'codex-tree-container';
        if(docid !== 0){
            targetClassName = 'submenu_Id' + docid;
        }

        const result = await this.state.data.nodes.map(el => {
            //let linkHref = el.element.alias + "_" + el.element.id;
            let linkHref = el.element.base64_uid;
            let isActive = "";
            if(this.state.displayChild.indexOf(el.element.id) !== -1){isActive = "active";}
            return (
                <li
                    key={el.element.id}
                    className={isActive}
                    data-docid={el.element.id}
                >
                    {(el.element.type_node != "article" && this.state.displayChild.indexOf(el.element.id) === -1) && React.createElement('i',{className: 'far fa-folder isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)}
                    {(this.state.displayChild.indexOf(el.element.id) !== -1) && React.createElement('i',{className: 'far fa-folder-open isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)}
                    {/*el.element.type_node == "codex" && React.createElement('i',{className: 'fs-fa-empty mr-2'},)/**/}
                    {el.element.type_node == "article" && React.createElement('i',{className: 'far fa-sticky-note mr-2'},)}
                    {el.element.type_node != "article" && React.createElement('i',{className: 'fas fa-book mr-2'},)}
                    <a className="resource_title" href={"/codex/"+linkHref} data-toggle="tooltip" data-placement="top" title={el.element.title}> <span dangerouslySetInnerHTML={this.createMarkup(el.element.title)} /></a> ({el.element.id})
                    <div id={"submenu_Id"+el.element.id}></div>

                </li>
            )
        });

        ReactDOM.render(
            <ul>
                {result}
            </ul>,
            document.getElementById(targetClassName)
        );
        this.getBreadCrumbCodex();
    }


    getBreadCrumbCodex = async (e) => {
        const tokenAPI = localStorage.getItem("tokenAPI");
        let getThisDocId = this.getThisDocId();
        const self = this;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let linkGet_BCC = global.apiLink+`api/codex/codex/breadcrumb-codex?id=`+getThisDocId;

        const api_url_BreadCrumbCodex = await
            fetch(linkGet_BCC,{headers:headers});
        const jsonBCC = await api_url_BreadCrumbCodex.json();
        handleStatus(jsonBCC.status);
        if(jsonBCC.status !== 200){
           // alert("Не верные данные авторизации");
            //localStorage.removeItem('tokenAPI');
            return false;
        }
        let BreadCrumbCodex = await jsonBCC.payload;

        BreadCrumbCodex = BreadCrumbCodex.reverse();
        BreadCrumbCodex.forEach(function(item, index, array) {
            if(index !== 0){
                var element = document.querySelector('li[data-docid="'+BreadCrumbCodex[index]+'"] .fa-folder-open');
                if(element === null){
                    self.expandParent(BreadCrumbCodex[index]);
                }
            }
        });
        BreadCrumbCodex.forEach(function(item, index, array) {
            if(index !== 0){
                var element = document.querySelector('li[data-docid="'+BreadCrumbCodex[index]+'"]');
                if(element !== null){
                    element.classList.add('active');
                }
            }
        });
        var element = document.querySelector('li[data-docid="'+getThisDocId+'"]');
        if(element !== null){
            element.classList.add('active');
        }
        return BreadCrumbCodex;
    }

    isfolderbtn = (element) => {
        this.expandParent(element.element.id);
        if(this.state.displayChild.indexOf(element.element.id) === -1){
            ReactDOM.render(
                <>

                </>,
                document.getElementById('submenu_Id' + element.element.id)
            );
        }else{
            this.getCodexTree(element.element.id);
        }

    }

    render(){

        this.getCodexTree();
        //this.getPlenumTree();

        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="d-none"><button name="refreshtree" onClick={() =>{this.setState({refreshtime: this.state.refreshtime + 1})}}></button></div>
                    <ul className="mb-1 pb-0">
                        <li>
                            {React.createElement('i',{className: 'fas fa-globe'},)}
                            <span className="resource_title " data-toggle="tooltip" data-placement="top" title="Рут"></span> <a className="resource_title" href={"/codex/"} data-toggle="tooltip" data-placement="top" title={"Кодексы"}>Кодексы</a>
                        </li>
                    </ul>
                    <div id="codex-tree-container" className="col-md-12 ml-3"><img src="/assets/images/826.gif" width="40px" alt="Загрузка..."/></div>
                </div>
            </div>
        );
    }

    _renderChildren =  async (docid)  => {
        const tokenAPI = localStorage.getItem("tokenAPI");
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let linkGet = global.apiLink+`api/codex/codex/list?recursive=1&pid=`+docid+`&exclude=s`;
        const api_url = await fetch(linkGet,{headers:headers});
        const json = await api_url.json();
        handleStatus(json.status);
        if(json.status !== 200){
           // alert("Не верные данные авторизации getCodexTree("+docid+")");
            //localStorage.removeItem('tokenAPI');
            return false;
        }


        let subMenu = await json.payload.list;



        const result = subMenu.nodes.map(el => {
            //let linkHref = el.element.alias+"_"+el.element.id;
            let linkHref = el.element.base64_uid;
            return ( <li
                    key={el.element.id}
                    className={el.element.id === this.state.displayChild && "active"}
                    data-docid={el.element.id}
                >
                    {el.nodes && (this.state.displayChild.indexOf(el.element.id) === -1) && React.createElement('i',{className: 'far fa-folder isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)}
                    {el.nodes && (this.state.displayChild.indexOf(el.element.id) !== -1) && React.createElement('i',{className: 'far fa-folder-open isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)}
                    {!el.nodes && React.createElement('i',{className: 'fs-fa-empty mr-2'},)}
                    {!el.nodes && React.createElement('i',{className: 'far fa-sticky-note mr-2'},)}
                    {el.nodes && React.createElement('i',{className: 'fas fa-book mr-2'},)}
                    <a className="resource_title" href={"/codex/"+linkHref} data-toggle="tooltip" data-placement="top" title={el.element.title}> <span dangerouslySetInnerHTML={this.createMarkup(el.element.title)} /></a> ({el.element.id})

                    {(this.state.displayChild.indexOf(el.element.id) !== -1) &&
                    el.nodes &&
                    this._renderChildren(el.nodes)}
                </li>
            )
        });

        return <ul>{result}</ul>;
    };

    expandParent = id => {
        let displayChild = this.state.displayChild;
        if(displayChild.length === 0){
            displayChild[0]=id;
        }else if(displayChild.length > 0){
            let indx = displayChild.indexOf(id)
            if(indx === -1 ){
                displayChild[displayChild.length]=id;
            }else{
                displayChild.splice(indx, 1);
            }
        }else{
            displayChild = [];
        }/**/
        this.setState({ displayChild: displayChild });
    };

}

class App extends React.Component {
    constructor() {
        super();

        this.state = {
            dataResourceQuickstart: '',
            dataCodexList: '',
            dataEventList: '',
            dataQaList: '',
            dataIsList: '',
        }
    }


    getDataCodexList = async (uri, p) => {
        let api_url = await fetch(uri, p);
        const output = await api_url.json();
        handleStatus(output.status);
        if(output.status !== 200){
            //alert("Не верные данные авторизации");
            return false;
        }
        return await output;
    }

    getApiRequest =  (controller, method = "index", params = {}, fmethod = "GET") => {
        const tokenAPI = localStorage.getItem("tokenAPI");
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let Data = new FormData(), DataLink = "", p;
        if(fmethod === "POST"){
            for(let num in params){ Data.append(num, params[num]); }
            p = {
                method: fmethod,
                body: Data,
                headers:headers
            };
        }else if(fmethod === "GET"){
            for(let num in params){ DataLink = "&"+num + "=" + params[num]; }
        }

        let uri = global.apiLink+`api/${controller}/${method}?token=${tokenAPI}${DataLink}`;
        var self = this;
        if(controller === "codex/codex"){
            self.getDataCodexList(uri, p).then(output => self.setState({
                dataCodexList: output
            }))
            return self.state.dataCodexList;
        }else if(controller === "situation/situation"){
            self.getDataCodexList(uri, p).then(output => self.setState({
                dataEventList: output
            }))
            return self.state.dataEventList;
        }else if(controller === "qa/qa"){
            self.getDataCodexList(uri, p).then(output => self.setState({
                dataQaList: output
            }))
            return self.state.dataQaList;
        }else if(controller === "is/is"){
            self.getDataCodexList(uri, p).then(output => self.setState({
                dataIsList: output
            }))
            return self.state.dataIsList;
        }
    }





    gettingResources = async () => {
        let output = {};
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        const api_url = await fetch(global.apiLink+`api/codex/codex/list?parent=0`,{
            headers:headers
        });
        output.quickstart = await api_url.json();
        handleStatus(output.quickstart.status);
        if(output.quickstart.status !== 200) {
            //alert("Не верные данные авторизации quickstart");
            return false;
        }
        return output;
    }



    getSituation = () => {
        let property = {"pid" : "0"}; 
        let dataEventList = this.state.dataEventList;
        if(dataEventList === ''){
            this.getApiRequest("situation/situation", "list", property);
        } else {
            const EventList = dataEventList.payload;
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>События</h3>
                            {this.state.dataEventList.map( p => (
                                <div key={p.id}>
                                    <Link  to={`/situation/${p.alias}/`}>{p.title}</Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
        }
    }



    getQa = () => {
        let property = {"pid" : "0"};
        let dataQaList = this.state.dataQaList;

        if(dataQaList === ''){
            this.getApiRequest("qa/qa", "list", property);
        }

        else {
            let QaList    = dataQaList.payload.list;
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Вопрос-ответ</h3>
                            {
                                QaList.map( p => (
                                        <div key={p.id}><Link  to={`/qa/${p.alias}_${p.id}/`}>{p.title}</Link></div>
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
            );
        }

    }
    getIs = () => {
        let property = {"pid" : "0"};
        let dataIsList = this.state.dataIsList;

        if(dataIsList === ''){
            this.getApiRequest("is/is", "list", property);
        } else {
            let IsList    = dataIsList.payload.list;
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Информационная система</h3>
                            {
                                IsList.map( p => (
                                        <div key={p.id}><Link  to={`/qa/${p.alias}_${p.id}/`}>{p.title}</Link></div>
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
            );
        }

    }



    render(){
        let toRenderMainPAgeBody = <MainPage/>;
        if(localStorage.getItem('tokenAPI')){
            toRenderMainPAgeBody = <ResourceViewerLk/>;
        }
        let toRenderMainPageHeader = <HeaderLk/>; //


        
        let pathname = window.location.pathname;
        console.log("pathname=");
        console.log(pathname);

        return (
            <>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" render={()=>toRenderMainPageHeader} />
                        <Route       path="/*" component={HeaderLk} />
                    </Switch>
                    <Switch>
                        <Route exact path="/" render={()=>toRenderMainPAgeBody} />
                        <Route exact path="/codex/" component={ResourceCodexMainpage} />
                        <Route       path="/codex/:alias/" component={ResourceViewerCodex} />
                        <Route exact path="/chat/" component={ChatPage} />
                        <Route       path="/chat/:roomid/" component={RoomPage} />
                        <Route exact path="/situation/" component={ResourceViewerSituation} />
                        <Route       path="/situation/part:part/" component={ResourceViewerSituationPart} />
                        <Route       path="/situation/:alias/" component={ResourceViewerSituationPartAlias} />
                        <Route exact path="/docs/" component={ResourceViewerDocs} />
						<Route       path="/docs/part:part/:id/" component={DocumentDetail} />
						<Route       path="/docs/part:part/" component={ResourceViewerDocsPart} />

				
                        <Route exact path="/qa/" component={ResourceViewerQa} />
                        <Route       path="/qa/:alias/" component={ResourceViewerQa} />
                        <Route exact path="/is/" component={InformationSystemSP} />
                        <Route       path="/is/:juristtype/:stateid/:cityid/:juristid/" component={InformationSystemSP} />
                        <Route       path="/is/:juristtype/:stateid/:cityid/" component={InformationSystemSP} />
                        <Route       path="/is/:juristtype/:stateid/" component={InformationSystemSP} />
                        <Route       path="/is/:juristtype/" component={InformationSystemSP} />
                        <Route exact path="/regions/" component={ResourceviewerSubject}/>
                        <Route       path="/regions/:region_id/" component={Subjects} />
                        <Route       path="/organization/:id/" component={Subject} />
                        <Route exact path="/registration/" component={Registration} />
                        <Route exact path="/registration/verify/" component={VerifyByEmail}/>
                        <Route exact path="/plenum/" component={ResourceViewerPlenum} />
                        <Route       path="/plenum/:alias/" component={ResourceViewerPlenum} />
                        <Route exact path="/news/" component={Newslist} />
                        <Route       path="/news/:alias/" component={NewsElement} />
                        <Route exact path="/call/" component={CallResource} />
                        <Route       path="/quickstart/:qs/" component={QuickStart_dtp} />
                        <Route       path="/search/" component={Search} />
                        <Route exact path="/lk/" component={Profile} />
                        <Route exact path="/login/" component={LoginForm} />
                        <Route exact path="/logout/" component={Logout} />
                        <Route exact path="/privpol/" component={PrivPol} />
                        <Route exact path="/about/" component={AboutProject} />
						<Route exact path="/services/" component={Serviceslist} />
						<Route exact path="/contactus/" component={ContactUs} />
                    </Switch>
                    <Switch>
                        <Route exact path="/chat/" render={()=>{return (<></>);}} />
                        <Route       path="/*" component={FooterLk} />
                    </Switch>
                </BrowserRouter>
            </>
        );

    }

}

export default App;
