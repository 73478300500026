import React from "react";
import LeftMenu_on_LK from '../../../layouts/personal-cab/LeftMenu_on_LK';

class YooKassaPage extends React.Component{

    render() {
        return (
            <>
            <div className="container page">
                <div className="row">
                            <div className="col-md-2 mb-3">
                                <LeftMenu_on_LK />
                            </div>
                    <div className="col-md-10 mb-5">
                        <h1 className="display-5 mt-3">Спасибо, за оплату.</h1> 
                        <div className="border">
                            <div className="card-body">
                                <a href="/lk/">Личный кабинет</a>
                                <br />
                                <a href="/panel/">Главная</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}
export default YooKassaPage;