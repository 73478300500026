import React from "react";
import {Link} from 'react-router-dom';
import LogoSvg from '../assets/logo.svg';
import SearchSvg from '../assets/search.svg';
import BellSvg from '../assets/bell.svg';
import './styles.css';

const Header = (props) => {
    return (
        <div className="d-flex gap-3 align-items-center">
            <Link to="/">
                <img src={LogoSvg} alt="my-advo.cat" />
            </Link>
            <div className="rounded-pill px-3 shadow-sm d-flex gap-2 align-items-center flex-grow-1">
                <img src={SearchSvg} alt="search" />
                <input 
                type="text"
                className="py-2 border-0 search-input" 
                placeholder="Поиск" />
            </div>
            <Link to="/">
                <img src={BellSvg} alt="notifications" />
            </Link>
        </div>
    );
}

export default Header;