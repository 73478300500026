import React from "react";
import {Link} from "react-router-dom";
import "../../global.js";
import {FormErrors} from '../FormErrors';
import "./registration.styles.css";
import "./assets/form.css";

import { isMobile } from 'react-device-detect';

class ForgotPassword extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            formErrors: {email: ''},
            emailValid: false,
            formValid: false
        }
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value) });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;

        switch(fieldName) {
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : ' не корректен';
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
            emailValid: emailValid,
        }, this.validateForm);
    }

    validateForm() {
        this.setState({formValid: this.state.emailValid});
    }
    errorClass(error) {
        return(error.length === 0 ? '' : 'input-field__error');
    }
    /*
    badRequest(payload){
        console.log(payload);
        let result='';
        for(let key in payload){
            result +=payload[key].join(' ')+"\n";
        }
        alert(result);
    }*/

    sendForm = async (e) => {
        e.preventDefault();
        if (!this.state.formValid) {
            document.getElementById("form_result").innerHTML ='<p>'
                + 'Укажите корректный адрес электронной почты'
                + '</p>';
            return false;//this.badRequest(response.payload);
        }
        let url = global.apiLink+"/api/user/forgot_password";
        let formData = new FormData(e.target);
        const res = await fetch(url, {
            body:formData,
            method:'POST'
        });
        const response = await res.json();
        console.log(this.state.MOBILE_URL)
        if (response.status === 200){
            alert('Вам на почту отправленно письмо со ссылкой для восстановления доступа в Личный кабинет.');
            if (isMobile) {
                window.location.replace(this.state.MOBILE_URL);
            }
        } else {
            /*if (isMobile) {
                if(typeof response?.payload?.email === 'string') {
                    alert(response.payload.email);
                }
                if(typeof response?.payload?.password === 'string') {
                    alert(response.payload.password);
                }
                return;
            }*/
            let messageText = "";
            if(response.payload.email !== undefined){
                console.log(response.payload.email);
                if(typeof response.payload.email === 'string' || response.payload.email instanceof String)
                    messageText +=response.payload.email;
                else
                    messageText +=response.payload.email.join(', <br />');
            }

            document.getElementById("form_result").innerHTML ='<p>'+
                messageText
                +'</p>';
            return false;//this.badRequest(response.payload);
        }

        
        window.location.href = '/lk';
    }
    render() {
        document.getElementById("root").style.background = "#fff";
        return (
            <>
            <div className="qa-page page pt-0">
                <div className="container">
                    <div className="row">
                        <div className="offset-md-2 col-md-8 card shadow-sm">
                            <div className="row card-body">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <legend className="legend text-center"><h2>Восстановление пароля</h2></legend>
                                        <p className="mb-3">На указанный Вами адрес электронной почты будет отправлено письмо со ссылкой на смену пароля.</p>
                                    </div>
                                    <div className="row panel panel-default" id="form_result">
                                        <FormErrors formErrors={this.state.formErrors} />
                                    </div>
                                    <div className="row">
                                        <form onSubmit={this.sendForm} style={{width:"70%"}}>
                                            <div className="row">
                                                <div className={'col-xl-6 col-lg-12 col-12 ${this.errorClass(this.state.formErrors.email)}'}  style={{marginBottom:"15px"}}>
                                                    <label className="form-label">Логин</label>
                                                    <input value={this.state.email}
                                                           type="email"
                                                           className="form-control"
                                                           id="email"
                                                           name="email"
                                                           required
                                                           onChange={this.handleUserInput}
                                                           placeholder="Адрес электронной почты"
                                                    />
                                                </div>
                                                <div className="col-xl-6 col-lg-12 col-12">
                                                    <button type="submit"
                                                            className="btn btn-biryza"
                                                            style={{height:"38px"}}
                                                            disabled={!this.state.formValid}>
                                                        Получить ссылку
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row" style={{marginBottom:"15px", marginTop:"20px"}}>
                                                <div className="col-xl-6 col-lg-12 col-12 d-flex gap-2">
                                                    <Link className="btn btn-link py-0 px-0" to="/login">Войти</Link> / <Link className="btn btn-link py-0 px-0" to="/register">Зарегистрироваться</Link>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <p className="small">Отправляя адрес электронной почты, Вы принимаете <Link to="/privpol">пользовательское соглашение и политику конфиденциальности</Link>.</p>
                                            </div>
                                        </form>

                                        <div id="reg_result"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        );
    }

}
export default ForgotPassword;