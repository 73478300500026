import React from "react";
import LeftMenu_on_LK from '../../../layouts/personal-cab/LeftMenu_on_LK';
import "../../../global";
import PageTitle from "../../elements/PageTitle";
import BreadCrumbs from "../../elements/BreadCrumbs";
import validator from 'validator/validator.js'
import { InputMask } from '@react-input/mask';
import {PayInfoAccordion} from "../../../layouts/personal-cab/InfoAccordion";
import {normalizeCountForm} from "../../../helper";

let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));

let APIendpoint = global.apiLink;

class YooKassaPage extends React.Component{

    yook_customer_email = React.createRef();
    yook_customer_phone = React.createRef();
    yook_captcha = React.createRef();
    yook_captcha_text = React.createRef();
    yook_captcha_hash = React.createRef();
    yook_captcha_privpol = React.createRef();

    constructor(props) {
        super(props);
        this.state  = {
            pay_form_tarif: null,
            pay_form_methodtype: null,
            pay_form: false,
            tarif_list: [],
            isPremium: false,
            idLastPaydTariff: null,
            isLoading: true,
            formErrors: { 
                email: false,
                phone: false,
                captcha: false,
                privpol: false,
            }
        };
        
    }

    stringFormat = (value, pattern) => {
        let i = 0;
        const v = value.toString();
        return pattern.replace(/#/g, _ => v[i++]);
    }

    getUserInfo = (f = null) => {
        let self = this;
        let url = APIendpoint + '/api/user/get-profile';
        fetch(url, { 
            method:'GET',
            headers:headers,
        })
            .then(response=>response.json())
            .then((json)=>{
                if (json.status !== 200) {
                    localStorage.clear();
                    window.location.href = '/login';
                }else if(json.payload){
                    self.setState({isLoading: false});
                    if(json.payload.email !== undefined)
                        self.yook_customer_email.current.value = json.payload.email;

                    if(json.payload.phones !== undefined){
                        let phones = "";
                        console.log(json.payload.phones);
                        if(typeof (json.payload.phones) === 'string' && json.payload.phones.length > 0)
                            phones = this.stringFormat(json.payload.phones, '############');
                        self.yook_customer_phone.current.value = phones;
                        if(f !== null)if(typeof (f) === "function")f();
                    }
                }
            });/**/
    }

    tarifs_list = () => {
        let self = this;
        let url = APIendpoint + "/api/prices/list";
        fetch(url, { 
            method:'GET',
            //headers:headers,
        })
            .then(response=>response.json())
            .then((json)=>{
                if(json.payload){
                    self.setState({
                        tarif_list: json.payload.list
                    });
                }
            });
    }

    pay_order_submit = () => {
        let self = this;
        let formErrorState = false;
        let formErrors = {
            email: false,
            phone: false,
            captcha: false,
            privpol: false,
        };
        let pay_form_tarif = self.state.pay_form_tarif;
        if( !pay_form_tarif ){
            alert("Не выбран тариф");
            return ;
        }
        if(self.yook_captcha_privpol.current.checked === false){
            formErrorState = true;
            formErrors.privpol = true;
        }
        if(self.yook_customer_email.current.value == ""){
            formErrorState = true;
            formErrors.email = true;
        }else{
            if (validator.isEmail(self.yook_customer_email.current.value) === false) {
                formErrorState = true;
                formErrors.email = true;
             }
        }
        if(self.yook_customer_phone.current.value == ""){
            formErrorState = true;
            formErrors.phone = true;
        }else{
            if (validator.isMobilePhone(self.yook_customer_phone.current.value) === false) {
                formErrorState = true;
                formErrors.phone = true;
            }
        }
        self.setState({ formErrors: formErrors });
        if(formErrorState === true)return false;

        let url = APIendpoint + "/api/pay/yookassa-pay/yook-create-payment";
        let request = {
            price_id: pay_form_tarif,
            email: self.yook_customer_email.current.value,
            phone: self.yook_customer_phone.current.value,
            captcha : self.yook_captcha_text.current.value,
            captchaHash : self.yook_captcha_hash.current.value,
        };
            fetch(url, { 
                method:'POST',
                headers:headers,
                body: JSON.stringify(request)
            })
            .then(response=>response.json())
            .then((json)=>{
                console.log(json);
                if(json.status == 200){
                    if(json.payload !== null){
                        if(json.payload.confirmation_url){
                            window.open(json.payload.confirmation_url, '_blank');
                            setTimeout(() => {
                                window.location.href = "/paymentstatus/"+json.payload.id;
                            }, 500);
                        }
                    }
                }else{
                    formErrorState = true;
                    formErrors.captcha = true;
                    self.setState({ formErrors: formErrors });
                }
            });

        self.loadCaptchaImage();
        self.yook_captcha_text.current.value = "";
    }

    
    tarifs_action = () => {
        let self = this;
        let url  = global.apiLink + '/api/token/check-token';
        fetch(url, { headers: headers })
            .then( response =>response.json() )
            .then((response)=>{
                let status = parseInt(response.status);
                switch(status){
                    case 200 : {
                        self.setState({loggedIn: true});
                        let url  = global.apiLink + '/api/pay/yookassa-pay/get-id-last-payd-tariff';
                        fetch( url, { headers: headers } )
                            .then( response =>response.json() )
                            .then( (response)=>{
                                if(response.status == 200)
                                    if(response.payload.price_id !== undefined)
                                        self.setState( {idLastPaydTariff: response.payload.price_id} );
                            } );
                        url  = global.apiLink + 'api/user/get-profile';
                        fetch( url, { headers: headers } )
                            .then( response =>response.json() )
                            .then( (response)=>{
                                if(response.status == 200)
                                    if(response.payload.premium !== undefined)
                                        self.setState( {isPremium: Boolean(response.payload.premium)} );
                            } );
                        break;
                    }
                    case 403 : {
                        self.setState({loggedIn: false});
                        break;
                    }
                }
            })
    }/**/


    loadCaptchaImage = () => {
        let self = this;
        self.yook_captcha.current.src="/assets/images/icons/loader1.gif";
        let url = APIendpoint+"/api/captcha/captcha/ver1";
        fetch(url)
        .then( response =>response.json() )
        .then(resp=>{
            self.yook_captcha.current.src = "data:image/png;base64,"+resp.payload.imagedata;
            self.yook_captcha_hash.current.value = resp.payload.captchaHash
        });
    }

    createMarkup = (s) => {
        return {__html: s};
    }

    componentDidMount() {
        let self = this;
        self.setState({pay_form:true});
        self.tarifs_list();
        self.tarifs_action();
        self.setState({isLoading: false});
        
        /*
        self.getUserInfo(()=>{
            const queryParams = new URLSearchParams(window.location.search);
            const price_id = queryParams.get("price_id");
            if( price_id !== null ){
                self.tarifs_list();
                self.setState({pay_form_tarif: price_id});
            } else {
                self.tarifs_list();
            }
            //self.tarifs_action();
            //self.loadCaptchaImage();
            console.log("componentDidMount");
        });/**/
    }

    render() { 
        let self = this;
        let {
            tarif_list, 
            pay_form_tarif,
            idLastPaydTariff,
            isPremium,
            isLoading
        } = self.state;

 
        let humen_level1 = {
            position: "relative",
            display: "block",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            marginBottom: "15px",
            background: "#fff",
            //height: "365px", 
            height: "100%", 
            borderRadius: "5px"
        };

        let humen_level_title = {
            color: "#fff",
            fontSize: "18px",
            lineHeight: "21px",
            textAlign: "right",
            padding: "8px 5px",
            background: "#00baff",
            position: "absolute",
            bottom: "50px",
            right: "0"
        }

        const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Оплата',
                path: '',				
            },
        ];		

        let cardFooterStyle={
            border: 0,
            background: "unset",
            paddingTop: 0,
            paddingBottom: "15px"
        };


  const modify = (input) => {
    return { mask: input[0] === '0' ? '+_ (___) ___-__-__' : undefined };
  };

        return (
            <>
                <div className="page-docs pay_page page">
                    <div className="container">
                        <div className="row">
							<BreadCrumbs crumbs={breadcrumbs} />						
							<PageTitle pageTitle="Оплата" />
                        </div>
                        <div className="row">
                            {/*}<div className="col-md-12 quick-start-title-wrapper title-inner">
                                <div className="uk-h1 quick-start-header-title">Оплата премиум</div>
                                <div className="all-hr all-hr-2 color-lightblue"></div>
                            </div>{/**/}
                            {isLoading ? 
                                <div className="col-md-12 text-center" dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)} /> 
                            :<>
                            <div className="col-md-2 mb-3">
                                <LeftMenu_on_LK />
                            </div>
                            <div className={"col-md-10"}>
                                <div className="row">
                                            <div className="col-md-12 yook-form mb-3">
                                                <PayInfoAccordion />
                                            </div>
                                    <div className="col-lg-12 pay_form_tarif_list">
                                        <div className=" mb-5">
                                            <form className="row" action={APIendpoint + "/api/pay/yookassa-pay/yook-create-payment"} method="POST">
                                                {!tarif_list ?
                                                    <div className="col-md-12" dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)} /> 
                                                :
                                                    <div className="col-md-12">
                                                        <div className="row justify-content-center">
                                                            {Object.keys(tarif_list).map((item)=>{
                                                                let repeatPay = (idLastPaydTariff == tarif_list[item].id && isPremium == true);
                                                                let active = (pay_form_tarif == tarif_list[item].id);
                                                                let activeId = tarif_list[item].active;

                                                                let CountOfDay = Math.ceil(tarif_list[item].sand_time / (60*60*24));
                                                                let CountOfDayTitle = normalizeCountForm(CountOfDay, ["день","дня","дней"]);
                
                                                                return(
                                                                    <>
                                                                        <div 
                                                                            className={"col-md-6 align-self-center mb-4"+(active?" active":"")}
                                                                            style={{display:(activeId == 2?"none":"")}}
                                                                        >
                                                                            <label for={"price_sale_" + tarif_list[item].id} className="situation_money grow" style={humen_level1}>
                                                                                <input className="form-check-input d-none mt-0" id={"price_sale_" + tarif_list[item].id} type="radio" onChange={()=>{if(activeId !== 2 )return false;self.setState({pay_form_tarif: tarif_list[item].id});self.getUserInfo();self.loadCaptchaImage();}} name="price_id" value={tarif_list[item].id} aria-label={tarif_list[item].name} />
                                                                                <div className="situation_title d-none" style={humen_level_title}>{tarif_list[item].name}</div>
                                                                                <div className="card shadow-sm" style={{height: "100%"}}>
                                                                                    <div className="card-body pb-0">
                                                                                        <div className="price_sale_name">
                                                                                            {tarif_list[item].name}
                                                                                            <div className="price_sale_days text-center" style={{fontSize: "16px"}}>
                                                                                                ({CountOfDay + " " + CountOfDayTitle})
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="price_sale_price">
                                                                                            {tarif_list[item].price} руб
                                                                                        </div>
                                                                                        <div className="price_sale_description text-center">
                                                                                            <p dangerouslySetInnerHTML={this.createMarkup(tarif_list[item].description)} /> 
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="card-footer d-none" style={cardFooterStyle}>
                                                                                        <div className="price_sale_btn_wrapper price_sale_pay pt-2">
                                                                                                <a
                                                                                                    className={"go_to_callpage_btn"+
                                                                                                    ((tarif_list[item].id == pay_form_tarif)?" disabled":"")}
                                                                                                    href="#yook-custom-form"
                                                                                                    onClick={()=>{document.getElementById("price_sale_" + tarif_list[item].id).click();return false;}}
                                                                                                    style={{padding: 0, float: "none"}}
                                                                                                    disabled={((tarif_list[item].id == pay_form_tarif)?true:false)}
                                                                                                >
                                                                                                    <div className={"go_to_callpage_title text-center"}
                                                                                                        style={{
                                                                                                            fontSize:"1.1rem", 
                                                                                                            float: "none",
                                                                                                            width: "100%"
                                                                                                        }}
                                                                                                    >{((tarif_list[item].id == pay_form_tarif)?"Выбрано":(repeatPay?"Продлить":"Выбрать"))}</div>
                                                                                                </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                }

                                                {/** */}<div id={"yook-custom-form"} className={pay_form_tarif ? "col-md-12 yook-form pt-5" : "d-none"}>
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-12 align-self-center mb-3">
                                                            <div className="card shadow-sm">
                                                                <div className="card-body text-center">
                                                                    <div className="yook-customer-email mb-3">
                                                                        <label for="yook-customer-email" class="form-label float-start">Почта <span class="form-required">*</span></label>
                                                                        <input 
                                                                            type="email"
                                                                            inputMode="email"
                                                                            rel=""
                                                                            className={"form-control yook-customer-email" + (self.state.formErrors.email?" is-danger":"")} 
                                                                            id="yook-customer-email"
                                                                            ref={ self.yook_customer_email }
                                                                            name="email" 
                                                                            placeholder="Адрес электронной почты" />
                                                                    </div>
                                                                    <div className="yook-customer-phone">
                                                                        <label for="yook-customer-phone" class="form-label float-start">Номер телефона <span class="form-required">*</span></label>
                                                                        <InputMask 
                                                                            type="tel"
                                                                            inputMode="tel"
                                                                            mask="+___________"
                                                                            modify={modify} 
                                                                            replacement={{ _: /\d/ }}
                                                                            className={"form-control yook-customer-phone" + (self.state.formErrors.phone?" is-danger":"")} 
                                                                            id="yook-customer-phone"
                                                                            ref={ self.yook_customer_phone }
                                                                            name="phone" 
                                                                            placeholder="Номер телефона" />
                                                                    </div>
                                                                    <div className={"yook-customer-captcha"}>
                                                                        <img 
                                                                            className={"img-thumbnail mt-4" + (self.state.formErrors.captcha?" is-danger":"")} 
                                                                            id="yook-captcha"
                                                                            style={{height:"104px"}}
                                                                            ref={ self.yook_captcha }
                                                                            src={"/assets/images/icons/loader1.gif"} />
                                                                        <div onClick={()=>self.loadCaptchaImage()}
                                                                            style={{cursor:"pointer"}}>Обновить</div>
                                                                        <div className="row justify-content-center mt-3">
                                                                            <div className="col-md-7">
                                                                                <input 
                                                                                    type="hidden" 
                                                                                    className={"d-none"} 
                                                                                    id="yook-captcha-hash"
                                                                                    ref={ self.yook_captcha_hash }
                                                                                    name="captchaHash"/>
                                                                                <input 
                                                                                    type="text" 
                                                                                    className={"form-control yook-captcha-text" + (self.state.formErrors.captcha?" is-danger":"")} 
                                                                                    id="yook-captcha-text"
                                                                                    ref={ self.yook_captcha_text }
                                                                                    name="captcha" 
                                                                                    placeholder="Капча" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-footer text-center mb-3" style={{background:"#fff", borderTop:0, borderBottom:"15px"}}>
                                                                   <div className="row justify-content-center">
                                                                        <div className="col-md-8">
                                                                            <div className="mb-3 form-check">
                                                                                <label style={{margin:"0"}} className="form-check-label" for="privpol_accept">
                                                                                <input 
                                                                                    type="checkbox"
                                                                                    style={{margin:"4px 0 0"}} 
                                                                                    ref={ self.yook_captcha_privpol }
                                                                                    className={"form-check-input" + (self.state.formErrors.privpol?" is-danger":"")} 
                                                                                    id="privpol_accept"/>
                                                                                    Я принимаю условия <a href="/privpol/" title="Политика конфиденциальности" target="_blank">политики конфиденциальности</a></label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <a 
                                                                        className="go_to_callpage_btn" 
                                                                        ref={ ( yook_create_payment ) => ( this.yook_create_payment_btn = yook_create_payment ) }
                                                                        href="#"
                                                                        onClick={ (e)=>{ e.preventDefault(); this.pay_order_submit(); return false; } }
                                                                        style={{padding: 0, float: "none"}}
                                                                    ><div className="go_to_callpage_title">Оплатить</div></a>
                                                                    <div className="text-center"><noindex><small className="text-secondary">Оплата проводится в системе ЮКасса</small></noindex></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>{/**/}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </>}
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
export default YooKassaPage;