import React from 'react';
import "../../global.js";

export class Plenum_folder_tpl extends React.Component {
    render() {
        let url = this.props.url;
        return (
            <>
			<div className="grid_container_plenum-item" key={this.props.id}>
				<a href={url} title={this.props.name}>
					<span className="card-body__box">
						<h4 className="card-body__title">{this.props.name}</h4>
					</span>
	
					<span className="card-body__link-open">Открыть</span>
					<img className="card-body__icon-plenum" src="/assets/images/icons-books.svg" alt={this.props.name} />
				</a>	
		    </div>
            </>
        );
    }
}
export default Plenum_folder_tpl;