import React from "react";
import {Link} from "react-router-dom";
import "../../global.js";
import MediaQuery from 'react-responsive';
import EyeSvg from './assets/eye-svg.svg';
import VkSvg from './assets/vk.svg';
import GoogleSvg from './assets/google.svg';
import '../../styles/styles.mobile.css';
import "./assets/form.css";
import Button from "../mobile/Button/index.jsx";
import { isMobile } from 'react-device-detect';
import BackSvg from './assets/back.svg'; 

import { gapi } from 'gapi-script';
import {FormErrors} from "../FormErrors";

window.gapi = gapi;

class LoginForm extends React.Component{

    constructor(props) {
        super(props);

        function arrayBufferToBase64(arrayBuffer) {
            var byteArray = new Uint8Array(arrayBuffer);
            var byteString = '';
            for(var i=0; i < byteArray.byteLength; i++) {
                byteString += String.fromCharCode(byteArray[i]);
            }
            var b64 = window.btoa(byteString);
        
            return b64;
        }
        
        function addNewLines(str) {
            var finalString = '';
            while(str.length > 0) {
                finalString += str.substring(0, 64) + '\n';
                str = str.substring(64);
            }
        
            return finalString;
        }
        
        function toPem (Key, type = "private") {
            var b64 = addNewLines( arrayBufferToBase64(Key));
            if(type == "private")
                var pem = "-----BEGIN PRIVATE KEY-----\n" + b64 + "-----END PRIVATE KEY-----";
            if(type == "public")
                var pem = "-----BEGIN PUBLIC KEY-----\n" + b64 + "-----END PUBLIC KEY-----";
            return pem;
        }
        
    
        this.genKeyParsOnFrontend = async (modulusLength = 2048, hash = 'SHA-256') => {
                return new Promise((resolve, reject) => {
                    var pem1, pem2;
                    window.crypto.subtle.generateKey(
                        {
                            name: "RSA-OAEP",
                            modulusLength: modulusLength, // can be 1024, 2048 or 4096
                            publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
                            hash: {name: hash} // or SHA-512
                        },
                        true,
                        ["encrypt", "decrypt"]
                    ).then(function(keyPair) {
                        window.crypto.subtle.exportKey(
                            "pkcs8",
                            keyPair.privateKey
                        ).then(function(exportedPrivateKey) {
                            pem1 = toPem(exportedPrivateKey);
                            localStorage.setItem('pvs_private_Key', pem1);
                        }).catch( function(err) {
                            console.log(err);
                        });
    
                        window.crypto.subtle.exportKey(
                            "spki",
                            keyPair.publicKey
                        ).then(function(exportedPublicKey) {
                            pem2 = toPem(exportedPublicKey, "public");
                            localStorage.setItem('pvs_public_key', pem2);
                        }).catch(function(err) {
                            console.log(err);
                        });
                    });
                    //let pem1 = localStorage.getItem('pvs_private_Key');
                    //let pem2 = localStorage.getItem('pvs_public_key');
                    if (pem1 == localStorage.getItem('pvs_private_Key') && pem2 == localStorage.getItem('pvs_public_key')) {
                      resolve(true);
                    } 
                    reject(false);
                });
        }

            this.sendCryptoKeys = () => {
                let tokenAPI = localStorage.getItem('tokenAPI');
                let client = new WebSocket(global.apiWsLink + '?token=' + tokenAPI);
                client.onerror = () => {
                    console.log('Connection Error');
                    window.location.href = '/logout/';
                }
                client.onopen = () => {
                    console.log('WebSocket Client Connected');
                    let pem = localStorage.getItem('pvs_public_key');
                    let pvs_public_key_base64 = Buffer.from(pem).toString('base64');
                    console.log(pem);
                        client.send(
                            JSON.stringify(
                                {
                                    token: tokenAPI,
                                    action: "takePublic",
                                    msg: pvs_public_key_base64
                                }
                            )
                        );
                }
                client.onclose = () => console.log('echo-protocol Client Closed');
                
                client.onmessage = function(e) { 
                    if (typeof e.data === 'string') {
                        let PayLoad = JSON.parse(e.data);
                        if(PayLoad.payload.action == "takePublic"){
                            window.location.href = '/';
                        }
                    }
                }
            }
            this.login = this.login.bind(this);
            this.state = {
                login: '',
                password: '',
                formErrors: {login: '', password: ''},
				formResponceErrors: {error: ''},
                loginValid: false,
                passwordValid: false,
                formValid: false,
                passwordShown: false,
                loading: false
            }
            
            this.state.MOBILE_URL = process.env.NODE_ENV === 'development' ?
            "http://localhost:3000" : "https://m.my-advo.cat";

            console.log(this.state.MOBILE_URL);

        this.handleUserInput= (e) => {
            const name = e.target.name;
            const value = e.target.value;
            console.log('name=' +name);
            console.log('value=' +value);
            this.setState({[name]: value},
                () => { this.validateField(name, value) });
        }
        this.validateField= (fieldName, value) =>{
            let fieldValidationErrors = this.state.formErrors;
            let loginValid = this.state.loginValid;
            let passwordValid = this.state.passwordValid;
            switch(fieldName) {
                /*case 'login':
                    loginValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i);
                    fieldValidationErrors.login = loginValid ? '' : 'Указан некорректный логин.';
                    break;
                case 'password':
                    passwordValid = value.length >= 6;
                    fieldValidationErrors.password = passwordValid ? '': 'Минимальная длина пароля - 6 символов.';
                    break;*/
                case 'login':
                    loginValid = value.length > 0;
                    fieldValidationErrors.login = loginValid ? '' : 'Укажите логин.';
                    break;
                case 'password':
                    passwordValid = value.length >= 1 && value.length <= 20;
                    fieldValidationErrors.password = passwordValid ? '': 'Укажите пароль.';
                    break;					
                default:
                    break;
            }
            this.setState({formErrors: fieldValidationErrors,
                loginValid: loginValid,
                passwordValid: passwordValid
            }, this.validateForm);
        }
        this.validateForm=()=> {
            this.setState({formValid: this.state.loginValid && this.state.passwordValid});
        }
        this.errorClass=(error)=> {
            return(error.length === 0 ? '' : ' form-line-error');
        }
        this.errorHintClass=(error)=> {
            return(error.length === 0 ? '' : ' form-hint-error');
        }		
    }


    componentDidMount() {

        //document.getElementById("root").style.background = "url(/assets/images/young-beautiful-woman.jpg?235325)";
            
        const _onInit = auth2 => {
            //console.log('init OK', auth2)
        }
        const _onError = err => {
            //console.log('error', err)
        }
        let url  = global.apiLink + '/api/token/check-token';
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        
        fetch(url,{
            headers:headers
        })
            .then(response =>response.json())
            .then((response)=>{
                if (!isMobile) {
                    if(response.status === 200 && response.payload === true){
                        console.log('user loggined');
                        window.location.href = '/lk';
                        return;
                    }
                }
				
                localStorage.removeItem('tokenAPI');
                localStorage.removeItem('pvs_public_key');
                localStorage.removeItem('pvs_private_Key');
                localStorage.removeItem('id_user');
            })
        window.gapi.load('auth2', function() {
            window.gapi.auth2
                .init({ // не забудьте указать ваш ключ в .env
                    client_id:
                        '704349581358-8klcbvslsidqnpt3415qp1a6vokmagc4.apps.googleusercontent.com',
                })
                .then(_onInit, _onError)
        });
        /**/
    }

    /*AutorizationProcessOn(){
		try{
			document.querySelector(".login-form button").style.display = "none";
			document.querySelector(".auth_login_tohide1").style.display = "none";
			document.querySelector(".auth_login_tohide2").style.display = "none";
			document.querySelector(".auth_login_tohide4").style.display = "none";
			document.querySelector(".auth_login_tohide5").style.display = "none";
			document.querySelector(".auth_login_tohide3").innerHTML = global.CssLoader;
			document.querySelector(".google-logo.logo-social").style.display = "none";
			document.querySelector(".vk-logo.logo-social").style.display = "none";
			document.querySelector(".service-login").style.display = "none";
			document.querySelector("#reg_result").innerHTML = "";
		} catch (err){
			console.log(err);
		}
    }

    AutorizationProcessOff(){
		try{
			document.querySelector(".login-form button").style.display = "block";
			document.querySelector(".auth_login_tohide1").style.display = "block";
			document.querySelector(".auth_login_tohide2").style.display = "block";
			document.querySelector(".auth_login_tohide4").style.display = "block";
			document.querySelector(".auth_login_tohide5").style.display = "block";
			document.querySelector(".auth_login_tohide3").innerHTML = "";
			document.querySelector(".google-logo.logo-social").style.display = "inline-block";
			document.querySelector(".vk-logo.logo-social").style.display = "inline-block";
			document.querySelector(".service-login").style.display = "block";
		} catch (err){
			console.log(err);
		}
    }/**/

    async login(e) {
        e.preventDefault(); 
        //if (!isMobile) this.AutorizationProcessOn();
        this.setState({loading: true});

        let formData = new FormData(e.target);
        let url = global.apiLink+"/api/token";
		//alert(formData);
        fetch(url, {
            method:'POST',
            body:formData
        })
            .then(res => res.json())
            .then((result)=>{
                if(result.payload){
                    if (result.status === 200) {
                        if (isMobile) {
							window.location.replace(`${this.state.MOBILE_URL}/auth/${result.payload.token}`);
                            return;
                        }

                        localStorage.setItem('tokenAPI',result.payload.token);
                        localStorage.setItem('id_user',result.payload.id_user);
                        localStorage.setItem('public_key',result.payload.public_key.trim());
                        /*try {
                            this.genKeyParsOnFrontend().then((res) => {
                                setTimeout(()=>{
                                   // this.sendCryptoKeys();
                                }, 1500);
                            });
                        } catch (error) {
                            console.log(error);
                        }/**/
						//alert(result);
						window.location.href=  '/lk';
						return;
                    } else {
						let errorStr = "Внимание! Авторизация не пройдена. "
						+ "Проверьте корректность введенных данных и повторите попытку.";						
                        this.setState({ loading: false, formResponceErrors:{error: errorStr}});
                    }
                }
            })
            .catch(function (error){
                console.log(error);
            });
            //this.signInFuncGoogle = this.signInFuncGoogle.bind(this);
    }
	
    signInFuncGoogle = () => {
        let self = this;
        //if (!isMobile) this.AutorizationProcessOn();

        const auth2 = window.gapi.auth2.getAuthInstance();
        auth2.signIn().then(googleUser => {
            const id_token = googleUser.getAuthResponse().id_token;
            let url  = global.apiLink+'/api/token/social?token='+id_token+'&type_network=google';
            fetch(url, {
                mode: 'no-cors',
                credentials: 'include',
                method: 'POST'
            })
                .then(res=>res.json())
                .then(function (response){
                    if(response.payload){

                        if (isMobile) {
							//window.location.replace(`${this.state.MOBILE_URL}/auth/${response.payload.token}`);
                            
                            window.location.href = `${this.state.MOBILE_URL}/auth/${response.payload.token}`;
                            return;
                        }

                        localStorage.setItem('tokenAPI',response.payload.token);
                        localStorage.setItem('id_user',response.payload.id_user);
                        localStorage.setItem('public_key',response.payload.public_key.trim());
                        /*try {
                            self.genKeyParsOnFrontend().then((res) => {
                                setTimeout(()=>{
                                    self.sendCryptoKeys();
                                }, 1500);
                            });
                        } catch (error) {
                            console.log(error);
                            //if (!isMobile) this.AutorizationProcessOff();
                        }/**/
                        //window.location.href=  '/';
                    }
                });
        });
    }

    signInFuncVkontakte = () => {
        let self = this;
        //if (!isMobile) this.AutorizationProcessOn();

        let VK_get_code_url = 'https://oauth.vk.com/authorize?client_id='+global.vk_client_id+'&display=popup&redirect_uri=https://api.my-advo.cat/api/token/vkauth/&scope=email&response_type=code&v=5.120';
        var VK_Auth_win = window.open(VK_get_code_url, "ModalWindowVkAuth", 'width=600,height=auto');
        
        window.addEventListener("message", (event) => {
            if (event.origin !== "https://api.my-advo.cat")return;

            if (isMobile) {
				window.location.href = (`${this.state.MOBILE_URL}/auth/${event.data.token}`);
                return;
            }
              
            localStorage.setItem('tokenAPI',event.data.token);
            localStorage.setItem('id_user',event.data.id_user);
            localStorage.setItem('public_key',event.data.public_key.trim());
            VK_Auth_win.close();
            /*try {
                self.genKeyParsOnFrontend().then((res) => {
                    setTimeout(()=>{
                        self.sendCryptoKeys();
                    }, 1500);
                });
            } catch (error) {
                console.log(error);
               //if (!isMobile) this.AutorizationProcessOff();
            }/**/
          }, false);
    }
	
    render() {
        const {passwordShown, loading} = this.state;
        return (
            <>
            <MediaQuery minWidth={768}>
            <div className="qa-page page">
                <div className="container uk-container-center">
                    <div className="row news-container" >
                        <div className="offset-md-2 col-md-8 card shadow-sm">
                            <div className="row card-body">
                                <div className="col-lg-6">
                                    <div className="row">
                                    <form className="login-form test" onSubmit={this.login} autoComplete="off">
                                        <legend className="legend text-center"><h2>Авторизация</h2></legend>
                                        <div className="panel panel-default" id="form_result">
                                            <FormErrors formErrors={this.state.formResponceErrors} />
                                        </div>
                                        <div className="mb-3 auth_login_tohide1 form-line">
                                            <label htmlFor="exampleInputEmail1" className="form-label">
                                                Почта <span className="form-required">*</span>
                                            </label>
                                            <input type="text" id="exampleInputEmail1"
                                                   className={`form-control ${this.errorClass(this.state.formErrors.login)}`}
                                                   name="login"
                                                   placeholder="E-mail"
                                                   autoComplete="off"
                                                   value={this.state.login}
                                                   onChange={this.handleUserInput}
												   required="required"
                                            />
                                            <div className={`form-hint-line ${this.errorHintClass(this.state.formErrors.login)}`}>
											    Укажите корректный адрес электронной почты.
											</div>
                                        </div>
                                        <div className="mb-3 auth_login_tohide2 form-line jf-required">
                                            <label htmlFor="exampleInputPassword1" className="form-label">
                                                Пароль <span className="form-required">*</span>
                                            </label>
                                            <input type="password"
                                                   id="exampleInputPassword1"
                                                   className={`form-control ${this.errorClass(this.state.formErrors.password)}`}
                                                   name="password"
                                                   placeholder="Пароль"
                                                   autoComplete="current-password"
                                                   value={this.state.password}
                                                   onChange={this.handleUserInput}
												   required="required"
                                            />
                                            <div className={`form-hint-line ${this.errorHintClass(this.state.formErrors.password)}`}>
											    Укажите пароль.
											</div>											
                                        </div>
                                        <div className="auth_login_tohide3 text-center"></div>
                                        <div className="auth_login_tohide4 text-center2 form-buttons-wrapper">
                                            <button type="submit"
                                                    className="btn btn-biryza form-submit-button submit-button jf-form-buttons"
                                                    disabled={!this.state.formValid}>
                                                Войти
                                            </button>
                                        </div>
                                        <div className="d-none">
                                            <p className="service-login">или зайдите с помощью одного из сервисов</p>
                                            <div>
                                                <div>
                                                    {/*<button  type="button" onClick={this.signInFuncGoogle} style={{marginRight:"10px"}} className="google-logo logo-social"></button>*/}
                                                    <button  type="button" onClick={()=>{this.signInFuncVkontakte();}} style={{marginRight:"10px"}} className="vk-logo logo-social"></button>
                                                </div>
                                            </div>
                                        </div>
                                            <div className="auth_login_tohide5 mt-4">
                                                <span style={{color: "#000"}}>Нет аккаунта? <Link to="/registration">Создать аккаунт</Link></span>
                                            </div>
                                        </form>
                                        <div id="reg_result"></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 left-border">
                                    <h4 className="mt-5">&nbsp;&nbsp;&nbsp;&nbsp;При авторизации Вам будут доступны:</h4>
                                    <ul className="mt-3">
                                        <li>Разделы консультаций в чате и видеосвязи</li>
                                        <li>Полные версии статей судебной практики</li>
                                        <li>Полная база адвокатов и нотариусов в любом регионе России</li>
                                        <li>и многие другие разделы <br />нашего портала my-advo.cat</li>
                                    </ul>
                                    <p className="small">Нажимая «Войти», Вы принимаете <a href="/privpol/" target="_blank" title="Политика в отношении обработки персональных данных">пользовательское соглашение и политику конфиденциальности</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </MediaQuery>
            <MediaQuery maxWidth={768}>
                <div className="d-flex flex-column align-items-center justify-content-center vh-100 gap-5 px-3">
                    <a 
                    role="button"
                    href={this.state.MOBILE_URL}
                    className="py-4 px-3 pb-0 align-self-start">
                        <img src={BackSvg} alt="back" />
                    </a>
                    <div className="d-flex flex-column align-items-center gap-3">
                        <h2 className="mb-0">Вход</h2>
                        <span className="font-weight-light text-secondary text-center">Введите логин и пароль, чтобы войти</span>
                        <b>Нет аккаунта? <Link className="mobile-link" to="/registration">Регистрация</Link></b>
                    </div>
                    <form
                    onSubmit={this.login} 
                    className="d-flex flex-column gap-3 w-100 align-items-stretch">
                        <label>
                            <small className="d-block mb-2">Почта или номер телефона</small>
                            <input
                            type="text"
                            className="mobile-input"
                            name="login"
                            placeholder="Введите логин"
                             />
                        </label>
                        <label>
                            <small className="d-block mb-2">Пароль</small>
                            <div className="position-relative">
                                <input
                                type={passwordShown ? 'text' : 'password'}
                                className="mobile-input"
                                name="password"
                                placeholder="Введите пароль" />
                                <img 
                                src={EyeSvg} 
                                onClick={() => this.setState({passwordShown: !passwordShown})}
                                className="mobile-input__eye" 
                                alt="показать/скрыть" />
                            </div>
                        </label>
                        <Link className="mt-2 text-center mobile-link" to="/">Забыли пароль?</Link>
                        <Button 
                        className="mobile-btn mt-2"
                        loading={loading}
                        disabled={loading} 
                        type="submit">Войти</Button>
                    </form>
                    <div className="d-flex flex-column w-100 align-items-stretch gap-4">
                        <span className="text-center">Или войти через:</span>
                        <div className="d-flex gap-3 justify-content-center">
                            <div
                            onClick={this.signInFuncVkontakte}  
                            role="button"><img className="oauth-img" src={VkSvg} alt="vk"/></div>
                            {/*<div
                            onClick={this.signInFuncGoogle} 
                            role="button"><img className="oauth-img" src={GoogleSvg} alt="google"/></div>*/}
                        </div>
                    </div>
                </div>
            </MediaQuery>
            </>
        );
    }
}
export default LoginForm;