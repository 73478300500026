import React from 'react';
import SituationCard from './../panel/SituationCard';
import BreadCrumbs from "../elements/BreadCrumbs";
import PageTitle from "../elements/PageTitle";
import "../../global.js";
import * as utils from '../../utils.js';
import {PremiumAccess} from "../../layouts/personal-cab/PremiumAccess";

let headers = new Headers();
headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));

export class ResourceViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageData: [
                {
                    title: "",
                    items: [
                        {
                            href: "/docs/part10/",
                            bg: "/assets/images/panel/Icon_Grazhd.png",
                            title: "Документы по гражданским делам",
                            description: "Образцы процессуальных документов, составленных в соответствии с гражданско-процессуальным законодательством.",
                        },
                        {
                            href: "/docs/part11/",
                            bg: "/assets/images/panel/Icon_Admin.png",
                            title: "Документы по административным делам",
                            description: "Шаблоны искового заявления, жалобы, представления и иных процессуальных документов, составленных в соответствии с административным законодательством.",
                        },
                        {
                            href: "/docs/part12/",
                            bg: "/assets/images/panel/Icon_Nalogi.png",
                            title: "Документы по налоговым делам",
                            description: "Образцы процессуальных документов, составленных в соответствии с арбитражно-процессуальным законодательством.",
                        },
                        {
                            href: "/docs/part13/",
                            bg: "/assets/images/panel/Icon_Ugolov.png",
                            title: "Документы по уголовным делам",
                            description: "Образцы процессуальных документов, составленных в соответствии с уголовно-процессуальным законодательством.",
                        },
                        {
                            href: "/docs/part52/",
                            bg: "/assets/images/panel/Icon_Dogov.png",
                            title: "Договоры",
                            description: "Образцы процессуальных документов, составленных в соответствии с конституционным законодательством.",
                        },
                    ]
                }
            ],
        };
    }
	
    getComplete = async () => {

        let query = document.querySelector("form input[name=q]").value;
        let target = document.querySelector("input[name=target]").value;		
		

        if (query != null) {
        	query = utils.escapeAndTrim(query);
        } else {
			query = '';
		}
        console.log('query=' + query);
        if (query.length <= 2) {
        	document.querySelector(".all_search_autocomplete").innerHTML = '';
        	return false;
        }

        if (target != null) {
        	target = utils.escapeAndTrim(target);
        } else {
			target = '';
		}
        if (!target || !utils.elementInArray(target, ['doc'])) {
        	target = "doc";
        }

        let url = global.apiLink + '/api/search/complete?q=' + query + '&target=' + target;		
		
        const api_ResourceLL = await
            fetch(url, {
                headers: headers
            });

        var output = await api_ResourceLL.json();

        if (output.status == 200) {
			
        	let list = output.payload.list;
        	let resultComplete = "";
        	for (let i = 0; i < list.length; i++) {
        		let linkTitleTmp = list[i].tags;
        		let linkUrlTmp = '/search/?target=' + target + '&q=' + linkTitleTmp.split(" ").join("+");
        		resultComplete += "<div onclick='window.location.href=\"" + linkUrlTmp + "\";'>" + linkTitleTmp + "</div>";
        	}

        	let curQuery = document.querySelector("form input[name=q]").value;
        	if (curQuery != null) {
        		curQuery = utils.escapeAndTrim(curQuery);
        	} else {
				curQuery = '';
			}
        	if (curQuery.length > 2) {
        		document.querySelector(".all_search_autocomplete").innerHTML = resultComplete;
        	} else {
        		document.querySelector(".all_search_autocomplete").innerHTML = '';
        	}			
	
        } else {
            return false;
        }
    }	

    componentDidMount() {

    }

    render() {
        const {pageData} = this.state;
		
		document.getElementById("root").style.background = "#fafafb";
		const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Сервисы',
                path: '/services/',				
            },			
            {
                name: 'Формы документов',
                path: '',				
            },
        ];			

            return (
                <div className="page-docs page">
                    <div className="container ">
                        <div className="row">
                            <BreadCrumbs crumbs={breadcrumbs} />
                            <PageTitle pageTitle="Формы документов" />
                        </div>	
                        <div className="row">
                            <div className="col-md-8">
                                <div className="all-search-wrapper">
                                    <form className="uk-search" method="GET" action="/search/" style={{display: "block"}}>
                                        <input type="hidden" name="target" value="doc" />
                                        <label htmlFor="search">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                            <span className="sr-only">Поиск</span>
                                        </label>
                                        <input type="search" name="q" onChange={() => {
                                                this.getComplete()
                                            }} placeholder="Что делать, если..." autocomplete="off" className="all-search-input" minLength={3} maxLength={255} required="required" />
                                        <button type="submit" className="search_btn" >Найти</button>
                                        <div className="all_search_autocomplete"></div>
                                    </form>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12 mt-3'>
                                        {pageData ? pageData.map((block, i) => {
                                            return (<>
												<ul className="situation-list">
                                                    {
                                                        block.items.map(item => <SituationCard key={item.href} {...item} />)
                                                    }															
												</ul>
                                            </>);
                                        }):<>{global.CssLoader}</>}
                                    </div>
                                </div>								
                                
                            </div>
                            <div className="col-md-4 page-description__desktop">
                                <div className="situation-description row">
                                    <p>В данном разделе реализована возможность просмотра и скачивания различных форм заявлений.</p>
                                    <p>Заявления выводятся структурированным списком. При нажатии на документ открывается окно
                                    быстрого просмотра. При нажатии на «иконку» скачивания происходит загрузка данного документа
                                    на устройство пользователя.</p>
                                    <p>В дальнейшем в данной вкладке будет реализована возможность генерации заявления по
                                    данным, введенным пользователем. Есть возможность заполнения заявления самостоятельно или
                                    оператором системы и пересылка по электронной почте, а также вывод его на печать.</p>
                                </div>
                            </div>
							<div className="col-md-12">
                                <br /><br />
                                <PremiumAccess CheckForPremium={true} />
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}

export default ResourceViewer;