import Peer from "peerjs";
import React, { useEffect, useRef, useState } from "react"
import s from './operator.module.css';
import { FemidaSocket } from "./socket";
import { ReactComponent as RefreshIcon } from "./refresh.svg";
import { ReactComponent as DeclineIcon } from "./decline.svg";

const OperatorPage = (props) => {
    const remoteVidRef = useRef(null);
    const localVidRef = useRef(null);
    const [c, setCall] = useState(null)

    // UI
    const [ls, setLs] = useState(false)
    const [onCall, setOnCall] = useState(false)

    useEffect(() => {
        const token = localStorage.getItem("tokenAPI");
        if (!token) window.location.replace("/login")
        let myPeerId = null;

        checkMedia()

        const peer = new Peer({
            config: {'iceServers': [
              { url: 'stun:stun1.l.google.com:19302' },
              { url: 'stun:stun2.l.google.com:19302' }
            ]}
          });
          
        peer.on('open', (id) => {
            myPeerId = id
            FemidaSocket.connectSocket({
                token, socket_room: 'call',
                events: [
                    {
                        e: 'connect', 
                        fn: () => {
                            FemidaSocket.Instance.emit('call:op-available', id)
                        }
                    },
                    {
                        e: 'call:caller-disconnect', 
                        fn: () => {
                            if (c) c.close();
                            console.log('call closed by caller');
                            clearCall()
                        }
                    },
                    {
                        e: 'connect_error', 
                        fn: (err) => console.log(err)
                    }
                ]
            })
        })

        peer.on('call', async (call) => {
            call.on('stream', (stream) => {
                if (remoteVidRef.current) {
                    remoteVidRef.current.srcObject = stream
                }
            })
            call.on('close', () => clearCall())
            setCall(call)
        })

        peer.on('error', (err) => {
            console.log(err);
            clearCall()
            if (FemidaSocket.Instance) {
                FemidaSocket.Instance.emit('call:im-available', myPeerId)
            }
        })

        return () => { // component will unmount
            if (FemidaSocket.Instance) {
                FemidaSocket.Instance.removeAllListeners()
            }
        }
    }, [])

    function checkMedia() {
        navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
        })
        .then(ms => setLs(ms))
        .catch(e => {
            console.log(e);
            alert('Камера и/или микрофон отсутствуют')
        })
    }

    async function answerCall() {
        if (!c || !FemidaSocket.Instance) return;
        const ms = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
        })
        FemidaSocket.Instance.emit('call:op-oncall', c.peer)
        c.answer(ms);
        setOnCall(true)
        if (localVidRef.current) {
            localVidRef.current.srcObject = ms
        }
    }

    const closeCall = () => {
        if (!c || !FemidaSocket.Instance) return;
        c.close();
        clearCall()
        FemidaSocket.Instance.emit('call:op-disconnect')
        console.log('call closed by operator');
    }

    function clearCall() {
        console.log('clearCall()');
        if (remoteVidRef.current && localVidRef.current) {
            remoteVidRef.current.srcObject = null;
            remoteVidRef.current.src = '';
            localVidRef.current.srcObject = null;
            localVidRef.current.src = '';
        }
        setCall(null)
        setOnCall(false)
    }

    return (
        <div className={`${s.containerWrap}`}>
            <div className="container">
                <h1>Прием звонков</h1>
                {
                onCall ? <></> :
                <div className={`${(ls || c) ? 'alert-primary' : 'alert-danger'} p-3 mt-4 ${s.alert}`}>
                    {
                        (c) ? // imcoming call
                        <div>
                            <span>Входящий вызов!</span>
                            <div className="d-flex gap-4 mt-4">
                                <div 
                                onClick={closeCall}
                                role="button" 
                                class={`${s.btn} ${s.btnPrimary} px-3`}>Отклонить</div>
                                <div
                                onClick={answerCall} 
                                role="button" 
                                class={`${s.btn} ${s.btnPrimary} px-3`}>Принять</div>
                            </div>
                        </div>
                        :
                        ls ? // no call, media stream check
                        'Ожидаем входящий звонок' :
                        <div className="d-flex gap-2 align-items-center">
                            <span>Камера или микрофон отсутствуют</span>
                            <div role="button" onClick={checkMedia} className={s.btn}>
                                <span>Обновить</span>
                                <RefreshIcon height={28} width={28} />
                            </div>
                        </div>
                    }
                </div>
                }
                {
                    (!c || !onCall) ? <></> :
                    <div className={`mt-4 ${s.callCont}`}>
                        <div className={`mb-2 ${s.vidCont}`}>
                            <video muted autoPlay playsInline ref={localVidRef} />
                            <video autoPlay playsInline ref={remoteVidRef} />                        
                        </div>
                        <DeclineIcon role="button" onClick={closeCall} />
                    </div>
                }
            </div>
        </div>
    );
}

export default OperatorPage;