import React from "react";
import "../../global";
import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";
import {PayInfoAccordion} from"../../layouts/personal-cab/InfoAccordion";
import {normalizeCountForm} from "../../helper";

let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));

class TarifPage extends React.Component{

    constructor(props) {
        super(props);
        this.state  = {
            loggedIn: false,
            isPremium: false,
            tarifList: false,
            idLastPaydTariff: null
        };
    }

    tarifs_list = () => {
        let self = this;
        let url = "https://api.my-advo.cat/api/prices/list";
        fetch(url, { 
            method:'GET',
        })
            .then(response=>response.json())
            .then((json)=>{
                if(json.payload){
                    self.setState({
                        tarifList: json.payload.list
                    });
                    self.tarifs_action();
                }
            });
    }

    

    tarifs_action = () => {
        let self = this;
        let url  = global.apiLink + '/api/token/check-token';
        fetch(url, { headers: headers })
            .then( response =>response.json() )
            .then((response)=>{
                let status = parseInt(response.status);
                switch(status){
                    case 200 : {
                        self.setState({loggedIn: true});
                        let url  = global.apiLink + '/api/pay/yookassa-pay/get-id-last-payd-tariff';
                        fetch( url, { headers: headers } )
                            .then( response =>response.json() )
                            .then( (response)=>{
                                if(response.status == 200)
                                    if(response.payload.price_id !== undefined)
                                        self.setState( {idLastPaydTariff: response.payload.price_id} );
                            } );
                        url  = global.apiLink + 'api/user/get-profile';
                        fetch( url, { headers: headers } )
                            .then( response =>response.json() )
                            .then( (response)=>{
                                if(response.status == 200)
                                    if(response.payload.premium !== undefined)
                                        self.setState( {isPremium: Boolean(response.payload.premium)} );
                            } );
                        break;
                    }
                    case 403 : {
                        self.setState({loggedIn: false});
                        break;
                    }
                }
            })
    }/**/


    createMarkup = (s) => {
        return {__html: s};
    }
    componentDidMount() {
        let self = this;
        self.tarifs_list();
    }

    render() { 
        let self = this;
        let {tarifList, loggedIn, idLastPaydTariff, isPremium} = self.state;


        let humen_level1 = {
            position: "relative",
            display: "block",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            marginBottom: "15px",
            height: "100%", 
            //borderRadius: "5px",
        };

        const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Тарифы',
                path: '',				
            },
        ];		

        let cardStyle={
            //boxShadow: "0 0 11px 0 rgb(0 0 0 / 27%)",
            height: "100%"
        };

        let cardFooterStyle = {
            border: 0,
            background: "unset",
            paddingTop: 0,
            paddingBottom: "15px",
        };


        return (
            <>
                <div className="page-docs pay_page page">
                    <div className="container">
                        <div className="row">
							<BreadCrumbs crumbs={breadcrumbs} />						
							<PageTitle pageTitle="Тарифы" />
                        </div>
                        <div className="row">
                            {/*}<div className="col-md-12 quick-start-title-wrapper title-inner">
                                <div className="uk-h1 quick-start-header-title">Тарифы</div>
                                <div className="all-hr all-hr-2 color-lightblue"></div>
                            </div>{/**/}
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-12 yook-form">
                                        <PayInfoAccordion />
                                    </div>
                                    <div className="col-lg-12 mb-5">
                                        <div className="row justify-content-center">
                                            {!tarifList ? 
                                            <div className="col-md-12 text-center" dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)} /> 
                                            :
                                            Object.keys(tarifList).map((item)=>{
                                                let repeatPay = (idLastPaydTariff == tarifList[item].id && isPremium == true);

                                                let CountOfDay = Math.ceil(tarifList[item].sand_time / (60*60*24));
                                                let CountOfDayTitle = normalizeCountForm(CountOfDay, ["день", "дня", "дней"]);

                                                return(
                                                    <>
                                                        <div className={"col-md-6 align-self-center  mt-2 "+(repeatPay?" price_sale_repeat":"")}>
                                                            <label for={"price_sale_" + tarifList[item].id} className="situation_money grow" style={humen_level1}>
                                                                <div className="card shadow-sm" style={cardStyle}>
                                                                    <div className="card-body pb-0">
                                                                        <div className="price_sale_name">
                                                                            {tarifList[item].name}
                                                                            <div className="price_sale_days text-center" style={{fontSize: "16px"}}>
                                                                                ({CountOfDay + " " + CountOfDayTitle})
                                                                            </div>
                                                                        </div>
                                                                        <div className="price_sale_price" style={{fontSize: "30px"}}>
                                                                            {tarifList[item].price} руб
                                                                        </div>
                                                                        <div className="price_sale_description text-center">
                                                                            <p dangerouslySetInnerHTML={this.createMarkup(tarifList[item].description)} /> 
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-footer" style={cardFooterStyle}>
                                                                        {loggedIn == false ? <div className="price_sale_btn_wrapper price_sale_registration pt-2">
                                                                            <a 
                                                                                href="/registration/"
                                                                                className="go_to_callpage_btn"
                                                                                style={{
                                                                                    padding: 0, 
                                                                                    float: "none",
                                                                                    color: "#3f3f3f"
                                                                                }}
                                                                            >
                                                                                <div className="go_to_callpage_title text-center"
                                                                                style={{
                                                                                    fontSize:"1.1rem",
                                                                                    float: "none",
                                                                                    backgroundColor: "initial",
                                                                                    color: "#3f3f3f",
                                                                                    boxShadow: "inset 0 0 0 1px #00baff"
                                                                                }}>Зарегистрироваться</div>
                                                                            </a>
                                                                        </div> : <></>}
                                                                        <div className="price_sale_btn_wrapper price_sale_pay pt-2 d-none">
                                                                            <a 
                                                                                href={loggedIn == false ? "/login/":("/tarifs/?price_id="+tarifList[item].id)}
                                                                                className="go_to_callpage_btn"
                                                                                style={{padding: 0, float: "none"}}
                                                                            >
                                                                                <div className="go_to_callpage_title text-center"
                                                                                    style={{
                                                                                        fontSize:"1.1rem",
                                                                                        float: "none",
                                                                                        width: "100%",
                                                                                        display: "block",
                                                                                        minWidth: "auto"
                                                                                    }}
                                                                                >{repeatPay?"Продлить":"Оплатить"}</div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
							<div className="col-md-3">
								<div className="right-nav-page aside-nav">
                                    <div className="aside-widget">
                                        <img src="/assets/images/bg_btn_male.png" height="140" alt="" className="aside-widget__image" />
                                        <h4 className="aside-widget__title">Нужна срочная помощь?</h4>
                                        <div className="aside-widget__text pulse-button-wrap">
                                            <a href="/call/" className="go_to_callpage_btn">
                                                <div class="go_to_callpage_title">Позвоните нам</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>									
                            </div>	
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
export default TarifPage;