import React from 'react';
import { Link } from 'react-router-dom';

import "../global.js";

import * as utils from '../utils.js';

import Pagination from "./elements/Pagination";
import searchStat from "./searchStat";

let headers = new Headers();
headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));

const urlParams = new URLSearchParams(window.location.search);

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchResultList: [],
            count: 0,
            target: "all",
			error: false,
			loader: true,
            isPremium: Boolean(localStorage.getItem('isPremium')?(JSON.parse(localStorage.getItem('isPremium'))):false),
            isAuth: Boolean((localStorage.getItem('tokenAPI'))?true:false),
        }
    }
	                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
    static getDerivedStateFromProps(props, state) {
		const urlParams = new URLSearchParams(window.location.search);
		let target = urlParams.get("target");
		if (target == undefined || target == null) {
			target = "all";
		}		
        let q = urlParams.get("q");
		if (q == undefined || q == null) {
			return {error: true, loader: '', target: target};
		} else {
			return {error: false, target: target};
		}
    }


    createMarkup = (s) => {
        return {__html: s};
    }

    getDataCodexList = async (uri, p) => {
        let api_url = await fetch(uri, p);
        const output = await api_url.json();
        if (output.status !== 200) {
            //alert("Неверные данные авторизации");
            return false;
        }
        return await output;
    }
    onPageChanged = paginationData => {
        //document.querySelector('.search-result__wrapper').innerHTML = global.CssLoader;
        let currentPage = paginationData.currentPage;
        const urlParams = new URLSearchParams(window.location.search);
        let target = this.state.target;
        if(target){target = '&target='+target;}
        let query = urlParams.get('q');
        let url = global.apiLink + '/api/search?q=' + query+'&page='+currentPage+target;
        
        fetch(url, { 
            headers: headers,
         })
            .then(response =>response.json())
            .then((response)=>{
                this.setState({
                    searchResultList: response.payload.list,
                    count: response.payload.count
                });
                //document.querySelector('.search-result__wrapper').innerHTML = "";
            })
    }

    getPageLoad = async (target) => {



		let urlParams = new URLSearchParams(window.location.search);
		
        let query = urlParams.get('q');
        if(query == null){
            alert("Не указан поисковый запрос.");
            return false;
        }
        //document.querySelector('.search-result__wrapper').innerHTML = global.CssLoader;
        document.querySelector("input[name=target]").value = target;
        if(target){ target = '&target='+target; }
		
		
		let page = urlParams.get('page');
		if (page === null) {
		   page = 1;
		}		
		
		
        let url = global.apiLink + '/api/search?q=' + query + '&page='+page+target;
        document.querySelector(".all-search-input").value = query;
        fetch(url, { 
            headers: headers,
         })
            .then(response => response.json())
            .then((response) => {
                //document.querySelector('.search-result__wrapper').innerHTML = "";
                this.setState({ 
                    searchResultList: response.payload.list, 
                    count: response.payload.count, 
                    searched: response.payload.searched,
					loader: false
                });

            });
    }

    componentWillMount() {
        let target = urlParams.get('target');
        if(target){ this.setState({target: target}); }
    }


    componentDidMount() {
        this.getPageLoad(this.state.target);
    }
    

    getComplete = async () => {
        let query = document.querySelector(".all-search-input").value;
        let target = document.querySelector("input[name=target]").value;


        if (query != null) {
            query = utils.escapeAndTrim(query);
        }
        console.log('query='+query);
        if (query.length <= 2) {
			 document.querySelector(".all_search_autocomplete").innerHTML = '';
            return false;
        }
        if (target != null) {
            target = utils.escapeAndTrim(target);
        }
        console.log('target='+target);
        if (target.length) {
           target = '&target=' + target;
        } else {
            target = '';
        }

        let url = global.apiLink + '/api/search/complete?q=' + query + target;

        const api_ResourceLL = await fetch(url, { headers: headers });
        var output = await api_ResourceLL.json();

        if (output.status == 200) {
            let list = output.payload.list;
            let resultComplete = "";
            for (let i=0; i < list.length; i++) {
                let linkTitleTmp = list[i].tags;
                let linkUrlTmp = '/search/?target=' + target + '&q='+linkTitleTmp.split(" ").join("+");
                resultComplete += "<div onclick='window.location.href=\""+linkUrlTmp+"\";'>"+linkTitleTmp+"</div>";
            }
			let curQuery = document.querySelector(".all-search-input").value;
        if (curQuery != null) {
            curQuery = utils.escapeAndTrim(curQuery);
        }
        console.log('curQuery='+curQuery);
        if (curQuery.length > 2) {
            document.querySelector(".all_search_autocomplete").innerHTML = resultComplete;
        } else {
            document.querySelector(".all_search_autocomplete").innerHTML = '';
        } 
			/*if ((document.querySelector(".all-search-input").value).length > 2 && document.querySelector(".all-search-input").value == ) {
                document.querySelector(".all_search_autocomplete").innerHTML = resultComplete;
			}*/
        } else {
            return false;
        }
    }



    getCodexChain = async(pid, tgt) => {
        let url = global.apiLink+"/api/codex/codex/breadcrumb-codex?id="+pid+"&title=1";
        fetch(url, {
                headers:headers
            })
            .then(res=>res.json())
            .then(
                (result)=>{ 
                    if(result.status==200 && result.payload){ 
                        let output = "";
                        let elCount = result.payload.length;
                        let elCounter = 0;
                        result.payload.map( ( element ) => { 
                            if((elCount - 1) > elCounter){ 
                                let arrTmp = Object.values(element);
                                let elText = arrTmp[0].trim().replace(/^&nbsp;(.*)&nbsp;$/g, '$1');
                                output += elText+' ';
                            }
                            elCounter++;
                        } );
                       // document.querySelector("#bcs_"+tgt+"_"+pid).innerHTML = output;
                    }
                }
        );
    }

    getBreadcrumbs = async(pid, tgt) => {
        let url = global.apiLink+"/api/codex/codex/breadcrumb-codex?id="+pid+"&title=1";
        fetch(url, {
                headers:headers
            })
            .then(res=>res.json())
            .then(
                (result)=>{ 
                    if(result.status==200 && result.payload){ 
                        let output = "";
                        let elCount = result.payload.length;
                        let elCounter = 0;
                        result.payload.map( ( element ) => { 
                            if((elCount - 1) > elCounter){ 
                                let arrTmp = Object.values(element);
                                let elText = arrTmp[0].trim().replace(/^&nbsp;(.*)&nbsp;$/g, '$1');
                                output += '<li class="breadcrumb-item">'+
                                    '    <a class="codexTreeFont" href="/codex/'+arrTmp[1]+'">'+
                                    '        '+elText+' '+
                                    '    </a>'+
                                    '</li> ';
                            }
                                elCounter++;
                        } );
                       // document.querySelector("#bcs_"+tgt+"_"+pid).innerHTML = output;
                    }
                }
        );
    }
    checkTargetClass = (target) =>{
        let targetLink = urlParams.get('target');
        if (targetLink == target) {
            return " filter-item-active ";
        }
        return "";
    }

    render() {
        document.getElementById("root").style.background = "#fafafb";
        let self = this;
        const {
            searchResultList, 
            count,
            isAuth
        } = self.state;
		
		let urlParams = new URLSearchParams(window.location.search);
		
        let target = urlParams.get('target');
        if (!target || !utils.elementInArray(target, ['codex', 'situation', 'doc', 'qa'])) {
			target = "all";
		}

        let searchStr = urlParams.get('q');
        if (searchStr!=null) {
            searchStr = utils.escapeAndTrim(searchStr);
        } else {
            searchStr = '';
        }

        return (
            <>
            <div className="page-search page">
                <div className="d-none gt3-page-title gt3-page-title_horiz_align_center gt3-page-title_vert_align_middle"
                     style={{backgroundColor: "#ffffff", height: "80px", color: "#27323"}}>
                    <div className="gt3-page-title__inner">
                        <div className="container">
                            <div className="gt3-page-title__content">
                                <div className="page_title">
                                    <h1>Результаты поиска</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                     <div className="container">
                         <div className="row">
                            <div className="col-md-12">
                                <div className="all-search-wrapper mb-0">
                                    <form className="uk-search" method="GET" action="/search/" style={{display: "block"}}>
                                        <input type="hidden" name="target" value={this.state.target} />
                                        <label htmlFor="search">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                            <span className="sr-only">Поиск</span>
                                        </label>
                                        <input type="search" name="q" onChange={()=>{this.getComplete()}} placeholder="Что делать, если..." autocomplete="off" className="all-search-input" maxLength={255} required="required" />
                                        <button type="submit" className="search_btn">Найти</button>
                                        <div className="all_search_autocomplete"></div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-12 mt-2 mb-3 filters">
                                <a href={"/search/?target=all&q=" + searchStr} className={"filter-item"+this.checkTargetClass("all")}>Все</a> &nbsp;
                                <a href={"/search/?target=codex&q=" + searchStr} className={"filter-item"+this.checkTargetClass("codex")}>Кодексы, законы, судебная практика</a> &nbsp;
                                <a href={"/search/?target=qa&q=" + searchStr} className={"filter-item"+this.checkTargetClass("qa")}>Вопрос-ответ</a> &nbsp;
                                <a href={"/search/?target=doc&q=" + searchStr} className={"filter-item"+this.checkTargetClass("doc")}>Правовые документы</a> &nbsp;
                                {isAuth ? <a href={"/search/?target=situation&q=" + searchStr} className={"filter-item"+this.checkTargetClass("situation")}>Практические рекомендации</a> : <></>}
                            </div>                            
                            {!this.state.error && !this.state.loader && count && 
							
                            <div className="col-md-12 mb-3">
                                <span>По запросу «{searchStr}» найдено страниц {this.state.count}.</span>
                            </div>
							}
                         </div>
                    </div>

                <div className="container search-result__wrapper">
                    {this.state.error  &&
                        <div>Укажите корректный поисковый запрос.</div>
                    }
					
					{this.state.loader && !this.state.error &&
					<div style={{display: "block", marginLeft: "auto", marginRight: "auto", position: "relative"}} dangerouslySetInnerHTML={{ __html: global.CssLoader}}></div>
					}
					
                    {searchResultList  && count > 0 &&
                        <Pagination totalRecords={count} pageLimit={10} onPageChanged={this.onPageChanged}/>
                    }

                        {searchResultList  && searchResultList.map((element) =>{
                            console.log(element.target);
                            let TargetElement = "Кодексы";
                            let TargetElementClass = "text-success";
                            if(element.target == "codex"){
                                //this.getBreadcrumbs(element.elid, element.target);
                            }
                            if(element.target == "situation"){
                               // this.getBreadcrumbs(element.elid, element.target);
                                TargetElement = "Рекомендации";
                                TargetElementClass = "text-warning";
                            }
                            if(element.target == "qa"){
                                //this.getBreadcrumbs(element.elid, element.target);
                                TargetElement = "Вопрос-ответ";
                                TargetElementClass = "text-danger";
                            }
                            if(element.target == "docs"){
                                //this.getBreadcrumbs(element.elid, element.target);
                                TargetElement = "Документы";
                                TargetElementClass = "text-info";
                            }
                            let elText = element.title.trim().replace(/^&nbsp;(.*)&nbsp;$/g, '$1');

                        return (<>
                                <div className='search_item_wrapper'>
                                    <h5 className="search_title">
                                        <i className={"fa fa-file-text "+TargetElementClass}></i>&nbsp;&nbsp;&nbsp;
                                        <a href={element.link}>{TargetElement}: {elText}</a>
                                    </h5>
                                    <ul className="breadcrumb breadcrumb-search" id={"bcs_"+element.target+"_"+element.elid}></ul>
                                    {element.content !=='' &&
                                        <div className="clear post_clear" dangerouslySetInnerHTML={{ __html: element.content}}></div>
                                    }

                                    <hr />
                                </div>
                            </>);
                        }
                    )}


                </div>
            </div>
            </>
        );
    }
}

export default Search;
 