import React from 'react';
import {Link} from 'react-router-dom';
import BreadCrumbs from "../elements/BreadCrumbs";
import PageTitle from "../elements/PageTitle";
import Coedx_folder_tpl from './codex_folder_tpl'
import "../../global.js";
import * as utils from '../../utils.js';

let headers = new Headers();
headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));

class Getasinccodex extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        subcodexTree: {}
      }
    }

    componentDidMount() {
        let self = this;
        if(this.props.parentid === undefined) return false;
        let url = global.apiLink+"api/codex/codex/list?recursive=1&pid="+this.props.parentid+"&exclude=s";
        fetch(url, {
                headers:headers
            })
            .then(res=>res.json())
            .then(
                (result)=>{
                    if(result.status==200 && result.payload){
                        self.setState({
                            subcodexTree: result.payload,
                        });
                    }
                }
        );
    }
    render() {
        const {subcodexTree} = this.state;

    return <ul id={"CodexIdElement_"+this.props.parentid} className="uk-grid CodexIdElement">
        {!subcodexTree.list && global.payload}
                            {subcodexTree.list && Object.keys(subcodexTree.list).map( ( element ) => {
                                if( Number(element) !== 'NaN'){
                                    return (<>
                                        <li className="uk-width-medium-1-1">
                                            <Link className="codexTreeFont" to={"/codex/"+subcodexTree.list[element].base64_uid}>
                                                {subcodexTree.list[element].title}
                                            </Link>
                                            <Getasinccodex parentid={subcodexTree.list[element].id}/>
                                        </li>
                                    </>);
                                }
                            } ) }
        </ul>;
    }
}

export class CodexMainpage extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        dataResourceQuickstart: '',
        resourceid: 0,
        codexTree: {}
      }
    }

   utils = {
		dummy: document.createElement('div'),
		escapeHTML: function(s) {
			return {__html: s};
		}
	}
	


    getCodexes = async() => {
        let self = this;

        let url = global.apiLink+"api/codex/codex/list?recursive=1&pid=0&exclude=s";
        fetch(url, {
                headers:headers
            })
            .then(res=>res.json())
            .then(
                (result)=>{
                    if(result.status==200 && result.payload){
                        self.setState({
                            codexTree: result.payload,
                        });
                    }
                }
        );
    }
    

     Getasinccodex (props) {
    
        return <>
            {props.parentid}
        </>;
    }
  
    parentFunc = (text) => {
        this.setState({dataResourceList: text});
    }

    createMarkup = (s) => {
        return {__html: s};
    }

    componentDidMount() {
        this.getCodexes();
    }
  
    

    getComplete = async () => {

        let query = document.querySelector("form input[name=q]").value;
        let target = document.querySelector("input[name=target]").value;		
		

        if (query != null) {
        	query = utils.escapeAndTrim(query);
        }
        console.log('query=' + query);
        if (query.length <= 2) {
        	document.querySelector(".all_search_autocomplete").innerHTML = '';
        	return false;
        }

        if (target != null) {
        	target = utils.escapeAndTrim(target);
        }
        if (!target || !utils.elementInArray(target, ['codex'])) {
        	target = "codex";
        }
		
        console.log('target=' + target);
       	target = '&target=' + target;

        let url = global.apiLink + '/api/search/complete?q=' + query + target;		
		
        const api_ResourceLL = await
            fetch(url, {
                headers: headers
            });

        var output = await api_ResourceLL.json();

        if (output.status == 200) {
			
        	let list = output.payload.list;
        	let resultComplete = "";
        	for (let i = 0; i < list.length; i++) {
        		let linkTitleTmp = list[i].tags;
        		let linkUrlTmp = '/search/?target=' + target + '&q=' + linkTitleTmp.split(" ").join("+");
        		resultComplete += "<div onclick='window.location.href=\"" + linkUrlTmp + "\";'>" + linkTitleTmp + "</div>";
        	}

        	let curQuery = document.querySelector("form input[name=q]").value;
        	if (curQuery != null) {
        		curQuery = utils.escapeAndTrim(curQuery);
        	}
        	console.log('curQuery=' + curQuery);
        	if (curQuery.length > 2) {
        		document.querySelector(".all_search_autocomplete").innerHTML = resultComplete;
        	} else {
        		document.querySelector(".all_search_autocomplete").innerHTML = '';
        	}			
	
        } else {
            return false;
        }
    }
	
	
    render(){
        const {codexTree} = this.state;
		
		document.getElementById("root").style.background = "#fafafb";
		const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Сервисы',
                path: '/services/',
            },			
            {
                name: 'Кодексы, законы, судебная практика',
                path: '',				
            },
        ];		
		
        return(
            <>
            <div className="codex-page page">
                <div className="container">

                <div className="row">
				    <BreadCrumbs crumbs={breadcrumbs} />
					<PageTitle pageTitle="Кодексы, законы, судебная практика" />
                </div>
                    <div className="row">
                                <div className="col-md-12">
								    <div className="all-search-wrapper">
                                        <form className="uk-search" method="GET" action="/search/" style={{display: "block"}}>
                                            <input type="hidden" name="target" value="codex"/>
                                            <label htmlFor="search">
                                                <i className="fa fa-search" aria-hidden="true"></i>
                                                <span className="sr-only">Поиск</span>
                                            </label>
                                            <input type="search" name="q" onChange={() => {
                                                this.getComplete()
                                            }} placeholder="Что делать, если..." autocomplete="off" className="all-search-input" minLength={3} maxLength={255} required="required" />
                                            <button type="submit" className="search_btn">Найти</button>
                                            <div className="all_search_autocomplete"></div>
                                        </form>
                                    </div>
                                </div>
						</div>	
<div className="row">						
                        <div className="col-md-12 mb-3">

<p>В данном разделе реализована возможность просмотра информации из кодексов, а также сопутствующих разделов (ситуации, вопрос-ответы). Когда пользователь заходит в данную вкладку, то он видит быстрый старт: 9
популярных кодексов. Внизу есть кнопка «раскрыть», которая показывает все кодексы. Приложение выводит на экран главы, части, разделы, подразделы, параграфы и статьи кодекса, справочную информацию,
содержащую данные документа (действующий или утратил силу, дата вступления в силу, последняя редакция и т.д.), а также аннотацию, которая позволит Вам ознакомиться с кратким содержанием документа.</p>
<p>При нажатии на статью происходит переход на просмотр ее содержания. Помимо этого, выводится информация о самой статье, рассматриваются ситуации, регулируемые этой статьей или попадающие под ее
действие, а так же вопросы-ответы, касающиеся разрешения этих ситуаций.</p>
                        </div>
						</div>
						<div className="row">
                                <div id="ResourceListC" className="grid_container grid_container-situations row">
                                    {codexTree.list && Object.keys(codexTree.list).map((element) => {
                                        if( !isNaN(Number(element))){
											codexTree.list[element].base64_uid = (codexTree.list[element].base64_uid).length < 2 ? btoa(codexTree.list[element].id) : codexTree.list[element].base64_uid;
                                            return (
                                                <Coedx_folder_tpl id={codexTree.list[element].id} name={codexTree.list[element].title} url={"/codex/"+codexTree.list[element].base64_uid} />
											);
                                        }
                                    } ) }
                                </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

export default CodexMainpage;