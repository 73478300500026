import React from "react";
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import "../../global.js";
import './subjectselement.css';

class SubjectsElement extends React.Component {
    constructor(props) {
        super(props);
        try {
            let schedule = JSON.parse(props.schedule);
            this.state = {schedule: schedule};
        } catch (e) {
            this.state = {schedule: props.schedule};
        }
    }

    render() {
        let phones = this.props.contacts.map((contact) => {
            let isEmail = (contact.data).match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i);
            if (isEmail) {
                return (
                    <>
                        <a href={`mailto:${contact.data}`} key={contact.id}>{contact.data}</a><br/>
                    </>
                );
            } else {
                return (
                    <>
                        <a href={`tel:${contact.data}`} key={contact.id}>{contact.data}</a><br/>
                    </>);
            }
        })
        let scheduleList = null;

        let scheduleDays = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];
        if (Array.isArray(this.state.schedule)) {
            scheduleList = this.state.schedule.map((element, index) => {
                    if (element[0] && element[1]) {
                        return (
                            <>
                                <div className="widget edgtf-opening-hours-widget">
                                    <div className="edgtf-opening-hours-holder">
                                        <div className="edgtf-opening-hours-holder-inner">
                                            <div className="edgtf-opening-hours-day-holder">
                                                <div className="edgtf-opening-hours-day">{scheduleDays[index]}</div>
                                            </div>
                                            <div className="edgtf-opening-hours-line"></div>
                                            <div className="edgtf-opening-hours-time">{element[0]} - {element[1]}</div>
                                        </div>
                                    </div>
                                </div>
                            </>);
                    } else {
                        return (
                            <>
                                <div className="widget edgtf-opening-hours-widget">
                                    <div className="edgtf-opening-hours-holder">
                                        <div className="edgtf-opening-hours-holder-inner">
                                            <div className="edgtf-opening-hours-day-holder">
                                                <div className="edgtf-opening-hours-day">{scheduleDays[index]}</div>
                                            </div>
                                            <div className="edgtf-opening-hours-line"></div>
                                            <div className="edgtf-opening-hours-time">выходной</div>
                                        </div>
                                    </div>
                                </div>
                            </>);
                    }

                }
            );
        }

        let typeId = this.props.type;
        if (typeId == null) {
            typeId = 1;
        }
        return (
            <>
                <div className="row" id={"sectionid_" + this.props.sectionid}>
                    <div className="col-md-12">
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="card-title">
                                    <img src={`/assets/images/regions/i${typeId}.svg`} />
                                    <h4>
                                        <span>{this.props.name}</span>
                                    </h4>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="widget edgtf-footer-column-1 widget_text">
                                            <h5 className="edgtf-widget-title">Контакты:</h5>
                                        </div>
                                        <div className="textwidget"><p>{phones}</p></div>
                                        <div className="widget edgtf-footer-column-1 widget_text">
                                            <h5 className="edgtf-widget-title">Адрес:</h5>
                                        </div>
                                        <div className="textwidget"><p>{this.props.str_address}</p></div>
                                    </div>
                                    <div className="col-md-6">

                                        <div className="widget edgtf-footer-column-1 widget_text">
                                            <h5 className="edgtf-widget-title">Время работы:</h5>
                                        </div>


                                        {scheduleList}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default SubjectsElement;