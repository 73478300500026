import React from "react";
import './user.css';
import $ from 'jquery';
import InputMask from "react-input-mask";
import { isMobile } from 'react-device-detect';
import LeftMenu_on_LK from '../../layouts/personal-cab/LeftMenu_on_LK';
import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";
import {FormErrors} from "../FormErrors";
import {FormMsg} from "../FormMsg";

let token = localStorage.getItem('tokenAPI');
let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));

class Profile extends React.Component {
    constructor(props) {
        super(props);
        if (!token) {
			localStorage.clear();
            window.location.href = '/login';
        } else {
            if (isMobile) {
                const MOBILE_URL = process.env.NODE_ENV === 'development' ?
                "http://localhost:3000" : "https://m.my-advo.cat";

                window.location.replace(MOBILE_URL+'/auth/'+token);
            }
        }
        this.state = {
            name: '',
            login: '',
            email: '',
            birthday:null,
            address:'',
            phones: '', 
            avatar_image:null,
            socialList:[{id:0,name:''}],
            socialLinks: [{id:0,link:'',social_id:0}],
            loading: true,
            file: false,
            imagePreviewUrl: '',
            premium_user: false,
            advocat_user: false,
            sand_time: false,
		    formResponceErrors: {error: ''},
		    formResponceMsg: {msg: ''},	
            errors: {name: '', phones: '' },			
        };
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    createMarkup = (s) => {
        return {__html: s};
    }
/*
    handleChangePhones(i, event) {
        let phones = [...this.state.phones];
        phones[i].data = event.target.value;
        console.log(event.target.value);
        this.setState({phones: phones});
    }
    addPhoneInput() {
        this.setState(prevState => ({phones: [...prevState.phones, {id: 0, name: ''}]}));
    }*/

    addSocialInput() {
        this.setState(prevState => {
            const socialLinksList = prevState.socialLinks;

            let newLink = { 
                id: 0,
                socialLinks: '',
                link: '',
            };

            if (socialLinksList.length === 0) return {
                socialLinks: [...prevState.socialLinks, newLink]
            };

            const lastItem = socialLinksList[socialLinksList.length - 1];

            if (lastItem?.link?.trim().length === 0 || 
                lastItem?.link === null) return prevState;
            if (!lastItem?.social_id || 
                lastItem?.social_id === null) return prevState;

            return {
                socialLinks: [...prevState.socialLinks, newLink]
            };
        });
    }

    handleChangeLink(i,event){
        let socialLinks = [...this.state.socialLinks];
        socialLinks[i].link= event.target.value;
        this.setState({socialLinks:socialLinks});
    }
    handleChangeSocialId(i,event){
        let socialLinks = [...this.state.socialLinks];
        socialLinks[i].social_id = event.target.value;
        this.setState({socialLinks:socialLinks});
    }
    deleteContact(i) {
        let phones = this.state.phones;
        delete phones[i];
        phones = phones.slice(0, i).concat(phones.slice(i + 1))
        this.setState({phones: phones});
    }
    deleteSocSet(i) {
        let socialLinks = this.state.socialLinks;
        delete socialLinks[i];
        socialLinks = socialLinks.slice(0, i).concat(socialLinks.slice(i + 1))
        this.setState({socialLinks: socialLinks});
    }
    
    handleChangeFile(e){
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(file);
    }

    componentDidMount() {
        let url = global.apiLink + '/api/user/get-profile';
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
		
        fetch(url, {
            method: 'GET',
            headers: headers
        })
		.then(response => response.json())
		.then((response) => {
			console.log(response);
			if (response.status !== 200) {
				localStorage.clear();
				window.location.href = '/login';
			}

			let socialLinks = [{id:0,link:'', social_id:0}];
			if(response.payload.socialLinks){
				if(response.payload.socialLinks.length>0){
					socialLinks = response.payload.socialLinks;
				}
			}

			console.log(response.payload);
			this.setState({
				name: response.payload.name,
				login: response.payload.login,
				email: response.payload.email,
				gender:response.payload.gender,
				birthday:response.payload.birthday,
				address:response.payload.address,
				phones: response.payload.phones,
				sand_time: response.payload.sand_time,
				avatar_image: response.payload.avatar_image,
				loading: false
			});

			let url = global.apiLink + '/api/user/this-user-role-list';
			let headers = new Headers();
			headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
			fetch(url, {
				method: 'GET',
				headers: headers
			})
			.then(response => response.json())
			.then((response) => {
				response.payload.list.forEach((i)=>{
					if(i.id_role == 7){
						this.setState({premium_user:true});
					}
					if(i.id_role == 8){
						this.setState({advocat_user:true});
					}
				});
			});

		}).catch((response)=>{
			let errorStr = "<br />Внимание! При обновлении профиля возникли ошибки."
				+ "<br />" + response.payload;						
			this.setState({formResponceErrors:{error: errorStr}});				
		});
    }
	
/*	
	
const filterObject = (obj, allowedFields, mapFields = []) => {
    let newObj = {};

    Object.keys(obj).forEach((key) => {
        if (allowedFields.includes(key)) {
            newObj[key] = obj[key];
        }
        if (mapFields.includes(key)) {
            obj[key].forEach((value, i) => {
                Object.keys(value).forEach((valueKey) => {
                    newObj[`${key}[${i}][${valueKey}]`] = value[valueKey];
                });
            })
        }
    });
    return newObj;
}	
*/	
	
    submitForm(e){
        e.preventDefault();
		this.setState({formResponceMsg:{msg: ''}});
        this.setState({formResponceErrors:{error: ''}});
		
		/*let formData = new FormData(e.target);
		
            const allowedFields = [
                'login',
                'email',
                'name',
                'gender',
                'birthday',
                'address',
                'avatar_image',
				'phones',
            ];
            const mapFields = [
                //'phones',
                'links',
            ];
		*/	
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
         /*   
			const filteredObj = this.filterObject(formData, allowedFields, mapFields);		
		
		*/
		
        let formData = new FormData(e.target);
		console.log(formData);
		
		formData.append("login", this.state.email);
		
		let changePassword = false;
		
		console.log('old=' + formData.get("old-password"));
		console.log('new=' + formData.get("password"));
		console.log('new2=' + formData.get("repeat-password"));
		if (formData.get("old-password") !== undefined && formData.get("old-password").length > 5) {
			if (formData.get("password") !== undefined && formData.get("password").length > 5
			&& formData.get("repeat-password") !== undefined && formData.get("repeat-password").length > 5 
			&& formData.get("repeat-password") == formData.get("password") && formData.get("password") !== formData.get("old-password")
			) {
				changePassword = true;
			}
		}
		console.log( 'changePassword='+ changePassword);
        if (!changePassword) {
			formData.delete("password");
			formData.delete("repeat-password");
			formData.delete("old-password");
		}
		
		
        if (this.state.imagePreviewUrl) {
			formData.append('avatar_image', this.state.file, 'avatar.jpg');
		}
		
		this.setState({errors:{phones: '', name: ''}});
		let errors = {};
		errors.name = '';
		let name = formData.get("name");
        if (name !== undefined && name.length) {
			name = (name).replace(/(<([^>]+)>)/gi, '');
			name = name.replace(/\s{2,}/gi, ' ');

            if (!name.length) {
                errors.name = 'Указано недопустимое ФИО.';
            } else {
				formData.set("name", name);
            }				
        } else {
            errors.name = 'Поле ФИО обязательно для заполнения.';		
        }



		errors.phones = '';
		let phones = formData.get("phones");
        if (phones !== undefined && (phones).length) {
			const regExpr = /[^\d\+]/g;
			phones = phones.replace(regExpr, '');
			
            var regPhoneExpr = /^\+7[0-9]{10}$/;
            if (!phones.match(regPhoneExpr)) {
				errors.phones = "Указан некорректный номер телефона.";
            } else {
				
				formData.set("phones", phones);
            }				
        }
		
		this.setState({errors:{phones: errors.phones || '', name: errors.name || ''}});		
		
		
		
		
		if (Object.values(errors).join('').length === 0) {
		
		let url = global.apiLink + '/api/user/edit-profile';
        fetch(url,
		    {
                method:'POST',
                body:formData,
                headers:headers
            })
            .then(response => response.json())
            .then((response)=>{
                console.log(response);
                if (response.status == 200) {
					
                   	let successStr = "<br/>Профиль успешно обновлен.";						
                    this.setState({formResponceMsg:{msg: successStr}});					
                    
                } else {
					
					let errorStr = "<br />Внимание! При обновлении профиля возникли ошибки."
						+ "<br />" + response.payload;						
                    this.setState({formResponceErrors:{error: errorStr}});						
                }
            });
		} else {
			let errorStr = "<br />Внимание! При обновлении профиля возникли ошибки.";
            this.setState({formResponceErrors:{error: errorStr}});				
		}
		return false;
    }

    PREloader = <div dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)}></div>
	
	
	

    render() {
        document.getElementById("root").style.background = "#fff";
		
        let {sand_time, avatar_image,imagePreviewUrl,premium_user,advocat_user} = this.state;

		
        let imagePreview = null;
        if (imagePreviewUrl) {
            imagePreview = (<div className="avatarimagecontainer_profilepage img-thumbnail shadow-sm" style={{backgroundImage: "url("+imagePreviewUrl+")",backgroundRepeat: "no-repeat",backgroundSize: "cover",backgroundPosition:"center",borderRadius: "10px",position: "relative"}} alt="">{(sand_time > 0 && sand_time != null ) && <div className="count_of_premium_days" dangerouslySetInnerHTML={this.createMarkup('<span class="badge bg-secondary">Триал ' + Math.floor(sand_time / (60 * 60 * 24)) + " дней</span>")}/>}</div>);
        } else {
            //let defaaultimage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP88eN7PQAJPwNoN++SxwAAAABJRU5ErkJggg==";
            let defaaultimage = "/assets/images/no-avatar-300x300.png";
            if (avatar_image) defaaultimage = avatar_image;
            console.log(defaaultimage);
            imagePreview = (<div className="avatarimagecontainer_profilepage img-thumbnail shadow-sm" style={{backgroundImage:"url("+defaaultimage+")",backgroundRepeat: "no-repeat",backgroundSize: "cover",backgroundPosition:"center", borderRadius: "10px",position: "relative"}} alt="" >{sand_time  > 0 && sand_time != null && <div className="count_of_premium_days" dangerouslySetInnerHTML={this.createMarkup('<span class="badge bg-secondary">Триал ' + Math.floor(sand_time / (60 * 60 * 24)) + " дней</span>")}/>}</div>);
        }

        console.log(imagePreview);
        console.log(avatar_image);
		
        const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Мой кабинет',
                path: '',				
            },
        ];		
		
        return (
            <>
                <div className="codex-page page">
                    <div className="container ">
                        <div className="row">
						    <BreadCrumbs crumbs={breadcrumbs} />
							<PageTitle pageTitle="Профиль пользователя" />
                        </div>

                        <div className="row">
                            <div className="col-md-2 mb-3">
                                <LeftMenu_on_LK />
                            </div>
                            <div className="col-md-10">
                                {this.state.loading && <div className="row"><div className="col-md-12 text-center">{this.PREloader}</div></div> }
                                {!this.state.loading && <>
                                    <div>
								
                                        <form onSubmit={this.submitForm} autoComplete="off" noValidate className="row">
                                        <div className="col-lg-3">
                                            <div className="card shadow-sm">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12 text-center">
                                                            <div className="circle-wrapper">
                                                                {imagePreview}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 form-group mt-3 text-center">
                                                        <div className="user_account_type">
                                                                {(premium_user===true) && <>
                                                                    <i 
                                                                        class="user_account_type_gray premium_user fa fa-diamond" 
                                                                        style={{
                                                                            width: "50px",
                                                                            position: "absolute",
                                                                            top: "37px",
                                                                            right: "15px",
                                                                            fontSize: "25px",
                                                                            color:"lightgoldenrodyellow"
                                                                        }}
                                                                        aria-hidden="true" 
                                                                        title={sand_time && ("Триал " + Math.floor(sand_time / (60 * 60 * 24)) + " дней")}></i>
                                                                    {/**}<img className="user_account_type_gray premium_user" title={sand_time && ("Триал " + Math.floor(sand_time / (60 * 60 * 24)) + " дней")} src="/assets/images/imperial-crown-of-russia.png" />{/**/}
                                                                </>}
                                                                {(advocat_user===true) && <>
                                                                    <i 
                                                                    class="user_account_type_gray advocat_user fa fa-balance-scale" 
                                                                    style={{
                                                                        width: "50px",
                                                                        position: "absolute",
                                                                        top: "80px",
                                                                        right: "15px",
                                                                        fontSize: "25px",
                                                                        color:"lightgoldenrodyellow"}}
                                                                    title="Пользователь с расширеными полномочиями"
                                                                    aria-hidden="true"></i>
                                                                    {/**}<img className="user_account_type_gray advocat_user" title="Адвокат" src="/assets/images/znachok-advokata.png" />{/**/}
                                                                </>}
                                                                {/*(premium_user===false) && <>
                                                                    <img title="" className="user_account_type_gray" src="/assets/images/imperial-crown-of-russia.png" />
                                                                </>/**/}
                                                            </div>
                                                            <fieldset>
                                                                    <div className="avatar-div">
                                                                        <input type="file" name={"avatar"} 
																		    accept="image/*"
                                                                            style={{display:"none"}} className="form-control avatar-input"
                                                                            onChange={this.handleChangeFile.bind(this)}/>
                                                                    </div>
                                                                <button type="button" 
                                                                    onClick={()=>{document.querySelector("input[name=avatar]").click();}} 
                                                                    style={{height: "37px", width: "auto", padding: "0 30px"}} 
                                                                    className="btn btn-biryza">
                                                                    Загрузить фото
                                                                </button>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            <div className="col-lg-9">
                                                <div className="card shadow-sm">
                                                    <div className="card-body">
                                                        <h4 style={{marginBottom: "25px"}}>Общая информация</h4>
                                                        <div className="form-group row">
                                                            <label htmlFor="email" className="col-md-3 col-form-label text-left">Почта <span className="form-required">*</span></label>
                                                            <div className="col-md-9">
                                                                <input 
                                                                    type="email"
                                                                    inputMode="email"
                                                                    className="form-control" 
                                                                    id="email" 
                                                                    placeholder="E-mail"
                                                                    name='email'
																	disabled="true"
                                                                    autoComplete="off"
                                                                    value={this.state.email === undefined ? ''  : this.state.email}
                                                                    onChange={e=> this.setState({email:e.target.value})}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label htmlFor="name" className="col-md-3 col-form-label text-left">ФИО <span className="form-required">*</span></label>
                                                            <div className="col-md-9">
                                                                <input 
                                                                    type="text" 
																    className={`form-control ${this.state.errors.name && 'form-line-error'}`}
                                                                    id="name" 
                                                                    placeholder="ФИО"
                                                                    name="name"
																	required="required"
                                                                    autoComplete="off"
                                                                    value={this.state.name || ''}
                                                                    onChange={e=> this.setState({name:e.target.value})}
                                                                />
                                                            </div>
                                                        </div>
														<div className="form-group row">
                                                            <label htmlFor="phones" className="col-md-3 col-form-label text-left">Номер телефона</label>
                                                            <div className="col-md-9">
                                                                <input 
                                                                    type="tel" 
                                                                    inputMode="tel"
																    className={`form-control ${this.state.errors['phones'] && 'form-line-error'}`}
                                                                    id="phones" 
                                                                    placeholder="+7__________"
                                                                    autoComplete="off"
                                                                    name="phones"
                                                                    value={this.state.phones || ''}
                                                                    onChange={e => this.setState({phones: e.target.value})}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label htmlFor="gender" className="col-md-3 col-form-label text-left">Пол</label>
                                                            <div className="col-md-9">
                                                                <select className="form-control"
                                                                    id="gender"
                                                                    name="gender"
                                                                    value={this.state.gender}
                                                                    onChange={e => this.setState({gender:e.target.value})} >
                                                                    <option value="">не выбрано</option>
                                                                    <option value="M">мужской</option>
                                                                    <option value="F">женский</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label htmlFor="birthday" className="col-md-3 col-form-label text-left">Дата рождения</label>
                                                            <div className="col-md-9">
                                                                <input type="date" 
																    className="form-control" 
                                                                    id="birthday" 
                                                                    placeholder="Дата рождения"
                                                                    name="birthday"
																	min="1940-01-01"
																	max="2015-01-01"
                                                                    autoComplete="off"
                                                                    value={this.state.birthday || ''}
                                                                    onChange={e=> this.setState({birthday:e.target.value})}
                                                                />
                                                            </div>
                                                        </div>
														
                                                        <div className="form-group row">
                                                            <label htmlFor="address" className="col-md-3 col-form-label text-left">Адрес проживания</label>
                                                            <div className="col-md-9">
                                                                <input type="text" 
																    className="form-control" 
                                                                    id="address" 
                                                                    placeholder="Адрес"
                                                                    autoComplete="off"
                                                                    readOnly
                                                                    onFocus={(e)=>{e.target.removeAttribute('readonly')}}
                                                                    value={this.state.address || ''}
                                                                    onChange={e=> this.setState({address: e.target.value})}
                                                                />
                                                            </div>
                                                        </div>														
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 mt-3">
                                                <div className="card shadow-sm">
                                                    <div className="card-body">
                                                        <h4 style={{marginBottom: "25px"}}>Изменить пароль</h4>
                                                        <div className="form-group row">
                                                            <label htmlFor="old-password" className="col-md-3 col-form-label text-left">Текущий пароль <span className="form-required">*</span></label>
                                                            <div className="col-md-9">
                                                            <input type="password" 
															    className="form-control"
                                                                id="old-password" 
																name="old-password" 
                                                                autoComplete="new-password"
																placeholder="Текущий пароль" autocomplete="current-password"
                                                            />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label htmlFor="password" className="col-md-3 col-form-label text-left">Новый пароль <span className="form-required">*</span></label>
                                                            <div className="col-md-9">
                                                                <input type="password" className="form-control"
                                                                   id="password" name="password" placeholder="Новый пароль"
                                                                   autoComplete="new-password"
                                                                   onChange={e => this.setState({password: e.target.value})}
                                                            />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label htmlFor="repeat-password" className="col-md-3 col-form-label text-left">Подтвердить пароль <span className="form-required">*</span></label>
                                                            <div className="col-md-9">
                                                                <input type="password" className="form-control"
                                                                   id="repeat-password" name="repeat-password"
                                                                   autoComplete="new-password"
                                                                   placeholder="Подтвердить пароль"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mt-3">
                                                <div className="card shadow-sm">
                                                    <div className="card-body">

                                                        <div className="col-md-12 btn-pluss">
                                                            <button type="submit" style={{height: "37px", width: "auto", padding: "0 30px"}} className="btn btn-biryza">
                                                                Сохранить
                                                            </button>
                                                            {/*}<a href="/logout/" className="btn btn-warning btn-sm" style={{marginLeft:"10px",float:"right"}}>
                                                                Выйти
                                                            </a>{/**/}
                                                        </div>
														
                                                        <div className="panel panel-default" id="form_result">
                                                            <FormErrors formErrors={this.state.formResponceErrors} />
										                    <FormMsg formMsg={this.state.formResponceMsg} />
                                                        </div>														
														
                                                    </div>
                                                </div>
											
                                            </div>
											
                                        </form>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Profile;