import React from "react";
import {Link} from 'react-router-dom';
import LeftMenu_on_LK from '../../../layouts/personal-cab/LeftMenu_on_LK';

let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));

class YooKassaPage extends React.Component{

    constructor(props) {
        super(props);
        this.state  = {
            pay_form: false,
            //payment_list: [],
            tarif_list: []
        };
        
        //this.payments_list = this.payments_list.bind(this);
    }

    


    /*payment_ = (e) => {
        if(!document.getElementById("payment-form-init")){
            e.target.innerHTML = 'Продолжить <i class="fa fa-arrow-right" aria-hidden="true"></i>';
            const script = document.createElement("script");
            script.id = "payment-form-init";
            script.innerHTML = "checkout.render('payment-form');";
            document.body.appendChild(script);
        }
    }/**/

    tarifs_list = () => {
        let self = this;
        let url = "https://api.my-advo.cat/api/prices/list";
        fetch(url, { 
            method:'GET',
            headers:headers,
        })
            .then(response=>response.json())
            .then((json)=>{
                if(json.payload){
                    self.setState({
                        tarif_list: json.payload.list
                    });
                }
            });
    }/**/


    createMarkup = (s) => {
        return {__html: s};
    }

    /*payments_list = (e) => {
        let self = this;
        let url = "https://api.my-advo.cat/api/pay/yookassa-pay/get";
        fetch(url, { 
            method:'GET',
            headers:headers,
        })
            .then(response=>response.json())
            .then((json)=>{
                if(json.payload){
                    self.setState({
                        payment_list: json.payload.list
                    });
                }
            });
    }/**/
     payment_form_init  = () => {
        if(!document.getElementById("payment-form-init")){
            const script1 = document.createElement("script");
            script1.src = "https://yookassa.ru/checkout-widget/v1/checkout-widget.js";
            script1.async = true;
            document.body.appendChild(script1);
            let price_id = document.querySelector("input[name='price_sale[]']:checked").value;
            if(price_id === undefined){
                alert("Не выбран тариф");
                return false;
            }
            let url = "https://api.my-advo.cat/api/pay/yookassa-pay/yook-create-payment";
            fetch(url, { 
                method:'POST',
                headers:headers,
                body: JSON.stringify({price_id:price_id})
            })
                .then(response=>response.json())
                .then((json)=>{
                    if(json.payload){
                        console.log(json.payload);
                        document.getElementById("payment-form").innerHTML = "";
                        let paymentText = "<div class='yook_pay_informer'>"+
                        "<b>Оплата премиум</b><br />На "+json.payload.tarif_name+
                            "<br />за "+json.payload.how_much+" руб";
                        document.getElementById("payment_id").innerHTML = paymentText;
                        const script = document.createElement("script");
                        script.id = "payment-form-init";
                        script.innerHTML = "const checkout = new window.YooMoneyCheckoutWidget({"+"\n"+
                        "    confirmation_token: '"+json.payload.confirmation_token+"',"+"\n"+
                        "    return_url: 'https://my-advo.cat/pay_yook/',"+"\n"+
                        "    error_callback: function(error) {"+"\n"+
                        "        console.log(error)"+"\n"+
                        "    }"+"\n"+
                        "});"+"\n"+
                        "checkout.render('payment-form');";
                        document.body.appendChild(script);
                        //self.setState({ script: script });
                    }
                });
            //e.target.innerHTML = 'Продолжить <i class="fa fa-arrow-right" aria-hidden="true"></i>';
        }
    }

    checkForAuth = () => {
        
        let url = global.apiLink + '/api/user/get-profile';
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
		
        fetch(url, {
            method: 'GET',
            headers: headers
        })
		.then(response => response.json())
		.then((response) => {
			console.log(response);
			if (response.status !== 200) {
				localStorage.clear();
				window.location.href = '/login';
			}
        });
    }

    componentDidMount() {
        let self = this;
        self.checkForAuth();
        const queryParams = new URLSearchParams(window.location.search);
        const price_id = queryParams.get("price_id");
        if( price_id !== null ){
            self.setState({pay_form:true});
            self.tarifs_list();
            setTimeout(()=>{
                document.getElementById("price_sale_"+price_id).click();
            }, 1500);
        }else{
            self.tarifs_list();
        }
    }

    render() { 
        let self = this;
        let {script, pay_form, tarif_list} = self.state;


        let shadowButtons = "0px 5px 5px -2px #000";
 
        let humen_level1 = {
            position: "relative",
            display: "block",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            boxShadow: shadowButtons,
            marginBottom: "15px",
            background: "#fff",//"no-repeat center/cover url(/assets/images/humen_levels/1.png)",
            height: "365px", 
            borderRadius: "5px",
            boxShadow: "0 0 11px 0 rgb(0 0 0 / 27%)"
        };

        let humen_level_title = {
            color: "#fff",
            fontSize: "18px",
            lineHeight: "21px",
            textAlign: "right",
            padding: "8px 5px",
            background: "#00baff",
            position: "absolute",
            bottom: "50px",
            right: "0"
        }

        return (
            <>
                <div className="page-docs pay_page page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 quick-start-title-wrapper title-inner">
                                <div className="uk-h1 quick-start-header-title">Оплата премиум</div>
                                <div className="all-hr all-hr-2 color-lightblue"></div>
                            </div>
                            <div className="col-md-2 mb-3">
                                <LeftMenu_on_LK />
                            </div>
                            <div className={"col-md-10"+(pay_form?" d-none":"")}>
                                <div className="row">
                                    <div className="col-lg-4 d-none">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="display-6">Что дает абонемент?</div>

                                                <ul>
                                                    <li>
                                                        Доступ ко всему контенту
                                                    </li>
                                                    <li>
                                                        Возможность скачивать видео
                                                    </li>
                                                    <li>
                                                        Безлимитный просмотр
                                                    </li>
                                                    <li>
                                                        Без абонемента просмотр ограничен 30 минутами в сутки
                                                    </li>
                                                </ul>
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            {!tarif_list && <div className="col-md-12" dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)} /> }
                                
                                            {tarif_list && Object.keys(tarif_list).map((item)=>{
                                                return(
                                                    <>
                                                        <div className="col-md-3">
                                                            <label for={"price_sale_" + tarif_list[item].id} className="situation_money grow" style={humen_level1}>
                                                                <input className="form-check-input d-none mt-0" id={"price_sale_" + tarif_list[item].id} type="radio" onChange={()=>{this.yook_create_payment_btn.click()}} name="price_sale[]" value={tarif_list[item].id} aria-label={tarif_list[item].name} />
                                                                <div className="situation_title d-none" style={humen_level_title}>{tarif_list[item].name}</div>
                                                                <div className="card" style={{height: "100%"}}>
                                                                    <div className="card-body">
                                                                        <div className="price_sale_name">
                                                                            {tarif_list[item].name}
                                                                        </div>
                                                                        <div className="price_sale_price">
                                                                            {tarif_list[item].price} руб
                                                                        </div>
                                                                        <div className="price_sale_description">
                                                                            {tarif_list[item].description}
                                                                        </div>
                                                                        <div className="btn btn-biryza">Оплатить</div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </div>
                                        <div className="col-md-12 mb-3 d-none">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <div className="btn btn-biryza yook-create-payment" id="yook-create-payment" ref={(yook_create_payment) => (this.yook_create_payment_btn = yook_create_payment)} onClick={(e)=>{self.setState({pay_form:true});self.payment_form_init();}}>
                                                    <i class="fa fa-plus" aria-hidden="true"></i> Создать счёт
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-10 pay-form"+(pay_form?"":" d-none")}>
                                <div className="card shadow-sm">
                                    <div id="payment_id"></div>
                                    <div className="card-body" style={{minHeight:"300px"}}>
                                        <div id="payment-form" className="text-center"><div dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)} /></div>
                                        <div className="payment-form-yook-close text-centar w-100">
                                            <Link className="btn btn-biryza yook-close-payment-form" onClick={() => {window.location.href = "/yookassapay/";}} to={"/yookassapay/"}>
                                                <i className="fa fa-undo float-start" aria-hidden="true"></i>
                                                <div className="float-start">
                                                    <div className="go_back_to_select_tarif">Вернуться к выбору тарифа</div>
                                                </div><br className="clearfix" />
                                            </Link>
                                        </div>
                                        <script type="javascript">
                                            {script && script}
                                        </script>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
export default YooKassaPage;