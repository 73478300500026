import React from 'react';
import {Link} from 'react-router-dom';

import "../../global.js";

class LeftMenu_on_LK extends React.Component{

    

    render() {
        let Menu = [
            {title: "Профиль",   link: "/lk/",        faicon: "fa fa-user"},
            {title: "Оплата",    link: "/tarifs/",    faicon: "fa fa-credit-card-alt"},
            {title: "Счета",     link: "/payments/",  faicon: "fa fa-list"},
            {title: "Выход",     link: "/logout/",    faicon: "fa fa-sign-out"},
        ];
        return (
            <>
                <div className="btn-toolbar mb-3" role="toolbar" aria-label="Панель меню личного кабинета">
                    <div className="me-2 w-100" role="group" aria-label="Основное">
                        {Menu && Menu.map((item)=>{
                           return (
                            <>
                                <Link to={item.link} className="profile_left_menu_item btn btn-biryza w-100 text-star" ><i className={item.faicon} aria-hidden="true"></i> {item.title}</Link>
                            </>
                           ); 
                        })}
                    </div>
                </div>
            </>
        );
    }
}
export default LeftMenu_on_LK;