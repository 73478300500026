import React from 'react';

export class GridSituationInnerCard extends React.Component {




    truncate = (str, n) => {
        return (str.length > n) ? str.substr(0, n-1) + '...' : str;
      };


    render() {
		let base64_link = btoa(this.props.codex_id);
		let url = '/situation/'+ base64_link;
		
        return (
            <>
	    <div className="grid_container_situations-item">
		    <a href={url} title={this.props.name}>
		        <span className="card-body__box">
			        <h4 className="card-body__title">{this.props.name}</h4>
			        <p className="card-body__description">{this.truncate(this.props.title, 200)}</p>
			    </span>	
			    <span className="card-body__link_open">Открыть документ</span>
			    <img className="card-body__icon-info" src="/assets/images/icons-info.svg" />
			</a>
		</div>
            </>
        );
    }
}
export default GridSituationInnerCard;