import React from 'react';

export class SituationRightPanel extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            dataResourceQa: null
        }
    }

    componentDidMount() {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let uriget = global.apiLink + `api/qa/qa/list?codex_id=${this.props.resourceId}&getcontent=1`;
        fetch(uriget, {
            headers: headers
        })
        .then(res=>res.json())
        .then((json)=>{
            if (json.status !== 200) {
                alert("Не верные данные авторизации tv");
                return false;
            }
            this.setState({dataResourceQa: json});
        });
    }

    render(){
        
        let { dataResourceQa } = this.state;

        return ( <>
                                <div className={(dataResourceQa !== null && dataResourceQa !== undefined && dataResourceQa.payload.list.nodes.length > 0) ? "col-md-4 qa_wrapper" : "d-none"}>
									{/*<div className="row">
                                            <div className="col-md-12">
                                                <div className="situation-search-description">
                                                </div>
                                            </div>
		                                </div>/**/}
                                        {(dataResourceQa !== null && dataResourceQa !== undefined &&  dataResourceQa.payload.list.nodes.length > 0) && <>
										
                                        <div className="row right-nav">
                                                <div className="col-md-12 filter-search-result-accordion-wrapper">
                                                    <h2>Вопрос-ответ</h2>
                                                </div>
                                                <div className="col-md-12 filter-search-result-accordion-wrapper">
                                                    <div className="filter-search-result-accordion">
                                                        <div id="accordion-qa" className="accordion" data-uk-accordion="{collapse:false}">
                                                            {dataResourceQa.payload.list.nodes.map( (answer, i) => {
                                                                let item_content = answer.element.content;
                                                                let item_group_name = answer.element.group_name;
                                                                let item_title = answer.element.title.replace(/\s+/g, ' ');
                                                                item_title = item_title.replaceAll(" по ст. "+item_group_name+" УК РФ","");
                                                                item_title = item_title.replaceAll("по ст. "+item_group_name+"УК РФ","");
                                                                let item_id = answer.element.id;
                                                                return(
                                                                    <div key={item_id}>
                                                                        <h3 className="uk-accordion-title" data-toggle="collapse" data-target={"#collapse"+item_id} aria-expanded="false" aria-controls={"collapse"+item_id}>{item_title}</h3>
                                                                        <div  id={"collapse"+item_id} class="collapse" aria-labelledby={"heading"+item_id} data-parent="#accordion-qa" style={{height: "0px", position: "absoluterelative", overflow: "hidden"}} aria-expanded="false">
                                                                            <div className="uk-accordion-content"><div dangerouslySetInnerHTML={{__html: item_content}}></div></div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>										
										
                                        {/*<div className="row right-nav">
                                                <div className="col-md-12 qa_title">
                                                    <h4><span>Вопрос-ответ</span></h4>
                                                </div>
                                                <div className="col-md-12 accordion sidebar-qa-items" id="accordion-qa" data-uk-accordion="{collapse:false}">
                                                            {dataResourceQa.payload.list.nodes.map( (answer, i) => {
                                                                let item_content = answer.element.content;
                                                                let item_group_name = answer.element.group_name;
                                                                let item_title = answer.element.title.replace(/\s+/g, ' ');
                                                                item_title = item_title.replaceAll(" по ст. "+item_group_name+" УК РФ","");
                                                                item_title = item_title.replaceAll("по ст. "+item_group_name+"УК РФ","");
                                                                let item_id = answer.element.id;
                                                                return(
                                                                    <div className="sidebar-qa" key={item_id}>
                                                                        <span className="sidebar-qa__title" data-toggle="collapse" data-target={"#collapse"+item_id} aria-expanded="false" aria-controls={"collapse"+item_id}>{item_title}</span>
                                                                        <div id={"collapse"+item_id} className="sidebar-qa__content collapse" aria-labelledby={"heading"+item_id} data-parent="#accordion-qa" style={{height: "0px", position: "absoluterelative", overflow: "hidden"}} aria-expanded="false">
                                                                            <div className="sidebar-qa__description" dangerouslySetInnerHTML={this.createMarkup(item_content)}></div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                </div>
                                        </div>/**/}
                                        </>}
                                    {/*<div className="row mt-3">
                                        <div className="col-md-12">
                                            <Link to="/situation/partkoap" className="situation_money" style={situation_money}>
                                                <div className="situation_title" style={situation_title}>По административным делам</div>
                                            </Link>
                                        </div>
                                        <div className="col-md-12">
                                            <Link to="/situation/partuk" className="situation_hummer" style={situation_hummer}>
                                                <div className="situation_title" style={situation_title}>По уголовным делам</div>
                                            </Link>
                                        </div>
                                        <div className="col-md-12">
                                            <Link to="/situation/partnalog" className="situation_nalog" style={situation_nalog}>
                                                <div className="situation_title" style={situation_title}>По налоговым делам</div>
                                            </Link>
                                        </div>
                                        <div className="col-md-12">
                                            <Link to="/situation/partfamily" className="situation_family" style={situation_family}>
                                                <div className="situation_title" style={situation_title}>По гражданским, семейным делам</div>
                                            </Link>
                                        </div>
                                        <div className="col-md-12" style={{clear:"left", height: "45px"}}><hr /></div>
                                        <div className="col-md-12">
                                            <Link to="/is" className="situation_is_link" style={situation_is_link}>
                                                <div className="situation_title" style={situation_title}>Адвокаты, Юристы, Нотариусы</div>
                                            </Link>
                                        </div>
                                        <div className="col-md-12" style={{clear:"left", height: "45px"}}><hr /></div>
                                        <div className="col-md-12"></div>
                                    </div>/**/}
                                </div>
                            </>);
    }

}

export default SituationRightPanel;