import React from 'react'
import {Link} from "react-router-dom";
import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";

class Newslist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            news: [],
            currentPage:1,

        };
    }

    componentDidMount() {
        let limit = 9;
        let url = global.apiLink + 'news/list?active=1&page='+this.state.currentPage+'&limit='+limit;
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === 200) {
                        this.setState({
                            news: result.payload.news,
                            countPage:result.payload.countPage
                        });
                    }
                }
            );
    }
    loadMore(){
        let currentPage =this.state.currentPage;
        if(currentPage < this.state.countPage){
            currentPage ++;
            let  limit = 9;
            let url = global.apiLink + 'news/list?active=1&page='+currentPage +'&limit='+limit;
            fetch(url)
                .then(res=>res.json())
                .then((result)=>{
                    if(result.status === 200){
                        let reciviedNews = result.payload.news;
                        let currentNews = this.state.news;
                         let news = currentNews.concat(reciviedNews);
                        this.setState({
                            news:news,
                            currentPage:currentPage
                        });
                    }
                });
        }
        this.setState({});

    }
    render() {
        const {news} = this.state;
		console.log('this.state.currentPage=' + this.state.currentPage);
		console.log('this.state.countPage=' + this.state.countPage);
		
		
        let newsList = null;
        var MonthArray=[
               'ЯНВ',
               'ФЕВ',
               'МАР',
               'АПР',
               'МАЙ',
               'ИЮН',
               'ИЮЛ',
               'АВГ',
               'СЕН',
               'ОКТ',			   
               'НОЯ',
               'ДЕК',
            ];
            
        newsList = news.map((element) => {

            let imageLink = "/assets/images/no-image.png";
            if(element.file)imageLink = global.apiLink + 'uploads/' + element.file.path + '/' + element.file.name;
            if(element.image)imageLink = element.image;
			let elDate = (element.pubdate).split(" ");
            /*let dateDB = new Date(element.created_at);
            let CreatedDay = ('00' + dateDB.getUTCDate()).slice(-2);
            let CreatedMonth = ('00' + (dateDB.getUTCMonth()+1)).slice(-2);
            let CreatedYear = dateDB.getUTCFullYear();*/

            return (<>
            
            
            <div className="grid_container_news-item">
			
                <Link className="news-item-image" to={element.url} style={{
                        background:'url(' + imageLink + ')',
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        display: "block",
                        position: "relative",
                        height: "200px"
                    }} ></Link>
<div className="news-item-date">
    {elDate[0]}
	<div className="news-item-date-month">{elDate[1].slice(0, 3).toLocaleUpperCase()}</div>
    <div className="news-item-date-yaer">{elDate[2]}</div>
</div>

<div className="news-item-shield">
    <h4><Link className="news-item-title" to={element.url}>{element.title}</Link></h4>
	<div className="news-item-description">{element.anonce}</div>
</div>
</div>

        </>);
    });
    document.getElementById("root").style.background = "#fff";
        const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Сервисы',
                path: '/services/',				
            },			
            {
                name: 'Юридические новости',
                path: '',				
            },
        ];	
        return (
            <div className="news-page page">
                <div className="container ">
				
                    <div className="row">
						<BreadCrumbs crumbs={breadcrumbs} />
						<PageTitle pageTitle="Юридические новости" />
                    </div>
					
					<div className="row page-wisiwig">
					    <p>В данном разделе представлена новостная лента мобильной правовой системы «Мой адвокат».</p>
					</div>
					
					<div className="row">
					
                        <div className="col-md-9 news-content-wrapper grid_container grid_container-news" style={{maxWidth:"100%"}}>				
                            {newsList}
                    { this.state.currentPage < this.state.countPage &&
                        <>
						<div className="d-block">
						    <div className="clearfix"></div>
							<button className="go_to_callpage_btn btn btnprimary btnlg news-more {}" onClick={()=>this.loadMore()} n ><div className="go_to_callpage_title">{this.state.countPage}Показать еще</div></button>
                            <div className="clearfix"></div>
							</div>
						</>
                    }
				
                            </div>
							<div className="col-md-3">
								<div className="right-nav-page aside-nav">
					
<div className="aside-widget">
    <img src="/assets/images/advocate.svg" alt="Срочная юридическая помощь" className="aside-widget__image2" />
    <h4 className="aside-widget__title">Нужна срочная помощь?</h4>
	<div className="aside-widget__text pulse-button-wrap">
	    <a href="/call/?do" className="go_to_callpage_btn">
		    <div className="go_to_callpage_title">Позвоните нам</div>
		</a>
	</div>
</div>
                                </div>									
                            </div>
							
						<br /><br />	
					</div>		
                </div>
            </div>
        );
    }
}

export default Newslist;