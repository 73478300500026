import React from 'react';
import Regions from "./regions";
import Subjects from "./subjects";


class Resourceviewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            region_id: props.match.params.region_id ? props.match.params.region_id : 0
        }
    }

    render() {
        document.getElementById("root").style.background = "#fff";
        return (
            <>
                    {this.state.region_id ? <Subjects region_id={this.state.region_id}/> : <Regions/>}
            </>
        )

    }

}

export default Resourceviewer;