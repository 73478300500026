import React from 'react';
import "../../global.js";
import RoomList from './RoomPage/RoomList';
import { getDateString } from './MessageList/helpers';
//const crypto = require('crypto');
//const publicExponent = new Uint8Array([1, 0, 1]);
import Notification from "../Notifications";

var tokenAPI = localStorage.getItem('tokenAPI');
let headers = new Headers();
headers.append('Authorization', 'Bearer ' + tokenAPI);

var heartbeat_msg_q = '--heartbeat-question--',
    heartbeat_msg_a = '--heartbeat-answer--', 
    heartbeat_interval = null, 
    missed_heartbeats = 0;

export class ResourceViewer extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        
        self.audio = new Audio(global.msgSound);
        this.state = {
            countItems: 0,
            elementsLR: [],
            currentRoomId: 0,
            roomtitle: "",
            roommessage: "",
            isAdvocat: false,
            NotificationText: ""
        };
        
        this.connectWSS = ()=>{
            self.client = new WebSocket(global.apiWsLink + '?token=' + tokenAPI);
            self.client.onerror = function() {
                console.log('Connection Error');
            };
            
            self.client.onopen = function() {
                console.log('WebSocket Client Connected');
                    if (heartbeat_interval === null) {
                        heartbeat_interval = setInterval(function() {
                            try {
                                missed_heartbeats++;
                                if (missed_heartbeats >= 3)
                                    throw new Error("Too many missed heartbeats.");
                                self.client.send(
                                    JSON.stringify(
                                        {
                                            token: tokenAPI,
                                            action: "heartbeat",
                                            message: heartbeat_msg_q,
                                        }
                                    )
                                );
                            } catch(e) {
                                clearInterval(heartbeat_interval);
                                heartbeat_interval = null;
                                console.warn("Closing connection. Reason: " + e.message);
                                self.client.close();
                                setTimeout(()=>{
                                    self.connectWSS();
                                }, 2000);
                            }
                        }, 8000);
                    }
            };
            
            self.client.onclose = function() {
                console.log('echo-protocol Client Closed');
                setTimeout(()=>{
                    console.log('WebSocket Client reconnecting...');
                    self.connectWSS();
                }, 2000);
            };
            
            self.client.onmessage = function(e) { 
                if (typeof e.data === 'string') {
                    let PayLoad = JSON.parse(e.data);
                    if(PayLoad.payload.action == "heartbeat"){
                        if (PayLoad.payload.message === heartbeat_msg_a) {
                            missed_heartbeats = 0;
                        }
                    }else if(PayLoad.payload.action == "refreshRoomList"){
                        self.getRoomInfo();
                        self.refreshListRoom();
                        missed_heartbeats = 0;
                    }else if(PayLoad.payload.action == "getNewMsg"){
                        self.refreshListRoom();
                        setTimeout(() => {
                            if(PayLoad.payload.sender_id != localStorage.getItem('id_user')){
                                if (self.audio) self.audio.pause();
                                self.audio.play(); // надо пофиксить уведомление на стороне API потом раскомментировать
                            }
                        }, 500);
                        missed_heartbeats = 0;
                    }
                    
                }
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeM = this.handleChangeM.bind(this);
        this.handleRoomChange = this.handleRoomChange.bind(this);
        this.rootheight = null;
        
        this.getRoomLoad = this.getRoomLoad.bind(this);

        this.refreshListRoom = (refreshedRoomId = null) => {
            let QweryLink = global.apiLink + 'api/chat/chat/list-room?getmeta=1';
            fetch(QweryLink, { headers: headers })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.status !== 200) {
                            throw new Error(result.status)
                        }
                        if (result.payload) {
                            let elementsLR = Object.values(result.payload.array);
                            elementsLR.sort(function(a, b) {
                                if (a.latest_sort > b.latest_sort) { return 1; }
                                if (a.latest_sort < b.latest_sort) { return -1; }
                                return 0;
                            });
                            elementsLR = elementsLR.reverse();
                            console.log(elementsLR);
                            self.setState({
                                countItems: elementsLR.length,
                                isAdvocat: result.payload.IsAdvocat,
                                elementsLR
                            });
                            if(refreshedRoomId !== null){
                                document.getElementById("room_item_"+refreshedRoomId)
                                    .setAttribute("data-status", 0);
                                try {
                                    self.getRoomLoad(refreshedRoomId);
                                } catch (error) {
                                    console.warn(error);
                                }
                            }
                        }
                    }
                ).catch(function (e) {
                    if (e.message == "401") {
                        window.location.href = '/login';
                    }
                });
        }

        this.setEditAvailable = (status) => {
            let CreateRoomBtn = document.getElementById("createRoom");
            if(status == true){
                CreateRoomBtn.classList.add("disabled");
                CreateRoomBtn.setAttribute("disabled","disabled");
                document.getElementById("room_title").setAttribute("disabled","disabled");
                document.getElementById("room_message").setAttribute("disabled","disabled");
            }else{
                CreateRoomBtn.classList.remove("disabled");
                CreateRoomBtn.removeAttribute("disabled");
                document.getElementById("room_title").removeAttribute("disabled");
                document.getElementById("room_message").removeAttribute("disabled");
            }
        }


        this.clearMessageText = (text) => {
            let output = (text).replace(/(<([^>]+)>)/gi, '');
            return output.replace(/\s{2,}/gi, ' ');
        }


        this.createRoom = () => {
            if(document.getElementById("createRoom").getAttribute("disabled") == true)return;
            if (this.client.readyState === this.client.OPEN) {
                this.setEditAvailable(true);

                let roomtitle = this.clearMessageText(localStorage.getItem('roomtitle')?.trim());
                let roommessage = this.clearMessageText(localStorage.getItem('roommessage')?.trim());

                if( roomtitle?.trim().length == 0 || 
                    roommessage?.trim().length == 0 ||
                    roomtitle == null ||
                    roommessage == null
                ){
                    setTimeout(() => this.setState({NotificationText: 'Не все поля заполнены'}), 0);
                    setTimeout(() => this.setState({NotificationText: null}), 6000);
                    this.setEditAvailable(false);
                    return false;
                }
                
                if(roomtitle?.trim().length == 256){
                    setTimeout(() => this.setState({NotificationText: 'Слишком длинныое название'}), 0);
                    setTimeout(() => this.setState({NotificationText: null}), 6000);
                    this.setEditAvailable(false);
                    return false;
                }
                
                if(roommessage?.trim().length == 600){
                    setTimeout(() => this.setState({NotificationText: 'Слишком длинныое сообщение'}), 0);
                    setTimeout(() => this.setState({NotificationText: null}), 6000);
                    this.setEditAvailable(false);
                    return false;
                }

                let body = new FormData();
                body.append('title', roomtitle);
                body.append('message', roommessage);

                fetch(global.apiLink + 'api/chat/chat/add-room', {
                    headers, 
                    'method': "POST",
                    'body': body,
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.status !== 200) {
                                throw new Error(result.status)
                            }
                            if (result.payload) {
                                localStorage.removeItem('roomtitle');
                                localStorage.removeItem('roommessage');
                                props.history.push('/chat/'+result.payload.roomid);
                            }
                        }
                    )
                    .catch(function (e) {
                        if (e.message == "401") {
                            window.location.href = '/login';
                        }
                    });
            }
        }
        /*

        this.client = new WebSocket(global.apiWsLink + '?token=' + tokenAPI);
        
        this.client.onerror = function() {
            console.log('Connection Error');
        };
        
        this.client.onopen = function() {
            console.log('WebSocket Client Connected');
        };
        
        this.client.onclose = function() {
            console.log('echo-protocol Client Closed');
        };
        
        this.client.onmessage = function(e) { 
            if (typeof e.data === 'string') {
                let received = (e.data);
                let jsonRec = JSON.parse(received);
                if(jsonRec.payload.handler !== undefined){
                    if(jsonRec.payload.handler.payload.id !== undefined){
                        props.history.push('/chat/'+jsonRec.payload.handler.payload.id);
                    }
                }
                if(jsonRec.payload.action == "refreshRoomList"){
                    self.refreshListRoom();
                }
            }
        };/**/
    }

    handleRoomChange(event) {
        let parent_room_Link = event.target.closest(".room_list_item");
        let data_room_id = parent_room_Link.getAttribute("data-room-id");
        window.location.href = "/chat/"+data_room_id+"/";
    }
    handleChange(event) {
        localStorage.setItem('roomtitle',event.target.value);
    }
    handleChangeM(event) {
        localStorage.setItem('roommessage',event.target.value);
    }

    createMarkup = (s) => {
        return {__html: s};
    }

    formatMessagePreview = (args) => {
        let type = args.type;
        let content = args.content;
        if (!content) content = args;
    
        let out = '';
        switch(type) {
            case 'text':
                if (content?.length > 45) {
                    out = content.slice(0, 42).trim() + '...';
                    return out;
                } else {
                    return content;
                }
            case 'image':
                return '[Изображение]';
            case 'video':
                return '[Видео]';
            case 'audio':
                return '[Аудио]';
            case 'call':
                return '[Звонок]';
            case 'file':
                return '[Документ]';
            default:
                if (content?.length > 45) {
                    out = content.slice(0, 42).trim() + '...';
                    return out;
                } else {
                    return content;
                }
        }
    }
    
     getRoomLoad = (roomid) => {
        if(!roomid)return;
        let self = this;
        let ThisRoom = document.getElementById("room_item_"+roomid);
        if(ThisRoom.getAttribute("data-status") == 0){
            ThisRoom.setAttribute("data-status", 1);
            let run = () => {
                if(ThisRoom.getAttribute("data-status") < 2)
                    fetch(global.apiLink + 'api/chat/chat/get-room?id='+roomid, {headers})
                        .then(res => res.json())
                        .then(
                            (result) => {
                                if (result.status !== 200) {
                                    throw new Error(result.status)
                                }
                                if (result.payload) {
                                    let i = 0;
                                    console.log(i++);
                                    let ThisRoomDataStatus = parseInt(ThisRoom.getAttribute("data-status"));
                                    let roominfo = result.payload.roominfo;
                                    //let title = decodeMessage(roominfo.title);
                                    //let message = decodeMessage(roominfo.message);
                                    console.log(i++);
                                    try {
                                        
                                        let title = self.formatMessagePreview(roominfo.title);
                                        console.log(i++);
                                        let message = self.formatMessagePreview({
                                            type:roominfo.message_type,
                                            content:roominfo.message
                                        });
                                        console.log(i++);
                                        let msgCreatedon = new Date((parseInt(roominfo.msg_createdon)) * 1000);
                                        msgCreatedon = getDateString(msgCreatedon);
    
                                        console.log(ThisRoomDataStatus);
                                        console.log(i++);
                                        if( ThisRoomDataStatus == 1){
                                            console.log(i++);
                                            ThisRoom.setAttribute("data-status", 2);
                                            console.log(ThisRoom.getAttribute("data-status"));
                                            let RoomItem = document.querySelectorAll(
                                                "#room_item_"+roominfo.id+" .card-title"
                                            )[0];
                                            RoomItem.innerHTML = title;
                                            //if(roominfo.message_type == "text"){
                                            let RoomItemM = document.querySelectorAll(
                                                    "#room_item_"+roominfo.id+" .room_info .card-body span"
                                                )[0];
                                                RoomItemM.innerHTML = message + ` <small class="text-muted"><i>(${msgCreatedon})</i></small>`;
                                            //}
                                            if(roominfo.image){
                                                let RoomItemAvatar = document.querySelectorAll(
                                                    "#room_item_"+roominfo.id+" .interlocutor_avatar img"
                                                )[0];
                                                RoomItemAvatar.src = roominfo.image;
                                            }
                                        }
                                    } catch (error) {
                                        console.log(error)
                                    }
                                }
                            }
                        )
                        .catch(function (e) {
                            if (e.message == "401") {
                                window.location.href = '/login';
                            }
                        });
                setTimeout(()=>{
                    if(ThisRoom.getAttribute("data-status") == 1){
                        console.log(roomid);
                        run();
                    }
                }, 6000);
            };
            run();
        }
    }
    componentDidMount() {
        let self = this;
        
        if(tokenAPI){
            let url  = global.apiLink + '/api/token/check-token';
				fetch(url, { headers:headers })
				.then(response =>response.json())
				.then((response)=>{
					if(response.status === 200 && response.payload === true){
                        self.connectWSS();
                        self.refreshListRoom();
                        document.getElementsByTagName("html")[0].style.height = "auto";
						return;
					}
					localStorage.removeItem('tokenAPI');
					headers = null;
				});
        }
    }

    render() {
        let self = this;
        const {isAdvocat, elementsLR, NotificationText} = self.state;
/*
        let listRoom = elements.map( (room) => {
            if(room.visitor_id_user !== null){
                return(<Room key={room.id} roomid={room.id} currentRoomId={0} isAdvocat={self.state.isAdvocat} status={room.status} visitor_id_user={room.visitor_id_user} title={room.title} createdon={room.createdon} message={room.message.message} message_type={room.message.message_type} filename={room.message.filename}/>);
            }
        });
        if(listRoom == null)listRoom = <h5>Обращений нет</h5>
        let listRoomUn = elements.map( (room) => {
            if(room.visitor_id_user === null){
                return(<Room key={room.id} roomid={room.id} currentRoomId={0} isAdvocat={self.state.isAdvocat} status={room.status} visitor_id_user={room.visitor_id_user} title={room.title} createdon={room.createdon} message={room.message.message} message_type={room.message.message_type} filename={room.message.filename}/>);
            }
        });/**/
        let PREloader = <div dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)}></div>

            return (
                <div className="page-chat">
                    <div className="container mb-4">
                        <div className="row">
                            
                            {/*}
                            <div className="col-md-12 quick-start-title-wrapper">
                                <div className="uk-h1 quick-start-header-title">Мои обращения</div>
                                <div className="all-hr all-hr-2 color-lightblue"></div>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-9 textarea_task_creater"></div>
                                </div>
                            </div>
                            {/**/}
                            
                            {!tokenAPI && <> <div className="col-md-12">
                                <div className="card card-default mb-3 shadow">
                                    <div className="card-body">
                                        <h4 className="card-title"><b>Создавать обращения в чате могут только авторизованные пользователи.</b></h4>
                                    </div>
                                </div>
                            </div></>}
                            
                            {tokenAPI && <>
                            <div className="col-md-12">
                                <div className="card card-body shadow" style={{ padding: 0, height: "calc(100vh - 127px)" }}>
                                    <div className="row">
                                        <div className="col-md-4 order-md-1 order-2 room-list" style={{paddingRight:0,height: "calc(100vh - 127px)"}}>
                                            <div className="listroom_toppanel">
                                                <div key={"RoomKey_0"}  id="listRoomTop">
                                                    <h4 class="pt-3" style={{paddingLeft:"20px"}}>Чат</h4>
                                                </div>
                                            </div>
                                            {elementsLR ? <RoomList key={0} handleRoomChange={this.handleRoomChange} getRoomLoad={this.getRoomLoad} isAdvocat={isAdvocat} MetaRooms={elementsLR} /> : PREloader}
                                        </div>
                                        <div className="col-md-8 order-md-2 order-1"
                                                style={{
                                                    paddingLeft: "0"
                                                }}>
                                            <div className="" id="appeal">Новое обращение</div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <form className="room_create_form">
                                                        <textarea
                                                            id="room_title"
                                                            name="room_title"
                                                            style={{resize: "none"}}
                                                            className="uk-textarea"
                                                            maxlength="254"
                                                            onChange={this.handleChange}
                                                            rows="1"
                                                            placeholder="Введите тему вашего обращения"
                                                        >{localStorage.getItem('roomtitle') && localStorage.getItem('roomtitle')}</textarea>
                                                        <br />
                                                        <textarea
                                                            id="room_message"
                                                            name="room_message"
                                                            style={{resize: "none"}}
                                                            className="uk-textarea"
                                                            maxlength="599"
                                                            onChange={this.handleChangeM}
                                                            rows="5"
                                                            placeholder="Введите вопрос вашего обращения максимально развёрнуто"
                                                        >{localStorage.getItem('roommessage') && localStorage.getItem('roommessage')}</textarea>
                                                        <br />{/*}
                                                            <input type="file" id="createroom_attache_file"  onChange={this.onFileSelection} ref={input => this.input_attache_files = input} className="uk-hidden" multiple />
                                                            <button className="uk-button uk-button-default" title="Прикрепить файлы" onClick={this.handleAttacheFilesClick} type="button" tabIndex="-1">
                                                                <i className="fas fa-paperclip" alt="Прикрепить файлы"></i>
                                                            </button>
                                                        <br />{/**/}
                                                        <div
                                                            id="createRoom"
                                                            onClick={this.createRoom}
                                                            className="btn btn-biryza btn-biryza-mod float-left"
                                                        >Добавить обращение</div>
                                                        <br />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            </>}
                        </div>
                    </div>
                    {NotificationText && <Notification>{NotificationText}</Notification>}
                </div>
            );

    }

}

export default ResourceViewer;