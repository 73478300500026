import React from "react";

class Logout extends React.Component{
    constructor(props) {
        super(props);

    }
    render() {
        localStorage.clear();
        window.location.href = '/login';
        return (
            <>
            </>
        )


    }
}
export default Logout;