
let handleStatus = function (code){
    switch (code){
        case 401:
            alert('авторизуйтесь');
            localStorage.removeItem('tokenAPI')
            window.href.location = '/login';

            break
        case 403:
            alert('Нету прав для просмотра данной страницы');
            //window.href.location = '/';
    }
}
export default handleStatus;