import React from 'react';

export class PayMethods extends React.Component {

    render(){
		const images = require.context('./images', true);
		const imageList = images.keys().map(image => images(image));
		const pay_methods = [
			{
				title: "МИР",
				img: imageList[2]
			},
			{
				title: "MasterCard",
				img: imageList[1]
			},
			{
				title: "Maestro",
				img: imageList[3]
			},
			{
				title: "Ю Money",
				img: imageList[0]
			},
			{
				title: "SberPay",
				img: imageList[4]
			},
			{
				title: "СБП",
				img: imageList[5]
			}
		];
		const pay_methods_wrapper_style = {
			maxWidth: "100%",
			display: "inline-flex",
			flexFlow: "wrap",
		};
		const pay_method_item_style = {
			width: "76px",
			height: "34px",
			padding: "2px 3px",
			background: "#fff",
			borderRadius: "5px",
			margin: "2px",
			textAlign: "center"
		};
		const pay_methods_style = {
			maxWidth: "70px",
			maxHeight: "30px",
		};
        return (<>
			<div
				className='pay_method_wrapper'
				style={pay_methods_wrapper_style}
			>
				{pay_methods? pay_methods.map((item, i)=>{
					return (
							<div
								key={i} 
								className='pay_method_item'
								style={pay_method_item_style}
							>
								<img
									src={item.img}
									alt={item.title}
									title={item.title}
									style={pay_methods_style}
								/>
							</div>
					);
				}):<></>}
			</div>
		</>);

    }

}

export default PayMethods;