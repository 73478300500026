import React, { startTransition, useEffect, useState, useContext } from 'react';

let token = localStorage.getItem('tokenAPI');
let headers = new Headers();
headers.append('Authorization', 'Bearer ' + token);
let apiLink = "https://api.my-advo.cat";


const GridPresetInnerCard = (props) => {
    const [IsPremium, setIsPremium] = useState(false);

    useEffect(() => {
                    let url  = apiLink + '/api/user/get-profile';
                        fetch( url, { method:'GET', headers: headers } )
                            .then( response =>response.json() )
                            .then( (response)=>{
                                if(response.status === 200)
                                    if(response.payload.premium !== undefined)
                                        setIsPremium(Boolean(response.payload.premium));
                            } );

    }, []);

    
    let {
        href,
        id,
        title,
        description
    } = props;



    return (
	<a className="page_preset_item-inner iconbox-hover-moving-border" href={"/preset/p"+id}>
	    <div className="page_preset_container">
			<div style={{maxHeight: "150px", overflow: "hidden",display:"inline-block"}}>
                <div style={{height: "100%",overflow: "hidden", bottom: "unset", top:"unset", left:"unset", right:"unset", position: "relative", padding: "0"}}>
                    <h5 className="preset-item-title" dangerouslySetInnerHTML={{__html: title}}/>
                    <div className="preset-item-description" dangerouslySetInnerHTML={{__html: description}}/>
                </div>
            </div>
            <div className="law_element_2" style={{position: "absolute", left: "0", bottom: "calc(10% - 20px)"}}>
                {/*}<img src="https://rrdevs.net/demos/html/lawgist/assets/img/home_01/law_hamer.svg"  />{/**/}
            </div>
		</div>
    </a> 		
    );
}

export default GridPresetInnerCard;