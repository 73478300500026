import React from 'react';
import Punycode from 'punycode';

export class JuristListItem extends React.Component {

    extractHostname = (url)=>{
        var hostname;
        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        } else {
            hostname = url.split('/')[0];
        }
        hostname = hostname.split(':')[0];
        hostname = hostname.split('?')[0];
        return hostname;
    }
      
    extractLink = (url)=>{
        var hostname;
        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        } else {
            hostname = url.split('/')[0];
        }
        hostname = hostname.split(':')[0];
        hostname = hostname.split('?')[0];
        hostname = hostname.replace("https://","");
        hostname = hostname.replace("http://","");
        hostname = "https://"+hostname;
        return hostname;
    }

	
    gettingJuristAddresses = (addressses) => {
        let count = addressses.length;
        let result = "";
        for(let i = 0; i < count; i++){
            let item = addressses[i];
            let innerText = item["country"]+", "+
            item["state"]+", "+
            item["city"]+", "+
            item["street"]+", "+
            item["house"]+
            (item["office"]?(", "+item["office"]):"");
            result += "<div>"+innerText+"</div>";
        }
        return result;
    } 
    makeListParams = (array, elName, wrappertagname = "div", attributes = "", isLink = false)=>{
        let count = array.length;
        let result = "";
        for(let i = 0; i < count; i++){
            let item = array[i];
            let innerText = item[elName];
            if(innerText == "-")continue;
            if(isLink == true ){
                let hostname = this.extractHostname(innerText);
                let Link = this.extractLink(innerText);
                
                if(Link) {
                    try{
                        Link = Punycode.toUnicode(Link);
                    }catch (Joni){
                        console.log(Joni);
                        console.log("Punycode is not work with "+Link);
                    };
                }
                innerText = "- <a href='"+Link+"' target='_blank'>"+hostname+"</a>";
            }
            result += "<"+wrappertagname+" "+attributes+">"+innerText+"</"+wrappertagname+">";
        }
        return result;
    }

    createMarkup = (s) => {
        return {__html: s};
    }
    
    render() {
        let answer = this.props.answer;
        let juristtype = this.props.juristtype;
        let stateid = this.props.stateid;

        let name = answer.name;
        let juristuser_id = answer.juristuser_id;
        let city_id = answer.city_id;
        let jurist_type = answer.jurist_type;
        let image = answer.image;
        if(!image) image = "/assets/images/no-avatar-300x300.png";
        else image = global.apiLink+image;

        let services = answer.services;
        let links = answer.links;
        let phones = answer.phones;
        let socialnets = answer.socialnets;
        let addresses = answer.addresses;
        
        let Jurist = {};
        Jurist.servicesText = this.makeListParams(services, "title", "li", ' class="list-inline-item ml-0 pl-2 mr-3"');
        Jurist.linksText = this.makeListParams(links, "link", "li", ' class=""', true);
        Jurist.phonesText = this.makeListParams(phones, "number", "li", ' class="m"');
        Jurist.socialnetsText = this.makeListParams(socialnets, "link", "li", ' class=""', true);
        Jurist.AddressesText = this.gettingJuristAddresses(addresses);
        
        let reg_num = answer.reg_num;
        let reg_num_text = "";
        if(reg_num)reg_num_text = "<div style='text-align:center;line-height:18px;'>Реестровый номер: <br /><b>"+reg_num+"</b></div>  ";
        if(jurist_type == "lawyer"){
            jurist_type = "Адвокат";
        }else if(jurist_type == "jurist"){
            jurist_type = "Юрист";
        }else jurist_type = "Нотариус";
        
        let LinkUrl = `/is/${juristtype}/${stateid}/${city_id}/${juristuser_id}`;
        return(
            <>
                <div className="col-md-12 juristuser_item" key={juristuser_id}>
                    <div className="card card-default  mb-2 shadow-sm">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="card-body">
                                    <a href={LinkUrl}>
                                        <img src={image} class="rounded float-left" style={{width:"100%"}} alt="..." />
                                    </a>
                                    <span dangerouslySetInnerHTML={this.createMarkup(reg_num_text)} />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        <a href={LinkUrl}>
                                            <span dangerouslySetInnerHTML={this.createMarkup(name)} />
                                        </a>
                                    </h5>
                                    <h6 className="card-subtitle mb-2 text-muted">
                                        <span dangerouslySetInnerHTML={this.createMarkup(jurist_type)} />{/**/}
                                    </h6>
                                    <div className="card-text" dangerouslySetInnerHTML={this.createMarkup("<b>Адрес:</b> "+Jurist.AddressesText)}/>
                                    <ul className="ml-0 list-inline" dangerouslySetInnerHTML={this.createMarkup("<b>Специализация:</b><br />"+Jurist.servicesText)}/>
                                    
                                    <div className="row">
                                        <div className="col-md-12">
                                            
                                        </div>
                                        {/*}<div className="col-md-6">
                                            <ul className="list-unstyled" dangerouslySetInnerHTML={this.createMarkup(Jurist.linksText)} />
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="list-unstyled" dangerouslySetInnerHTML={this.createMarkup(Jurist.phonesText)} />
                                        </div>
                                        <div className="col-md-12">
                                            <ul className="list-unstyled" dangerouslySetInnerHTML={this.createMarkup(Jurist.socialnetsText)} />
                                        </div>{/**/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );

    }

}
export default JuristListItem;