import React from 'react';
import "./resourceviewer.styles.css";
import ArrowRightSvg from './assets/next-arrow-right.svg';
import BreadCrumbs from "../elements/BreadCrumbs";
import PageTitle from "../elements/PageTitle";
import {PremiumAccess} from "../../layouts/personal-cab/PremiumAccess";

import "../../global.js";
import CollapsedList from '../CollapsedList';

let headers = new Headers();
headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));

let LoadingState_backend = 0;
class Getasinccodex extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        subcodexTree: {},
        isPremium: Boolean(localStorage.getItem('isPremium')?(JSON.parse(localStorage.getItem('isPremium'))):false),
        isAuth: Boolean((localStorage.getItem('tokenAPI'))?true:false),
        LoadingState: 0
      }
    }

    componentDidMount() {
        let self = this;
        if (this.props.parentid === undefined) return false;
        //CodexMainpage.refrachFooterPos();
        let url = global.apiLink+"api/codex/codex/list?recursive=1&exclude=p&pid="+this.props.parentid;
        fetch(url, {
                headers: headers
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status == 200 && result.payload) {
                        self.setState({
                            subcodexTree: result.payload,
                        });
                    }
                }
        );
    }

    render() {
        const {subcodexTree, LoadingState} = this.state;

        window.onresize = () => {
            CodexMainpage.refrachFooterPos();
        }
if (subcodexTree.list && subcodexTree.list.nodes && (subcodexTree.list.nodes).length) {
    return (
	<>
	<ul id={"CodexIdElement_"+this.props.parentid} className="row CodexIdElement">
        {!subcodexTree.list && global.payload}
                            {subcodexTree.list && subcodexTree.list.nodes && Object.keys(subcodexTree.list.nodes).map((el) => {

                                if (el) {
                                    return (<>
                                        <li className="col-md-12">
                                            <a className="codexTreeFont codex-link" href={"/codex/"+subcodexTree.list[el].base64_uid}>
                                                {subcodexTree.list[el].title}
                                            </a>
                                            <Getasinccodex parentid={subcodexTree.list[el].id}/>
                                        </li>
                                    </>);
                                }
                            } ) }
        </ul>
		</>
		);
} else {
	    return (<></>);
}
}


}



export class CodexMainpage extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        dataResourceQuickstart: '',
        resourceid: 0,
        subcodexTree: {},
        codexTree: {},
        thisCodexElement: {},
        thisCodexNavigate: {},
        subArticlePunkts: {},
        breabcrumb: {},
        isCodexesCollapsed: true,
		codexTitle: '',
        LoadingState: 0,
        isPremium: Boolean(localStorage.getItem('isPremium')?(JSON.parse(localStorage.getItem('isPremium'))):false),
        isAuth: Boolean((localStorage.getItem('tokenAPI'))?true:false),
      }
    }

    toggleCodexesCollapse = () => {
        this.setState(prevState => ({
            isCodexesCollapsed: !prevState.isCodexesCollapsed
        }));
    }

   utils = {
		dummy: document.createElement('div'),
		escapeHTML: function(s) {
			return {__html: s};
		}
	}
	


    getCodexes = async(pid) => {
        let self = this;
        LoadingState_backend++;
        self.setState({ LoadingState: LoadingState_backend });
        let url = global.apiLink+"api/codex/codex/list?recursive=1&exclude=p&pid="+pid;
        fetch(url, {
                headers:headers
            })
            .then(res=>res.json())
            .then(
                (result)=>{
                    if(result.status==200 && result.payload){
                        self.setState({
                            subcodexTree: result.payload,
                        });
                        self.refrachFooterPos();
                    }
                    LoadingState_backend--;
                    self.setState({ LoadingState: LoadingState_backend });
                }
        );
    }

    getCodexesTree = async() => {
        let self = this;
        LoadingState_backend++;
        self.setState({ LoadingState: LoadingState_backend });
        let url = global.apiLink + "api/codex/codex/list?recursive=1&exclude=p&pid=0";
        fetch(url, {
                headers:headers
            })
            .then(res=>res.json())
            .then(
                (result)=>{
                    if(result.status==200 && result.payload){
                        self.setState({
                            codexTree: result.payload,
                        });
                        self.refrachFooterPos();
                    }
                    LoadingState_backend--;
                    self.setState({ LoadingState: LoadingState_backend });
                }
        );
    }


    getPunkts = async(pid) => {
        let self = this;
        LoadingState_backend++;
        self.setState({ LoadingState: LoadingState_backend });
        let url = global.apiLink+"api/codex/codex/list?exclude=s&pid="+pid;
        fetch(url, {
                headers:headers
            })
            .then(res=>res.json())
            .then(
                (result)=>{
                    if(result.status==200 && result.payload){
                        self.refrachFooterPos();
                        self.setState({
                            subArticlePunkts: result.payload,
                        });
                    }
                    LoadingState_backend--;
                    self.setState({ LoadingState: LoadingState_backend });
                }
        );
    }


    getBreadcrumbs = async(pid) => {
        let self = this;
        LoadingState_backend++;
        self.setState({ LoadingState: LoadingState_backend });
        let url = global.apiLink+"/api/codex/codex/breadcrumb-codex?id="+pid+"&title=1";
        fetch(url, {
                headers:headers
            })
            .then(res=>res.json())
            .then(
                (result)=>{
                    if (result.status==200 && result.payload) {
                        self.refrachFooterPos();
                        self.setState({
                            breabcrumb: result.payload,
                            breabcrumb: result.payload,
							codexTitle: result.payload[0].title
                        });						
                    }
                    LoadingState_backend--;
                    self.setState({ LoadingState: LoadingState_backend });
                }
            );
    }



    getThisCodexElement = async(pid) => {
        let self = this;
        LoadingState_backend++;
        self.setState({ LoadingState: LoadingState_backend });
        let url = global.apiLink+"api/codex/codex/get?id="+pid;
        fetch(url, {
                headers:headers
            })
            .then(res=>res.json())
            .then(
                (result)=>{
                    if(result.status==200 && result.payload){
                        self.setState({ 
                            thisCodexElement: result.payload,
                        });
						if (result.payload.parent_id < 1) {
							self.setState({codexTitle: result.payload.title});
							let tmp = result.payload;
							tmp.title = '';
							self.setState({thisCodexElement: tmp});
						}
                        if(result.payload.type_node == "article"){
                            self.getPunkts(pid);
                            self.getThisCodexNavigate(pid);
                        }else{
                            self.getCodexes(pid);
                        }
                        self.getBreadcrumbs(pid);
                    }
                    LoadingState_backend--;
                    self.setState({ LoadingState: LoadingState_backend });
                }
        );
    }

    getThisCodexNavigate = async(pid) => {
        let self = this;
        LoadingState_backend++;
        self.setState({ LoadingState: LoadingState_backend });
        let url = global.apiLink+"api/codex/codex/codex-article-navigation?id="+pid;
        fetch(url, {
                headers:headers
            })
            .then(res=>res.json())
            .then(
                (result)=>{
                    if(result.status==200 && result.payload){
                        self.setState({ thisCodexNavigate: result.payload, });
                        self.refrachFooterPos();
                    }
                    LoadingState_backend--;
                    self.setState({ LoadingState: LoadingState_backend });
                }
        );
    }
    
    

    refrachFooterPos = () => {
        setTimeout(()=>{
            let body = document.body,
            html = document.documentElement;
            let RootHeightOld = (Math.max( body.scrollHeight, body.offsetHeight, 
                html.clientHeight, html.scrollHeight, html.offsetHeight ) - 69);
            let RootHeight = (window.outerHeight - 69);
            if(RootHeightOld <= RootHeight){
                document.getElementById("root").style.height = RootHeight+"px";
                //document.getElementById("footer").style.bottom = "-28px";
            } else {
                document.getElementById("root").style.height = "auto";
                document.getElementById("footer").style.position = "relative";
            }
            if(document.getElementById("footer") !== null){
                document.getElementById("footer").style.display = "block";
                document.getElementById("footer").style.opacity = 1;
            }
        }, 500);
    }/**/


     Getasinccodex (props) {
    
        return <>
            {props.parentid}
        </>;
    }
  
    parentFunc = (text) => {
        this.setState({dataResourceList: text});
    }

    createMarkup = (s) => {
        return {__html: s};
    }

    componentDidMount() {
        let self = this;
        this.getCodexesTree();
        const alias = self.props.match.params.alias;
        let resourceId;
        if (alias) resourceId = atob(alias);
        if(resourceId === undefined)return false;
        this.getThisCodexElement(resourceId);
    }
  
    render(){
        const {
            codexTree, 
            subcodexTree, 
            thisCodexElement, 
            subArticlePunkts,
            breabcrumb,
            thisCodexNavigate,
			codexTitle,
            isPremium,
            LoadingState
        } = this.state;
		
		document.getElementById("root").style.background = "#fafafb";
		const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Сервисы',
                path: '/services/',				
            },
            {
                name: 'Кодексы, законы, судебная практика',
                path: '/codex/',				
            },			
        ];			

        let PREloader = <div dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)}></div>
        
        let isNotParentPlenum = (thisCodexElement.type_node == "plenum" && thisCodexElement.parent_id !== 0);
        
        return(
            <>
            <div className="codex-page page">
                    <div className="container ">
                        <div className="row">
						{breabcrumb && Object.keys(breabcrumb).map((element) => {
							breadcrumbs.push({name: breabcrumb[element].title, path: "/codex/"+breabcrumb[element].base64_uid});
                        })}
							<BreadCrumbs crumbs={breadcrumbs} />
					        <PageTitle pageTitle={codexTitle && codexTitle} />							
                        </div>
                    </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mb-3">
                            <div className="list-group codex-list-group mt-4">
								{
								codexTree?.list && Object.keys(codexTree.list).map( ( element, i ) => {
                                    if (!isNaN(Number(element))) {
                                            let IsActive = "";
                                            if(breabcrumb[0] !== undefined){
                                                let CodexAlias = breabcrumb[0]["base64_uid"];
                                                if(CodexAlias == codexTree.list[element].base64_uid) {
													IsActive = " active"; 
												}
                                            }
								    let item_id = codexTree.list[element].base64_uid;
                                            return (
                                                <>
                                                    <a key={item_id} href={`/codex/${item_id}/`}
                                                       className={"list-group-item list-group-item-action"+IsActive}>
                                                        <div className="clearfix other-stat__item sectortransition-all">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <p className="m-0" dangerouslySetInnerHTML={{__html: (codexTree.list[element].title ? codexTree.list[element].title : '')}}>{ /*codexTree.list[element].title ? codexTree.list[element].title : ''*/ }</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </>
                                            );
                                        }
								})}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            {(LoadingState > 0) ? <><div className="mb-0 pb-0">{PREloader}</div></> : <></>}
                            <div id="ResourceElement" className={"row "+(isPremium ? "" : ((thisCodexElement.type_node == "plenum" && isNotParentPlenum) ? 'text-restricted':""))}>
							    <div className="col-md-12 my-3">
							        {thisCodexElement.title && (thisCodexElement.title).length && <h3 dangerouslySetInnerHTML={{__html:thisCodexElement.title}}></h3>}
								</div>
								{(thisCodexElement.content && (thisCodexElement.content).length) ?
                                    <div className="col-md-12 my-3 codexTreeFont" dangerouslySetInnerHTML={{__html:thisCodexElement.content}}></div>
                                :
                                    <></>
								}
                            </div>

                            {isNotParentPlenum ? 
                                <PremiumAccess CheckForPremium={true} text={<>
                                    <h4 className="alert-box_title alert-heading">Хотите получить полный текст постановления?</h4>
                                    <p>Полный доступ к информации о Постановлении имеют только Премиум пользователи.</p>
                                </>} />
                            :
                                <></>
                            }

                            {subcodexTree.list != "" && subcodexTree.list != undefined ? <ul id="ResourceListTree" className="row" style={{paddingLeft: "0"}}>
                                {subcodexTree.list && Object.keys(subcodexTree.list).map((element) => {
                                    if( !isNaN(Number(element))){
                                        let hrefUrl = subcodexTree.list[element].base64_uid;
										hrefUrl = btoa( subcodexTree.list[element].id);
                                        if(hrefUrl !== undefined){
                                            return (<>
                                                <li className="col-md-12">
                                                    <a className="codexTreeFont codex-link" href={"/codex/"+hrefUrl} dangerouslySetInnerHTML={{__html:subcodexTree.list[element].title}}>
                                                    </a>
                                                    <Getasinccodex parentid={subcodexTree.list[element].id}/>
                                                </li>
                                            </>);
                                        }
                                    }
                                } ) }
                            </ul>
                            :
                            <></>
                            }

                            {subArticlePunkts.list != "" ? <ul id="ResourceArticlePunkts" className="row">
                                {subArticlePunkts.list && Object.keys(subArticlePunkts.list).map( ( element ) => {
                                    if( !isNaN(Number(element))){
                                        let counterElement = '<div style="float:left;margin-right:10px;font-weight:700;">' + subArticlePunkts.list[element].title + ') </div>';
                                        
                                        return (<>
                                            <div style={{maxWidth: "100%"}} className="codexTreeFont" dangerouslySetInnerHTML={{__html: counterElement + subArticlePunkts.list[element].content}}></div>
                                        </>);
                                    }
                                } ) }
                            </ul>
                            :
                            <></>
                            }
                            {thisCodexNavigate.links != "" ? <div id="ResourceElementNavigation" className="row mb-3">
                                <div className="col-md-6">
                                    {thisCodexNavigate.links && thisCodexNavigate.links.prev && 
                                        <>
                                                <a href={"/codex/"+thisCodexNavigate.links.prev.base64_uid}  className="d-flex align-items-center gap-3 mb-4">
                                                    <img src={ArrowRightSvg} className="next-arrow-left" alt="next" />
                                                    <div style={{maxWidth: "100%"}} className="codexTreeFont" dangerouslySetInnerHTML={{__html: thisCodexNavigate.links.prev.title}}></div>
                                                </a>
                                        </>
                                    }
                                </div>
                                <div className="col-md-6 float-right">
                                    {thisCodexNavigate.links && thisCodexNavigate.links.next && 
                                        <>
                                                <a href={"/codex/"+thisCodexNavigate.links.next.base64_uid} className="d-flex align-items-center gap-3">
                                                    <div style={{maxWidth: "100%"}} className="codexTreeFont" dangerouslySetInnerHTML={{__html: thisCodexNavigate.links.next.title}}></div>
                                                    <img src={ArrowRightSvg} className="next-arrow-right" alt="next" />
                                                </a>
                                        </>
                                    }
                                </div>
                            </div>
                            :
                            <></>
                            } 
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

export default CodexMainpage;