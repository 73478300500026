import React from 'react';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { ReactMediaRecorder } from "react-media-recorder";
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { getDateString } from './MessageList/helpers';
//import SmoothScrolling from "../../helper/smoothScrolling";
//import Room from "./room";
import MessageList from './RoomPage/MessageList';
import RoomList from './RoomPage/RoomList';
//import { decodeMessage } from './MessageList/helpers';
import Notification from "../Notifications";

import "../../global.js";
import './MessageList/styles.css';

//const crypto = require('crypto');

let tokenAPI = localStorage.getItem('tokenAPI');
let headers = new Headers( { 'Authorization' : 'Bearer ' + tokenAPI } );
let TimeCounter = null;

var heartbeat_msg_q = '--heartbeat-question--',
    heartbeat_msg_a = '--heartbeat-answer--', 
    heartbeat_interval = null, 
    missed_heartbeats = 0;

    
export class ResourceViewer extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        self.audio = new Audio(global.msgSound);
        this.state = {
            elementsLM: [],
            elementsLR: [], 
            loaded: 0,
            recording: false,
            isAdvocat: false,
            NotificationText: null
        };

        this.connectWSS = ()=>{
            self.client = new WebSocket(global.apiWsLink + '?token=' + tokenAPI);
            self.client.onerror = function() {
                console.log('Connection Error');
            };
            
            self.client.onopen = function() {
                console.log('WebSocket Client Connected');
                    if (heartbeat_interval === null) {
                        heartbeat_interval = setInterval(function() {
                            try {
                                missed_heartbeats++;
                                if (missed_heartbeats >= 3)
                                    throw new Error("Too many missed heartbeats.");
                                self.client.send(
                                    JSON.stringify(
                                        {
                                            token: tokenAPI,
                                            action: "heartbeat",
                                            message: heartbeat_msg_q,
                                        }
                                    )
                                );
                            } catch(e) {
                                clearInterval(heartbeat_interval);
                                heartbeat_interval = null;
                                console.warn("Closing connection. Reason: " + e.message);
                                self.client.close();
                                setTimeout(()=>{
                                    self.connectWSS();
                                }, 2000);
                            }
                        }, 8000);
                    }
                self.refreshListMsg();
            };
            
            self.client.onclose = function() {
                console.log('echo-protocol Client Closed');
                setTimeout(()=>{
                    console.log('WebSocket Client reconnecting...');
                    self.connectWSS();
                }, 2000);
            };
            
            self.client.onmessage = function(e) { 
                if (typeof e.data === 'string') {
                    let PayLoad = JSON.parse(e.data);
                    //console.log(PayLoad);
                    if(PayLoad.payload.action == "heartbeat"){
                        if (PayLoad.payload.message === heartbeat_msg_a) {
                            missed_heartbeats = 0;
                        }
                    }else if(PayLoad.payload.action == "refreshRoomList"){
                        self.getRoomInfo();
                        self.refreshListRoom();
                        self.refreshListMsg();
                        missed_heartbeats = 0;
                    }else if(PayLoad.payload.action == "refreshMsgList"){
                        if(document.getElementById("root").getAttribute("data-mlr") == 1){
                            document.getElementById("root").removeAttribute("data-mlr")
                        }
                        self.refreshListMsg();
                        self.refreshListRoom(PayLoad.payload.roomid);
                        missed_heartbeats = 0;
                    }else if(PayLoad.payload.action == "keepMetaMsgs"){
                        let MetaMsgs = Object.values(PayLoad.payload.meta_msgs).reverse();
                        self.setState({
                            MetaMsgs,
                        });
                        missed_heartbeats = 0;
                    }else if(PayLoad.payload.action == "getMsgsById"){
                        console.log("getMsgsById");
                        console.log(PayLoad.payload);
                        let msg = PayLoad.payload.msg;
                        let ThisMessage = document.getElementById("message-item-"+msg.id);
                        let MessageStatus = ThisMessage.getAttribute("data-status");
                        if(MessageStatus == 1){
                            document.querySelectorAll("#message-item-"+msg.id+" .message-item__text")[0]
                                .innerHTML = msg.message;
                            ThisMessage.setAttribute("data-status", 2);
                        } 
                        missed_heartbeats = 0;
                    }else if(PayLoad.payload.action == "getNewMsg"){
                        self.refreshListMsg();
                        self.refreshListRoom();
                        setTimeout(() => {
                            //SmoothScrolling.scrollTo("listMessageWrapper", "listMessageBottom", null, 100, "smooth" );
                            console.log(PayLoad.payload.sender_id + " | " + localStorage.getItem('id_user'));
                            if(PayLoad.payload.sender_id != localStorage.getItem('id_user')){
                                //if(PayLoad.payload.action == "AddMsg"){
                                    if (self.audio) self.audio.pause();
                                    self.audio.play(); // надо пофиксить уведомление на стороне API потом раскомментировать
                                //}
                            }
                        }, 500);
                        missed_heartbeats = 0;
                    }
                    
                }
            }
        }

        this.mlsScrollToBottom = () => {
            //setTimeout(() => {
                let mlc = document.getElementById("message-list-container");
                let mlcScrollHeight = mlc.scrollHeight;
                //console.log("mlcScrollHeight");
                //console.log(mlcScrollHeight);

                mlc.scrollTo(0, mlcScrollHeight);
            //}, 1300);
        }



        this.handleRoomChange = this.handleRoomChange.bind(this);
        this.getRoomLoad = this.getRoomLoad.bind(this);
        //this.handleChange = this.handleChange.bind(this);
        this.handleAttacheFilesClick = (e) => { this.input_attache_files.click(); }
        this.roomid = null;
        this.rootheight = null;

        

        this.refreshListMsg = () => {          
            let QweryLink = global.apiLink + 'api/chat/chat/list-msg'+
                '?room_id='+this.roomid+'&index='+0+'&count='+100+'&order='+1+'&getmeta=1';  
            fetch( QweryLink, { headers: headers } )
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.status !== 200) {
                            throw new Error(result.status)
                        }
                        if (result.payload) {
                            let MetaMsgs = Object.values(result.payload.array).reverse();
                            self.setState({ MetaMsgs, });
                            document.getElementById("textarea_message").focus();
                            //console.log({elementsLM});
                            this.mlsScrollToBottom();
                            this.setEditAvailable(false);
                            //SmoothScrolling.scrollTo("listMessageWrapper", "listMessageBottom", null, 100, "instant");
                        }
                    }
                ).catch(function (e) {
                    if (e.message == "401") {
                        window.location.href = '/login';
                    }
                });
        }
        
        this.refreshListRoom = (refreshedRoomId = null) => {
            let QweryLink = global.apiLink + 'api/chat/chat/list-room?getmeta=1';
            fetch(QweryLink, { headers: headers })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.status !== 200) {
                            throw new Error(result.status)
                        }
                        if (result.payload) {
                            let elementsLR = Object.values(result.payload.array);
                            elementsLR.sort(function(a, b) {
                                if (a.latest_sort > b.latest_sort) { return 1; }
                                if (a.latest_sort < b.latest_sort) { return -1; }
                                return 0;
                            });
                            elementsLR = elementsLR.reverse();
                            console.log(elementsLR);
                            self.setState({
                                countItems: elementsLR.length,
                                isAdvocat: result.payload.IsAdvocat,
                                elementsLR
                            });
                            if(refreshedRoomId !== null){
                                document.getElementById("room_item_"+refreshedRoomId)
                                    .setAttribute("data-status", 0);
                                try {
                                    self.getRoomLoad(refreshedRoomId);
                                } catch (error) {
                                    console.warn(error);
                                }
                            }

                            
                            //SmoothScrolling.scrollTo("listRoomWrapper", "current_room", null, 100, "instant", -65 );
                            /*setTimeout(() => {
                                //SmoothScrolling.scrollTo("listMessageWrapper", "listMessageBottom", null, 100, "smooth" );
                                
                                self.mlsScrollToBottom();
                            }, 1000);/**/
                        }
                    }
                ).catch(function (e) {
                    if (e.message == "401") {
                        window.location.href = '/login';
                    }
                });
        }

        
        this.isBase64 = (str) => {
            if (str ==='' || str.trim() ===''){ return false; }
            try {
                return btoa(atob(str)) == str;
            } catch (err) {
                return false;
            }
        }
        
        this.messageReloadByRoom = (roomid) => {
            let message = localStorage.getItem('message'+roomid)?.trim();
            if(message === undefined)message = "";
            
            if(message?.length){
                document.getElementById("send_message").removeAttribute("disabled");
            }else{
                document.getElementById("send_message").setAttribute("disabled","disabled");
            }
            document.getElementById("textarea_message").value = message;
            document.getElementById("textarea_message").innerHTML = message;
            document.getElementById("textarea_message").removeAttribute("disabled");
            setTimeout(()=>{
                document.getElementById("textarea_message").focus();
            }, 500);
        }

        this.getRoomInfo = () => {
            let QweryLink = global.apiLink + 'api/chat/chat/get-room?id='+this.roomid;
            fetch(QweryLink, {headers})
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.status !== 200) {
                            throw new Error(result.status)
                        }
                        if (result.payload) {
                            let roominfo = Object.values(result.payload.roominfo);
                            let title = roominfo[1];
                            document.getElementById("appeal").innerHTML = title;
                        }
                    }
                )
                .catch(function (e) {
    
                    if (e.message == "401") {
                        window.location.href = '/login';
                    }
                });
        }
        
        this.onFileSelection = event => {
            const cyrillicToTranslit = new CyrillicToTranslit();
            let self = this;
            let FileCount = event.target.files.length;
            let url = global.apiLink + 'api/chat/chat/upload-file-to-room?room_id='+this.roomid;
            
            for(let i = 0; i < FileCount; i++){
                let file_item = event.target.files[i];
                let CheckResult = this.checkAttachingFile(file_item);
                if(CheckResult == false){continue;}
                this.setState({ loaded: (i+1) });
                let body = new FormData();
                let filenameTr = cyrillicToTranslit.transform(file_item.name, '_').toLowerCase();
                body.append('file', file_item, filenameTr);
                fetch(url, {
                    'headers': headers, 
                    'method': "POST",
                    body,
                })
                .then(function(){
                    self.SendRMUntilRefresh();
                })
            }/**/
        }
        
        this.blobToFile = (theBlob, fileName) => {
            //A Blob() is almost a File() - it's just missing the two properties below which we will add
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        }

        this.getBase64 = (file, callback) => {
            var reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = () => {
                callback(reader.result);
            };
         }


         this.blobToUint8Array = (b) => {
            var uri = URL.createObjectURL(b),
                xhr = new XMLHttpRequest(),
                i,
                ui8;
        
            xhr.open('GET', uri, false);
            xhr.send();
        
            URL.revokeObjectURL(uri);
        
            ui8 = new Uint8Array(xhr.response.length);
        
            for (i = 0; i < xhr.response.length; ++i) {
                ui8[i] = xhr.response.charCodeAt(i);
            }
        
            return ui8;
        }

        this.Uint8ToBase64 = (u8Arr) => {
            var CHUNK_SIZE = 0x8000; //arbitrary number
            var index = 0;
            var length = u8Arr.length;
            var result = '';
            var slice;
            while (index < length) {
              slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length)); 
              result += String.fromCharCode.apply(null, slice);
              index += CHUNK_SIZE;
            }
            return btoa(result);
          }


        this.SendAudioMessage = async (audioBlob) => {
            let self = this;
            let url = global.apiLink + 'api/chat/chat/upload-file-to-room?room_id='+this.roomid;
            var timeInMs = Date.now();
            let blob = await fetch(audioBlob).then(r => r.blob());

            audioBlob = this.blobToFile(blob, timeInMs+".wav");
            const audiofile = new File([audioBlob], "audiofile_"+timeInMs+".wav", { type: "audio/wav" })
            
            let body = new FormData();
            body.append('file', audiofile);
            fetch(url, {
                'headers': headers, 
                'method': "POST",
                'body': body,
            })
            .then(function(){
                self.SendRMUntilRefresh();
            });
        }

        this.SeparateArray = (array, bufferSize) => {
            let subarray = [];
            for (let i = 0, mcalb = Math.ceil(array.length/bufferSize); i < mcalb; i++){
                subarray[i] = array.slice((i*bufferSize), (i*bufferSize) + bufferSize);
            }
            return subarray;
        }

        this.MargeArray = (ArrayInner1, ArrayInner2) => {
            let ai2Count = ArrayInner2.length;
            for( let i = 0; i < ai2Count; i++){
                ArrayInner1.push(ArrayInner2[i]);
            }
            return ArrayInner1
        }

        this.checkAttachingFile = (file) => {
            if(file.size > 6291456){
                setTimeout(() => this.setState({NotificationText: 'Выберите файл менее 6Мб'}), 0);
                setTimeout(() => this.setState({NotificationText: null}), 6000);
                return false;
            }
            let ext = null, 
            parts = file.name.split('.'),
            extable = ["jpg","png","gif","txt","doc","docx","odf","xml","xmlx","rtf","log","webp",
                "mp4","avi","mp3","wav","ogg","zip","tgz","pdf"];
            if (parts.length > 1) ext = parts.pop().toLowerCase();
            if (extable.indexOf(ext) < 0 || ext === null){
                setTimeout(() => this.setState({NotificationText: 'Не допустимый тип файла .'+ext}), 0);
                setTimeout(() => this.setState({NotificationText: null}), 6000);
                return false;
            }
            return true
        }

        this.SendRMUntilRefresh = () => {
            document.getElementById("root").setAttribute("data-mlr", 1);
            function iterationMsgListRefresh(){
                self.client.send(
                    JSON.stringify(
                        {
                            token: tokenAPI,
                            action: "refreshMessage",
                            roomid: self.roomid,
                        }
                    )
                );
                setTimeout(()=>{
                    if(document.getElementById("root").getAttribute("data-mlr") == 1){
                        iterationMsgListRefresh();
                    }}, 1000
                );
            }
            iterationMsgListRefresh();
        }
        
        this.setEditAvailable = (status) => {
            let SendMessageBtn = document.getElementById("send_message");
            if(status == true){
                SendMessageBtn.classList.add("disabled");
                SendMessageBtn.setAttribute("disabled","disabled");
                this.textareaMessageInput.setAttribute("disabled","disabled");
            }else{
                SendMessageBtn.classList.remove("disabled");
                SendMessageBtn.removeAttribute("disabled");
                this.textareaMessageInput.removeAttribute("disabled");
            }
        }
        
        this.sendMessage = () => {
            this.setEditAvailable(true);
            if (this.client.readyState === this.client.OPEN) {
                let message = localStorage.getItem('message'+this.roomid)?.trim();
                message = message?message:"";
                message = this.clearMessageText(message);

                if(
                    (message?.trim().length == 0) || (message == null)
                ){
                    setTimeout(() => this.setState({NotificationText: 'Не все поля заполнены'}), 0);
                    setTimeout(() => this.setState({NotificationText: null}), 6000);
                    this.setEditAvailable(false);
                    return false;
                }
                
                if(message?.trim().length > 600){
                    setTimeout(() => this.setState({NotificationText: 'Слишком длинныое сообщение'}), 0);
                    setTimeout(() => this.setState({NotificationText: null}), 6000);
                    this.setEditAvailable(false);
                    return false;
                }

                let body = new FormData();
                body.append('message', message);
                body.append('room_id', self.roomid);
                fetch(global.apiLink + 'api/chat/chat/add-msg', {
                    headers, 
                    'method': "POST",
                    'body': body,
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setEditAvailable(false);
                            if (result.status !== 200) {
                                throw new Error(result.status)
                            }
                            if (result.payload) {
                                document.getElementById("root").setAttribute("data-mlr", 1);
                                this.SendRMUntilRefresh();
                                document.getElementById("room_item_"+self.roomid)
                                    .setAttribute("data-status", 0);
                                localStorage.removeItem('message'+self.roomid);
                                this.textareaMessageInput.value = "";
                                setTimeout(()=>{
                                    this.textareaMessageInput.focus();
                                }, 500);
                            }
                        }
                    )
                    .catch(function (e) {
                        console.log(e);
                        this.setEditAvailable(false);
                        if (e.message == "401") {
                            window.location.href = '/login';
                        }
                    });
            }
        }

        this.keydownHandler = e => {
            if(e.keyCode===13 && e.ctrlKey) this.sendMessage()
        }
    }

    createMarkup = (s) => {
        return {__html: s};
    }

    clearMessageText = (text) => {
        let output = (text).replace(/(<([^>]+)>)/gi, '');
        return output.replace(/\s{2,}/gi, ' ');
    }
    
    handleRoomChange(event) {
        this.setState({
            MetaMsgs: "",
        });
        let elems = document.querySelectorAll(".current_room");
        [].forEach.call(elems, function(el) {
            el.classList.remove("current_room");
        });
        document.getElementById("appeal").innerHTML = "";
        let this_roomTarget = event.target;
        if (!this_roomTarget.classList.contains('room_list_item')) {
            this_roomTarget = event.target.closest(".room_list_item");
        }
        let data_room_id = null;
        let data_room_url = null;
        if(this_roomTarget !== undefined){
            data_room_id = this_roomTarget.getAttribute("data-room-id");
            data_room_url = this_roomTarget.getAttribute("data-to");
            this_roomTarget.classList.add("current_room");
        }
        if(data_room_id == null){
            console.log("data_room_id is null");
            return false;
        }
        this.roomid = data_room_id;
        this.messageReloadByRoom(data_room_id);
        this.refreshListMsg();
        this.getRoomInfo();
        let hist = createBrowserHistory();
        hist.push(data_room_url);
        this.setEditAvailable(false);
    }

    

formatMessagePreview = (args) => {
    let type = args.type;
    let content = args.content;
    if (!content) content = args;

    let out = '';
    switch(type) {
        case 'text':
            if (content?.length > 45) {
                out = content.slice(0, 42).trim() + '...';
                return out;
            } else {
                return content;
            }
        case 'image':
            return '[Изображение]';
        case 'video':
            return '[Видео]';
        case 'audio':
            return '[Аудио]';
        case 'call':
            return '[Звонок]';
        case 'file':
            return '[Документ]';
        default:
            if (content?.length > 45) {
                out = content.slice(0, 42).trim() + '...';
                return out;
            } else {
                return content;
            }
    }
}


 getRoomLoad = (roomid) => {
    if(!roomid)return;
    let self = this;
    let ThisRoom = document.getElementById("room_item_"+roomid);
    if(ThisRoom.getAttribute("data-status") == 0){
        ThisRoom.setAttribute("data-status", 1);
        let run = () => {
            if(ThisRoom.getAttribute("data-status") < 2)
                fetch(global.apiLink + 'api/chat/chat/get-room?id='+roomid, {headers})
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.status !== 200) {
                                throw new Error(result.status)
                            }
                            if (result.payload) {
                                let i = 0;
                                console.log(i++);
                                let ThisRoomDataStatus = parseInt(ThisRoom.getAttribute("data-status"));
                                let roominfo = result.payload.roominfo;
                                //let title = decodeMessage(roominfo.title);
                                //let message = decodeMessage(roominfo.message);
                                console.log(i++);
                                try {
                                    
                                    let title = self.formatMessagePreview(roominfo.title);
                                    console.log(i++);
                                    let message = self.formatMessagePreview({
                                        type:roominfo.message_type,
                                        content:roominfo.message
                                    });
                                    console.log(i++);
                                    let msgCreatedon = new Date((parseInt(roominfo.msg_createdon)) * 1000);
                                    msgCreatedon = getDateString(msgCreatedon);

                                    console.log(ThisRoomDataStatus);
                                    console.log(i++);
                                    if( ThisRoomDataStatus == 1){
                                        console.log(i++);
                                        ThisRoom.setAttribute("data-status", 2);
                                        console.log(ThisRoom.getAttribute("data-status"));
                                        let RoomItem = document.querySelectorAll(
                                            "#room_item_"+roominfo.id+" .card-title"
                                        )[0];
                                        RoomItem.innerHTML = title;
                                        //if(roominfo.message_type == "text"){
                                        let RoomItemM = document.querySelectorAll(
                                                "#room_item_"+roominfo.id+" .room_info .card-body span"
                                            )[0];
                                            RoomItemM.innerHTML = message + ` <small class="text-muted"><i>(${msgCreatedon})</i></small>`;
                                        //}
                                        if(roominfo.image){
                                            let RoomItemAvatar = document.querySelectorAll(
                                                "#room_item_"+roominfo.id+" .interlocutor_avatar img"
                                            )[0];
                                            RoomItemAvatar.src = roominfo.image;
                                        }
                                    }
                                } catch (error) {
                                    console.log(error)
                                }
                            }
                        }
                    )
                    .catch(function (e) {
                        if (e.message == "401") {
                            window.location.href = '/login';
                        }
                    });
            setTimeout(()=>{
                if(ThisRoom.getAttribute("data-status") == 1){
                    console.log(roomid);
                    run();
                }
            }, 6000);
        };
        run();
    }
}



    componentDidMount() {
        this.roomid = this.props.match.params.roomid;
        document.addEventListener('keydown',this.keydownHandler);
        this.connectWSS();
        this.refreshListRoom();
        this.getRoomInfo();
        /*if(this.rootheight === null){
            this.rootheight = document.getElementById("root").offsetHeight;
            let listRoomWrapper = document.getElementById("listRoomWrapper")
            let listRoomWrapperTop = listRoomWrapper.offsetTop;
            listRoomWrapper.style.height = (this.rootheight - listRoomWrapperTop + 30) + "px";
        }/**/
        this.textareaMessageInput.focus();
        document.getElementsByTagName("html")[0].style.height = "auto";
        this.setEditAvailable(false);
    }
    componentWillUnmount(){
        document.removeEventListener('keydown',this.keydownHandler);
    }

    

    render() {
        var self = this;
        const {elementsLR, MetaMsgs, NotificationText} = this.state;
        let PREloader = <div style={{margin: "auto"}} dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)}></div>
        let LocalStorageRoomMessage;
        if(this.textareaMessageInput !== undefined){
            LocalStorageRoomMessage = localStorage.getItem('message'+self.roomid);
            let inputedMessage = this.textareaMessageInput.value;
            if(LocalStorageRoomMessage && !inputedMessage){
                this.textareaMessageInput.value = LocalStorageRoomMessage;
            }
        }
            return (
                <div className="page-chat">
                    <div className="container mb-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-body shadow" style={{ padding: 0, height: "calc(100vh - 127px)" }}>
                                    <div className="row">
                                        <div className="col-md-4 order-md-1 order-2 room-list" style={{paddingRight:0,height: "calc(100vh - 127px)"}}>
                                            <div className="listroom_toppanel">
                                                <div key={"RoomKey_0"}  id="listRoomTop">
                                                    <h4 class="pt-3" style={{paddingLeft:"20px"}}>Чат</h4>
                                                    <Link to="/chat/" title="Создать обращение" style={{ borderColor: "#0077a3" }} className="btn btn-biryza btn-biryza-mod createRoombtn"><img style={{width:"20px"}} src={"/assets/images/plus_chat_add.png"} />{/*<i class="fa fa-plus" aria-hidden="true"></i>/**/}</Link>
                                                </div>
                                            </div>
                                                {elementsLR ? <RoomList key={self.props.match.params.roomid} handleRoomChange={this.handleRoomChange} getRoomLoad={this.getRoomLoad} thisroomid={self.props.match.params.roomid} isAdvocat={self.state.isAdvocat} MetaRooms={elementsLR} /> : PREloader}
                                        </div>
                                        <div className="col-md-8 order-md-2 order-1 d-flex flex-column right-side-message-list"
                                                style={{
                                                    paddingLeft: "0"
                                                }}>
                                            <div className="" id="appeal"></div>
                                                {MetaMsgs ? <MessageList key={self.roomid} webSocket={self.client} MetaMsgs={MetaMsgs} /> : PREloader}
                                            <div className="message_form_panel">
                                                <form className='d-flex'>
                                                    <textarea
                                                        id="textarea_message"
                                                        className="textarea scroll"
                                                        onChange={(e)=>{localStorage.setItem('message'+this.roomid, e.target.value);}}
                                                        maxlength="599"
                                                        style={{
                                                            borderRadius: "4px 0 0 4px",
                                                            borderColor: "#04b6f5",
                                                            borderRight: "0",
                                                            padding: "5px 10px"
                                                        }}
                                                        ref={(input) => { if(this.textareaMessageInput == undefined)this.textareaMessageInput = input; }}
                                                    >{LocalStorageRoomMessage}
                                                    </textarea>
                                                    <div 
                                                        id="send_message" 
                                                        style={{borderRadius: "0 4px 4px 0", minWidth: 'max-content'}} 
                                                        onClick={this.sendMessage} 
                                                        className="btn btn-biryza-light float-right">
                                                        <b>Отправить</b><br /><small>(Ctrl+Enter)</small>
                                                    </div>
                                                </form>   <br />                                     
                                                <div className="js-upload" uk-form-custom="">
                                                    <form>
                                                        <input type="file" id="attache_files"  onChange={this.onFileSelection} ref={input => this.input_attache_files = input} className="d-none" multiple />
                                                        <button style={{ borderColor: "#04b6f5" }} className="btn btn-biryza btn-biryza-mod" title="Прикрепить файлы" onClick={this.handleAttacheFilesClick} type="button" tabIndex="-1">
                                                            <i className="fa fa-paperclip" alt="Прикрепить файлы"></i>
                                                        </button> &nbsp; | &nbsp;
                                                        <ReactMediaRecorder
                                                            audio
                                                            render={({ status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl }) => {
                                                                
                                                                return(
                                                                <>
                                                                    {status!=="recording" ? <>&nbsp; <button style={{ borderColor: "#04b6f5" }} onClick={startRecording} id="start" className="btn btn-biryza btn-biryza-mod" title="Начать запись аудиосообщения" type="button">
                                                                        &nbsp;<i className="fa fa-microphone" alt="Начать запись аудиосообщения"></i>&nbsp;
                                                                    </button></>
                                                                    :
                                                                     <>&nbsp; <button style={{ borderColor: "#04b6f5" }} onClick={() => {stopRecording(); self.setState({NotificationText:"Запись готова к отправке"});setTimeout(()=>self.setState({NotificationText:null}), 6000);}} id="stop" className="btn btn-biryza btn-biryza-mod" title="Остановить запись аудиосообщения" type="button">
                                                                        &nbsp;<i className="fa fa-microphone-slash" alt="Остановить запись аудиосообщения"></i>&nbsp;
                                                                    </button> </>}
                                                                    {status=="stopped" && <><audio src={mediaBlobUrl} controls style={{
                                                                        height: "30px",
                                                                        width: "50%",
                                                                        marginLeft: "10px",
                                                                    }} /> &nbsp; <button style={{ borderColor: "#04b6f5" }} onClick={() => {clearBlobUrl();self.setState({NotificationText:"Запись удалена"});setTimeout(()=>{self.setState({NotificationText:null});}, 6000);}} id="sendaudio" className="btn btn-biryza btn-biryza-mod" title="Удалить запись" type="button">
                                                                    &nbsp;<i className="fa fa-trash" alt="Удалить запись"></i>&nbsp;
                                                                </button> &nbsp; <button style={{ borderColor: "#04b6f5" }} onClick={() => {this.SendAudioMessage(mediaBlobUrl); clearBlobUrl();self.setState({NotificationText:"Запись отправленна"});setTimeout(()=>{self.setState({NotificationText:null});}, 6000);}} id="sendaudio" className="btn btn-biryza btn-biryza-mod" title="Отправить запись сообщением" type="button">
                                                                        &nbsp;<i className="fa fa-file-audio-o" alt="Отправить запись сообщением"></i>&nbsp;
                                                                    </button></>}
                                                                </>
                                                            );}}
                                                        />
                                                    </form>
                                                </div>
                                                <div className="clearfix" ></div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {NotificationText && <Notification>{NotificationText}</Notification>}
                </div>
            );
    }

}

export default ResourceViewer;