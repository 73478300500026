import React from 'react';
import './styles.css';

const DateBadge = ({ date }) => {
    return (
        <div className='date-badge-c'>
            <span className='date-badge'>{date}</span>
        </div>
    );
};

export default DateBadge;