import React from "react";

//import { Viewer, Worker } from '@react-pdf-viewer/core';
//import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import BreadCrumbs from "../elements/BreadCrumbs";
import PageTitle from "../elements/PageTitle";
import docStyle  from "./docs.module.css";
import AlertAccess from "../elements/AlertAccess";
import {PremiumAccess} from "../../layouts/personal-cab/PremiumAccess";

import {FieldsEnabled, FieldsDisabled} from "./layouts/fieldsTpl";


//import '@react-pdf-viewer/core/lib/styles/index.css';
//import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const fs = require('fs');
const pdf = require('pdf-page-counter');
//const defaultLayoutPluginInstance = defaultLayoutPlugin();

let tokenAPI = localStorage.getItem('tokenAPI');
class DocumentDetail extends React.Component{
    constructor(props) {
        super(props);
        this.state  = {
            notFound:true,
            description:'',
            variables: [],
            devServerUrl: "",
            file: null,
            filedocx: null,
            filepdf: null,
            crumbs:[],
            isPremium: Boolean(localStorage.getItem('isPremium')?(JSON.parse(localStorage.getItem('isPremium'))):false),
            isAuth: Boolean((localStorage.getItem('tokenAPI'))?true:false),
        };
    }

    getFormVariables = (id) => {
        let self = this;
        let url = global.apiLink+'/doc/document/get-form-variables?id='+id;
        fetch(url)
            .then(res => res.json())
            .then((response)=>{
                if(response.payload){
                    self.setState({ variables:response.payload.variables });
                }
            });
    }

    getDocFiles = (id) => {
        let self = this;
        let url = global.apiLink+'/doc/document/get-doc-files?id='+id;
        fetch(url)
            .then(res => res.json())
            .then((response)=>{
                if(response.payload){
                        let devServerUrl = global.apiLink+'/doc/document/convert?file-id='+response.payload.files.id+'#toolbar=0&navpanes=0';
                        if(response.payload.filepdfs)devServerUrl = global.apiLink+"uploads/"+response.payload.filepdfs.path+response.payload.filepdfs.name+"#toolbar=0&navpanes=0";
                        self.setState({
                        file:response.payload.files,
                        filedocx:response.payload.filedocxs,
                        filepdf:response.payload.filepdfs,
                        devServerUrl
                    })
                }
            });
    }

    getBreadcrumbs = (id) => {
        let self = this;
        let url = global.apiLink+'/doc/document/breadcrumbs?id='+id;
        fetch(url)
            .then(res => res.json())
            .then((response)=>{
                if(response.payload){
                    let crumbs = response.payload.reverse();
                    self.setState({ crumbs: crumbs })
                }
            });
    }

    componentDidMount() {
        let self = this;
        let id= this.props.match.params.id;

        self.getFormVariables(id);
        self.getBreadcrumbs(id);
        self.getDocFiles(id);

        if(tokenAPI !== undefined){

            let headers = new Headers();
            headers.append('Authorization', 'Bearer ' + tokenAPI);
            let url = global.apiLink+'/doc/document/get?id='+id;
            fetch(url,{
                headers:headers
            })
                .then(res => res.json())
                .then((response)=>{
                    if(response.payload){
                        self.setState({
                            name:response.payload.name,
                            description:response.payload.description,
                            notFound:false
                        })
                        
    
                    //Прилипание превьюшки НАЧАЛО
    
                        var docPreview = document.getElementById("docPreview");
                        var docForm = document.getElementById("docForm");
                        var docPreviewWidth = parseInt(docPreview.offsetWidth);
                        var docPreviewHeight = parseInt(docPreview.offsetHeight);
                        var docFormHeight = parseInt(docForm.offsetHeight);
                        let scrollTop = window.pageYOffset;
                        if(docFormHeight > docPreviewHeight){
                            var docPreviewBounding = docPreview.getBoundingClientRect();
                            var docPreviewTop = parseInt(scrollTop + docPreviewBounding.top),
                            heightWindow = window.innerHeight,
                            heightDocument = document.documentElement.scrollHeight;
                            docPreview.style.margin = "0!important";
                            
                            var TopMenuHeight = document.querySelector(".block_1 .site-header").offsetHeight;
        
                            window.addEventListener('scroll', function(event) {
        
                                scrollTop = window.pageYOffset;
                                let itemTranslate = Math.max(0, (scrollTop + heightWindow + (TopMenuHeight+5)) - heightDocument);
    
                                    if(scrollTop + heightWindow + (TopMenuHeight+5) >= heightDocument) {
                                        docPreview.style.position = "fixed";
                                        docPreview.style.width = docPreviewWidth+"px";
                                        docPreview.style.top = "auto";
                                        docPreview.style.bottom = (itemTranslate+5)+"px";
                                    }else if((docPreviewTop - (scrollTop + TopMenuHeight + 5)) < 0){
                                        docPreview.style.position = "fixed";
                                        docPreview.style.width = docPreviewWidth+"px";
                                        docPreview.style.top = (TopMenuHeight+5)+"px";
                                        docPreview.style.bottom = "auto";
                                    }else{
                                        docPreview.style.position = "relative";
                                        docPreview.style.width = "auto";
                                        docPreview.style.top = "auto";
                                        docPreview.style.bottom = "auto";
                                    }
                            });
                        }
    
                    //Прилипание превьюшки КОНЕЦ
    
    
                    }else{
                        let notFound = true;
                    }
    
                });
        }else{
            this.setState({
                name:null,
                description:null,
                variables:null,
                notFound:false,
                file:null,
                filedocx:null,
                filepdf:null
            })
        }

            

    }

    createMarkup = (s) => {
        return {__html: s};
    }


    onBtnDisable = (element) => {
        if (element){ 
            element.style.setProperty('clear', 'both', 'important');
            element.style.setProperty('float', 'none', 'important');
            element.style.setProperty('margin', 'auto', 'important');
        }
    }

    onBtnDisable2 = (element) => {
        if (element) {
            element.style.setProperty('float', 'right', 'important'); 
            element.style.setProperty('margin', '0', 'important');
        }
    }

    downloadFile = async (e) => {
        let self = this;
        e.preventDefault();
        //if(self.state.isPremium == false)return false;
        let url = global.apiLink + '/doc/document/generate';
        let formData = new FormData(e.target);
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + tokenAPI);
        let result = await fetch(url,{
            method:'POST',
            body:formData,
            headers:headers
        });
        let blob = await result.blob();

        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download=this.state.file.name;
        link.click();
    }

    PREloader = <div dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)}></div>

    render() {
		document.getElementById("root").style.background = "#fafafb";
		let self = this;
        var { 
            name, 
            devServerUrl, 
            crumbs, 
            notFound,
            variables,
            isPremium,
            description
         } = this.state;

		const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Сервисы',
                path: '/services/',				
            },			
            {
                name: 'Формы документов',
                path: '/docs/',				
            },
            {
                name: crumbs.length && crumbs[0].name,
                path: ((crumbs.length > 0) ? '/docs/part'+crumbs[0].id : ""),				
            },			
            {
                name: name,
                path: '',				
            },
        ];

        if(notFound){
           return (
               
            <div className="page-docs page">
                <div className="container ">
                    <div className="row">
					    <BreadCrumbs crumbs={breadcrumbs} />
					    <PageTitle pageTitle={name} />	
                            {/*<div className="col-md-12 quick-start-title-wrapper title-inner">
                                <div className="uk-h1 quick-start-header-title">{name}</div>
                                <div className="all-hr all-hr-2 color-lightblue"></div>
                            </div>
                            <div className="col-md-12 breadcrumbs mb-3">
                                <ul>
                                    <li className="">
                                        <a className="codexTreeFont" href="/docs/">
                                            Документы
                                        </a>
                                    </li>
                                    {BreadCrumbs && BreadCrumbs.map( 
                                        (v, index) =>{
                                            let link = "/docs/" + v.id;
                                            if(v.parent_id == 0){
                                                link = "/docs/part" + v.id;
                                            }
                                            if(v.id == ThisDocId){
                                                return(
                                                    <li className="">{v.name}</li> 
                                                );
                                            
                                            }else{
                                                return(
                                                <li className="">
                                                        <a className="codexTreeFont" href={link}>
                                                            {v.name}
                                                        </a>
                                                    </li> 
                                                );
                                            }
                                        }
                                            
                                        )}
                                    
                                </ul>
                            </div>
		*/ }
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row mb-4">
                                    <div className="col-lg-8">
                                        {this.state.description && this.state.description}
                                    </div>
                                    <div className="col-lg-4">
                                        <a href="/" className="btn btn-biryza btn-biryza-mod float-right">Скачать шаблон .docx</a>&nbsp;
                                        <a href="/" className="btn btn-biryza btn-biryza-mod float-right">Скачать шаблон .pdf</a>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-lg-5">
                                <div className="card" id="docForm">
                                    <div className="card-body">
                                        <div className="PREloader text-center">{this.PREloader}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7" style={{position: "relative"}}>
                                <div className="card" id="docPreview" style={{height:"850px",overflow:"hidden",marginBottom:"0!important"}}>
                                    
                                </div>
                            </div>
                        </div>
                </div>
            </div>
           )
        }
        
        return(
            <>
                <div className="page-docs page">
                    <div className="container ">
                        <div className="row">
                            <BreadCrumbs crumbs={breadcrumbs} />
                            <PageTitle pageTitle={this.state.name} />	
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <PremiumAccess CheckForPremium={true} text={<>
                                    <h4 className="alert-box_title alert-heading">Хотите получить возможность составить любой документ с помощью конструктора?</h4>
                                    <p>Полный доступ к списку документов и генерации любого из них по шаблону имеют только Премиум пользователи.</p>
                                </>} />
                            </div>
                            <div className="col-lg-12">
                                <div className="row mb-4">
                                    <div className="col-lg-8">
                                        {description??""}
                                    </div>
                                    <div className="col-lg-4">
                                        {this.state.filedocx && <>
                                            <a 
                                                target="_blank" 
                                                href={(isPremium)?(global.apiLink+"uploads/"+this.state.filedocx.path+this.state.filedocx.name):"#"} 
                                                className={"btn btn-biryza btn-biryza-mod "+(isPremium?"":" disabled")} 
                                                ref={e => self.onBtnDisable2(e)}
                                                disabled={(!isPremium)}
                                                >
                                                Скачать шаблон .docx
                                            </a>&nbsp;
                                        </>}
                                        {this.state.filepdf && <>
                                            <a 
                                                target="_blank" 
                                                href={(isPremium)?(global.apiLink+"uploads/"+this.state.filepdf.path+this.state.filepdf.name):"#"} 
                                                className={"btn btn-biryza btn-biryza-mod"+(isPremium?"":" disabled")} 
                                                ref={e => {
                                                    if (e) e.style.setProperty('float', 'right', 'important'); 
                                                }}
                                                disabled={(!isPremium)}
                                                >
                                                Скачать шаблон .pdf
                                            </a>
                                        </>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="card shadow-sm" id="docForm">
                                    <div className="card-body">
                                        <h4 className="mb-5">Параметры автозаполнения:</h4>
                                        <form onSubmit={(e)=>this.downloadFile(e)}>
                                            <input type="hidden" name="file-id" value={this.state.file.id}/>
                                            {variables.map( 
                                                (v, index) => {
                                                    return <>
                                                        {isPremium?
                                                            <FieldsEnabled index={index} {...v} />
                                                        :
                                                            <FieldsDisabled index={index} {...v} />
                                                        }
                                                        {/**}<fieldset key={index} className={"row mb-1 "+docStyle.fieldset_flex}>
                                                            <div className="col-md-4">
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <span className="small">{v.title}</span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <input type="hidden" name={"varval[" + index + "][name]"} value={v.name_variable}/>
                                                                <input type="text" name={"varval[" + index + "][value]"} placeholder={v.title}
                                                                    className={docStyle.input_control}/>
                                                            </div>
                                                        </fieldset>{/**/}
                                                    </>
                                                }
                                            ) }
                                            <div className="text-center template-download-panel mt-3">
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-biryza btn-biryza-mod" 
                                                    ref={e => self.onBtnDisable(e)}
                                                    disabled={!isPremium}
                                                    >
                                                    Сгенерировать документ
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7" style={{position: "relative"}}>
                                <div className="card shadow-sm" id="docPreview" style={{height:"850px",overflow:"hidden",marginBottom:"0!important"}}>
                                    {devServerUrl && <> <div className="PREloader text-center">{this.PREloader}</div>
                                        <object 
                                            type="application/pdf" 
                                            className="scroll" 
                                            onLoad={()=>{ document.querySelector("#docPreview .PREloader").remove(); }} 
                                            data={devServerUrl} 
                                            width="100%" 
                                            style={{
                                                height:"850px",
                                                pointerEvents:((isPremium)?"all":"none")
                                            }}
                                            disabled={(!isPremium)}
                                            >
                                            <param name="view" value="Fit" />
                                        </object></> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default DocumentDetail;