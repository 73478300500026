import React from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import PanelCard from './../panel/PanelCard';
import GridCard from './../panel/GridCard';
import SituationCard from './../panel/SituationCard';
import GridSituation from "./GridSituation";

import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";

//import BackButton from '../buttonback'
import {TreeCodex} from '../../App'

import "../../global.js";
import './situations.css';
import * as utils from '../../utils.js';

let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));

export class ResourceViewer extends React.Component {
    constructor() {
        super();

        this.state = {
            dataResourceQuickstart: '',
            resourceid: 0,
            list: [],
            count: 0,
            isPremium: Boolean(localStorage.getItem('isPremium')?(JSON.parse(localStorage.getItem('isPremium'))):false),
            isAuth: Boolean((localStorage.getItem('tokenAPI'))?true:false),
            pageData: [
                {
                    title: "",
                    items: [
                        {
                            href: "/situation/partkoap/",
                            bg: "/assets/images/panel/Icon_Admin.png",
                            title: "Практические рекомендации по КоАП РФ",
							description: ""
                           // description: "Сборник статей КоАП РФ для лиц, подвергшихся преследованию за административные правонарушения.",
                        },
                        {
                            href: "/situation/partuk/",
                            bg: "/assets/images/panel/Icon_Ugolov.png",
                            title: "Практические рекомендации по УК РФ",
							description: ""
                            //description: "Сборник статей УК РФ и типичные ситуации, знание которых поможет человеку, не знакомому с работой правоохранительных органов, в защите собственных прав и прав своих близких.",
                        },
                        {
                            href: "/situation/partnalog/",
                            bg: "/assets/images/panel/Icon_Nalogi.png",
                            title: "Практические рекомендации  по НК РФ",
							description: ""
                            //description: "Сборник статей НК РФ и типичные случаи, знание которых позволит эффективно разрешить спорные ситуации, связанные с ФСН.",
                        },
                    ]
                }
            ],
        }
    }

    utils = {
        dummy: document.createElement('div'),
        escapeHTML: function (s) {
            return {__html: s};
        }
    }


    gettingResourcesToAccardion = async (pid) => {
        let llLink = global.apiLink + `api/codex/codex/list?pid=${pid}&lvl=1`;
        const api_ResourceLL = await fetch(llLink, {
            headers: headers
        });
        let result = await api_ResourceLL.json();
        if (result.status !== 200) {
            alert("Не верные данные авторизации ll");
            return false;
        }
        return result;
    }

    getFindResources = async (text) => {
        let llLink = global.apiLink + `api/codex/codex/list?search=${text}&lvl=1`;
        const api_ResourceLL = await fetch(llLink, {
            headers: headers
        });
        let result = await api_ResourceLL.json();
        if (result.status !== 200) {
            alert("Неверные данные авторизации ll");
            return false;
        }
        return result;
    }


    gettingSearchResourcesToAccardion = async () => {
        let SearchInput = document.getElementById("input_search_on_head");
        if (SearchInput == null) return false;
        let text = SearchInput.value;
        let result = await this.getFindResources(text);
        this.setState({dataResourceLvl1: result});
        return "";
    }

    onKeyUp(event) {
        if (event.charCode === 13) {
            this.gettingSearchResourcesToAccardion();
        }
        return false;
    }

    gettingResources = async (pid) => {
        const tokenAPI = localStorage.getItem("tokenAPI");
        let output = {};
        const api_url = await fetch(global.apiLink + `api/situation/situation/list`, {
            headers: headers
        });
        output.quickstart = await api_url.json();
        if (output.quickstart.status !== 200) {
            alert("Не верные данные авторизации quickstart");
            return false;
        }
        if (pid == 0) return output;

        output.lvl1 = await this.gettingResourcesToAccardion(pid);
        console.log(output.lvl1);

        const api_url2 = await
            fetch(global.apiLink + `api/codex/codex/get?id=${pid}`, {
                headers: headers
            });
        output.page = await api_url2.json();
        if (output.page.status !== 200) {
            alert("Не верные данные авторизации page");
            return false;
        }

        let breadcrumb_link = global.apiLink + "api/codex/codex/breadcrumb-codex?id=" + pid + "&title=1";
        const breadcrumbR = await fetch(breadcrumb_link, {
            headers: headers
        });
        output.breadcrumb = await breadcrumbR.json();
        if (output.breadcrumb.status !== 200) {
            // alert("Не верные данные авторизации breadcrumb");
            return false;
        }

        let uriget2 = global.apiLink + `api/qa/qa/list?codex_id=${pid}&getcontent=1`;
        let api_urlget2 = await fetch(uriget2, {
            headers: headers
        });
        output.qa = await api_urlget2.json();
        if (output.qa.status !== 200) {
            alert("Не верные данные авторизации tv");
            return false;
        }
        //let uriget = global.apiLink+`api/templates/templateariable/get?token=${tokenAPI}&resourceid=${pid}&tvid=1`;
        let uriget3 = global.apiLink + `api/situation/situation/list?&codex_id=${pid}&getcontent=1`;
        let api_urlget3 = await fetch(uriget3, {
            headers: headers
        });
        output.situations = await api_urlget3.json();
        if (output.situations.status !== 200) {
            console.log(output.situations);
            alert("Неверные данные авторизации situations");
            return false;
        }

        return output;
    }


    parentFunc = (text) => {
        this.setState({dataResourceList: text});
    }

    createMarkup = (s) => {
        return {__html: s};
    }

    subElements = (nodes) => {
        return (
            <div>
                {nodes &&
                nodes.map(p => (
                    <div key={p.element.id}>
                        <Link to={`/codex/${p.base64_uid}/`} onClick={() => this.setState({"dataResourceList": ""})}>
                            <span dangerouslySetInnerHTML={this.createMarkup(p.element.title)}/>
                        </Link>

                        {p.element.punkts && this.showPunkts(p.element.punkts)}
                        {p.nodes && this.subElements(p.nodes)}
                    </div>
                ))
                }
            </div>
        );
    }

    showPunkts = (json) => {
        let dataResourceTvalue = [];
        if (json !== undefined)
            var tmp = json;//JSON.parse(json);
        for (var dataResourceTVItem in tmp) {
            dataResourceTvalue[dataResourceTVItem] = tmp[dataResourceTVItem];
        }

        return (
            <div>
                {json && dataResourceTvalue.map((answer, i) => {
                        let content = dataResourceTvalue[i].content;
                        let title = dataResourceTvalue[i].title;
                        return (
                            <div key={i}>
                                <div
                                    dangerouslySetInnerHTML={this.createMarkup("<span style='float:left;'>" + (title) + ".&nbsp;</span>" + content)}/>
                                <br/>
                            </div>
                        );
                    }
                )}
            </div>
        );
    }

    sortData = (key, data, type) => {
        let ordered = {};
        let compareFunction = function (a, b) {
            return data[b][key] - data[a][key];
        };
        if (type === "asc") {
            compareFunction = function (a, b) {
                return data[a][key] - data[b][key];
            }
        }
        Object.keys(data).sort(compareFunction).forEach(function (key) {
            ordered[key] = data[key];
        });
        return ordered;
    }

    quickStartAllBtn = () => {
        return "";
    }

    subResOpen = async (ElId, TypeNode, B64uid) => {
        let Element = document.getElementById(ElId);
        if (TypeNode == "article") {
            document.location.href = ("/codex/" + B64uid);
            return false;
        }
        let dataid = Element.getAttribute("data-id");
        let nodes = await this.gettingResourcesToAccardion(dataid);
        ReactDOM.render(
            <div>
                {}
                {nodes &&
                nodes.payload.list.map(p => (
                    <div>
                        <div className={"uk-accordion-" + p.type_node}
                             onClick={() => this.subResOpen("ac_" + p.id, p.type_node, p.base64_uid)}>
                            <span dangerouslySetInnerHTML={this.createMarkup(p.title)}/>
                        </div>
                        {/*p.content && <span class="uk-accordion-article-content" dangerouslySetInnerHTML={this.createMarkup(p.content)} />/**/}
                        <div className="uk-accordion-content" id={"ac_" + p.id} data-id={p.id}></div>
                    </div>
                ))
                }
            </div>,
            Element
        );
    }

    getComplete = async () => {
		
        let query = document.querySelector("form input[name=q]").value;
        let target = document.querySelector("input[name=target]").value;		
		

        if (query != null) {
        	query = utils.escapeAndTrim(query);
        } else {
			query = '';
		}
        console.log('query=' + query);
        if (query.length < 3) {
        	document.querySelector(".situation_search_autocomplete").innerHTML = '';
        	return false;
        }

        if (target != null) {
        	target = utils.escapeAndTrim(target);
        } else {
			target = '';
		}
        if (!target || !utils.elementInArray(target, ['situation'])) {
        	target = "situation";
        }
		
        console.log('target=' + target);
       	//target = '&target=' + target;

        let url = global.apiLink + '/api/search/complete?q=' + query + '&target=' + target;		
		
        const api_ResourceLL = await
            fetch(url, {
                headers: headers
            });

        var output = await api_ResourceLL.json();

        if (output.status == 200) {
			
        	let list = output.payload.list;
        	let resultComplete = "";
        	for (let i = 0; i < list.length; i++) {
        		let linkTitleTmp = list[i].tags;
        		let linkUrlTmp = '/search/?target=' + target + '&q=' + linkTitleTmp.split(" ").join("+");
        		resultComplete += "<div onclick='window.location.href=\"" + linkUrlTmp + "\";'>" + linkTitleTmp + "</div>";
        	}

        	let curQuery = document.querySelector("form input[name=q]").value;
        	if (curQuery != null) {
        		curQuery = utils.escapeAndTrim(curQuery);
        	} else {
				curQuery = '';
			}
        	console.log('curQuery=' + curQuery);
        	if (curQuery.length > 2) {
        		document.querySelector(".situation_search_autocomplete").innerHTML = resultComplete;
        	} else {
        		document.querySelector(".situation_search_autocomplete").innerHTML = '';
        	}			
			
			
			
        } else {
            return false;
        }
    }


    newPageLoader = async (pid, pageNum, pageId) => {
        let llLink = global.apiLink + `api/codex/codex/list?pid=${pid}&recursive=2&page=${pageNum}`;
        const api_ResourceLL = await
            fetch(llLink, {
                headers: headers
            });
        console.log(llLink);
        var output = await api_ResourceLL.json();
        if (output.status !== 200) {
            alert("Неверные данные авторизации");
            return false;
        }
        if (pageNum > output.payload.pagecount) {
            document.getElementById(pageId).innerHTML = "";
            return false;
        }
        let nodes = output.payload.list.nodes;

        ReactDOM.render(
            <div>
                {nodes &&
                nodes.map( p => (
                    <div key={p.id}>
                        <a href={`/codex/${p.base64_uid}/`} onClick={() => this.setState({"dataResourceList": ""})}>
                            <span dangerouslySetInnerHTML={this.createMarkup(p.title)}/>
                        </a>
                        <div className="element-content" dangerouslySetInnerHTML={this.createMarkup(p.content)}></div>
                        {p.punkts && this.showPunkts(p.punkts)}
                    </div>
                ) )
                }
            </div>,
            document.getElementById(pageId)
        );
        document.querySelector('body').removeAttribute("data-paginationatwork");

    }
  
    componentDidMount() {
        let self = this;
        const alias = self.props.match.params.alias;
        let resourceId = 0;
        if(alias) resourceId = atob(alias);
        self.gettingResources(resourceId).then(output => {
            self.setState({
                dataResourceQuickstart: output.quickstart,
                dataResourceQa: output.qa,
                dataResourceSituation: output.situations,
            });
            //self.createQATree(output.quickstart.payload.list);
        });
        /* 
        const urlParams = new URLSearchParams(window.location.search);
        let query = urlParams.get('q');
        if(query !== null){
            let url = global.apiLink + '/api/search?q=' + query + '&target=situation';
            console.log(url);
            fetch(url)
                .then(response => response.json())
                .then((response) => {
                    this.setState({
                        list: response.payload.list,
                        count: response.payload.count
                    });
                    document.querySelector(".situation-search-input").value=query;
                });
        }*/
    }
            
    getBreadcrumbs = async(pid, tgt) => {
        let url = global.apiLink+"/api/codex/codex/breadcrumb-codex?id="+pid+"&title=1";
        fetch(url, {
                headers:headers
            })
            .then(res=>res.json())
            .then(
                (result)=>{
                    console.log(result);
                    if(result.status==200 && result.payload){ 
                        let output = "";
                        let elCount = result.payload.length;
                        let elCounter = 0;
                        result.payload.map( ( element ) => { 
                            if((elCount - 1) > elCounter){
                                let arrTmp = Object.values(element);
                                let elText = arrTmp[0].trim().replace(/^&nbsp;(.*)&nbsp;$/g, '$1');
                                output += '<li class="breadcrumb-item">'+
                                    '    <a class="codexTreeFont" href="/codex/'+arrTmp[1]+'">'+
                                    '        '+elText+' '+
                                    '    </a>'+
                                    '</li> ';
                            }
                                elCounter++;
                        } );
                        document.querySelector("#bcs_"+tgt+"_"+pid).innerHTML = output;
                    }
                }
        );
    }

    render() {
        let self = this;
        const { 
            dataResourceQuickstart,
            isPremium,
            isAuth
         } = this.state;
        
        const alias = self.props.match.params.alias;
        let resourceId = 0;
        if (alias) resourceId = atob(alias);

        if (dataResourceQuickstart != '') {


            let searchResult = this.state.list.map((element) =>{
                let TargetElement = "Кодекс";
                let TargetElementClass = "text-success";
                if(element.target == "codex"){
                    this.getBreadcrumbs(element.elid, element.target);
                }
                if(element.target == "situation"){
                    this.getBreadcrumbs(element.elid, element.target);
                    TargetElement = "Ситуации";
                    TargetElementClass = "text-warning";
                }
                if(element.target == "qa"){
                    this.getBreadcrumbs(element.elid, element.target);
                    TargetElement = "Вопрос-ответ";
                    TargetElementClass = "text-danger";
                }
                if(element.target == "docs"){
                    //this.getBreadcrumbs(element.elid, element.target);
                    TargetElement = "Документ";
                    TargetElementClass = "text-info";
                }
                let elText = element.title.trim().replace(/^&nbsp;(.*)&nbsp;$/g, '$1');
                return (<>
                    <div className='search_item_wrapper'>
                            <h5 className="search_title">
                                <i className={"fa fa-file-text "+TargetElementClass}></i>&nbsp;&nbsp;&nbsp;
                                <a href={element.link}>{TargetElement}: {elText}</a>
                            </h5>
                            <ul className="breadcrumb breadcrumb-search" id={"bcs_"+element.target+"_"+element.elid}></ul>
                            {element.content !=='' &&
                                <div className="clear post_clear" dangerouslySetInnerHTML={{ __html: element.content}}></div>
                            }

                        <hr />
                    </div>
                </>);
        });

		document.getElementById("root").style.background = "#fafafb";
		const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Сервисы',
                path: '/services/',				
            },
            {
                name: 'Практические правовые рекомендации',
                path: '',				
            },			
        ];	

            return (
                <>
                    <div className="page-situation page">
                        <div className="container">
                            <div className="row">
							    <BreadCrumbs crumbs={breadcrumbs} />
							    <PageTitle pageTitle="Практические правовые рекомендации" />
							</div>
							<div className="row">
                                <div className="col-md-8">
							        {isAuth ? <div className="situation-search-wrapper">
                                        <form className="uk-search" method="GET" action="/search/" style={{display: "block"}}>
                                            <input type="hidden" name="target" value="situation" />
                                            <label htmlFor="search"><i className="fa fa-search" aria-hidden="true"></i><span className="sr-only">Поиск</span></label>
                                            <input type="search" name="q" onChange={()=>{this.getComplete()}} placeholder="Что делать, если..." autocomplete="off" className="situation-search-input" minLength={3} maxLength={255} required="required" />
                                            <button type="submit" className="search_btn" >Найти</button>
                                            <div className="situation_search_autocomplete"></div>
                                        </form>
		                            </div> : <></>}
                                    <div className='row'>
                                        <div className='col-md-12 mt-3'>
                                            {this.state.pageData.map((block, i) => {
                                                    return (<>
															<ul className="situation-list">
                                                                {
                                                                    block.items.map(item => <SituationCard key={item.href} {...item} />)
                                                                }															
															</ul>
                                                    </>);
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 page-description__desktop">
                                    <div className="row situation-description">
                                        <p>Раздел содержит практические разъяснения и рекомендации по действиям в экстреных ситуациях для граждан и предпринимателей по всем статьям административного, уголовного и налогового кодексов РФ.</p>
										<p>Этот раздел ориентирован на простых граждан, не имеющих специальных юридических познаний.</p>
										<p>Пользуясь данным разделом, Вы получите реальные и практические рекомендации по поведению в экстренных ситуациях, содержащие советы по действиям для достижения желаемого положительного результата при производстве по уголовным, административным делам.</p>
										<p>Следуя данным рекомендациям, создается правильная правовая ситуация, облегчающая в последующем работу адвокатов по тому или иному делу следующего нашим рекомендациям гражданина или предпринимателя.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <br /><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );

        } else {
            return (
                <>
                </>
            );
        }
    }
}

export default ResourceViewer;