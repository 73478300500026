import React, { useRef, useState, useEffect } from "react";
import Peer from "peerjs";
import FemidaLoader from "../FemidaLoader"
//import { useNavigate } from "react-router-dom";

import { ReactComponent as DeclineIcon } from "./Call_node/decline.svg";
import 'bootstrap';
import './call.css';
import s from './Call_node/call.module.css'
import Person from './person.svg';
import "../../global";

var PingIntervalId,PagingPeer,CallPeer,MyPeer = null;

const VideoStreamPlayer = (props) => {
    
    navigator.getMedia = ( navigator.getUserMedia || // use the proper vendor prefix
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia
    );
    //const [MyPeer, setMyPeer] = useState(null);
    const [MyPeerId, setMyPeerId] = useState(null);
    const [gotStream, setGotStream] = useState(false);
    const [VideoAvalable, setVideoAvalable] = useState(false);
    const [AudioAvalable, setAudioAvalable] = useState(false);
    //const [tokenAPI, setAPIToken] = useState(null);
    const [onCall, setOnCall] = useState(false);
    //const [mediaStream, setMediaStream] = useState(null);
    //const [CallingPeer, setCallingPeer] = useState(null);
    const [StatusCalling, setStatusCalling] = useState("");
    const [MyRoomId, setMyRoomId] = useState(null);
        
    const RefVideoBig = useRef(null);
    const RefVideoSmall = useRef(null);

    useEffect(() => {
        //setAPIToken(localStorage.getItem('tokenAPI'));
        //if (!tokenAPI) {useNavigate('/login', {replace: true});return;}

        navigator.getMedia({video: true}, function() {
            setVideoAvalable(true);
        }, function() {
            setVideoAvalable(false);
        });
        navigator.getMedia({audio: true}, function() {
            setAudioAvalable(true);
        }, function() {
            setAudioAvalable(false);
        });

        MakePeerConnection();

    }, []);


    const MakePeerConnection = () => {
        let callOptions={
            host:"api.my-advo.cat",
            port:"2087",
            path:'/',
            config:{
                'iceServers': [
                    //{urls:'stun:stun01.sipphone.com'},
                    //{urls:'stun:stun.ekiga.net'},
                    //{urls:'stun:stun.fwdnet.net'},
                    //{urls:'stun:stun.ideasip.com'},
                    //{urls:'stun:stun.iptel.org'},
                    //{urls:'stun:stun.rixtelecom.se'},
                    //{urls:'stun:stun.schlund.de'},
                    //{urls:'stun:turn1.woodycart.ru:3478'},
                    //{urls:'turn:turn1.woodycart.ru:3478'},
                    //{
                        //username: 'turnuser1',
                        //credential: 'tu2pVDfcds',
                        //urls:'stuns:turn1.woodycart.ru:5349'
                    //}//,
                    {urls:'stun:stun1.l.google.com:19302'},
                    {urls:'stun:stun2.l.google.com:19302'},
                    //{urls:'stun:stun3.l.google.com:19302'},
                    //{urls:'stun:stunserver.org'},
                    //{urls:'stun:stun.softjoys.com'},
                    //{urls:'stun:stun.voiparound.com'},
                    //{urls:'stun:stun.voipbuster.com'},
                    //{urls:'stun:stun.voipstunt.com'},
                    //{urls:'stun:stun.voxgratia.org'},
                    //{urls:'stun:stun.xten.com'}
                ]
            },
            debug: 3
        };
        MyPeer = new Peer(callOptions);
        MyPeer.on('open', function (MyPeerId){
            RegisterPeerAbonement(MyPeerId);
            //MakeUserOnline();
            setMyPeerId(MyPeerId);
        });

        MyPeer.on('error', function(err) { 
            console.log("error");
            console.log(err);
         });
        //MyPeer = peer;
    }

    /*const MakeUserOnline = () => {
        let IAmOnline = () => {
            let headers = new Headers();
            headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
            let peer_fetch_Url = 'https://api.my-advo.cat/api/call/call/set-user-online/';
            fetch(peer_fetch_Url, {
                'headers': headers, 
                'method': "GET",
            })
            .then(res => res.json())
            .then((response) => {
                if(response.status == 200){
                    console.log("В сети");
                }else{
                    console.log("Ошибка получения статуса В сети");
                }
            });
            setTimeout(()=>IAmOnline(), 45000);
        }
        IAmOnline();
    }/**/

    const RegisterPeerAbonement = (peerId) => {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let peer_fetch_Url = 'https://api.my-advo.cat/api/call/call/add-abonent/?peer_id='+peerId;
        fetch(peer_fetch_Url, {
            'headers': headers, 
            'method': "GET",
        })
        .then(res => res.json())
        .then((response) => {
            if(response.payload.success === true){
                console.log(response.payload.message);
                console.log("Получен абонентский номер");
            }else{
                console.log(response.payload.message);
                console.log("Ошибка получения абонентского номера");
            }
        });
    }
    
    /*const makeMediaStream = (type) => {
        let LetmediaStream = null;
        let UserMedia = {audio: true,video: false};
        if(type == "video")UserMedia = {audio: true,video: true};
        try {
            LetmediaStream = navigator.mediaDevices.getUserMedia(UserMedia);
            console.log("LetmediaStream created");
            console.log(UserMedia);
        } catch(e) {
            console.log("LetmediaStream error");
            console.log(UserMedia);
            console.log(e);
            LetmediaStream = null;
        }
        return(LetmediaStream);
    }/**/


    const makePagingPeer = (peer_id, type)=>{
        //let LetmediaStream = null;
        let UserMedia = {audio: true,video: false};
        if(type == "video" && VideoAvalable == true)UserMedia = {audio: true,video: true};
        navigator.getMedia(UserMedia, (MyMediaStream)=>{
            if (RefVideoSmall.current) RefVideoSmall.current.srcObject = MyMediaStream;
                                        
            PagingPeer = MyPeer.connect(peer_id);

            PagingPeer.on('open', function() {
                setTimeout(()=>{
                    let sendData = JSON.stringify({
                        do: "createRoom", 
                        clientPeerId: MyPeerId, 
                        operatorPeerId: peer_id
                    });
                    console.log(sendData)
                    PagingPeer.send(sendData);
    
                    /*PingIntervalId = setInterval(function() {
                            let sendData = JSON.stringify({do: "postPing"});
                            console.log(sendData)
                            PagingPeer.send(sendData);
                        }, 5000);/**/
                }, 500);
            });
            PagingPeer.on('data', function(data) {
                console.log('Полученные данные:', data);
                console.log(data);
                console.log(typeof(data));
                let response = JSON.parse(data); 
                if(response.do !== undefined){
                    switch (response.do){
                        case 'makeCall': 
                                setMyRoomId(response.room_id);
                                console.log("Получен room ID:" + response.room_id);
                                //makeCallingPeer(peer_id);
                                try {
                                    CallPeer = MyPeer.call(peer_id, MyMediaStream);
                                    console.log(CallPeer);
                                    //setCallingPeer(CallPeer);
                                    
                                    CallPeer.on('stream', function (mediaStreamOperator) { //нам ответили, получим стрим
                                        setStatusCalling(" ");
                                        if (RefVideoBig.current) RefVideoBig.current.srcObject = mediaStreamOperator;
                                        console.log('clientVideo & myVideo set stream');
                                        setGotStream(true);
                                    });
                                    CallPeer.on('close', function() { 
                                        console.log("CallPage - line 261 - MyPeer CallPeer close");
                                     });
                                    CallPeer.on('error', function(e) { 
                                        console.log("CallPage - line 262 - MyPeer CallPeer error");
                                        console.log(e);
                                    });
                                } catch (error) {
                                    console.log("error");
                                    console.log(error);
                                }
                            break;
                        case 'closeRoom':
                                CallDisconnect();
                            break;
                        /*case 'postPong':    
                                console.log(response);
                            break;/**/
                    }
                }
            });
            PagingPeer.on('error', function(err) { 
                console.log("CallPage - line 278 - MyPeer PagingPeer error");
                console.log(err);
             });
             PagingPeer.on('close', function(err) { 
                 console.log("CallPage - line 284 - MyPeer PagingPeer close");
                 clearInterval(PingIntervalId);
              });
        });
            
        

    }
/*
    const makeCallingPeer = async (peer_id)=>{
        let calling = MyPeer.call(peer_id, mediaStream);
            
            let TryToGetStream = () => {
                try {
                    console.log(MyPeer);
                    console.log(calling);
                    calling.on('stream', function (mediaStreamOperator) { //нам ответили, получим стрим
                        setStatusCalling(" ");
        
                        if (rsRef.current) rsRef.current.srcObject = mediaStreamOperator;
                        console.log('clientVideo & myVideo set stream');
                        setGotStream(true);
                    });
                    calling.on('close', function() { 
                        console.log("CallPage - line 261 - MyPeer calling close"); });
                    calling.on('error', function(e) { 
                        console.log("CallPage - line 262 - MyPeer calling error");
                        console.log(e);
                    });
                    setCallingPeer(calling);
                } catch (error) {
                    console.log(error);
                    setTimeout(()=>{TryToGetStream();}, 500);
                }
            }
            
        setTimeout(()=>{
            console.log("calling");
            console.log(calling);
            TryToGetStream();
        }, 1000);
    }/**/

    const CallDisconnect = ()=>{
        //clearInterval(PingIntervalId);
        if(PagingPeer !== null){
            let sendData = JSON.stringify({do: "closeRoom", room_id: MyRoomId});
            console.log(sendData)
            PagingPeer.send(sendData);
            PagingPeer = null;
        }
        setGotStream(false);
        setOnCall(false);
        setMyRoomId(null);
    }


    const CallConnecting = async (type) => {
        setOnCall(true);
        //makeMediaStream(type);
        setStatusCalling("Поиск свободного оператора");
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let peer_fetch_Url = 'https://api.my-advo.cat/api/call/call/get-free-operator/';
        fetch(peer_fetch_Url, {
            'headers': headers, 
            'method': "GET",
        })
        .then(res => res.json())
        .then((response) => {
            if(response.payload.success === true){
                setStatusCalling("Оператор найден. <br /> Ждём ответа оператора...");
                let peer_id = response.payload.peer_id;
                let userId = response.payload.user_id;
                makePagingPeer(peer_id, type);
            }else{
                setTimeout(()=>{
                    //setMediaStream(null);
                    CallConnecting(type);
                }, 2000);
                return;
            }
        });
    }

    return (
        <>
                                  {onCall ? 
                                      <>
                                          <div>
                                              <div className="position-relative">
                                              <video id="clientVideo" ref={RefVideoBig} className={s.rs} autoPlay playsInline  />
                                              <video id="myVideo" ref={RefVideoSmall} className={s.ls} autoPlay playsInline muted />
                                                  {/*<video className={s.ls} ref={lsRef} autoPlay playsInline />
                                                  <video className={s.rs} ref={rsRef} muted autoPlay playsInline />*/}
                                                  { !MyRoomId && <div className={s.loader}><FemidaLoader /> <p dangerouslySetInnerHTML={{__html:StatusCalling}} /></div> }
                                                  <div className={s.controls}>
                                                      <DeclineIcon onClick={() => CallDisconnect()} className={s.decline} />
                                                  </div>
                                              </div>
                                          </div> 
                                      </>
                                      :
                                      <>
                                        <div class="text-center max-w-[500px]">
                                            <span>
                                                <img src={Person} />
                                            </span>
                                            <h1 class="font-medium tracking-wide text-2xl mb-3">Позвонить</h1>
                                            <p class="text-gray-300 font-light tracking-wide">Получите совет по видеозвонку о том, как<br /> действовать в экстренной правовой ситуации<br /> от ведущих адвокатов и юристов России.</p>
                                        </div>
                                          <div class="flex flex-col text-center gap-3">
                                              <div class="flex flex-col items-center gap-1">
                                                  {!MyPeerId ? <small class="text-gray-300 font-light" style={{"display":"block"}}>
                                                          <div class="spinner-border h-4 w-4 opacity-50" role="status"></div>
                                                      </small>:
                                                      <>
                                                      <br />
                                                          {localStorage.getItem('tokenAPI') && (gotStream == false) && <>
                                                              <div role="button" class="inline-flex items-center gap-2 select-none _callbtn_ecqgq_1" onClick={(e) => {e.preventDefault();CallConnecting('video');}}>
                                                                  <span>Позвонить нам </span>
                                                                  <svg width="25" height="25" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                      <path d="M2 5.88889C2 4.85749 2.40972 3.86834 3.13903 3.13903C3.86834 2.40972 4.85749 2 5.88889 2H12.2667C12.6746 2.00031 13.0721 2.12891 13.4029 2.3676C13.7337 2.60628 13.9811 2.94297 14.11 3.33L17.0228 12.0664C17.1704 12.5104 17.1529 12.9927 16.9736 13.4249C16.7943 13.857 16.4652 14.21 16.0467 14.4192L11.6581 16.6164C13.8092 21.3774 17.6226 25.1908 22.3836 27.3419L24.5808 22.9533C24.7899 22.5348 25.143 22.2057 25.5751 22.0264C26.0073 21.8471 26.4896 21.8296 26.9336 21.9772L35.67 24.89C36.0573 25.019 36.3943 25.2667 36.633 25.5979C36.8717 25.9291 37.0001 26.327 37 26.7353V33.1111C37 34.1425 36.5903 35.1317 35.861 35.861C35.1317 36.5903 34.1425 37 33.1111 37H31.1667C15.0589 37 2 23.9411 2 7.83333V5.88889Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                  </svg>
                                                              </div>
                                                              <br />
                                                              <div role="button" class="inline-flex items-center gap-2 select-none scale-75 _callbtn_ecqgq_1"onClick={(e) => {e.preventDefault();CallConnecting("audio");}}>
                                                                  <span>Позвонить нам (аудио) </span>
                                                                  <svg width="25" height="25" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                      <path d="M2 5.88889C2 4.85749 2.40972 3.86834 3.13903 3.13903C3.86834 2.40972 4.85749 2 5.88889 2H12.2667C12.6746 2.00031 13.0721 2.12891 13.4029 2.3676C13.7337 2.60628 13.9811 2.94297 14.11 3.33L17.0228 12.0664C17.1704 12.5104 17.1529 12.9927 16.9736 13.4249C16.7943 13.857 16.4652 14.21 16.0467 14.4192L11.6581 16.6164C13.8092 21.3774 17.6226 25.1908 22.3836 27.3419L24.5808 22.9533C24.7899 22.5348 25.143 22.2057 25.5751 22.0264C26.0073 21.8471 26.4896 21.8296 26.9336 21.9772L35.67 24.89C36.0573 25.019 36.3943 25.2667 36.633 25.5979C36.8717 25.9291 37.0001 26.327 37 26.7353V33.1111C37 34.1425 36.5903 35.1317 35.861 35.861C35.1317 36.5903 34.1425 37 33.1111 37H31.1667C15.0589 37 2 23.9411 2 7.83333V5.88889Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                  </svg>
                                                              </div>
                                                              </>
                                                          }
                                                          {(gotStream == true) && (MyRoomId == false) && <>
                                                              <div className="card-body">
                                                                  <h4 className="card-title"><b>Идёт соединение c оператором.</b></h4>
                                                              </div>
                                                          </>}
                                                      </>
                                                  }
                                                  {!localStorage.getItem('tokenAPI') && <> 
                                                      <div className="card-body">
                                                          <h4 className="card-title"><b>Звонок доступен только авторизованным пользователям.</b></h4>
                                                      </div></>
                                                  }
                                              </div>
                                              <a class="self-center" href="/">
                                                  <button class="femida-btn flex items-center gap-2" loading="false">
                                                      <span>На главную </span>
                                                      <svg width="25" height="25" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M2 17.1767L5.33333 13.8041M5.33333 13.8041L17 2L28.6667 13.8041M5.33333 13.8041V30.6671C5.33333 31.1143 5.50893 31.5432 5.82149 31.8594C6.13405 32.1757 12.7365 31.8594 13.1785 31.8594C13.4911 31.5432 13.6667 31.1143 13.6667 30.6671V23.9219C13.6667 23.4746 13.8423 23.0457 14.1548 22.7295C14.4674 22.4132 14.8913 22.2356 15.3333 22.2356H18.6667C19.1087 22.2356 19.5326 22.4132 19.8452 22.7295C20.1577 23.0457 20.3333 23.4746 20.3333 23.9219V30.6671C20.3333 31.1143 20.5089 31.5432 20.8215 31.8594C-15.5714 31.8594 27.866 32.1757 28.1785 31.8594C28.4911 31.5432 28.6667 31.1143 28.6667 30.6671V13.8041M28.6667 13.8041L32 17.1767" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                      </svg>
                                                  </button>
                                              </a>
                                          </div>

                                      </>
                                  }
        </>
    );
}

class CallResource extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            state:0
        };
    }

    render() {

        return(
            <>
            <div className="page-call page">
                <div className="container">
                    <div className="row">
                        <div class="mb-4"><h1>Позвонить</h1><hr class="call_hr__3PDNh" /></div>
                        <div class="col-md-12">
                            <div class="call-new mt-0">
                                <div class="text-white flex flex-col gap-5 items-center px-3">
                                    <VideoStreamPlayer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );

    }

}

export default CallResource;