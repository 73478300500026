import React from 'react';
//import {Link} from 'react-router-dom';
import JuristType_List from './juristtype_list'
import States_List from './states_list'
import Citys_List from './citys_list'
import Jurist_List from './jurist_list'
import Jurist_Page from './jurist_page'
import "../../global.js";

export class SearchPage extends React.Component {

  
    render(){
        
        const {
            juristid,
            cityid,
            stateid,
            juristtype
        } = this.props.match.params;

        if(juristid){
            return (
                <>
                    <Jurist_Page juristtype ={juristtype} cityid = {cityid} stateid = {stateid} juristid = {juristid} />
                </>
            );
        }else if(cityid){
            return (
                <>
                    <Jurist_List juristtype ={juristtype} cityid = {cityid} stateid = {stateid} />
                </>
            );
        }else if(stateid){
            return (
                <>
                    <Citys_List juristtype ={juristtype} stateid = {stateid} />
                </>
            );
        }else if(juristtype){
            return (
                <>
                    <States_List juristtype ={juristtype}/>
                </>
            );
        }
            return (
                <>
                    <JuristType_List />
                </>
            );
    }
}

export default SearchPage;