import React from "react";
//import docStyle  from "./docs.module.css";
//import Document from "./document";
import GridDocument from "./GridDocument";
//import AlertAccess from "../elements/AlertAccess";
import BreadCrumbs from "../elements/BreadCrumbs";
import PageTitle from "../elements/PageTitle";

const tokenAPI = localStorage.getItem('tokenAPI');
let headers = new Headers();
headers.append('Authorization', 'Bearer ' + tokenAPI);

class DocPartDetail extends React.Component{
    constructor(props) {
        super(props);
        this.state  = {
            notFound:true,
            description:'',
            DocList: [],
            documents: [],
            crumbs:[],
            name:""
        };

        this.getDocList = (part_id) =>{
            let url = global.apiLink+'/doc/document/list?parent_id='+part_id;
                fetch(url, {
                    headers:headers
                })
                .then(res => res.json())
                .then((response)=>{
                    console.log(response);
                    if(response.payload){
                        let DocList = Object.values(response.payload);
                        this.setState( {
                            DocList: DocList,
                            notFound : false
                        } )
                    } else {
                        this.setState( {notFound : true});
                    }

                })
        }
    }

    componentDidMount() {
        let id = this.props.match.params.part;
		console.log('part='+id);

        this.getDocList(id);
        let url = global.apiLink+'/doc/document/breadcrumbs?id='+id;

        fetch(url,{
            headers:headers
        })
            .then(res => res.json())
            .then((response)=>{
                console.log(response);
                if(response.payload){
                    let crumbs = response.payload.reverse();
                    this.setState({
                        crumbs: crumbs
                    })
                    console.log(response.payload);
                }
        });

        
        url = global.apiLink+'/doc/document/get?id='+id;
        fetch(url,{
            headers:headers
        })
            .then(res => res.json())
            .then((response)=>{
                if(response.payload){
                    this.setState({
                        name:response.payload.name,
                    })
                }

        });

    }

    createMarkup = (s) => {
        return {__html: s};
    }
    
    downloadFile = async (e) => {
        e.preventDefault();
        let url = global.apiLink + '/doc/document/generate';
        let formData = new FormData(e.target);
        let result = await fetch(url,{
            method:'POST',
            body:formData,
            headers:headers
        });
        let blob = await result.blob();

        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download=this.state.file.name;
        link.click();
    }


    PREloader = <div dangerouslySetInnerHTML={this.createMarkup(global.CssLoader)}></div>

    render() {
		document.getElementById("root").style.background = "#fafafb";
		
        let ThisDocId = this.props.match.params.part;
        let {DocList, documents, crumbs} = this.state;
        if(DocList){
            documents = DocList.map((documentEl)=>
                <GridDocument key={documentEl.id} parent_id={documentEl.parent_id} id={documentEl.id} name={documentEl.name} description={documentEl.description} file={documentEl.files}/>
            );
        }
		
		
/*
        if(tokenAPI == undefined){
            const breadcrumbs = [
                {
                    name: 'Главная',
                    path: '/',				
                },
                {
                    name: 'Сервисы',
                    path: '/services/',				
                },				
                {
                    name: 'Формы документов',
                    path: '/',				
                },	
            ];			
            
           return (<>
            <div className="page-docs page">
                <div className="container ">
                    <div className="row">
					    <BreadCrumbs crumbs={breadcrumbs} />
						<PageTitle pageTitle="Формы документов" />
                    </div>
                    <AlertAccess />
                </div>
            </div>

            </>);
        }/**/


		const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Сервисы',
                path: '/services/',				
            },			
            {
                name: 'Формы документов',
                path: '/docs/',				
            },
            {
                name: this.state.name,
                path: '',				
            },		
        ];
        if(this.state.notFound){
			
           return (
            

            <div className="page-docs page">
                <div className="container ">
                        <div className="row">
					    <BreadCrumbs crumbs={breadcrumbs} />
						<PageTitle pageTitle={this.state.name} />

						
						{ /* <div className="col-md-12 quick-start-title-wrapper title-inner">
                                <div className="uk-h1 quick-start-header-title">{this.state.name}</div>
                                <div className="all-hr all-hr-2 color-lightblue"></div>
                            </div>
                            <div className="col-md-12 breadcrumbs mb-5">
                                <ul>
                                    <li className="">
                                        <a className="codexTreeFont" href="/docs/">
                                            Документы
                                        </a>
                                    </li>
                                    {BreadCrumbs && BreadCrumbs.map( 
                                        (v, index) =>{
                                            let link = "/docs/" + v.id;
                                            if(v.parent_id == 0){
                                                link = "/docs/part" + v.id;
                                            }
                                            if(v.id == ThisDocId){
                                                return(
                                                   <li className="">{v.name}</li> 
                                                );
                                            
                                            }else{
                                                return(
                                                   <li className="">
                                                        <a className="codexTreeFont" href={link}>
                                                            {v.name}
                                                        </a>
                                                    </li> 
                                                );
                                            }
                                        }
                                    )}
                                    
                                </ul>
		</div>*/ }
                        </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="PREloader text-center">{this.PREloader}</div>
                        </div>
                    </div>
                </div>
            </div>
           )
        }
        return(
            

            <div className="page-docs page">
                <div className="container ">
                        <div className="row">
					    <BreadCrumbs crumbs={breadcrumbs} />
						<PageTitle pageTitle={this.state.name} />
							{/*						
                            <div className="col-md-12 quick-start-title-wrapper title-inner">
                                <div className="uk-h1 quick-start-header-title">{this.state.name}</div>
                                <div className="all-hr all-hr-2 color-lightblue"></div>
                            </div>
                            <div className="col-md-12 breadcrumbs mb-5">
                                <ul>
                                    <li className="">
                                        <a className="codexTreeFont" href="/docs/">
                                            Документы
                                        </a>
                                    </li>
                                    {BreadCrumbs && BreadCrumbs.map( 
                                        (v, index) =>{
                                            let link = "/docs/" + v.id;
                                            if(v.parent_id == 0){
                                                link = "/docs/part" + v.id;
                                            }
                                            if(v.id == ThisDocId){
                                                return(
                                                   <li className="">{v.name}</li> 
                                                );
                                            
                                            }else{
                                                return(
                                                   <li className="">
                                                        <a className="codexTreeFont" href={link}>
                                                            {v.name}
                                                        </a>
                                                    </li> 
                                                );
                                            }
                                        }
                                            
                                        )}
                                    
                                </ul>
                            </div>*/ }
                        </div>

                    <div className="grid_container grid_container-docs row">
                            {DocList && documents}
                    </div>
                </div>
            </div>
        );
    }
}
export default DocPartDetail;