import React from "react";
import LeftMenu_on_LK from '../../../layouts/personal-cab/LeftMenu_on_LK';
import PageTitle from "../../elements/PageTitle";
import BreadCrumbs from "../../elements/BreadCrumbs";

let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));

let cancelStatus = '<span class="badge bg-warning text-dark">Отменён</span>';
let pendingStatus = '<span class="badge bg-info text-dark">В ожидании</span>';

//let correctGMCTime = (new Date).getTimezoneOffset();
class YooKassaPage extends React.Component{

    constructor(props) {
        super(props);
        this.state  = {
            payment_list: []
        };
        
        this.payments_list = this.payments_list.bind(this);
    }


    createMarkup = (s) => {
        return {__html: s};
    }

    payments_list = (e) => {
        let self = this;
        let url = "https://api.my-advo.cat/api/pay/yookassa-pay/get";
        fetch(url, { 
            method:'GET',
            headers:headers,
        })
            .then(response=>response.json())
            .then((json)=>{
                if(json.payload){
                    console.log(json.payload.list);
                    self.setState({
                        payment_list: json.payload.list.reverse()
                    });
                }
            });
    }

    /*cancel_payment = (paymentid) => {
        let self = this;
        let url = "https://api.my-advo.cat/api/pay/yookassa-pay/cancel-payment?payment_id="+paymentid;
        fetch(url, { 
            method:'GET',
            headers:headers,
        })
            .then(response=>response.json())
            .then((json)=>{
                if(json.payload){
                    console.log(json.payload);
                    if(json.payload.status != 200){
                        document.getElementById("payment_item_panel"+paymentid).style.display="block";
                        document.getElementById("payment_status_text"+paymentid).innerHTML = pendingStatus;
                    }
                }
            });
    }/**/

    componentDidMount() {
        this.payments_list();
    }

    render() { 
        let self = this;
        let { payment_list } = self.state;

        const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Счета',
                path: '',				
            },
        ];	

        return (
            <>
                <div className="page-docs pay_page page">
                    <div className="container">
                        <div className="row">
							<BreadCrumbs crumbs={breadcrumbs} />						
							<PageTitle pageTitle="Счета" />
                        </div>
                        <div className="row">
                            {/*}<div className="col-md-12 quick-start-title-wrapper title-inner">
                                <div className="uk-h1 quick-start-header-title">Счета</div>
                                <div className="all-hr all-hr-2 color-lightblue"></div>
                            </div>{/**/}
                                    <div className="col-md-2 mb-3">
                                        <LeftMenu_on_LK />
                                    </div>
                                    <div className="col-md-10 mb-3">
                                        <div className="card">
                                            <div className="card-body">
                                                <table className="table table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Номер счёта</th>
                                                            <th>Название тарифа</th>
                                                            <th>Дата создания</th>
                                                            <th>Статус</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {payment_list && Object.keys(payment_list).map((item)=>{
                                                            let status = payment_list[item].status;
                                                            let status_text = pendingStatus;
                                                            let LinkOptions = {
                                                                btnClass: "btn-success",
                                                                btnTitle: "Оплатить",
                                                                target: "_blank",
                                                                faIcon: "fa fa-credit-card-alt"
                                                            };
                                                            switch(status) {
                                                                case 'canceled':
                                                                    status_text = cancelStatus;
                                                                    LinkOptions = {
                                                                        btnClass: "btn-info",
                                                                        btnTitle: "Посмотреть оплату",
                                                                        target: "_blank",
                                                                        faIcon: "fa fa-external-link"
                                                                    };
                                                                break;
                                                              
                                                                case 'succeeded':
                                                                    status_text = '<span class="badge bg-success">Оплачен</span>';
                                                                    LinkOptions = {
                                                                        btnClass: "btn-info",
                                                                        btnTitle: "Посмотреть оплату",
                                                                        target: "_blank",
                                                                        faIcon: "fa fa-external-link"
                                                                    };
                                                                break;

                                                                case 'waiting_for_capture':
                                                                    status_text = '<span class="badge bg-primary">Ожидание оплаты</span>';
                                                                break;
                                                              }
                                                            //let CreatedOnTime = payment_list[item].createdon + Math.abs(correctGMCTime * 60);
                                                            let CreatedOnTime = (new Date(parseInt(payment_list[item].createdon+"000")).toLocaleString());
                                                            return(
                                                                <>
                                                                    <tr>
                                                                        <td>{payment_list[item].id}</td>
                                                                        <td>{payment_list[item].tarif_name}{!payment_list[item].tarif_name && <span dangerouslySetInnerHTML={this.createMarkup("&mdash;")} />}</td>
                                                                        <td>{CreatedOnTime}</td>
                                                                        <td>
                                                                            <div className="float-start payment_status_text" id={"payment_status_text"+payment_list[item].id} dangerouslySetInnerHTML={this.createMarkup(status_text)} />
                                                                            <div className="payment_item_panel float-start" id={"payment_item_panel"+payment_list[item].id}>
                                                                                    <a 
                                                                                        className={`btn ${LinkOptions.btnClass} btn-sm float-start`} 
                                                                                        data-bs-toggle="tooltip"
                                                                                        data-bs-placement="top" 
                                                                                        title={LinkOptions.btnTitle}
                                                                                        target={LinkOptions.target}
                                                                                        href={"https://yoomoney.ru/checkout/payments/v2/contract?orderId="+payment_list[item].payment_id}
                                                                                    >
                                                                                        <i 
                                                                                            className={LinkOptions.faIcon} 
                                                                                            ariaHidden="true"
                                                                                            ></i>
                                                                                    </a>
                                                                                    {/*}<div className="btn btn-danger btn-sm float-start" data-bs-toggle="tooltip" data-bs-placement="top" title="Отменить" onClick={()=>{this.cancel_payment(payment_list[item].id);document.getElementById("payment_status_text"+payment_list[item].id).innerHTML = cancelStatus;document.getElementById("payment_item_panel"+payment_list[item].id).style.display="none";}}><i className="fa fa-ban" ariaHidden="true"></i></div>{/**/}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
export default YooKassaPage;