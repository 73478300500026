import React from "react"
import "./style.css";

const Notification = (props) => {

    const {children} = props;

    const [ToastPanel, setToastPanel] = React.useState(null);

    return (
        <div ref={(input)=>{setToastPanel(input);}} role="alert" ariaLive="assertive" ariaAtomic="true" className="toast toastNotificationPosition" dataBsAutohide="true" style={{display: "block"}}>
            <div className="toast-header">
                <button type="button" className="btn-close float-end" ariaLabel="Закрыть" onClick={()=>{
                    ToastPanel.style.display = "none";
                }}></button>
            </div>
            <div className="toast-body"> {children} </div>
        </div>
    );
}

export default Notification;