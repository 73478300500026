import React from 'react';
import {Link} from 'react-router-dom';
import { YMaps, Map } from 'react-yandex-maps';
import JuristListItem from './jurist_list_item';

import "../../global.js";
import handleStatus from "../../helper/ajaxStatus";
import {PremiumAccess} from "../../layouts/personal-cab/PremiumAccess";

const tokenAPI = localStorage.getItem("tokenAPI");
let headers = new Headers();
headers.append('Authorization', 'Bearer ' + tokenAPI);
let dataJuristArray = null;
export class Citys_List extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        dataCityList: '',
        dataJuristList: '',
        dataServicesList: '',
        datastateInfo: '',
        searchText: '',
        serviceid: '',
        resourceid: 0,
        centerCoord: [],
        JuristTypeText: '',
        stateName: '',
        stateid:0,
        JuristCount: '',
        objectManager: null,
        ymaps:null,
        isPremium: Boolean(localStorage.getItem('isPremium')?(JSON.parse(localStorage.getItem('isPremium'))):false),
        isAuth: Boolean((localStorage.getItem('tokenAPI'))?true:false),
      }
    }

   utils = {
		dummy: document.createElement('div'),
		escapeHTML: function(s) {
			return {__html: s};
		}
	}
	
gettingCityesList = async (id) => {
    const tokenAPI = localStorage.getItem("tokenAPI");
    let output = {};
    const api_url = await 
    fetch(global.apiLink+`api/is/is/pos-cityes?id=`+id,{headers:headers});
    output.CityList = await api_url.json();
    handleStatus(output.CityList.status);
    if(output.CityList.status !== 200){
        //alert("Не верные данные авторизации list");
        return false;
    }
    return output;
}
	
gettingServicesList = async (id) => {
    let output = {};
    const api_url = await 
    fetch(global.apiLink+`api/is/is/services-list`,{headers:headers});
    output.ServicesList = await api_url.json();
    handleStatus(output.ServicesList.status);
    if(output.ServicesList.status !== 200){
        //alert("Не верные данные авторизации list");
        return false;
    }
    return output;
}
	
gettingJuristList = async (id) => {
    let output = {};
    let searchText = "";
    if(this.state.searchText !== ""){
        searchText = `&search=` + this.state.searchText;
    }
    let serviceid = "";
    if(this.state.serviceid !== ""){
        serviceid = `&serviceid=` + this.state.serviceid;
    }
    const api_url = await 
    fetch(global.apiLink+`api/is/is/juristlistbystate?id=`+id+searchText+serviceid+'&type='+this.props.juristtype,{headers:headers});
    output.JuristList = await api_url.json();
    handleStatus(output.JuristList.status);
    if(output.JuristList.status !== 200){
        //alert("Не верные данные авторизации list");
        return false;
    }
    return output;
}
	
gettingStateName = async (id) => {
    let output = {};
    const api_url = await 
    fetch(global.apiLink+`api/is/is/get-state?id=`+id,{headers:headers});
    output.stateInfo = await api_url.json();
    handleStatus(output.stateInfo.status);
    if(output.stateInfo.status !== 200){
        //alert("Не верные данные авторизации list");
        return false;
    }
    //console.log("op1");
    //console.log(output);
    return output;
}

searchFunction = async () => {
    let is_search = document.getElementById("is_search");
    if(is_search !== null){
        let self = this;
        const stateid = this.props.stateid;
        this.setState({searchText: is_search.value}, () => { 
            if(this.state.searchText != ""){
                this.gettingJuristList(stateid)
                    .then(output => self.setState({
                        dataJuristList: output.JuristList,
                        JuristCount: output.JuristList.payload.count
                    }));
            }
        });
    }
    console.log(this.state.searchText);
    return false;
}



setServiceFilter = async (ServiceId = null) => {
    if(ServiceId !== null){
        let self = this;
        const stateid = this.props.stateid;
        let ServicesTMP = this.state.serviceid;
        if(ServicesTMP.indexOf(ServiceId+"") === -1){ //если услуга не выбрана
            ServicesTMP = (ServicesTMP==""?"":(ServicesTMP +","))+ServiceId;
        }else{ //если услуга уже была выбрана
            let NewServices = ServicesTMP.split(',');
            var index = NewServices.indexOf(ServiceId);
            if (index > -1) {
                NewServices.splice(index, 1);
            }
            ServicesTMP = NewServices.join(',');
        }
        
        this.setState({serviceid: ServicesTMP}, () => { 
            this.gettingJuristList(stateid)
                .then(output => self.setState({
                    dataJuristList: output.JuristList,
                    JuristCount: output.JuristList.payload.count
                }));
        });        
    }
    return false;
}
  

validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  
	getCoordinateProvider = (InnerdataJuristList)=>{
		let output = [];
		if(InnerdataJuristList){
			let dataJuristList_Counter = 0;
			let dataJuristList_Count = InnerdataJuristList.length;
			for(let j = 0; j < dataJuristList_Count; j++){
				let dataJuristItem = InnerdataJuristList[j];
                console.log(dataJuristItem);
				let addresses = dataJuristItem.addresses;
				let addresses_Count = addresses.length;
				for(let i = 0; i < addresses_Count; i++){
					let addresses_Item = addresses[i];
					let addressStr 	=	addresses_Item["country"]+", "+
										addresses_Item["state"]+", "+
										addresses_Item["city"]+", "+
										addresses_Item["street"]+", "+
										addresses_Item["house"]+
										(addresses_Item["office"]?(", "+addresses_Item["office"]):"");
					output[dataJuristList_Counter++] = { 
						"address" : addressStr,
						"name" : dataJuristItem["name"],
						"juristuser_id" : dataJuristItem["juristuser_id"],
						"jurist_type" : dataJuristItem["jurist_type"],
						"city_id" : dataJuristItem["city_id"],
						"state_id" : dataJuristItem["state_id"]
					};
				}
			}
			
		}
		return output;
	}
  

createMarkup = (s) => {
    return {__html: s};
}


  
componentDidMount() {
    let self = this;
    let stateid = self.props.stateid;
    self.setState({
        juristtype: self.props.juristtype,
        stateid: stateid,
    });
    
    self.gettingCityesList(stateid).then(output => self.setState({
        dataCityList: output.CityList,
    }));
        
    self.gettingServicesList(stateid).then(output => self.setState({
        dataServicesList: output.ServicesList,
    }));

    self.gettingStateName(stateid).then(output => {
        self.setState({
            datastateInfo: output.stateInfo,
            stateName: output.stateInfo.payload.value
        });
    });
    var dataJuristListTMP = null;
    self.gettingJuristList(stateid).then(output => {
        dataJuristListTMP = output.JuristList;
        dataJuristArray = self.getCoordinateProvider(dataJuristListTMP.payload.array);
        self.setState({
            dataJuristList: dataJuristListTMP,
            JuristCount: output.JuristList.payload.count,
            dataJuristArray: dataJuristArray
        });
        console.log("dataJuristListTMP");
        console.log(dataJuristArray);
    });
}
  
    render(){	
        let self = this;
        const searckEnterClick = event => {
            event.preventDefault();
            this.searchFunction();
        }

        var { 
            objectManager,
            stateName,
            dataJuristList,
            juristtype,
            dataServicesList,
            stateid,
            dataCityList,
            ymaps,
            isAuth,
            isPremium
        } = self.state;

        let JuristTypeText = "";
        if(juristtype == "lawyer"){
            JuristTypeText = "Адвокаты";
        }else if(juristtype == "jurist"){
            JuristTypeText = "Юристы";
        }else{
            JuristTypeText = "Нотариусы";
        }
        
	let myMap;

    var CoordinateArrayX = [];
    var CoordinateArrayY = [];

	function myGeoCode(ymaps, address) {
		if(ymaps !== null)
            ymaps.geocode(address.address, {
                results: 1
            }).then(function (res) {
                let firstGeoObject = res.geoObjects.get(0);
                let coords = firstGeoObject.geometry.getCoordinates();
                CoordinateArrayX[CoordinateArrayX.length] = coords[0];
                CoordinateArrayY[CoordinateArrayY.length] = coords[1];
                console.log(CoordinateArrayX);
                console.log(CoordinateArrayY);
            });
	}

    function MiddleArithmeticalNumber (x){
        let s = x.reduce((a,b)=>parseFloat(a) + parseFloat(b))
        return s / x.length;
     }

     var centerX, centerY = [];
     

    function PaintToMap (myMap){
        centerX = MiddleArithmeticalNumber(CoordinateArrayX);
        centerY = MiddleArithmeticalNumber(CoordinateArrayY);
        self.setState({
            centerCoord: [centerX.toFixed(6), centerY.toFixed(6)],
        });
        myMap.geoObjects.add(objectManager);
        myMap.setBounds(objectManager.getBounds(), {zoomMargin: 10});
    }




    async function init (ymaps, myMap) {
		if(!dataJuristList)return false;
		let dataJuristArray = self.getCoordinateProvider(dataJuristList.payload.array);
        if(dataJuristArray) setTimeout(function(){
            
                    var objectManager = new ymaps.ObjectManager({
                        clusterize: true,
                        gridSize: 32,
                        clusterDisableClickZoom: true
                    });
                    objectManager.objects.options.set('preset', 'islands#blueDotIcon');
                    objectManager.clusters.options.set('preset', 'islands#blueClusterIcons');
            var itemsProcessedmGC = 0;
            dataJuristArray.forEach( (address) => {
                itemsProcessedmGC++;
                myGeoCode(ymaps, address);
                if(itemsProcessedmGC === dataJuristArray.length) {
                    setTimeout(function(){
                            var objects = [];
                            var itemsProcessed = 0;
                            dataJuristArray.forEach( (address, index) => {
                                    itemsProcessed++;
                                    console.log(address);
                                    let coords = [ CoordinateArrayX[index], CoordinateArrayY[index] ];
                                    let JuristLink = "/is/"+address.jurist_type+"/"+address.state_id+"/"+address.city_id+"/"+address.juristuser_id;
                                    objects.push({
                                        type: 'Feature',
                                        id: index,
                                        geometry: {
                                            type: 'Point',
                                            coordinates: coords
                                        },
                                        properties: {
                                            balloonContent: address.address,
                                            clusterCaption: address.name,
                                            hintContent: address.name,
                                            balloonContentBody:  '<a href="'+JuristLink+'" target="_blank">'+address.name+'</a>'+ '<br />'+address.address
                                        }
                                    });
                                    if(itemsProcessed === dataJuristArray.length) {
                                        setTimeout(function(){
                                            objectManager.add(objects);
                                            centerX = MiddleArithmeticalNumber(CoordinateArrayX);
											centerY = MiddleArithmeticalNumber(CoordinateArrayY);
											self.setState({
												centerCoord: [centerX.toFixed(6), centerY.toFixed(6)],
											});
											myMap.geoObjects.add(objectManager);
											if( dataJuristArray.length > 1 )
												myMap.setBounds(objectManager.getBounds(), {zoomMargin: 10});
                                        }, 500);
                                    }
                            });
                        
        
                    }, 500);
                }
            } );
        }, 1000);
    }


    if(dataJuristArray !== null){
        console.log("dataJuristArray3");
        init(ymaps, myMap);
    }



    let dataJuristListArray = "";
        if(dataJuristList){
            if(!tokenAPI){
                if(typeof dataJuristList.payload.array === "array")
                if(0 in dataJuristList.payload.array)
                    dataJuristListArray[0] = dataJuristList.payload.array[0];
            }else{
                dataJuristListArray = dataJuristList.payload.array;
            }
        }
            return (
                <div className="page-is page">
                    <div className="container quick-start">
                        <div className="row">
                            <div className="col-md-12 quick-start-title-wrapper">
                                <div className="uk-h1 quick-start-header-title">Юристы, адвокаты, нотариусы по РФ</div>
                                <div className="all-hr all-hr-2 color-lightblue"></div>
                            </div>
                    <div className="col-md-12 breadcrumbs">
                        <ul className="">
                            <li ><a href="/is/">Юристы, адвокаты, нотариусы по РФ</a></li>
                            <li ><a href={"/is/"+juristtype}>{JuristTypeText}</a></li>
                            <li >{stateName}</li>
                        </ul>
                    </div>
                    <div className="bgs"></div>
                    <div className="col-md-12">
                        <div className="row">
                            {isPremium ? <div className="col-md-12 mb-2">
                                <div className="all-search-wrapper mt-3 mb-1">
                                    <form className="uk-search" onSubmit={searckEnterClick} style={{display: "block"}}>
                                        <label for="search">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                            <span className="sr-only">Поиск</span>
                                        </label>
                                        <input type="search" name="q" placeholder="" autocomplete="off" id="is_search" className="all-search-input" />
                                        <button type="button" onClick={searckEnterClick} className="search_btn" >Искать</button>
                                    </form>
                                </div>
                            </div>:<></>}
                            <div className="col-md-12">
                                <div class="mt-0 mb-3 filters">
                                    {dataServicesList && dataServicesList.payload.map( (answer, i) => {
                                        let ServiceId = answer.id;
                                        let ServiceTitle = answer.title;
                                        let ElementClassList = "filter-item";
                                        let AdditionalClass = "  ";
                                        if(this.state.serviceid.indexOf(ServiceId)!==-1){
                                            AdditionalClass = " filter-item-active ";
                                        }
                                        ElementClassList = ElementClassList + AdditionalClass;
                                        return(
                                            <a key={ServiceId} data-id={ServiceId} onClick={()=>this.setServiceFilter(ServiceId)} className={ElementClassList}>
                                                <span dangerouslySetInnerHTML={this.createMarkup(ServiceTitle)} />
                                            </a>
                                        );
                                    })}
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <div className="col-lg-8 order-lg-1 order-md-2 order-2 mt-2">
                                    <div className="row">
                                        { isAuth ? 
                                        <>
                                        {dataJuristListArray &&
                                        dataJuristListArray.map( (answer, i) => {
                                            return( <JuristListItem answer={answer} juristtype={juristtype} stateid={stateid} /> );
                                        })}
                                        </> : 
                                        <><div className='card'>
                                            <div className='card-body'>
                                                <PremiumAccess CheckForAuth={true} text={<>
                                                    <h4 className="alert-box_title alert-heading">Хотите получить возможность просматривать весь список юристов?</h4>
                                                    <p>Полный доступ к списку юристов, адвокатов и нотариусов имеют только авторизованные пользователи.</p>
                                                </>} />
                                            </div>
                                        </div></>
                                        }
                                        {/*!tokenAPI && <> <div className="col-md-12">
                                                    <div className="uk-card uk-card-default uk-margin-small-bottom shadow-sm">
                                                        <div className="uk-card-body">
                                                            <h4 className="card-title"><b>Просматривать весь список юристов могут только авторизованные пользователи.</b></h4>
                                                        </div>
                                                    </div>
                                                </div></>/**/}
                                        <div className="uk-clearfix"></div>
                                    </div>
                            </div>
                            <div className="col-lg-4 order-lg-2 order-md-1 order-1 mt-2">
                                <div className="card card-default mb-3 shadow-sm" >
                                    <div className="card-body pb-0">
                                        <h4>Города в регионе:</h4>
                                    </div>
                                    <div className="card-body StateListWrapper" style={{ columnWidth: "50%", columnCount: "2", columnGap: "0" }}>
                                        {dataCityList && dataCityList.payload.array.nodes.map( (answer, i) => {
                                            let cityname = answer.element.cityname;
                                            let cityid = answer.element.cityid;
                                            let cityStyle = {height:"30px"};
                                            return(
                                                <div key={cityid} className="reestr_itemlistbtn">
                                                    <div className="reestr_itemlist_wrapper ">
                                                        <Link to={`/is/${juristtype}/${stateid}/${cityid}/`} style={cityStyle} className="btn btn-biryza" dangerouslySetInnerHTML={this.createMarkup(cityname)} />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                                    {isPremium ? <div className="card card-default mb-3 shadow-sm" >
                                        <div id="YMapsIDcallbackID" style={{width:"100%"}}>
                                            <YMaps
                                                query={{
                                                    ns: 'use-load-option',
                                                    apikey: '078de686-4f74-432f-b339-5f518467d195',
                                                    load: 'package.full'
                                                }}
                                            >
                                            <Map
                                                key = { dataJuristList }
                                                defaultState = { { center: ( self.state.centerCoord && self.state.centerCoord ), zoom: 5 } } height="600px" width="100%" 
                                                state = { self.state.centerCoord && {
                                                    center: ( self.state.centerCoord && self.state.centerCoord ),
                                                    zoom: 15,
                                                    controls: ['zoomControl', 'searchControl', 'typeSelector',  'fullscreenControl', 'trafficControl' ]
                                                } }
                                                modules = { [ "geolocation", "geocode", 'geoObject.addon.balloon', 'geoObject.addon.hint' ] }
                                                onLoad = { ymaps => {
                                                    ymaps.ready( () => {
                                                        init(ymaps, myMap)
                                                    } );
                                                } }
                                                instanceRef = { yaMap => {
                                                    if (yaMap) {
                                                        myMap = yaMap;
                                                    }
                                                } }
                                            />
                                            </YMaps>
                                        </div>
                                    </div>:<>
								<div className="card card-default mb-3 shadow-sm" >
									<div className='card-body'>
										<PremiumAccess CheckForPremium={true} text={<>
											<h4 className="alert-box_title alert-heading">Хотите получить возможность просматривать карту с местоположением юристов?</h4>
											<p>Полный доступ к карте с расположением юристов, адвокатов и нотариусов имеют только Премиум пользователям.</p>
										</>} />
										</div>
								</div></>}
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    );}
}

export default Citys_List;