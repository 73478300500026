import React from 'react';
import { Link } from 'react-router-dom';

const BreadCrumbs = ({ crumbs }) => {
  // Don't render a single breadcrumb.
  if (crumbs.length < 1) {
    return (<></>);
  }


    return (
	<>
    <div className="breadcrumbs col-md-12 mb-2">
        <ul>
		
      {crumbs.map(({ name, path }, key) =>
	  	<li key={key}> 
		  {key + 1 === crumbs.length ? (
          <span id="bc__last-chain" dangerouslySetInnerHTML={{__html: name}}></span>
        ) : (
          <Link to={path} dangerouslySetInnerHTML={{__html: name}}></Link>
	  )}
	  	 </li>
      )}		
        </ul>
    </div>
	</>
    );
};
export default BreadCrumbs;