import React from 'react';
import "../../global.js";

export class Document extends React.Component {




    truncate = (str, n) => {
        return (str.length > n) ? str.substr(0, n-1) + '&hellip;' : str;
      };


    render() {
        let url = '/docs/'+this.props.id;
        return (
            <>
                <a href={url} className='col-lg-3 col-md-4 col-sm-6 col-12 documentation-file-wrapper grow' title={this.props.name}>
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <span className="uk-accordion-statya documentation-file">
                                <p className='small documentation-file-bold'>{this.props.name}</p>
                            </span>
                            <p className='documentation-file-btn-description small'>{this.truncate(this.props.description, 150)}</p>
                            <div className='documentation-file-btn-panel'>
                                <span className='btn btn-biryza btn-biryza-mod' href={url}>Открыть документ</span>
                            </div>
                            <img className='documentation-file-btn-icon' src="/assets/images/documents_ico.png" />
                        </div>
                    </div>
                </a>
            </>
        );
    }
}
export default Document;