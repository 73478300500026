import React from 'react';
import { Link } from 'react-router-dom';
import PageTitle from "../elements/PageTitle";
import BreadCrumbs from "../elements/BreadCrumbs";
import GridCard from './../panel/GridCard';
import SituationCard from './../panel/SituationCard';
import {PremiumAccess} from "../../layouts/personal-cab/PremiumAccess";
import "../../global.js";

export class ResourcePage extends React.Component {
    constructor() {
      super();
  
      this.state = {
        resourceid: 0,
        title: "",
        content: "",
        partTitle: "",
        group_name: "",
		tags: "",
        isPremium: Boolean(localStorage.getItem('isPremium')?(JSON.parse(localStorage.getItem('isPremium'))):false),
        isAuth: Boolean((localStorage.getItem('tokenAPI'))?true:false),
      }
    }

   utils = {
		dummy: document.createElement('div'),
		escapeHTML: function(s) {
			return {__html: s};
		}
	}

  createMarkup = (s) => {
    return {__html: s};
  }
    tagCloudElements = (elNode) => {
		let elTags = elNode;

        const re =  /\s*(?:,|;$)\s*/;///\s+/;
        const tagsCloud = elTags.split(re);

        return (
		<>
            <div className="qa-page__tags">
                {tagsCloud &&
                tagsCloud.map(p => (
                    <div className="qa-page__tags-list" key={p}>
                        <Link to={`/search/?target=qa&q=${p}`}>
                            #<span dangerouslySetInnerHTML={this.createMarkup(p)}/>
                        </Link>
                    </div>
                ))
                }
            </div>
		</>	
        );
    }
  
componentDidMount() {
    let resourceId= this.props.match.params.alias;
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
    

    let url = global.apiLink+'/api/qa/qa/get?id='+resourceId;
    fetch(url,{
        headers:headers
    })
        .then(res => res.json())
        .then((response)=>{
            console.log(response);
            if(response.payload){
                let partTitle = "";
                let pid = response.payload.group_name;
                if(pid == "up"){
                    partTitle = "По уголовным делам";
                }else if(pid == "ap"){
                    partTitle = "По административным делам";
                }else if(pid == "np"){
                    partTitle = "По налоговым делам";
                }
                this.setState({
                    title: response.payload.title,
                    content: response.payload.content,
                    resourceid: response.payload.id,
                    partTitle: partTitle,
                    group_name: pid,
					tags: response.payload.tags
                })
            }
            else{
                let notFound = true;
            }

        })
}
  
    render(){
        const { group_name, isPremium, title, content,tags } = this.state;

		document.getElementById("root").style.background = "#fafafb";
		const tokenAPI = localStorage.getItem("tokenAPI");
		const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Сервисы',
                path: '/services/',
            },
            {
                name: 'Вопрос-ответ',
                path: '/qa/',
            },
			{
                name: title,
                path: '',				
            },	
        ];	    
            return(
                <>
                <div className="qa-page page">
                    <div className="container ">
                        <div className="row">
                            <BreadCrumbs crumbs={breadcrumbs} />		
                            <PageTitle pageTitle={title && title} />	
                        </div>
                        <div className="row">
                            <div className="col-md-9 grid_container-faqs js-filtered-content" >
                                    {content ?
                                        <>
                                            <div 
                                                className={`element-content wysiwig_content ${!isPremium && 'text-restricted'}`} 
                                                dangerouslySetInnerHTML={{__html:content}} />
                                            <PremiumAccess CheckForPremium={true} text={<>
                                                <h4 className="alert-box_title alert-heading">Хотите получить возможность ознакомиться с полным ответом на вопрос?</h4>
                                                <p>Полный доступ к ответу на вопрос имеют только Премиум пользователи.</p>
                                            </>} />
                                        </>
                                    :<></>}
									{/*content && !tokenAPI && 
									<>
                                    <div className="text-center pb-4 pt-2">
									    <p className="d-block mb-3">Полный текст доступен для просмотра только авторизованным пользователям.</p>
										<a href="/login"><button className="btn btn-biryza form-submit-button submit-button">Авторизироваться</button></a>
									</div>												
									</>/**/}
									{tags ? this.tagCloudElements(tags) : <></>}
									
                                    <div className="row mt-5" style={{display: "none"}}><div className="col-md-12 mb-3"><div id="TableElements_documents" className="callout callout-info">
                                    <div className="card-body"><h5>Похожие вопросы</h5>
                                    <ul className="invent-list-square">
                                    <li><a href="/docs/107">Исковое заявление о расторжении брака и взыскании алиментов на содержание ребенка</a></li>
                                    <li><a href="/docs/108">Исковое заявление о расторжении брака и взыскании алиментов на содержание ребенка</a></li>
                                    <li><a href="/docs/130"> Заявление о выдачи свидетельства о разводе</a></li>
                                    <li><a href="/docs/134"> Заявление о признании брака недействительным</a></li></ul></div></div></div></div>									
                            </div>
							
							<div className="col-md-3">
                                <div className="right-nav-page aside-nav"><div className="aside-widget"><img src="/assets/images/advocate.svg" alt="Срочная юридическая помощь" className="aside-widget__image2"/><h4 className="aside-widget__title">Нужна срочная помощь?</h4><div className="aside-widget__text pulse-button-wrap"><a href="/call/?do" className="go_to_callpage_btn"><div className="go_to_callpage_title">Позвоните нам</div></a></div></div></div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            );
    }
}

export default ResourcePage;