import React from 'react';
//import {Link} from 'react-router-dom';
import "./Footer.css";
//import MediaQuery from 'react-responsive';
//import { isMobile } from 'react-device-detect';
//import useResizeObserver from '@react-hook/resize-observer'

import "../../global.js";
import {PayMethods} from"./PayMethods";

class Footer extends React.Component{

    newsSubmit = async () => {
        let email = document.getElementById("news_submit").value;
        let llLink = global.apiLink+`api/myac/main/news-submit?email=`+email;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        const api_ResourceLL = await fetch(llLink,{
            headers:headers
        });
        let result = await api_ResourceLL.json();
        if(result.status !== 200) {
            alert("Не верные данные авторизации ll");
            return false;
        }
        else{
            alert("Подписка оформлена");
            console.log(result);
            document.getElementById("news_submit").value = '';
        }
        return result;
    }

    /*useWindowSize() {
        const [size, setSize] = React.useState([0, 0]);
        React.useLayoutEffect(() => {
          function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
            this.refrachFooterPos();
          }
          window.addEventListener('resize', updateSize);
          updateSize();
          return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }/**/
    
    /*componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    updateDimensions = () => {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
      this.refrachFooterPos();
    };/**/

    refrachFooterPos = () => {
        setTimeout(()=>{
            let body = document.body,
            html = document.documentElement;
            let RootHeightOld = (Math.max( body.scrollHeight, body.offsetHeight, 
                html.clientHeight, html.scrollHeight, html.offsetHeight ) - 69);
            let RootHeight = (window.outerHeight - 69);
            console.log(RootHeightOld +"<="+ RootHeight);
            if(RootHeightOld <= RootHeight){
                console.log("Low height");
                document.getElementById("root").style.height = RootHeight+"px";
                //document.getElementById("footer").style.bottom = "-28px";
            } else {
                console.log("Height height");
                document.getElementById("footer").style.position = "relative";
            }
            if(document.getElementById("footer") !== null){
                document.getElementById("footer").style.display = "block";
                document.getElementById("footer").style.opacity = 1;
            }
        }, 500);
    }



    render() {
        
        let UrlAddress = window.location.pathname;
        if(UrlAddress !== ""){
            UrlAddress = UrlAddress.replace(/^./,"").split("/");
            if(UrlAddress[0] == "chat")return (<></>); 
        }
{/*}
        if (!isMobile) {

            window.onload = () => {
                this.refrachFooterPos();
            }

            window.onresize = () => {
                this.refrachFooterPos();
            }
            
        }{/**/}

        return (

            <>
			
			<a href="/chat" className="btn-call">
	            <div className="btn-call__ico"> </div>
            </a>
            {/*}<footer className="block_9" id="footer" style={{position: "absolute", paddingTop: "15px",    left: "0",    right: "0",    display: "none",  bottom: "0",borderBottom: "solid 2px #111", opacity: 0}}>{/**/}
            <footer className="block_9" id="footer">	
			<div className="container">
				<div className="row mt-3">
					<div className="col-lg-3 col-md-6 col-sm-3 pe-2 pe-sm-5 pe-lg-0 mt-4 mt-md-0">
						<div className="single_footer_widget g-0">
							<div className="logo_footer">
								<a className="logo" href="/" title="Правовая система Мой адвокат">
									<img src="/assets/temapltes/tmp1/images/logo.png" alt="Мой адвокат" style={{marginLeft: "0", paddingLeft: "0"}} />
									<div className="logo_text">МОЙ АДВОКАТ</div>
								</a>
							</div>
							<p style={{float: "left", display: "block", width: "100%", color: "#bdbdbd", paddingLeft: "6px"}} className="mt-3">Правовая система, где каждый найдет ответ по любому юридическому вопросу от опытных адвокатов и юристов по всей РФ.</p>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-3 ps-2 ps-lg-5 mt-4 mt-md-0 d-none d-md-block">
						<div className="single_footer_widget mt-3">
							<h5 className="footer-extended-menu-title"><a href="/services/" title="Сервисы">Сервисы</a></h5>
							<div>
								<ul className="footer-extended-menu-list">
									<li><a href="/situation/" title="Практические правовые рекомендации">Практические правовые рекомендации</a></li>
									<li><a href="/codex/" title="Кодексы, законы, судебная практика">Кодексы, законы, судебная практика</a></li>
									<li><a href="/qa/" title="Вопрос-ответ">Вопрос-ответ</a></li>							
									<li><a href="/docs/" title="Формы документов">Формы документов</a></li>
									<li><a href="/is/" title="Юристы, адвокаты, нотариусы по РФ">Юристы, адвокаты, нотариусы по РФ</a></li>
									<li><a href="/regions/" title="Справочная информация">Справочная информация</a></li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-2 col-md-6 col-sm-3 mt-4 mt-md-0">
						<div className="single_footer_widget mt-3">
							<h5 className="footer-extended-menu-title"><a href="/contactus/" title="Контакты">Контакты</a></h5>
							<div className="single_footer_address">
								 <div className="single_address">
									<div className="icon mt6"><img src="/assets/images/panel/icons-chat-64.png" /></div>
									<a href="mailto:myadvocat@pl-llc.ru" style={{fontSize: "1.1rem"}}>myadvocat@pl-llc.ru</a>
								</div>
								<div className="single_address mt-1">
									<div className="icon"><img src="/assets/images/panel/icons-chat-64.png" /></div>
									<a href="tel:88006006254" style={{fontSize: "1.1rem"}}>8 (800) 600 62 54</a>
								</div>
								<div className="single_address mt-1">
									<div className="icon"><img src="/assets/images/panel/icons-chat-64.png" /></div>
									<a href="/chat/" alt="Call-центр" style={{fontSize: "1.1rem"}}>call-центр</a>
								</div>
							</div>
                            <div className='mt-4'>
								<ul className="footer-extended-menu-list">
									<li><a href="/requisites/" rel='nofollow, noindex' title="Реквизиты">Реквизиты</a></li>
									<li><a href="/intarifs/" title="Тарифы">Тарифы</a></li>
									<li><a href="/feedback/" title="Обратная связь">Обратная связь</a></li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-3 pe-2 pe-sm-5 pe-lg-0 mt-4 mt-md-0">
						<div className="single_footer_widget mt-3">
                            <h5 className="footer-extended-menu-title" style={{color: "#fff"}}>Скачивайте приложение</h5>
                            <div className="sociallinks mb-2" style={{display:"inline-flex"}}>
                                <div className="sociallinks_item me-2">
                                    <a href="https://play.google.com/store/apps/details?id=cat.my_advo.m" target="_blank" rel="nofollow" >
                                        <img src="/assets/temapltes/tmp1/images/mobile_download_googleplay.png" alt="" style={{width: "120px"}} />
                                    </a>
                                </div>
                                <div className="sociallinks_item">
                                    <a href="https://apps.apple.com/us/app/my-advo-cat/id6470447828" target="_blank" rel="nofollow">
                                        <img src="/assets/temapltes/tmp1/images/mobile_download_appstore.png" alt="" style={{width: "120px"}} />
                                    </a>
                                </div>
                            </div>
						</div>
                        
                    <div className="single_footer_widget mt-3">
                        <div className="row justify-content-center">
                            <div className="col-12 ">
                                <h5 className="footer-extended-menu-title" style={{color: "#fff"}}>Методы оплаты</h5>
                            </div>
                            <div className="col-12 text-center">
                                <div className="pay_methods">
                                    <PayMethods />
                                </div>
                            </div>
                        </div>
                    </div>		
					</div>	
				</div>

                <hr />

				<div className="footer_bottom_widget text-center">
					<span>@ 2024 «Мой адвокат». <i className="d-none d-md-inline-block" style={{fontStyle: "normal"}}>Копирование любых материалов с сайта допустимо только с разрешения правообладателя. Не является публичной офертой.</i></span>
					<span><a href="/privpol/" title="Политика конфиденциальности сайта «Мой адвокат»">Политика конфиденциальности</a> | <a href="/assets/files/license.docx" title="Лицензионное соглашение «Мой адвокат»">Лицензионное соглашение</a></span>
				</div>
			</div>
            </footer>

            </>
        );
    }
}
export default Footer;