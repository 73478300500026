import React from 'react';
import {Link} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import BreadCrumbs from "../elements/BreadCrumbs";
import PageTitle from "../elements/PageTitle";
import JuristCard from './../panel/JuristCard';
import "../../global.js";
import './is.css';
import handleStatus from "../../helper/ajaxStatus";


export class JuristType_List extends React.Component {
    constructor(props) {
        super(props);
		
		if (isMobile) {
            window.location.replace('https://m.my-advo.cat/lawyer/');
        }
  
        this.state = {
            dataStateList: '',
            resourceid: 0,
            pageData: [
                {
                    title: "",
                    items: [
                        {
                            href: "/is/lawyer/",
                            bg: "/assets/images/is/advokat.jpg",
                            title: "Адвокаты",
                            description: "Справочник адвокатов по уголовным, административным, налоговым, гражданским делам, ДТП по регионам РФ.",
                        },
                        {
                            href: "/is/jurist/",
                            bg: "/assets/images/is/jurist.jpg",
                            title: "Юристы",
                            description: "Справочник юристов по уголовным, административным, налоговым, гражданским делам, ДТП по регионам РФ.",
                        },
                        {
                            href: "/is/notary/",
                            bg: "/assets/images/is/notarius.jpg",
                            title: "Нотариусы",
                            description: "Справочник нотариусов по уголовным, административным, налоговым, гражданским делам, ДТП по регионам РФ.",
                        },
                    ]
                }
            ],		
        }
    }

   utils = {
		dummy: document.createElement('div'),
		escapeHTML: function(s) {
			return {__html: s};
		}
	}
	 
gettingStateList = async () => {
    const tokenAPI = localStorage.getItem("tokenAPI");
    let output = {};
    let headers = new Headers();
    headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
    const api_url = await 
    fetch(global.apiLink+`api/is/is/pos-states`,{headers:headers});
    output.StateList = await api_url.json();
    handleStatus(output.StateList.status);
    if(output.StateList.status !== 200){
        return false;
    }
    return output;
}
  

  createMarkup = (s) => {
    return {__html: s};
  }
  
    render(){
        let self = this;
        document.getElementById("root").style.background = "#fafafb";
		
        var dataStateList = self.state.dataStateList;
        if(dataStateList === ''){
            self.gettingStateList().then(output => self.setState({
                dataStateList: output.StateList,
            }));
        }
        dataStateList = self.state.dataStateList;

            let situation_hummer = {
                position: "relative",
                display: "block",
                marginBottom: "15px",
                background: "no-repeat center/cover url(/assets/images/is/advokat.jpg)",
                height: "330px", 
                borderRadius: "5px",
                boxShadow: "0 0 11px 0 rgb(0 0 0 / 27%)"
            };
            let situation_hummer2 = {
                position: "relative",
                display: "block",
                marginBottom: "15px",
                background: "no-repeat right/cover url(/assets/images/is/jurist.jpg)",
                height: "330px", 
                borderRadius: "5px",
                boxShadow: "0 0 11px 0 rgb(0 0 0 / 27%)"
            };
            let situation_hummer3 = {
                position: "relative",
                display: "block",
                marginBottom: "15px",
                background: "no-repeat center/cover url(/assets/images/is/notarius.jpg)",
                height: "330px", 
                borderRadius: "5px",
                boxShadow: "0 0 11px 0 rgb(0 0 0 / 27%)"
            };

        let situation_title = {
            color: "#fff",
            fontSize: "18px",
            lineHeight: "21px",
            textAlign: "right",
            padding: "8px 5px",
            background: "#00baff",
            position: "absolute",
            bottom: "50px",
            right: "0",
            left: "auto"
        }
		
		const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Сервисы',
                path: '/services/',				
            },			
            {
                name: 'Юристы, адвокаты, нотариусы по РФ',
                path: '',				
            },
        ];		
		
		
		
        return (
			
		    <div className="page-is page">
                <div className="container quick-start">
                     <div className="row">
					    <BreadCrumbs crumbs={breadcrumbs} />
						<PageTitle pageTitle="Юристы, адвокаты, нотариусы по РФ" />
					</div>	
					<div className="row page-wisiwig">
					    <p>В данном разделе представлен список юристов, адвокатов и нотариусов по городам РФ.</p>
						<p>При выборе фильтров (местоположение, род услуг и прочее) выводится список адвокатов, юристов, нотариусов или компаний с краткой информацией. 
						Также присутствует интерактивная карта РФ, на которой метками показано местонахождение данных специалистов или организаций.</p>
                    </div>
					<div className="row">
                        {this.state.pageData.map((block, i) => {
                            return (
							<>
							    {
                                    block.items.map(item => <JuristCard key={item.href} {...item} />)
                                }															
                            </>);
                            })
						}
                </div> 
            </div>
		</div>
            );
    }
}

export default JuristType_List;