import React from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import PageTitle from "../elements/PageTitle";
import SituationCard from './../panel/SituationCard';
import GridSituation from "./GridSituation";
import BreadCrumbs from "../elements/BreadCrumbs";
import GridSituationInnerCard from "../elements/GridSituationInnerCard";

import "./resourceviewerpart.style.css";
import "../../global.js";
import './situations.css';
import * as utils from "../../utils.js";

let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));

export class ResourceViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataResourceQuickstart: '',
            resourceid: 0,
            partTitle: "",
        }
    }

    utils = {
        dummy: document.createElement('div'),
        escapeHTML: function (s) {
            return {__html: s};
        }
    }


    gettingResourcesToAccardion = async (pid) => {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let llLink = global.apiLink + `api/codex/codex/list?pid=${pid}&lvl=1`;
        const api_ResourceLL = await fetch(llLink, {
            headers: headers
        });
        let result = await api_ResourceLL.json();
        if (result.status !== 200) {
            alert("Неверные данные авторизации ll");
            return false;
        }
        return result;
    }

    getFindResources = async (text) => {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let llLink = global.apiLink + `api/codex/codex/list?search=${text}&lvl=1`;
        const api_ResourceLL = await fetch(llLink, {
            headers: headers
        });
        let result = await api_ResourceLL.json();
        if (result.status !== 200) {
            alert("Неверные данные авторизации ll");
            return false;
        }
        return result;
    }


    gettingSearchResourcesToAccardion = async () => {
        let SearchInput = document.getElementById("input_search_on_head");
        if (SearchInput == null) return false;
        let text = SearchInput.value;
        let result = await this.getFindResources(text);
        this.setState({dataResourceLvl1: result});
        return "";
    }

    onKeyUp(event) {
        if (event.charCode === 13) {
            this.gettingSearchResourcesToAccardion();
        }
        return false;
    }

    gettingResources = async (part) => {
        const tokenAPI = localStorage.getItem("tokenAPI");
        let output = {};

        let partId = 0;
        
        if(part == "partuk"){
            partId = 1;
        }else if(part == "partkoap"){
            partId = 2;
        }else if(part == "partnalog"){
            partId = 3;
        }else if(part == "partfamily"){
            partId = 4;
        }

        const api_url = await fetch(global.apiLink + `api/situation/situation/list?part=`+partId, {
            headers: headers
        });
        output.quickstart = await api_url.json();
        if (output.quickstart.status !== 200) {
            alert("Не верные данные авторизации quickstart");
            return false;
        }
        return output;
    }


    parentFunc = (text) => {
        this.setState({dataResourceList: text});
    }

    createMarkup = (s) => {
        return {__html: s};
    }

    subElements = (nodes) => {
        return (
            <div>
                {nodes &&
                nodes.map(p => (
                    <div key={p.element.id}>
                        <Link to={`/codex/${p.base64_uid}/`} onClick={() => this.setState({"dataResourceList": ""})}>
                            <span dangerouslySetInnerHTML={this.createMarkup(p.element.title)}/>
                        </Link>
                        
                        {p.element.punkts && this.showPunkts(p.element.punkts)}
                        {p.nodes && this.subElements(p.nodes)}
                    </div>
                ))
                }
            </div>
        );
    }

    showPunkts = (json) => {
        let dataResourceTvalue = [];
        if (json !== undefined)
            var tmp = json;//JSON.parse(json);
        for (var dataResourceTVItem in tmp) {
            dataResourceTvalue[dataResourceTVItem] = tmp[dataResourceTVItem];
        }

        return (
            <div>
                {json && dataResourceTvalue.map((answer, i) => {
                        let content = dataResourceTvalue[i].content;
                        let title = dataResourceTvalue[i].title;
                        return (
                            <div key={i}>
                                <div
                                    dangerouslySetInnerHTML={this.createMarkup("<span style='float:left;'>" + (title) + ".&nbsp;</span>" + content)}/>
                                <br/>
                            </div>
                        );
                    }
                )}
            </div>
        );
    }

    sortData = (key, data, type) => {
        let ordered = {};
        let compareFunction = function (a, b) {
            return data[b][key] - data[a][key];
        };
        if (type === "asc") {
            compareFunction = function (a, b) {
                return data[a][key] - data[b][key];
            }
        }
        Object.keys(data).sort(compareFunction).forEach(function (key) {
            ordered[key] = data[key];
        });
        return ordered;
    }

    quickStartAllBtn = () => {
        return "";
    }

    subResOpen = async (ElId, TypeNode, B64uid) => {
        let Element = document.getElementById(ElId);
        if (TypeNode == "article") {
            document.location.href = ("/codex/" + B64uid);
            return false;
        }
        let dataid = Element.getAttribute("data-id");
        let nodes = await this.gettingResourcesToAccardion(dataid);
        ReactDOM.render(
            <div>
                {}
                {nodes &&
                nodes.payload.list.map(p => (
                    <div>
                        <div className={"uk-accordion-" + p.type_node}
                             onClick={() => this.subResOpen("ac_" + p.id, p.type_node, p.base64_uid)}>
                            <span dangerouslySetInnerHTML={this.createMarkup(p.title)}/>
                        </div>
                        {/*p.content && <span class="uk-accordion-article-content" dangerouslySetInnerHTML={this.createMarkup(p.content)} />/**/}
                        <div className="uk-accordion-content" id={"ac_" + p.id} data-id={p.id}></div>
                    </div>
                ))
                }
            </div>,
            Element
        );
    }

    newPageLoader = async (pid, pageNum, pageId) => {
        const tokenAPI = localStorage.getItem("tokenAPI");
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let llLink = global.apiLink + `api/codex/codex/list?pid=${pid}&recursive=2&page=${pageNum}`;
        const api_ResourceLL = await
            fetch(llLink, {
                headers: headers
            });
        console.log(llLink);
        var output = await api_ResourceLL.json();
        if (output.status !== 200) {
            alert("Неверные данные авторизации");
            return false;
        }
        if (pageNum > output.payload.pagecount) {
            document.getElementById(pageId).innerHTML = "";
            return false;
        }
        let nodes = output.payload.list.nodes;

        ReactDOM.render(
            <div>
                {nodes &&
                nodes.map(p => (
                    <div key={p.id}>
                        <a href={`/codex/${p.base64_uid}/`} onClick={() => this.setState({"dataResourceList": ""})}>
                            <span dangerouslySetInnerHTML={this.createMarkup(p.title)}/>
                        </a>
                        <div className="element-content" dangerouslySetInnerHTML={this.createMarkup(p.content)}></div>
                        {p.punkts && this.showPunkts(p.punkts)}
                    </div>
                ))
                }
            </div>,
            document.getElementById(pageId)
        );
        document.querySelector('body').removeAttribute("data-paginationatwork");

    }

    newPageLoad = async (part) => {
        let self = this;
        let partTitle = "";
        self.gettingResources(part).then(output => {
            if(part == "partuk"){
                partTitle = "Практические рекомендации по УК РФ";
            }else if(part == "partkoap"){
                partTitle = "Практические рекомендации по КоАП РФ";
            }else if(part == "partnalog"){
                partTitle = "Практические рекомендации по НК РФ";
            }else if(part == "partfamily"){
                partTitle = "Гражданские и семейные дела";
            }
            self.setState({
                dataResourceQuickstart: output.quickstart,
                partTitle: partTitle,
            });
        });
    }
  
    componentDidMount() {
        let self = this;
        const part = self.props.match.params.part;
        console.log(part);
        this.newPageLoad("part"+part);
    }




    render() {
		document.getElementById("root").style.background = "#fafafb";
        let self = this;
        const { dataResourceQuickstart, partTitle, dataResourceSituation } = this.state;
        
        const alias = self.props.match.params.alias;
        const part = self.props.match.params.part;
        let resourceId = 0;
        if (alias) resourceId = atob(alias);

		const breadcrumbs = [
		    {
                name: 'Главная',
                path: '/',				
            },
            {
                name: 'Сервисы',
                path: '/services/',
            },
            {
                name: 'Практические правовые рекомендации',
                path: '/situation/',				
            },
            {
                name: this.state.partTitle,
                path: '',				
            },		
        ];

        if (dataResourceQuickstart != '') {

        /*if(DocList){
            documents = DocList.map((documentEl)=>
                <GridDocument key={documentEl.id} id={documentEl.id} name={documentEl.name} description={documentEl.description} file={documentEl.files}/>
            );
        }*/
            return (
                <>
                    <div className="page-situation page">
                        <div className="container">
                            <div className="row">
                                <BreadCrumbs crumbs={breadcrumbs} />
							    <PageTitle pageTitle={partTitle && partTitle} />
                            </div> 
                            <div className="grid_container grid_container-situations row">

                                {dataResourceQuickstart !== undefined && dataResourceQuickstart.payload.list &&
                                    dataResourceQuickstart.payload.list.nodes.map((p, i) => {
										if (p.element.codex_id) {
                                            let base64_link = btoa(p.element.codex_id);
											p.element.title = utils.replaceByTpl(p.element.title, 'Статья', 'Рекомендации по Статье');
											p.element.title = utils.replaceByTpl(p.element.title, 'Глава', 'Рекомендации по Главе');
                                            return (
											<>
											<div className="grid_container_situations-item" key={p.element.id}>
												<a href={`/situation/part${part}/${base64_link}`} title={p.element.title}>
													<span className="card-body__box">
														<h4 className="card-body__title">{p.element.title}</h4>
														{(p.element.name).length ?
													    <p className="card-body__description" dangerouslySetInnerHTML={{__html: p.element.name}}></p>
													    :
														""}
													</span>	
													<span className="card-body__link-open">Открыть документ</span>
													<img className="card-body__icon-info" src="/assets/images/icons-situation.svg" />
												</a>
											</div>	
									        </>		
                                                /*<div className="reestr_itemlistbtn" key={p.element.id}>
                                                    <Link className="btn btn-biryza btn-biryza-mod"
                                                        to={`/situation/${base64_link}/`}
                                                        style={{ width: "100%" }}
                                                        onClick={() => this.setState({"dataResourceQuickstart": ""})}>
                                                        {p.element.title}
                                                    </Link>
                                                </div>*/
                                            );
										} else {
											return <></>;
										}
                                    })
                                }
                            </div>
							<div className="row">
								<div className="col-md-12">
									<div className="situation-search-description">
									</div>
								</div>
							</div>
                                        {/*
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Link to="/situation/partkoap/" onClick={()=>{this.newPageLoad("partkoap")}} className="situation_money" style={situation_money}>
                                                <div className="situation_title" style={situation_title}>По административным делам</div>
                                            </Link>
                                        </div>
                                        <div className="col-md-12">
                                            <Link to="/situation/partuk/" onClick={()=>{this.newPageLoad("partuk")}} className="situation_hummer" style={situation_hummer}>
                                                <div className="situation_title" style={situation_title}>По уголовным делам</div>
                                            </Link>
                                        </div>
                                        <div className="col-md-12">
                                            <Link to="/situation/partnalog/" onClick={()=>{this.newPageLoad("partnalog")}} className="situation_nalog" style={situation_nalog}>
                                                <div className="situation_title" style={situation_title}>По налоговым делам</div>
                                            </Link>
                                        </div>
                                        <div className="col-md-12">
                                            <Link to="/situation/partfamily/" onClick={()=>{this.newPageLoad("partfamily")}} className="situation_family" style={situation_family}>
                                                <div className="situation_title" style={situation_title}>По гражданским, семейным делам</div>
                                            </Link>
                                        </div>
                                        <div className="col-md-12" style={{clear:"left", height: "45px"}}><hr /></div>
                                        <div className="col-md-12">
                                            <Link to="/is/" className="situation_is_link" style={situation_is_link}>
                                                <div className="situation_title" style={situation_title}>Адвокаты, Юристы, Нотариусы</div>
                                            </Link>
                                        </div>
                                    </div>/**/}
                        </div>
                    </div>
                </>
            );

        } else {
            return (
                <>
                </>
            );
        }
    }
}

export default ResourceViewer;