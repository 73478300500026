import React from 'react';
import "../../global.js";
import { getDateString/*, decodeMessage /**/} from './MessageList/helpers';
import { Link } from 'react-router-dom';
//import { useInView } from "react-intersection-observer";
//const crypto = require('crypto');
let headers = new Headers();
headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));

export class Room extends React.Component {


    /*formatMessagePreview = (args) => {
        let type = args.type;
        let content = args.content;
        if (!content) content = args;
    
        let out = '';
        switch(type) {
            case 'text':
                if (content?.length > 45) {
                    out = content.slice(0, 42).trim() + '...';
                    return out;
                } else {
                    return content;
                }
            case 'image':
                return '[Изображение]';
            case 'video':
                return '[Видео]';
            case 'audio':
                return '[Аудио]';
            case 'call':
                return '[Звонок]';
            case 'file':
                return '[Документ]';
            default:
                if (content?.length > 45) {
                    out = content.slice(0, 42).trim() + '...';
                    return out;
                } else {
                    return content;
                }
        }
    }
    
    getRoomInfo = (roomid) => {
        let QweryLink = global.apiLink + 'api/chat/chat/get-room?id='+roomid;
        fetch(QweryLink, {headers})
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status !== 200) {
                        throw new Error(result.status)
                    }
                    if (result.payload) {
                        let roominfo = result.payload.roominfo;
                        let title = this.formatMessagePreview(roominfo.title);
                        let message = this.formatMessagePreview({
                            type:roominfo.message_type,
                            content:roominfo.message
                        });
                        document.querySelectorAll(
                            "#room_item_-"+roominfo.id+" .room_list_title"
                        )[0].innerHTML = title;
                        if(roominfo.message_type == "text")
                            document.querySelectorAll(
                                "#room_item_-"+roominfo.id+" .room_info .card-body span"
                            )[0].innerHTML = message;
                    }
                }
            )
            .catch(function (e) {
                if (e.message == "401") {
                    window.location.href = '/login';
                }
            });
    }/**/

   /* isBase64 = (str) => {
        if (str === undefined || str ==='' || str.trim() ===''){ return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }/**/

    /*PDec = (text) => {
        let privK = localStorage.getItem('pvs_private_Key');
        //console.log("privK");
        //console.log(privK);
            try {
                text = crypto.privateDecrypt({
                    key: privK,
                    padding: crypto.constants.RSA_PKCS1_PADDING,
                    passphrase: '',
                  }, Buffer.from(text, 'base64')
                );
            } catch (error) {
                console.log(error); 
            }
            return text.toString('utf8');
    }/**/
    
    SetConsulterToRoomHandler(room_id) {
        let url = global.apiLink + 'api/chat/chat/enter-room?room_id='+room_id;

        fetch(url,{ headers:headers })
            .then(response => response.json())
            .then((response)=>{
                if(response.status === 200){
                    alert("Консультант назначен");
                    window.location.href = '/chat/'+room_id;
                }
                else if(response.status === 401){
                    localStorage.setItem('tokenAPI','');
                }
            });
    }

    render() {
        let status = this.props.status;
        if(status === "closed")return (<></>);
        let roomid = this.props.roomid;
        let title = "";//this.props.title;
        let LengthOfMessage = (this.props.LengthOfMessage>45)?42:this.props.LengthOfMessage;
        let LengthOfTitle = (this.props.LengthOfTitle>45)?42:this.props.LengthOfTitle;
        let message = "";//this.props.message;
        let message_type = this.props.message_type;
        let latest_sort = this.props.latest_sort;
        let isAdvocat = this.props.isAdvocat;
        let visitor_id_user = this.props.visitor_id_user;
        let handleRoomChange = this.props.handleRoomChange;
        //let filename = this.props.filename;
        //console.log(this.props);

        /*if(this.isBase64(title)){
            title = this.PDec(title);
        }

        if(this.isBase64(message)){
            message = this.PDec(message);
        }/**/

        
        for(let i = 0; i < LengthOfTitle; i++){title += "_";}
        //title = ReactDOMServer.renderToString(<><span className="textPlaceholder">{title}</span></>);

        let currentRoomId = this.props.currentRoomId;
        let createdon = this.props.createdon;
        let date_createdon = new Date((parseInt(createdon)) * 1000);
        const dateString = getDateString(date_createdon);
        let latest_sortString = new Date((parseInt(latest_sort)) * 1000);
        latest_sortString = getDateString(latest_sortString);
        // let hours   = date_createdon.getUTCHours();
        // let minutes = (date_createdon.getUTCMinutes()<10) ?( "0" + date_createdon.getUTCMinutes() ): date_createdon.getUTCMinutes();
        // let seconds = (date_createdon.getUTCSeconds()<10) ?( "0" + date_createdon.getUTCSeconds() ): date_createdon.getUTCSeconds();
        // let Month   = (((date_createdon.getMonth()+1)<10) ?( "0" + (date_createdon.getMonth()+1) ): (date_createdon.getMonth()+1));
        // let Day     = (date_createdon.getUTCDate()<10) ?( "0" + date_createdon.getUTCDate()) : date_createdon.getUTCDate();
        // let Year    = date_createdon.getFullYear();

        //var formattedTime = Day + '.' + Month + '.' + Year + "г " + hours + ':' + minutes + ':' + seconds;
        
        if(message_type === "text"){
            
            if(LengthOfMessage > 45){
                LengthOfMessage = 45;
                //message = message.slice(0, 45) + " ...";
            }
            for(let i = 0; i < LengthOfMessage; i++){message += "_";}
            //message = ReactDOMServer.renderToString(<><span className="textPlaceholder">{message}</span></>);
        }

        if(message_type === "image"){
            //message = <><img style={{maxWidth: "50px", maxHeight: "50px"}} src={global.apiLink + message} /></>;
            message = <>Картинка</>
        }
        
        if(message_type == "file"){
            //filename = filename?filename:"Скачать";
            //message = <> Файл: <a href={global.apiLink + message} target="_blank">{filename}</a> </>;
            message = <>Файл</>
        }
        if(message_type == "audio"){
            //filename = filename?filename:"Скачать";
            //message = <><audio src={global.apiLink + message} controls style={{ height: "30px", width: "100%", marginBottom: "15px"}} /></>;
            message = <>Аудиофайл</>
        }
        if(message_type == "video"){
            //filename = filename?filename:"Скачать";
            //message = <><audio src={global.apiLink + message} controls style={{ height: "30px", width: "100%", marginBottom: "15px"}} /></>;
            message = <>Видеофайл</>
        }
        if(message_type == "call"){
            message = <>Звонок</>
        }
        let RoomClass = "room_item_"+roomid;
        if(currentRoomId == roomid){
            RoomClass += " current_room";
        }

        let url = '/chat/'+roomid;
        let styleAddon = {borderRadius:"50px"};
        if(isAdvocat === true && visitor_id_user === null){
            url = '#';
            styleAddon.filter = "grayscale(80%)";
            RoomClass += " disabled_room";
            handleRoomChange = () => {}
        }
        //const { ref, inView } = useInView();
        return (
                <div key={roomid} id={"room_item_"+roomid} //onLoad={()=>{this.getRoomInfo(roomid);}} //ref={(e)=>ref(e)} 
                 className={"room_list_item mt-3 "+RoomClass} 
                 data-room-id={roomid}
                 data-status={0}
                 uk-grid="" data-to={url} onClick={handleRoomChange}>
                    <div className="">
                        <div>
                        <div className="row" style={{margin: "0"}}>
                            <div className="col-md-1 interlocutor_avatar">
                                <img className="" style={styleAddon} alt={title} width="50" height="50" src={"/assets/images/NoAvatar.jpg"} />
                            </div>
                            <div className="col-md-11 room_info">
                                <div className="card-title mb-0 room_list_title"><span className="textPlaceholder">{title}</span></div>
                                <div className="card-body mb-0 ">
                                    <span><span className="textPlaceholder">{message}</span></span>
                                    {isAdvocat === true && <>
                                        {visitor_id_user === null && <button type="button" title="Взять обращение в работу" className="btn btn-biryza btn-biryza-mod is_advocat_accept" onClick={()=>{this.SetConsulterToRoomHandler(roomid)}}><i className="fa fa-handshake-o" aria-hidden="true"></i></button>}
                                        {/*visitor_id_user !== null && <i class="fa fa-check-square-o is_advocat_accepted" aria-hidden="true"></i>/**/}
                                    </>}
                                </div>{/**/}
                                <div className="data-createdon" >{dateString}</div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        );

    }

}
export default Room;