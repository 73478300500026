import React from 'react';
import { Link } from 'react-router-dom';

const AlertAccess = () => {
    return (
	<>
        <div className="alert-box_wrapper bd-example-snippet bd-code-snippet text-center">
            <div className="bd-example">
                <div className="alert alert-dismissible fade show" role="alert">
                <h4 className="alert-box_title alert-heading">Хотите получить возможность составить любой документ с помощью конструктора?</h4>
                <p>Полный доступ к списку документов и генерации любого из них по шаблону имеют только авторизированные пользователи.</p>
                <hr/>
                <Link to={"/login"} className="mb-3 btn alert-box_btn-access">Авторизироваться</Link>
                <p className="mb-0" style={{display: "none"}}>Уже зарегистрированы? <Link to={"/auth/"} className="alert-box_link">Авторизируйтесь</Link> для продолжения работы с сервисом.</p>
                </div>
            </div>
        </div>	
	</>
    );
};
export default AlertAccess;