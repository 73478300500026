import React from 'react';
import {Link} from 'react-router-dom';
import BreadCrumbs from "../elements/BreadCrumbs";
import PageTitle from "../elements/PageTitle";
import "../../global.js";
import * as utils from '../../utils.js';

const tokenAPI = localStorage.getItem("tokenAPI");
let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));

export class ResourceViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataResourceQuickstart: '',
            resourceid: 0,
            partTitle: "",
            content: "",
            tags: [],
            htmlTagClouds: "",
            dataResourceTags: '',
            isPremium: Boolean(localStorage.getItem('isPremium')?(JSON.parse(localStorage.getItem('isPremium'))):false),
            isAuth: Boolean((localStorage.getItem('tokenAPI'))?true:false),
        }
    }

    utils = {
        dummy: document.createElement('div'),
        escapeHTML: function (s) {
            return {__html: s};
        }
    }

    createMarkup = (s) => {
        return {__html: s};
    }


    gettingResources = async (pid) => {
        let self = this;
        let output = {};
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + tokenAPI);
        const api_url = await fetch(global.apiLink + `api/qa/qa/list?codex_id=-1&getcontent=1`, {
            headers: headers
        });
        output.quickstart = await api_url.json();
        if (output.quickstart.status !== 200) {
            alert("Ошибка при загрузке данных quickstart");
            return false;
        }
        return output;
    }

    showTagCloud = (json) => {
        let dataResourceTvalue = [];
        if (json !== undefined)
            var tmp = json;//JSON.parse(json);
        for (var dataResourceTVItem in tmp) {
            dataResourceTvalue[dataResourceTVItem] = tmp[dataResourceTVItem];
        }

        return (
            <div>
                {json && dataResourceTvalue.map((answer, i) => {
                        let content = dataResourceTvalue[i].content;
                        let title = dataResourceTvalue[i].title;
                        return (
                            <div key={i}>
                                <div
                                    dangerouslySetInnerHTML={this.createMarkup("<span style='float:left;'>" + (title) + ".&nbsp;</span>" + content)}/>
                                <br/>
                            </div>
                        );
                    }
                )}
            </div>
        );
    }

    getComplete = async () => {
		
        let query = document.querySelector("form input[name=q]").value;
        let target = document.querySelector("input[name=target]").value;		
		

        if (query != null) {
        	query = utils.escapeAndTrim(query);
        }
        console.log('query=' + query);
        if (query.length <= 2) {
        	document.querySelector(".all_search_autocomplete").innerHTML = '';
        	return false;
        }

        if (target != null) {
        	target = utils.escapeAndTrim(target);
        }
        if (!target || !utils.elementInArray(target, ['qa'])) {
        	target = "qa";
        }
		
        console.log('target=' + target);
       //	target = '&target=' + target;

        let url = global.apiLink + '/api/search/complete?q=' + query + '&target=' + target;		
		
        const api_ResourceLL = await
            fetch(url, {
                headers: headers
            });

        var output = await api_ResourceLL.json();

        if (output.status == 200) {
			
        	let list = output.payload.list;
        	let resultComplete = "";
        	for (let i = 0; i < list.length; i++) {
        		let linkTitleTmp = list[i].tags;
        		let linkUrlTmp = '/search/?target=' + target + '&q=' + linkTitleTmp.split(" ").join("+");
        		resultComplete += "<div onclick='window.location.href=\"" + linkUrlTmp + "\";'>" + linkTitleTmp + "</div>";
        	}

        	let curQuery = document.querySelector("form input[name=q]").value;
        	if (curQuery != null) {
        		curQuery = utils.escapeAndTrim(curQuery);
        	}
        	console.log('curQuery=' + curQuery);
        	if (curQuery.length > 2) {
        		document.querySelector(".all_search_autocomplete").innerHTML = resultComplete;
        	} else {
        		document.querySelector(".all_search_autocomplete").innerHTML = '';
        	}			
	
        } else {
            return false;
        }
    }
	
    getCompleteBK = async () => {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let query = document.querySelector(".all-search-input").value;
        let target = document.querySelector("input[name=target]").value;
        if (target) {
            target = '&target='+target;
        }
        let url = global.apiLink + '/api/search/complete?codex_id=-1&q=' + query + target;

        const api_ResourceLL = await fetch(url, { headers: headers });
        var output = await api_ResourceLL.json();

        if (output.status == 200) {
            let list = output.payload.list;
            let resultComplete = "";
            for (let i = 0; i < list.length; i++) {
                resultComplete += "<div>"+list[i].tags+"</div>";
            }



            document.querySelector(".all_search_autocomplete").innerHTML = resultComplete;
            const selectCompleteItem = (completeItem) => {
                document.querySelector(".all-search-input").value = completeItem;
                document.querySelector(".all-search-input").focus();
                setTimeout(function() {
                    document.querySelector(".all_search_autocomplete").innerHTML = '';
                   // document.querySelector("form.uk-search").submit();
                }, 500);
                setTimeout(function() {
                    //document.querySelector(".all_search_autocomplete").innerHTML = '';
                    document.querySelector("form.uk-search").submit();
                }, 1000);
                return true;
            }
            let filterController = document.querySelectorAll(".all_search_autocomplete div");
            if (filterController) {
                for (let i = 0; i < filterController.length; i++) {
                    filterController[i].addEventListener("click", () => selectCompleteItem(filterController[i].innerHTML));
                }
            }
            return true;
        } else {
            //alert("Неверные данные авторизации getComplete");
            return false;
        }
    }

    componentDidMount() {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + tokenAPI);

        let url = global.apiLink + 'api/qa/qa/list?codex_id=-1&getcontent=1';//global.apiLink+'/api/qa/qa/get?id='+resourceId;
        fetch(url, {
            headers: headers
        })
            .then(res => res.json())
            .then((response) => {
                //console.log(response);
                if (response.payload) {
                    this.setState({
                        title: response.payload.title,
                        content: response.payload.content,
                        resourceid: response.payload.id
                    });
                }
            });


        let self = this;
        let part = '';

        self.gettingResources(part).then(output => {

            let htmlTagClouds = '';
            const tags = [];
            const tagsCloud = [];
            const counts = {};
            const counts2 = {};
            const counts3 = {};

            if (output.quickstart.payload.list && output.quickstart.payload.list.nodes) {
                let listNodes = output.quickstart.payload.list.nodes;
                let k = 0;

                for (let i = 0; i < listNodes.length; i++) {
                    tags[i] = listNodes[i].element.tags;

                    const re = /\s*(?:,|;$)\s*/;
                    const nameList = tags[i].split(/,/g);
                    for (let z = 0; z < nameList.length; z++) {
                        nameList[z] = (nameList[z]).replace(/(\r\n|\n|\r\s)/gm, "");
                        if ((nameList[z]).length) {
                            tagsCloud[k++] = nameList[z];
                        }
                    }
                }

                tagsCloud.forEach(function (x) {
                    counts[x] = (counts[x] || 0) + 1;
                });
                console.log(counts);

                tagsCloud.forEach(function(x) {
                    counts2[x] = (counts[x] / 10 < 1) ? counts[x] / 10 + 0.69 + "em" : (counts[x] / 10 >= 2) ? counts[x] / 10 + 1.05 +"em" : counts[x] / 5 + "em";
                });

                tagsCloud.forEach(function(x) {
                    counts3[x] = (counts3[x] || 0) + 1;
                    if (counts3[x] < 2) {
                        htmlTagClouds += '<li style="font-size:' + counts2[x] + ';" data-value="' + x + '">' + x + '</li>';
                    }
                });
                if (htmlTagClouds.length) {
                    htmlTagClouds = htmlTagClouds + '<li class="all-branches" data-value="all-branches">показать все</li>';
                }
                console.log(htmlTagClouds);
            }

            self.setState({
                dataResourceQuickstart: output.quickstart,
                dataResourceQa: output.qa,
                dataResourceTags: htmlTagClouds,
            });

            //console.log(self.state.dataResourceTags);

            //document.querySelector("#tagcloud-list").innerHtml = htmlTagClouds;
            console.log('ready');

            const SelectFilter = (elem) => {
                let filter = {};
                filter.theme = elem.getAttribute("data-for");
                let filteredContent = document.querySelectorAll(".js-filtered-content");
                if (filteredContent) {
                    for (let i = 0; i < filteredContent.length; i++) {
                        if (filteredContent[i].getAttribute("data-content") == filter.theme) {
                            filter.for = filteredContent[i];
                            break;
                        }
                    }
                }

                let filterController = document.querySelectorAll(".js-choice li");
                if (filterController) {
                    for (let i = 0; i < filterController.length; i++) {
                        changeFilterContent(filterController[i], filter.for);
                        filterController[i].addEventListener("click", changeFilterContent.bind(null, filterController[i], filter.for));
                    }
                }
            }

            const changeFilterContent = (controller, filterContent) => {

                let filteredElems = filterContent.querySelectorAll("[data-filter-group]");
                if (filteredElems) {
                    switch (controller.getAttribute("data-value")) {
                        case "all-branches":
                            for (let i = 0; i < filteredElems.length; i++) {
                                if (filteredElems[i].classList.contains("js-content-is-hidden")) {
                                    filteredElems[i].classList.remove("js-content-is-hidden");
                                }
                            }
                            break;
                        default:
                            for (let i = 0; i < filteredElems.length; i++) {
                                let match = false;
                                let filterGroupArr = filteredElems[i].getAttribute("data-filter-group").split(";");
                                for (let i = 0; i < filterGroupArr.length; i++) {
                                    if (filterGroupArr[i] == controller.getAttribute("data-value")) {
                                        match = true;
                                    }
                                }
                                switch (match) {
                                    case true:
                                        if (filteredElems[i].classList.contains("js-content-is-hidden")) {
                                            filteredElems[i].classList.remove("js-content-is-hidden");
                                        }
                                        break;
                                    case false:
                                        if (!filteredElems[i].classList.contains("js-content-is-hidden")) {
                                            filteredElems[i].classList.add("js-content-is-hidden");
                                        }
                                        break;
                                }
                            }
                            break;
                    }
                }
            }
            let filters = document.querySelectorAll(".js-select-filter");
            if (filters) {
                for (let i = 0; i < filters.length; i++) {
                    let filter = new SelectFilter(filters[i]);
                }
            }
        });
    }

    render() {
        const {isPremium, dataResourceQuickstart, dataResourceTags} = this.state;

        document.getElementById("root").style.background = "#fafafb";
        const breadcrumbs = [
            {
                name: 'Главная',
                path: '/',
            },
            {
                name: 'Сервисы',
                path: '/services/',
            },
            {
                name: 'Вопрос-ответ',
                path: '',
            },
        ];

        return (
            <>
                <div className="qa-page page">
                    <div className="container ">
                        <div className="row">
                            <BreadCrumbs crumbs={breadcrumbs}/>
                            <PageTitle pageTitle="Вопрос-ответ"/>
                        </div>
                        <div className="row tagcloud-wrapper js-select-filter" data-for="faqs">
                            <div className="col-md-9">

                                <div className="row g-0">
                                    <div className="all-search-wrapper">
                                        <form className="uk-search" method="GET" action="/search/" style={{display: "block"}}>
                                            <input type="hidden" name="target" value="qa"/>
                                            <label htmlFor="search">
                                                <i className="fa fa-search" aria-hidden="true"></i>
                                                <span className="sr-only">Поиск</span>
                                            </label>
                                            <input type="search" name="q" onChange={() => {
                                                this.getComplete()
                                            }} placeholder="Что делать, если..." autocomplete="off" className="all-search-input" minLength={3} maxLength={255} required="required"/>
                                            <button type="submit" className="search_btn">Найти</button>
                                            <div className="all_search_autocomplete"></div>
                                        </form>
                                    </div>
                                </div>
                                <div className="row g-0 grid_container-faqs faqs__list js-filtered-content" data-content="faqs">
                                    {dataResourceQuickstart && dataResourceQuickstart.payload.list.nodes.map((answer, i) => {
                                        let item_title = answer.element.title.replace(/\s+/g, ' ');
                                        return (
                                            <>
                                                <div className="faq-div faqs__item" key={answer.element.id}
                                                     data-filter-group={"all-branches;" + answer.element.tags}>
                                                    <h4><span>{item_title}</span></h4>
                                                    {isPremium? <>
                                                        <p dangerouslySetInnerHTML={{__html:answer.element.content}}></p>
                                                    </>:<></>}
                                                    <a href={"/qa/" + answer.element.id+ "/"} title={item_title}>Перейти</a>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="col-md-3">

                                <div className="right-nav-page aside-nav" style={{position: "relative", marginBottom: "30px", top: "0"}}><div className="aside-widget"><img src="/assets/images/advocate.svg" alt="Срочная юридическая помощь" className="aside-widget__image2"/><h4 className="aside-widget__title">Нужна срочная помощь?</h4><div className="aside-widget__text pulse-button-wrap"><a href="/call/?do" className="go_to_callpage_btn"><div className="go_to_callpage_title">Позвоните нам</div></a></div></div></div>
					
							<div className="row">
                                <div className="right-nav aside-nav" style={{position: "relative", marginBottom: "25px", border: "0", top: "0"}}>
                                        <div className="tagcloud-wrapper js-select-filter" data-for="faqs">
                                            <ul id="tagcloud-list"
                                                className="tagcloud-wrapper__list js-choice"
                                                autocomplete="off"
                                                dangerouslySetInnerHTML={{__html: this.state.dataResourceTags}}></ul>
                                        </div>
                                </div>
								</div>

                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="qa-search-description"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <br/><br/><br/><br/><br/>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default ResourceViewer;