import React from "react"
import s from './styles.module.css';

const FemidaLoader = ({smol}) => {
    return (
        <div 
        className={`spinner-border ${s.spinner} ${smol ? s.smol : ''}`} 
        role="status">
        </div>
    );
}

export default FemidaLoader;